import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box, Typography, useTheme } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { setSignUpUserdata } from '../../../redux/signUpApi';
import CustomButton from '../../../components/common/customButton';
import { setUserSignIn } from '../../../redux/userSignInAPI';
// import { userAPI } from '../../../redux/userProfileAPI';
import { useGetUser } from '../../../context/userProfile';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';

const ThankYouPage = () => {
  const accessToken = JSON.parse(sessionStorage.getItem('access_token_proxy_session'));
  const theme = useTheme();
  const { logInUserDetails } = useGetUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, setCookies, removeToken] = useCookies(['access_token_proxy']);

  useEffect(() => {
    const getData = async () => {
      if (logInUserDetails?.p_isAccepted === 1) {
        const token = accessToken.token;
        const expiresIn = accessToken.expiresIn;
        setCookies('access_token_proxy', token, {
          path: '/',
          httpOnly: false,
          expires: dayjs().add(expiresIn, 'seconds').toDate(),
          secure: false,
          sameSite: 'strict',
        });
        await dispatch(setSignUpUserdata(accessToken));
        await navigate('/');
      }
    };
    getData();
  }, [dispatch, navigate, setCookies]);

  return (
    <AuthenticationWrapper maxWidth={'817px'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.accent4,
          padding: '48px',
          borderRadius: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Typography variant='h1' sx={{ color: theme.palette.neutral.dark }}>
            Thank You!!
          </Typography>
          <Typography variant='subtitle5' sx={{ color: theme.palette.neutral.normal, textAlign: 'center' }}>
            You’re all set. You’ll receive an email once your account is approved.
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '24px',
            marginBottom: '40px',
            textAlign: 'center',
          }}
        >
          <Typography variant='subtitle4' sx={{ color: theme.palette.neutral.normal }}>
            Your application is now under review. We’ll get back to you soon. If you have any questions,
            please contact
            <Typography variant='subtitle4'>
              <Link to='mailto:prox-e.io@gmail.com' className='email-link-box'>
                support@prox-e.io
              </Link>
            </Typography>
          </Typography>
        </Box>

        <CustomButton
          onClick={async () => {
            if (logInUserDetails?.p_isAccepted === 1) {
              const token = accessToken.token;
              const expiresIn = accessToken.expiresIn;
              setCookies('access_token_proxy', token, {
                path: '/',
                httpOnly: false,
                expires: dayjs().add(expiresIn, 'seconds').toDate(),
                secure: false,
                sameSite: 'strict',
              });
              await dispatch(setSignUpUserdata(accessToken));
              await navigate('/');
            } else {
              sessionStorage.removeItem('access_token_proxy_session');
              await dispatch(setSignUpUserdata({}));
              await dispatch(setUserSignIn({}));
              await removeToken('access_token_proxy', { path: '/' });
              navigate('/login');
            }
          }}
        >
          Continue Sign in
        </CustomButton>
      </Box>
    </AuthenticationWrapper>
  );
};

export default ThankYouPage;
