import React from 'react';
import { Modal, Box } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

const TranzilaPaymentModal = ({ openModal, handleCloseModal, modalContentUrl }) => {
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        '& [tabindex="-1"]': {
          height: '500px',
          margin: 'auto',
          borderRadius: '20px',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          width: {
            xs: '250px',
            sm: '500px',
            md: '508px',
            lg: '508px',
            xl: '900px',
          },
          maxHeight: '100%',
          height: '158px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '20px',
          padding: '35px 35px 0px 35px',
          position: 'relative',
          outline: 'none',
        }}
      >
        <CloseIcon className='closeIconTranzilla' onClick={handleCloseModal} />

        <iframe
          allowpaymentrequest='true'
          title='payment'
          src={modalContentUrl}
          width='100%'
          height='500'
          frameBorder='0'
          allowFullScreen
          className='iframe-payment'
        />
      </Box>
    </Modal>
  );
};

export default TranzilaPaymentModal;
