export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString)
    .toLocaleDateString(undefined, options)
    .replace(/\//g, "-");
};

export const formatMonth = (date) => {
  const day = String(date?.getDate()).padStart(2, "0");
  const month = String(date?.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date?.getFullYear();

  return `${year}-${month}-${day}`;
};
