import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const resetPasswordEmail = createAsyncThunk('send-email', async (data) => {
  try {
    const response = await API.post(`/email-confirmation/send-email`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  resetPasswordEmail: {},
};

const resetPasswordEmailSlice = createSlice({
  name: 'resetPasswordEmail',
  initialState,
  reducers: {
    setResetPasswordEmail: (state, { payload }) => {
      state.resetPasswordEmail = payload;
    },
  },
});

export const { setResetPasswordEmail } = resetPasswordEmailSlice.actions;
export default resetPasswordEmailSlice.reducer;
