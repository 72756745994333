import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const EmailOTPverificationAPI = createAsyncThunk(
  'emilconfirmation-verify-email-otp',
  async (data, thunkAPI) => {
    try {
      const response = await API.post(`/email-confirmation/verify-email-otp`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  emailOTPConfirmation: null,
  error: null,
  status: false,
  isLoading: false,
};

const emailOTPVerificationSlice = createSlice({
  name: 'emilconfirmation-verify-email-otp',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(EmailOTPverificationAPI.fulfilled, (state, action) => {
        state.emailOTPConfirmation = action.payload;
        state.error = null;
        state.status = true;
        state.isLoading = false;
      })
      .addCase(EmailOTPverificationAPI.rejected, (state, action) => {
        state.error = action;
        state.status = false;
        state.isLoading = false;
      })
      .addCase(EmailOTPverificationAPI.pending, (state, action) => {
        state.error = action;
        state.status = true;
        state.isLoading = true;
      });
  },
});

export default emailOTPVerificationSlice.reducer;
