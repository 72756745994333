import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getCutomersubscriptionAPI = createAsyncThunk('subscriptions', async (data) => {
  try {
    const response = await API.get(
      `/users/customer/subscriptions?page=${data?.page}&limit=${data?.limit}&userId=${data?.userId}&planStatus=${data?.planStatus}&ownerId=${data?.ownerId}&search=${data?.search}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

export const updateCustomerPlanStatus = createAsyncThunk(
  'updateCustomer',
  async ({ customerPlanId, data }) => {
    try {
      const response = await API.put(`users/edit/customer/plan/${customerPlanId}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const initialState = {
  subscription: {},
};

const getSubscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptionDetails: (state, { payload }) => {
      state.subscription = payload;
    },
  },
});

export const { setSubscriptionDetails } = getSubscriptionSlice.actions;
export default getSubscriptionSlice.reducer;
