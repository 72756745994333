import { createTheme } from '@mui/material/styles';
import { colors } from './colors';

const getTypography = (theme) => ({
  fontFamily: 'Roboto Flex',
  h1: {
    fontSize: '42px',
    fontWeight: '600',
    lineHeight: '63px',
    '@media screen and (max-width:1440px)': {
      fontSize: '32px',
      lineHeight: '43px',
    },
    '@media screen and (max-width:960px)': {
      fontSize: '28px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '26px',
    },
  },
  h2: {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '42px',
    '@media screen and (max-width:1440px)': {
      fontSize: '26px',
    },
    '@media screen and (max-width:960px)': {
      fontSize: '22px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '20px',
    },
  },
  h3: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '36px',
    '@media screen and (max-width:960px)': {
      fontSize: '22px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '18px',
    },
  },
  h4: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '36px',
    '@media screen and (max-width:960px)': {
      fontSize: '22px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '18px',
    },
  },
  subtitle1: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '27px',
    '@media screen and (max-width:960px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '14px',
    },
  },
  subtitle2: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27px',
    '@media screen and (max-width:960px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '14px',
    },
  },
  subtitle3: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '30px',
    '@media screen and (max-width:960px)': {
      fontSize: '18px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '16px',
    },
  },
  subtitle4: {
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '30px',
    '@media screen and (max-width:960px)': {
      fontSize: '18px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '16px',
    },
  },
  subtitle5: {
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '36px',
    '@media screen and (max-width:1440px)': {
      fontSize: '22px',
    },
    '@media screen and (max-width:960px)': {
      fontSize: '20px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '18px',
    },
  },
  subtitle6: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '27px',
    '@media screen and (max-width:960px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '14px',
    },
  },
  tertiary1: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    '@media screen and (max-width:960px)': {
      fontSize: '14px',
    },
  },
  tertiary2: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    '@media screen and (max-width:960px)': {
      fontSize: '14px',
    },
  },
  body1: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '21px',
  },
  body3: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
  },
  body4: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
  },
  primarybutton: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '30px',
    '@media screen and (max-width:960px)': {
      fontSize: '18px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '16px',
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#364866',
    },
    secondary: {
      main: '#D40955',
    },
    accent1: '#FD337E',
    accent2: '#DD7396',
    accent3: '#FFF2F6',
    accent4: '#FFE5ED',
    danger: '#FF0000',
    neutral: {
      dark: '#242323',
      normal: '#686465',
      light: '#8E8A8B',
      lighter: '#CDCBCC',
      lightest: '#F5F5F5',
      background: '#F5F7FA',
      white: '#FFFFFF',
    },
    linkColor: '#2974EF',
  },
  typography: (theme) => getTypography(theme),
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        outlined: ({ theme }) => ({
          boxShadow: 'none',
          fontSize: '14px',
          fontWeight: '500',
          alignItems: 'center',
          color: colors.theme,
          border: `1px solid ${colors.theme}`,
          background: colors.basics.white,
          textTransform: 'capitalize',
          '&:hover': {
            background: colors.theme,
            border: `1px solid ${colors.theme}`,
            color: colors.basics.white,
          },
          '&.Mui-disabled': {
            color: colors.background.formBg,
            border: `1px solid ${colors.input.border}`,
            background: colors.input.border,
            pointerEvents: 'all',
          },
        }),
        filled: ({ theme }) => ({
          color: colors.basics.white,
          border: `1px solid ${colors.theme}`,
          background: colors.theme,
          '&.Mui-disabled': {
            color: colors.background.formBg,
            border: `1px solid ${colors.input.border}`,
            background: colors.input.border,
            pointerEvents: 'all',
          },
          '&:hover': {
            color: colors.theme,
            background: colors.basics.white,
          },
        }),
        grayOutlined: ({ theme }) => ({
          color: colors.basics.normal,
          border: `1px solid ${colors.basics.normal}`,
          background: colors.basics.white,
          '&.Mui-disabled': {
            // color: colors.background.formBg,
            // border: `1px solid ${colors.input.border}`,
            // background: colors.input.border,
            pointerEvents: 'all',
            opacity: '0.5',
          },
          '&:hover': {
            color: colors.theme,
            borderColor: colors.theme,
            background: colors.basics.white,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        // root: {
        //   boxSizing: 'border-box',
        //   borderRadius: 8,
        //   textTransform: 'capitalize',
        //   width: '100%',
        // },
        '& input::placeholder': {
          fontStyle: 'normal',
          fontWeight: 500,
          // fontSize: 18,
          lineHeight: '180%',
          alignItems: 'center',
          opacity: 1,
        },
        root: ({ theme }) => ({
          boxSizing: 'border-box',
          width: '100%',
          '& .MuiInputBase-root:hover': {
            '& fieldset': {
              borderColor: colors.theme,
            },
          },
          '& fieldset': {
            borderRadius: 8,
            transition: 'all .5s ease',
          },
          '& input': {
            // border: '1px solid #CDCBCC',
            borderRadius: 8,
            fontSize: '18px',
            padding: '14px',
            '@media screen and (max-width:1440px)': {
              fontSize: '16px',
            },
            '@media screen and (max-width:960px)': {
              fontSize: '14px',
            },
            height: 'auto',
            // textTransform: "capitalize",
            backgroundColor: colors.basics.white,
          },
          '& .Mui-focused fieldset': {
            borderColor: '#FD337E !important',
            borderWidth: '1px !important',
          },
          '& input::placeholder': {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 'inherit',
            color: colors.neutral.light,
            opacity: 1,
          },
        }),
        abc: {
          fontFamily: 'Poppins',
          border: '1px solid #CDCBCC',
          borderRadius: '8px',
          backgroundColor: 'lightblue',
          padding: '14px',
          fontSize: '18px',
          fontWeight: '400',
          lineHeight: '27px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          position: 'relative',
          width: '100%',
          backgroundColor: colors.basics.white,
          border: '0px',
          borderRadius: '8px',
          fontSize: '18px',
          '@media screen and (max-width:1440px)': {
            fontSize: '16px',
          },
          '@media screen and (max-width:960px)': {
            fontSize: '14px',
          },
          '& .MuiSelect-select ': {
            padding: '14px',
            minHeight: 'auto',
            paddingRight: '29px !important',
          },
          '& fieldset': {
            transition: 'all .5s ease',
          },
          '&.MuiInputBase-root:hover fieldset': {
            // '& fieldset': {
            borderColor: colors.theme,
            // }
          },
          '& .MuiSelect-icon': {
            display: 'none',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#FD337E !important',
            borderWidth: '1px !important',
          },
          '& em ': {
            color: colors.input.placeholder,
            fontStyle: 'normal',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

export default theme;
