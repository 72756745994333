import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCookie } from '../utils/getCookie';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { setUserprofile, userAPI } from '../redux/userProfileAPI';
import { setSignAdmin, setSignUpUserdata } from '../redux/signUpApi';
import { setUserSignIn } from '../redux/userSignInAPI';

const getUserContext = createContext();

export function GetUserContextProvider({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const adminPath = currentPath.match(/^\/admin\b/);
  const [_, __, removeToken] = useCookies(['access_token_proxy']);
  const [logInUserDetails, setLogInUserDetails] = useState({});
  const token = getCookie('access_token_proxy');
  const admintoken = getCookie('admin_access_token_proxy');

  useEffect(() => {
    if (token || admintoken) { // also consider admintoken saved in cookies
      const getUserAPI = async () => {
        const data = await dispatch(userAPI());
        if (
          data?.payload?.response?.data?.message === 'User with this id does not exist' ||
          data?.payload?.response?.data?.message === 'Unauthorized' ||
          data?.payload?.message === 'Network Error'
        ) {
          await sessionStorage.removeItem('access_token_proxy_session');
          await localStorage.removeItem('persist:root');
          await dispatch(setSignUpUserdata({}));
          await dispatch(setSignAdmin({}));
          await dispatch(setUserSignIn({}));
          await dispatch(setUserprofile({}));
          // await removeToken('access_token_proxy', { path: '/' });
          await removeToken(
            adminPath && adminPath[0]
              ? "admin_access_token_proxy"
              : "access_token_proxy",
            { path: "/" }
          );
          if (adminPath ? adminPath[0] : null) {
            navigate('/admin/login');
          } else {
            navigate('/login');
          }
        } else if (data?.payload.response?.status === 404) {
          toast.error(data?.payload.response.data.error);
        } else {
          setLogInUserDetails(data?.payload);
          await dispatch(setUserprofile(data?.payload));
        }
      };
      getUserAPI();
    }
  }, [dispatch]);

  return (
    <getUserContext.Provider
      value={{
        token: token,
        setLogInUserDetails,
        logInUserDetails,
      }}
    >
      {children}
    </getUserContext.Provider>
  );
}

export function useGetUser() {
  return useContext(getUserContext);
}
