import React, { useEffect, useState } from 'react';
import CustomButton from '../../../components/common/customButton';
import { ColorRing } from 'react-loader-spinner';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { Box, Typography, useTheme } from '@mui/material';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';
import { Images } from '../../../assets/images';
import { useGetUser } from '../../../context/userProfile';
import { useDispatch, useSelector } from 'react-redux';
import { EmailOTPverificationAPI } from '../../../redux/emailVerificationOtpAPI';
import { toast } from 'react-toastify';
import { EmailconfirmationAPI } from '../../../redux/emailConfiramationAPI';
import { useNavigate } from 'react-router-dom';
import KycModal from '../KycForm/kycFormModal';
import { userAPI } from '../../../redux/userProfileAPI';
import { setPhoneNumberState } from '../../../redux/signUpApi';

const EmailVerification = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.EmailOTPverificationReducer);
  const { loggedInEmailVerification } = useSelector((state) => state?.registerReducer);
  const dispatch = useDispatch();
  const { logInUserDetails, setLogInUserDetails } = useGetUser();
  const [otp, setOtp] = useState();
  const [accountInfoModal, setAccountInfoModal] = useState(false);
  const [errorMessage, setErrorMessgae] = useState('');

  const renderButton = (buttonProps) => {
    return (
      <>
        <Box sx={{ order: 1 }}>
          <span className="receve-otp">Didn't you receive the OTP?</span>
          <button
            {...buttonProps}
            sx={{ '&:disabled': { cursor: 'not-allowed' }, cursor: 'pointer', fontWeight: 500 }}
          >
            Resend OTP
          </button>
        </Box>
      </>
    );
  };
  useEffect(() => {
    renderTime();
  }, []);

  const renderTime = (remainingTime) => {
    const formattedTime = remainingTime > 9 ? String(remainingTime) : '0' + remainingTime;
    return (
      <Box sx={{ 'text-primary font-RobotoRegular': true, order: 2, fontWeight: '500' }}>
        00:{formattedTime}
      </Box>
    );
  };

  const handleOTP = async (e) => {
    setOtp('');
    try {
      if (otp === undefined) {
        setErrorMessgae('Please enter OTP');
      } else {
        const otpObject = {
          otp: otp,
        };
        const payload = await dispatch(EmailOTPverificationAPI(otpObject));
        const data = await dispatch(userAPI());
        setLogInUserDetails(data?.payload);
        if (payload?.payload?.status === true) {
          toast.success(payload?.payload?.message);
          if (data?.payload?.p_isPhoneNumberConfirmed === false) {
            await navigate('/verifycontact-number');
            dispatch(setPhoneNumberState(0));
          } else if (data?.payload?.p_isPhoneNumberConfirmed === true) {
            await navigate('/');
          } else if (data?.payload?.iskyc === 0) {
            setAccountInfoModal(true);
          }
        } else if (data?.payload?.p_isEmailConfirmed === true) {
          await toast.success('Your email already verified');
        } else if (payload?.payload?.status === false) {
          toast.error(payload?.payload?.message);
        }
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const resendOTP = async () => {
    const emailObject = {
      p_type: 4,
      p_contact_email: loggedInEmailVerification?.p_contact_email,
      isCheckTime: 1,
    };
    const data = await dispatch(EmailconfirmationAPI(emailObject));
    if (data?.payload?.status === true) {
      toast.success(data?.payload?.message);
      await navigate('/emailverification');
    } else {
      toast.error(data?.payload?.message);
    }
  };

  const handleUpdateEmailForm = async () => {
    if (logInUserDetails?.p_isEmailConfirmed === false) {
      await navigate('/editemailverification');
    } else if (logInUserDetails?.p_isEmailConfirmed === true) {
      await toast.success('Your email already verified');
    }
  };

  return (
    <AuthenticationWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        <img
          src={Images.EmailVerifyIcon}
          height="350px"
          width="350px"
          style={{ textAlign: 'center' }}
          alt="OTPVerification"
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant="h1" sx={{ color: theme.palette.neutral.dark }}>
            Email Verification
          </Typography>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px',
              flexDirection: { sm: 'row', xs: 'column' },
            }}
          >
            <Typography variant="subtitle5" sx={{ color: theme.palette.neutral.normal }}>
              Enter the verification code we sent to
            </Typography>
            <>
              <Typography variant="h3" sx={{ color: theme.palette.neutral.dark, textAlign: 'center' }}>
                <span variant="h3" style={{ color: theme.palette.neutral.dark }}>
                  {loggedInEmailVerification?.p_contact_email}
                </span>
              </Typography>
            </>
          </Box>
        </Box>
        <form
          style={{ width: '100%' }}
          onSubmit={(e) => {
            e.preventDefault();
            handleOTP();
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleOTP();
            }
          }}
        >
          <>
            <Box>
              <Typography className="subtitle-otp">Type 6 digit security code</Typography>
              <Box
                sx={{
                  alignItems: 'center',
                  gap: '20px',
                  '& .otp-wrapper': {
                    display: 'grid !important',
                    gap: { md: '30px', xs: '15px' },
                    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                    '& input': {
                      maxWidth: { xs: '46px', md: '92px' },
                      width: '100% !important',
                      color: `${theme.palette.neutral.normal} !important`,
                      marginRight: '0 !important',
                      fontSize: { md: '36px', xs: '25px' },
                      borderRadius: '5px',
                      border: '1px solid #FD337E',
                      height: 'auto',
                      minHeight: { md: '56px', xs: '50px' },
                    },
                  },
                  '& button': {
                    color: '#2974EF',
                    background: 'none',
                    border: 'none',
                    fontSize: '18px',
                    fontWeight: 700,
                    cursor: 'pointer',
                    '&:disabled': {
                      color: '#ddd',
                      cursor: 'not-allowed',
                    },
                    '& .resend-otp': {
                      justifyContent: 'center !important',
                      alignItems: 'center !important',
                    },
                  },
                }}
              >
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  autoFocus
                  className="otp-wrapper"
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
                {errorMessage && (
                  <Typography sx={{ color: theme.palette?.danger, marginTop: '8px' }}>
                    {errorMessage}
                  </Typography>
                )}
                <ResendOTP
                  renderButton={renderButton}
                  renderTime={renderTime}
                  onResendClick={resendOTP}
                  maxTime={30}
                  className="resend-otp"
                />
              </Box>
              <Typography className="contact-link">
                Your email is wrong? Please update &nbsp;
                <span className="contact-sub-link" onClick={handleUpdateEmailForm}>
                  Email
                </span>
              </Typography>
            </Box>

            <Box sx={{ marginTop: '15px' }}>
              <CustomButton type="submit" disabled={isLoading}>
                Verify & Process
                {isLoading && (
                  <ColorRing
                    visible={true}
                    height="30"
                    width="30"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                  />
                )}
              </CustomButton>
            </Box>
          </>
        </form>
      </Box>

      <Box>
        <KycModal accountInfoModal={accountInfoModal} setAccountInfoModal={setAccountInfoModal} />
      </Box>
    </AuthenticationWrapper>
  );
};

export default EmailVerification;
