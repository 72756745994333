import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Grid,
  RadioGroup,
  TextField,
  FormControlLabel,
  Button,
  Modal,
  Radio,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
} from '@mui/material';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { colors } from '../../../theme/colors';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import Calendar from '../../../assets/images/Calendar.svg';
import { addPlanAPI } from '../../../redux/addPlanAPI';
import { getCustomerAPI } from '../../../redux/getCustomerAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUser } from '../../../context/userProfile';
import { editPlanWiseCustomerAPI } from '../../../redux/editPlanCustomerAPI';
import { ColorRing } from 'react-loader-spinner';

const AddPlanModal = ({
  setIsAddModal,
  addIsModal,
  getPlanData,
  proxySelect,
  viewUserDetails,
  editPlanData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { logInUserDetails } = useGetUser();
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [addPlanLoading, setAddPlanLoading] = useState(false);
  const defaultCustomerIds = editPlanData?.customers?.map((customer) => customer.p_customer_id) || [];
  const defaultCustomers = customerData?.filter((customer) =>
    defaultCustomerIds?.includes(customer?.p_customer_id)
  );
  const [addplanData, setAddPlanData] = useState({
    promoCode: '',
    productType: '',
    name: '',
    volume: '',
    monthlyPrice: '',
    unitPrice: '',
    chargesType: 1,
    duration: 30,
    description: '',
    emailAndChatSupport: 1,
    customer: [],
    isRollover: editPlanData?.p_isRollover || 0,
  });

  useEffect(() => {
    setAddPlanData({
      ...addplanData,
      promoCode: editPlanData?.p_promoCode || '',
      productType: editPlanData?.p_product_id || '',
      name: editPlanData?.p_name || '',
      volume: editPlanData?.p_volume || '',
      monthlyPrice: editPlanData?.p_price || '',
      unitPrice: editPlanData?.p_unitPrice || '',
      chargesType: editPlanData?.p_chargesType || 1,
      isRollover: editPlanData?.p_isRollover || '',
      duration: editPlanData?.p_duration || 30,
      description: editPlanData?.p_description || '',
      emailAndChatSupport: editPlanData?.p_emailAndChatSupport || 1,
      customer: proxySelect?.customerId
        ? [proxySelect?.customerId]
        : [] || defaultCustomerIds || defaultCustomers || [],
    });
  }, [editPlanData]);

  const memoizedSetAddPlanData = useCallback((newData) => {
    setAddPlanData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  }, []);

  const ProductTypeArray = [
    {
      key: '1',
      planName: 'Datacenter Proxy',
    },
    {
      key: '2',
      planName: 'Residential Proxy',
    },
    {
      key: '3',
      planName: 'Mobile Proxy',
    },
    {
      key: '5',
      planName: 'Trial Proxy',
    },
  ];

  const chargeType = [
    {
      key: 1,
      chargeTypeName: 'Bandwidth',
    },
  ];

  const validationSchema = yup.object().shape({
    productType: yup.number().required('Product Type is required').typeError('Product Type is required'),
    name: yup
      .string()
      .min(3, 'Name is too short, minimum length is 3 characters')
      .max(20, 'Name is too long, maximum length is 20 characters')
      .required('Name is required'),
    volume: yup.number().typeError('Volume must be a number').required('Volume is required'),
    unitPrice: yup.number().typeError('Unit Price must be a number').required('Unit Price is required'),
    chargesType: yup.number().typeError('Charges Type is required').required('Charges Type is required'),
  });

  useEffect(() => {
    const getCustomerList = async () => {
      const payload = await dispatch(getCustomerAPI());
      setCustomerData(payload?.payload);
    };
    getCustomerList();
  }, [dispatch]);

  useEffect(() => {
    const volume = parseFloat(addplanData.volume) || 0;
    const unitPrice = parseFloat(addplanData.unitPrice) || 0;
    const calculatedTotalPrice = volume * unitPrice;
    const newTotalPrice = isNaN(calculatedTotalPrice) ? '' : calculatedTotalPrice;

    if (newTotalPrice !== addplanData.monthlyPrice) {
      setAddPlanData((prevData) => ({
        ...prevData,
        monthlyPrice: newTotalPrice,
      }));
    }
  }, [addplanData.volume, addplanData.unitPrice]);

  const handleAddPlan = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    if (addplanData?.duration <= 0) {
      setErrorMessage('Duration must be greater than 0');
    } else {
      try {
        setAddPlanLoading(true);
        setFormErrors({});
        await validationSchema.validate(addplanData, { abortEarly: false });
        const plandataPayload = {
          p_plan_id: editPlanData?.p_plan_id,
          p_isRollover: addplanData?.isRollover === 0 ? false : true,
          p_customer_id: addplanData?.customer || [],
          p_promoCode: addplanData?.promoCode,
          p_product_id: addplanData?.productType,
          p_name: addplanData?.name,
          p_volume: addplanData?.volume,
          p_price: addplanData?.monthlyPrice,
          p_unitPrice: addplanData?.unitPrice,
          p_chargesType: addplanData?.chargesType,
          p_duration: addplanData?.customer.length === 0 ? 30 : addplanData?.duration || 30,
          p_description: addplanData?.description || '',
          p_emailAndChatSupport: addplanData?.emailAndChatSupport,
          p_created_by: logInUserDetails?.p_ownerId,
        };
        if (!editPlanData) {
          const payload = await dispatch(addPlanAPI(plandataPayload));

          if (payload.payload?.status === true) {
            toast.success(payload?.payload?.message);
            setAddPlanLoading(false);
            if (proxySelect?.customerId) {
              setIsAddModal(false);
              navigate(`/admin/customer-details/customer-plan/${id}`);
              viewUserDetails();
            } else {
              setIsAddModal(false);
            }
            memoizedSetAddPlanData();
          } else {
            toast.error(payload.payload?.response?.data?.message);
          }
        } else {
          const data = await dispatch(editPlanWiseCustomerAPI(plandataPayload));
          if (data?.payload?.status === true) {
            setAddPlanLoading(false);
            toast.success(data?.payload?.message);
            setIsAddModal(false);
          } else {
            toast.error(data?.payload?.response?.data?.message);
            setIsAddModal(false);
          }
        }
        getPlanData();
      } catch (err) {
        const errors = {};
        setAddPlanLoading(false);
        if (err.inner && err.inner.length > 0) {
          err.inner.forEach((e) => {
            errors[e.path] = e.message;
          });
        } else {
          console.error(err);
        }

        setFormErrors(errors);
      }
    }
  };

  return (
    <>
      <Modal
        open={addIsModal}
        onClose={() => {
          setAddPlanData({
            ...addplanData,
            promoCode: editPlanData?.p_promoCode || '',
            productType: editPlanData?.p_product_id || '',
            name: editPlanData?.p_name || '',
            volume: editPlanData?.p_volume || '',
            monthlyPrice: editPlanData?.p_price || '',
            unitPrice: editPlanData?.p_unitPrice || '',
            chargesType: editPlanData?.p_chargesType || 1,
            isRollover: editPlanData?.p_isRollover || '',
            duration: editPlanData?.p_duration || 30,
            description: editPlanData?.p_description || '',
            emailAndChatSupport: editPlanData?.p_emailAndChatSupport || 1,
            customer: defaultCustomerIds || defaultCustomers || [],
          });
          setFormErrors({});
          setErrorMessage('');
          setIsAddModal(false);
        }}
        className="add-plan-modal"
        aria-labelledby="add-plan-modal"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: { sm: '30px', xs: '15px' },
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.basics.white,
            padding: { md: '24px', xs: '15px' },
            borderRadius: '16px',
            maxWidth: { md: '688px', xs: '90%' },
            width: '100%',
            height: 'auto',
            margin: 'auto',
            outline: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}
          >
            <Typography variant="h2" color={colors?.neutral?.dark}>
              {!editPlanData ? 'Add Plan' : 'Edit Plan'}
            </Typography>
            <Box
              onClick={() => {
                memoizedSetAddPlanData();
                setFormErrors({});
                setErrorMessage('');
                setIsAddModal(false);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <CloseIcon />
            </Box>
          </Box>
          <form action="" onSubmit={handleAddPlan}>
            <Grid
              container
              rowSpacing={'25px'}
              columnSpacing={{ md: '10px' }}
              sx={{
                '& fieldset': {
                  borderRadius: '6px',
                },
                '& label': {
                  display: 'block',
                  mb: '10px',
                  fontSize: '16px',
                },
                '& .MuiInputBase-input': {
                  fontWeight: '400',
                },
                '& .react-datepicker-wrapper': {
                  width: '100%',
                  '& svg': {
                    display: 'none',
                  },
                  '& input': {
                    padding: '17px 14px',
                    paddingRight: '46px',
                    fontSize: '18px',
                    width: '-webkit-fill-available',
                    borderRadius: '6px',
                    border: `1px solid ${colors.input.border}`,
                    backgroundColor: colors.basics.white,
                    backgroundImage: `url("${Calendar}")`,
                    backgroundPosition: 'right 14px center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '26px 26px',
                    transition: 'all .5s ease',
                    '&:hover, &:focus-visible': {
                      border: `1px solid ${colors.theme}`,
                      outline: 'none',
                    },
                  },
                },
              }}
            >
              <Grid item xs={12}>
                <Box className="add-plan" sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                  <Typography variant="h4" sx={{ color: colors.neutral.light, fontWeight: 500 }}>
                    Rollover :
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <RadioGroup
                      row
                      name="addplan"
                      defaultValue={(editPlanData?.p_isRollover && String(editPlanData?.p_isRollover)) || '0'}
                      sx={{
                        '& .MuiRadio-root': {
                          padding: '0',
                          marginRight: '8px',
                          marginTop: '-4px',
                        },
                        '& .MuiFormControlLabel-root ': {
                          ml: '0',
                        },
                        '& .MuiFormControlLabel-label': {
                          fontSize: '20px',
                        },
                        '& span svg[data-testid="RadioButtonCheckedIcon"] ': {
                          fill: colors.theme,
                        },
                        '& .Mui-checked input + span svg:last-child': {
                          fill: colors.theme,
                        },
                        '& .Mui-checked input + span svg:first-child': {
                          fill: colors.basics.normal,
                        },
                      }}
                      onChange={(e) => {
                        setAddPlanData({ ...addplanData, isRollover: Number(e.target.value) });
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                        sx={{
                          color: colors?.basics?.normal,
                          display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                          marginBottom: { xs: '5px', sm: '5px', lg: '0' },
                        }}
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                        sx={{
                          color: colors?.basics?.normal,
                          display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                          marginBottom: { xs: '5px', sm: '5px', lg: '0' },
                        }}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="form-control">
                  <TextField
                    id="promocode"
                    placeholder="Promo code"
                    value={addplanData?.promoCode}
                    onChange={(e) => {
                      setAddPlanData({ ...addplanData, promoCode: e.target.value });
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="form-control">
                  <TextField
                    id="planName"
                    placeholder="Plan name"
                    value={addplanData?.name}
                    onChange={(e) => {
                      setAddPlanData({ ...addplanData, name: e.target.value });
                      setFormErrors({ ...formErrors, name: '' });
                    }}
                  />
                </Box>
                {formErrors?.name && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {formErrors?.name}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Select
                  id="producttype"
                  value={addplanData?.productType}
                  onChange={(e) => {
                    setAddPlanData({ ...addplanData, productType: Number(e.target.value) });
                    setFormErrors({ ...formErrors, productType: '' });
                  }}
                  displayEmpty
                  sx={{
                    '& svg': {
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translateY(-50%) rotate(0deg)',
                      transition: 'all .5s ease',
                    },
                  }}
                >
                  {!addplanData.productType && (
                    <MenuItem
                      value=""
                      sx={{
                        textTransform: 'capitalize',
                      }}
                      disabled
                    >
                      <em>Product type</em>
                    </MenuItem>
                  )}

                  {ProductTypeArray?.map((plan, index) => (
                    <MenuItem value={plan?.key} key={index} className="filter-box">
                      {plan?.planName}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors?.productType && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {formErrors?.productType}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  id="customer"
                  name="customer"
                  multiple
                  options={customerData}
                  disabled={proxySelect?.customerId}
                  getOptionLabel={(option) => option?.p_login}
                  onChange={(e, selectedOptions) => {
                    const selectedValues = selectedOptions?.map((option) => option?.p_customer_id);
                    setAddPlanData({
                      ...addplanData,
                      customer: selectedValues,
                    });
                  }}
                  getOptionDisabled={(option, index) => {
                    return addplanData.customer?.includes(option?.p_customer_id);
                  }}
                  defaultValue={
                    proxySelect?.customerId
                      ? [{ p_customer_id: proxySelect?.customerId, p_login: proxySelect?.p_login }]
                      : defaultCustomers
                  }
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                    height: '100%',
                    textTransform: 'capitalize',
                    '& .MuiPopover-paper': {
                      backdropFilter: 'blur(5px)',
                      border: '1px solid rgba(0, 0, 0, 0.2)',
                      borderRadius: '4px',
                      width: '300px',
                      maxHeight: '300px',
                    },
                    '& .MuiOutlinedInput-root ': {
                      borderRadius: '10px',
                      height: '100%',
                      padding: '6px',
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      paddingRight: '0px !important',
                    },
                    '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                      display: proxySelect?.customerId ? 'none' : 'block',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        width: '300px',
                      },
                    },
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip label={option?.p_login} {...getTagProps({ index })} />)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={
                        addplanData?.customer?.length || proxySelect?.customerId ? '' : 'Select Customer'
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  id="chargetype"
                  disabled
                  value={addplanData?.chargesType}
                  onChange={(e) => {
                    setAddPlanData({ ...addplanData, chargesType: Number(e.target.value) });
                    setFormErrors({ ...formErrors, chargesType: '' });
                  }}
                  displayEmpty
                  sx={{
                    '& svg': {
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translateY(-50%) rotate(0deg)',
                      transition: 'all .5s ease',
                    },
                  }}
                >
                  {!addplanData?.chargesType && (
                    <MenuItem
                      value=""
                      sx={{
                        textTransform: 'capitalize',
                      }}
                      disabled
                    >
                      <em>Charge type</em>
                    </MenuItem>
                  )}
                  {chargeType?.map((chargeName, index) => (
                    <MenuItem value={chargeName?.key} key={index} className="filter-box">
                      {chargeName?.chargeTypeName}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors?.chargesType && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {formErrors?.chargesType}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box className="form-control">
                  <TextField
                    id="duration"
                    placeholder="Add duration"
                    type="text"
                    value={
                      !proxySelect?.customerId && addplanData?.customer.length === 0
                        ? 30
                        : addplanData?.duration
                    }
                    // value={addplanData?.duration}
                    onChange={(e) => {
                      let value = e.target.value.replace(/\D/, '');
                      if (value <= 0) {
                        value = '';
                        setFormErrors({ ...formErrors, duration: 'Duration must be greater than 0.' });
                      } else if (value >= 1000) {
                        value = 1000;
                        setFormErrors({ ...formErrors, duration: 'Duration cannot exceed 1000.' });
                      } else if (value > 1000) {
                        value = 30;
                        setFormErrors({ ...formErrors, duration: '' });
                        setErrorMessage('');
                      } else {
                        setFormErrors({ ...formErrors, duration: '' });
                        setErrorMessage('');
                      }
                      setAddPlanData({ ...addplanData, duration: Number(value) });
                      setFormErrors({});
                      setErrorMessage('');
                    }}
                    disabled={!proxySelect?.customerId && addplanData?.customer.length === 0}
                  />
                  {errorMessage && (
                    <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                      {errorMessage}
                    </Typography>
                  )}
                  {formErrors?.duration && (
                    <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                      {formErrors?.duration}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item md="6" xs="12">
                <TextField
                  id="volume"
                  placeholder="Volume"
                  value={addplanData?.volume}
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/, '');

                    // if (value >= 1000) {
                    //   value = 1000;
                    //   setFormErrors({ ...formErrors, volume: 'Volume cannot exceed 1000.' });
                    // } else {
                    //   setFormErrors({ ...formErrors, volume: '' });
                    // }
                    setAddPlanData({ ...addplanData, volume: Number(value) });
                  }}
                />
                {formErrors?.volume && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {formErrors?.volume}
                  </Typography>
                )}
              </Grid>
              <Grid item md="6" xs="12">
                <TextField
                  type="number"
                  id="unitprice"
                  placeholder="Unit Price"
                  value={addplanData?.unitPrice}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^0-9.]/g, '');
                    value = value.replace(/^(\d*\.\d{0,4}).*$/, '$1');

                    if (value === '' || value === '.') {
                      setAddPlanData({ ...addplanData, unitPrice: '' });
                    } else {
                      value = parseFloat(value);

                      if (isNaN(value)) {
                        setFormErrors({ ...formErrors, unitPrice: 'Please enter a valid number.' });
                      } else if (value > 1000) {
                        value = 1000;
                        setFormErrors({ ...formErrors, unitPrice: 'UnitPrice cannot exceed 1000.' });
                      } else {
                        setFormErrors({ ...formErrors, unitPrice: '' });
                      }
                      setAddPlanData({ ...addplanData, unitPrice: value });
                    }
                  }}
                />
                {formErrors?.unitPrice && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {formErrors?.unitPrice}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box className="form-control">
                  <TextField
                    id="monthlyPrice"
                    disabled
                    value={(+addplanData?.monthlyPrice).toFixed(2)}
                    placeholder="Total Price"
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="form-control">
                  <TextField
                    id="description"
                    placeholder="Plan Description"
                    value={addplanData?.description ? addplanData?.description : ''}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.length > 100) {
                        value = value.slice(0, 100);
                        setFormErrors({
                          ...formErrors,
                          description: 'Description cannot exceed 100 characters.',
                        });
                      } else {
                        setFormErrors({ ...formErrors, description: '' });
                      }
                      setAddPlanData({ ...addplanData, description: value });
                    }}
                  />
                </Box>
                {formErrors?.description && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {formErrors?.description}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box className="add-plan" sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                  <Box>
                    <RadioGroup
                      row
                      name="emailAndChatSupport"
                      defaultValue={
                        editPlanData?.p_emailAndChatSupport
                          ? editPlanData.p_emailAndChatSupport.toString()
                          : '1'
                      }
                      sx={{
                        '& .MuiRadio-root': {
                          padding: '0',
                          marginRight: '8px',
                          marginTop: '-4px',
                        },
                        '& .MuiFormControlLabel-root ': {
                          ml: '0',
                        },
                        '& .MuiFormControlLabel-label': {
                          fontSize: '20px',
                        },
                        '& span svg[data-testid="RadioButtonCheckedIcon"] ': {
                          fill: colors.theme,
                        },
                        '& .Mui-checked input + span svg:last-child': {
                          fill: colors.theme,
                        },
                        '& .Mui-checked input + span svg:first-child': {
                          fill: colors.basics.normal,
                        },
                      }}
                      onChange={(e) => {
                        setAddPlanData({ ...addplanData, emailAndChatSupport: Number(e.target.value) });
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Email Support"
                        sx={{
                          color: colors?.basics?.normal,
                          display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                          marginBottom: { xs: '5px', sm: '5px', lg: '0' },
                        }}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Email Support With Chat"
                        sx={{
                          color: colors?.basics?.normal,
                          display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                          marginBottom: { xs: '5px', sm: '5px', lg: '0' },
                        }}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="filled"
                  disabled={addPlanLoading}
                  sx={{
                    width: '100%',
                    fontSize: { xl: '20px' },
                    textTransform: 'capitalize',
                  }}
                >
                  Save
                  {addPlanLoading && (
                    <ColorRing
                      visible={true}
                      height="30"
                      width="30"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddPlanModal;
