import React from 'react';
import { Box } from '@mui/system';
import { ThreeDots } from 'react-loader-spinner';
import { colors } from '../../theme/colors';
const Loader = () => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: '70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1000',
      }}
    >
      <ThreeDots
        visible={true}
        height='80'
        width='80'
        color={colors.theme}
        radius='9'
        ariaLabel='three-dots-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
    </Box>
  );
};

export default Loader;
