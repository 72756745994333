import React, { useEffect, useState } from 'react';
import { colors } from '../../../theme/colors';
import { Box, Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';

const KycDetail = () => {
  const kycSelector = useSelector((state) => state);
  const [data, setKycData] = useState('');
  const [kycData, setData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setKycData(kycSelector?.kycDetailGetReducer?.kycDetail?.payload?.data?.kycDetails);
    setData(kycSelector?.singleUserGetReducer?.userById?.payload);
    setLoading(false);
  }, [
    kycSelector?.kycDetailGetReducer?.kycDetail?.payload?.data?.kycDetails,
    kycSelector?.singleUserGetReducer?.userById?.payload,
  ]);

  return (
    <Box
      className='kyc-updation-form'
      sx={{
        backgroundColor: '#ffffff',
        padding: '24px',
        borderRadius: '16px',
      }}
    >
      {loading ? (
        <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
          <ThreeDots
            visible={true}
            height='80'
            width='80'
            color={colors.theme}
            radius='9'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClass=''
          />
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              color: colors?.useractivitylabelcolor,
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            KYC Details
          </Typography>
          <Box borderBottom='1px solid #E5E5E5' sx={{ paddingTop: '15px' }}></Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '16px',
              rowGap: '24px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    First name
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {kycData?.billingAddress?.p_firstName
                      ? kycData?.billingAddress?.p_firstName
                      : '' || data?.p_firstName
                      ? data?.p_firstName
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Last name
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {kycData?.billingAddress?.p_lastName
                      ? kycData?.billingAddress?.p_lastName
                      : '' || data?.p_lastName
                      ? data?.p_lastName
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '16px',
              rowGap: '24px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Address
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {kycData?.billingAddress?.p_address
                      ? kycData?.billingAddress?.p_address
                      : '' || data?.p_address
                      ? data?.p_address
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '16px',
              rowGap: '24px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Entity type:
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {kycData?.billingAddress?.p_accountType || (data?.p_entity && data?.p_entity === 1)
                      ? 'Personal'
                      : 'Company'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Monthly usage:
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {data?.p_usageType === 0 && '1-10 GB'}
                    {data?.p_usageType === 1 && '10-50 GB'}
                    {data?.p_usageType === 2 && '50-100 GB'}
                    {data?.p_usageType === 3 && '100-500 GB'}
                    {data?.p_usageType === 4 && '500 GB - 1 TB'}
                    {data?.p_usageType === 5 && 'OVER 1 TB'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '16px',
              rowGap: '24px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    LinkedIn URL
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={data?.p_linkdinUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                      alt='not found'
                      className='website-display'
                    >
                      {data?.p_linkdinUrl ? data?.p_linkdinUrl : '-'}
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Contact number
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    <span>{data?.p_countryCode}</span>&nbsp;
                    {data?.p_contactNumber.includes(data?.p_countryCode)
                      ? data?.p_contactNumber.replace(data?.p_countryCode, '')
                      : data?.p_contactNumber.replace('+', '')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '16px',
            }}
          >
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Description
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'justify',
                      fontSize: '16px',
                      fontWeight: 400,
                      wordBreak: 'break-word',
                    }}
                  >
                    {data?.p_description
                      ? data?.p_description.charAt(0).toUpperCase() + data?.p_description.slice(1)
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default KycDetail;
