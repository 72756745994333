import axios from "axios";
import {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
} from "./clientHelper";
import { baseURL } from "../url";
import { getCookie } from "../../utils/getCookie";

export function setupInterceptorsTo(axiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

const instance = setupInterceptorsTo(
  axios.create({
    baseURL,
    headers: {
      "ngrok-skip-browser-warning": "any",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Accept: "application/json",
      contentLength: "content-length",
      contentType: "content-type",
      "Access-Control-Allow-Origin": "*",
    },
  })
);

instance.interceptors.request.use(async (config) => {
  const token = getCookie("access_token_proxy");
  const adminToken = getCookie("admin_access_token_proxy");

  if (token || adminToken) {
    if (window.location.href.split("/").includes("admin")) {
      config.headers.Authorization = `Bearer ${adminToken}`;
    } else {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

export { instance };
