import React from 'react';
import { Box } from '@mui/material';
import { Images } from '../assets/images';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/images/logo.svg';

const AuthenticationWrapper = ({ children, maxWidth }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url("${Images.Bg_Authentication_Screens}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 20px',
        height: 'calc(100vh - 80px)',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          position: { md: 'absolute', sm: 'relative' },
          top: { xl: '68px', md: '34px', sm: '0' },
          left: { xl: '68px', md: '34px', sm: '0' },
          '& svg': {
            width: {
              xl: '258px',
              lg: '180px',
              sm: '150px',
              xs: '120px',
            },
            height: 'auto',
          },
          marginBottom: { md: '0', xs: '30px' },
        }}
      >
        <Link to='/'>
          <Logo />
        </Link>
      </Box>
      <Box
        sx={{
          maxWidth: maxWidth ? maxWidth : '540px',
          margin: 'auto',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AuthenticationWrapper;
