import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminLayout from '../../layout/admin/adminLayout';

const AdminProtectedRoute = ({ children }) => {
  const { signInUser } = useSelector((state) => state?.registerReducer);

  return signInUser?.token ? <AdminLayout>{children}</AdminLayout> : <Navigate to='/admin/login' />;
};

export default AdminProtectedRoute;
