import { Box, Button, Modal, Typography } from '@mui/material';
import { colors } from '../../../theme/colors';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setPaymentList } from '../../../redux/paymentListAPI';
import { useLocation, useNavigate } from 'react-router-dom';

const ActivePlanconfirmationModal = ({
  activePlanconfirmationModal,
  setActivePlanconfirmationModal,
  customerDetail,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const adminPath = currentPath.match(/^\/admin\b/);
  return (
    <Modal
      open={activePlanconfirmationModal}
      onClose={() => setActivePlanconfirmationModal(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        '& [tabindex="-1"]': {
          height: 'fit-content',
          margin: 'auto',
          borderRadius: '12px',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          width: {
            xs: '250px',
            sm: '320px',
            md: '508px',
            lg: '508px',
            xl: '508px',
          },
          maxHeight: '100%',
          height: '158px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          outline: 'none',
        }}
      >
        <Box sx={{ padding: '24px' }}>
          <Typography
            sx={{
              fontSize: '20px',
              color: colors?.neutral?.dark,
              textAlign: 'center',
            }}
          >
            {adminPath && adminPath.length > 0 && adminPath[0]
              ? `Please review the '${customerDetail?.p_login}' current plan status before assigning a new plan`
              : 'Please review the current plan status before assigning a new plan'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '0px 20px 20px 20px',
            justifyContent: 'space-between',
            gap: '15px',
          }}
        >
          <Button
            variant='#ff4c6a'
            sx={{
              maxHeight: '100%',
              maxWidth: '100%',
              borderRadius: '12px',
              border: '1px solid gray',
              background: '#FFFFFF',
              color: '#686465',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              width: '224px',
              height: '56px',
              fontSize: { xs: '20px' },
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
            onClick={() => setActivePlanconfirmationModal(false)}
          >
            No
          </Button>

          <Button
            variant='#ff4c6a'
            sx={{
              backgroundColor: colors?.theme,
              maxHeight: '100%',
              maxWidth: '100%',
              width: '224px',
              height: '56px',
              borderRadius: '12px',
              color: '#ffffff',
              fontWeight: 700,
              fontSize: { xs: '20px' },
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#ff4c6a',
                color: '#ffffff',
              },
            }}
            onClick={async () => {
              if (adminPath && adminPath.length > 0 && adminPath[0]) {
                await dispatch(setPaymentList(customerDetail));
                navigate('/admin/buyplan');
                setActivePlanconfirmationModal(false);
              } else {
                navigate('/buyplan');
              }
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActivePlanconfirmationModal;
