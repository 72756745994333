import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const adminProfileAPI = createAsyncThunk('adminProfile', async (formData) => {
  try {
    const response = await API.post(`/users/admin/profile/${formData.get('id')}/edit`, formData);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  adimnProfile: {},
};

const adminprofileSlice = createSlice({
  name: 'adimnProfile',
  initialState,
  reducers: {
    setAdminProfile: (state, { payload }) => {
      state.adimnProfile = payload;
    },
  },
});

export const { setAdminProfile } = adminprofileSlice.actions;
export default adminprofileSlice.reducer;
