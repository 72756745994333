import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import { colors } from '../../../theme/colors';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as PrintIcon } from '../../../assets/images/Printer.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/Copy.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/Download.svg';

import companyLogo from '../../../assets/images/Logo.png';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SpecificCustomerPlanDetails from './specificCustomerPlanDetails';
import { setUseridWiseCustomerPlanDetails } from '../../../redux/idWiseCustomerPlanGetAPI';
import AddPlanModal from '../plans/addPlan';
import { useGetUser } from '../../../context/userProfile';
import { getCustomCustomerAPI } from '../../../redux/getCustomCustomerPlansAPI';

const CustomerPlans = () => {
  const tableRef = useRef();
  const totalCountRef = useRef(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { logInUserDetails } = useGetUser();
  const proxySelect = useSelector((state) => state?.paymentListReducer?.paymentList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState();
  const [searchCustomer, setSearchCustomer] = useState('');
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [DownloadMenu, setDownloadMenu] = React.useState(null);
  const [customerDetailsData, setCustomerDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const open = Boolean(DownloadMenu);
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  useEffect(() => {
    totalCountRef.current = totalCount;
  }, [totalCount]);

  const viewUserDetails = async () => {
    setIsLoading(true);
    try {
      await dispatch(setUseridWiseCustomerPlanDetails({}));
      const userWiseData = {
        userId: id,
        page: page,
        limit: rowsPerPage,
        search: searchCustomer ? searchCustomer : '',
      };
      await setCustomerDetailsData([]);
      if (id) {
        const userData = await dispatch(getCustomCustomerAPI(userWiseData));

        if (userData?.payload?.status === true) {
          const totalCount = userData?.payload?.paging?.totalItems;
          setCustomerDetailsData(userData?.payload?.data);
          setTotalCount(totalCount);

          if (searchCustomer !== '' && totalCount !== totalCountRef.current) {
            setPage(0);
          }
          setIsLoading(false);
          await dispatch(setUseridWiseCustomerPlanDetails(userData?.payload));
        } else {
          setRowsPerPage(10);
          setTotalCount(0);
          setPage(0);
          setCustomerDetailsData([]);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setRowsPerPage(10);
      setPage(0);
      setTotalCount(0);
      setCustomerDetailsData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      viewUserDetails();
    }
  }, [dispatch, id, page, rowsPerPage, searchCustomer]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const tableHeading = [
    { name: 'Plan Name', sortName: 'p_name' },
    { name: 'Product Type', sortName: 'productTypeName' },
    { name: 'Charge Type', sortName: 'p_chargesType' },
    { name: 'Volume', sortName: 'p_volume' },
    { name: 'Unit Price', sortName: 'p_unitPrice' },
    { name: 'Duration', sortName: 'p_duration' },
    { name: 'Total Price', sortName: 'p_price' },
  ];

  const sortData = (property, fallbackProperty) => {
    const newSortConfig = { ...sortConfig };

    if (newSortConfig.key === property) {
      newSortConfig.direction = newSortConfig.direction === 'asc' ? 'desc' : 'asc';
    } else {
      newSortConfig.key = property;
      newSortConfig.direction = 'asc';
    }

    const customerUsageData = Array.isArray(customerDetailsData.customerUsage)
      ? customerDetailsData.customerUsage
      : [];
    const sortedData = [...customerUsageData].sort((a, b) => {
      const valueA = String(a[property] || a[fallbackProperty]).toLowerCase();
      const valueB = String(b[property] || b[fallbackProperty]).toLowerCase();

      if (newSortConfig.direction === 'asc') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setSortConfig(newSortConfig);
    setCustomerDetailsData({
      ...customerDetailsData,
      customerUsage: sortedData,
    });
  };

  const handleCopyClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = document.querySelector('table').innerText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  const handlePrint = () => {
    const printWindow = window.open('customerPlan', '_blank');
    const tableContent = tableRef.current.innerHTML;

    printWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            #printSection {
              width: 100% !important;
              box-shadow: none;
              border-radius: 12px;
            }
            .table-wrapper {
              border: 1px solid #dddddd;
              border-radius: 6px;
              margin-top: 20px; /* Add margin to create space for header */
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              text-align: left;
              padding: 8px;
              font-size: 18px
            }
            th, tr:not(:last-child) td {
              border-bottom: 1px solid #dddddd;
            }
            th:last-child, td:last-child,
            th svg {
              display: none;
            }
            @media print {
              th:last-child, td:last-child,
              th svg {
                display: none;
              }
              th, td {
                text-align: left;
                padding: 8px;
                font-size: 12px
              }
            }
            .header {
              text-align: center;
              margin-bottom: 20px;
            }
            .header img {
              max-width: 100px; /* Adjust the max-width as needed */
            }
          </style>
        </head>
        <body>
          <div class="header" id="header">
            <img src=${companyLogo} alt='Company Logo' />
            <h1>Customer Plan Details</h1>
          </div>
          <div class="table-wrapper">
            ${tableContent}
          </div>
          <script>
          window.onload = function() {
            document.getElementById('header').style.display = 'block';
            window.print();
          };
        </script>
        </body>
      </html>
    `);

    printWindow.document.close();
  };

  const downloadFile = (format) => {
    let filteredData;
    filteredData = customerDetailsData.map((planGroup) => ({
      'Plan Name': planGroup?.p_name,
      'Product Type': planGroup?.productType?.p_name,
      'Charge Type':
        planGroup.p_chargesType === 1
          ? 'Bandwidth'
          : planGroup.p_chargesType === 2
          ? 'Requests'
          : planGroup.p_chargesType === 3
          ? 'OneTime'
          : '',
      Volume: `${planGroup.p_volume}GB`,
      'Unit Price': `$${planGroup.p_unitPrice}`,
      Duration: planGroup.p_duration,
      'Total Price': `$${planGroup.p_price}`,
    }));

    switch (format) {
      case 'CSV':
        const csvHeaders = Object.keys(filteredData[0]);
        const csvFileName = `customerPlan_${formattedDate}.csv`;
        const csvContent = [
          csvHeaders.join(','),
          ...filteredData.map((row) => csvHeaders.map((header) => row[header]).join(',')),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvFileName;
        link.click();
        break;

      case 'Excel':
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `customerPlan_${formattedDate}.xlsx`);
        break;

      case 'PDF':
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const imgWidth = 30;
        const imgHeight = 10;

        const imgX = (pdfWidth - imgWidth) / 2;
        pdf.addImage(companyLogo, 'PNG', imgX, 10, imgWidth, imgHeight);

        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(16);

        pdf.text(
          'Customer Plans Report',
          (pdfWidth -
            (pdf.getStringUnitWidth('Customer Plans Report') * pdf.internal.getFontSize()) /
              pdf.internal.scaleFactor) /
            2,
          30
        );

        if (filteredData && filteredData.length > 0) {
          const tableData = filteredData.map((row) => Object.values(row));

          const headers = tableHeading.map((heading) => heading.name);

          pdf.autoTable({
            head: [headers],
            body: tableData,
            startY: imgHeight + 25,
            styles: {
              cellPadding: 2,
              valign: 'middle',
              fontSize: 10,
              fontStyle: 'normal',
              lineWidth: 0.5,
            },
            margin: { top: imgHeight + 30 },
            tableWidth: 'auto',
          });

          pdf.save(`customerPlan_${formattedDate}.pdf`);
        } else {
          pdf.text('No data available', 10, imgHeight + 25);
        }

        break;

      default:
        console.error('Invalid format');
    }

    setDownloadMenu(null);
  };

  return (
    <>
      <Box>
        <Box className='edit-header'>
          <Typography variant='h2' className='edit-content-header'>
            {proxySelect?.p_login} Plans
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              '& > button ': {
                fontSize: { xl: '20px' },
                fontWeight: '700',
              },
            }}
          >
            <Button
              onClick={() => {
                setAddCustomerModal(true);
              }}
              type='button'
              variant='filled'
              sx={{
                minWidth: { lg: '200px', md: '140px', sm: '100px', xs: '80px' },
                fontSize: '20px',
                fontWeight: '700',
                textTransform: 'capitalize',
                borderRadius: '8px',
              }}
            >
              Add Plan
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: colors.background.formBg,
            borderRadius: '16px',
            padding: '24px',
            minHeight: 'calc(100vh - 260px)',
          }}
        >
          <Typography variant='h4' sx={{ paddingBottom: '8px', color: colors.neutral.dark }}>
            Custom Plans
          </Typography>

          <Box>
            <Box
              sx={{
                backgroundColor: colors.background.formBg,
                borderRadius: '16px',
                paddingTop: '24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                <TextField
                  type='search'
                  placeholder='Search'
                  value={searchCustomer}
                  onChange={(e) => setSearchCustomer(e.target.value.trimLeft())}
                  sx={{
                    maxWidth: { lg: '408px', sm: '270px', md: '408px' },
                    minWidth: '204px',
                    width: '100%',
                    mb: '24px',
                    '& .MuiInputBase-root': {
                      height: '48px !important',
                    },
                    '&.MuiInputBase-root': {
                      height: '48px !important',
                    },
                    '& input': {
                      padding: '10px 14px',
                      fontSize: '18px',
                      lineHeight: '1.5',
                    },

                    '@media (min-width: 540px) and (max-width: 720px)': {
                      width: '100%',
                    },
                    '@media (min-width: 280px) and (max-width: 653px)': {
                      minWidth: '194px',
                    },
                    '@media (min-width: 601px) and (max-width: 960px)': {
                      width: '36%',
                    },
                  }}
                />

                <Box
                  sx={{
                    '& button': {
                      mr: '8px',
                      marginTop: { xs: '15px', lg: '0', sm: '0', md: '0' },
                      padding: { xl: '11px 19px', xs: '7px' },
                      maxHeight: '100%',
                      maxWidth: '100%',
                      border: '1px solid #CDCBCC',
                      height: '48px',
                      width: '64px',
                      '&:last-child': {
                        mr: '0px',
                      },
                      '& svg path': {
                        transition: 'all .5s ease',
                      },
                      '&:hover svg path': {
                        fill: colors.theme,
                      },
                    },
                    display: 'flex',

                    ml: { lg: 'auto', xs: '0' },
                    mr: 0,
                    '@media (max-width: 600px)': {
                      marginLeft: 0,
                    },
                    '@media (min-width: 601px) and (max-width: 960px)': {
                      marginLeft: 0,
                    },
                  }}
                >
                  <Button
                    variant='grayOutlined'
                    type='button'
                    onClick={handleCopyClick}
                    disabled={!customerDetailsData?.length > 0}
                  >
                    <Tooltip
                      title='Copy to Clipboard'
                      arrow
                      open={tooltipOpen}
                      onClose={() => setTooltipOpen(false)}
                    >
                      <CopyIcon />
                    </Tooltip>
                  </Button>
                  <Button
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(event) => setDownloadMenu(event.currentTarget)}
                    variant='grayOutlined'
                    type='button'
                    disabled={!customerDetailsData?.length > 0}
                  >
                    <DownloadIcon />
                  </Button>
                  <Menu
                    id='basic-menu'
                    className='download-menu'
                    anchorEl={DownloadMenu}
                    open={open}
                    transformorigin={{ horizontal: 'right', vertical: 'top' }}
                    anchororigin={{ horizontal: 'right', vertical: 'bottom' }}
                    onClose={() => setDownloadMenu(null)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => downloadFile('CSV')}>CSV</MenuItem>
                    <MenuItem onClick={() => downloadFile('Excel')}>Excel</MenuItem>
                    <MenuItem onClick={() => downloadFile('PDF')}>PDF</MenuItem>
                  </Menu>
                  <Button
                    variant='grayOutlined'
                    type='button'
                    onClick={handlePrint}
                    disabled={!customerDetailsData?.length > 0}
                  >
                    <PrintIcon />
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box>
              <SpecificCustomerPlanDetails
                sortConfig={sortConfig}
                tableRef={tableRef}
                tableHeading={tableHeading}
                sortData={sortData}
                CustomerDetailsData={customerDetailsData}
                isLoading={isLoading}
                viewUserDetails={viewUserDetails}
                totalCount={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                logInUserDetails={logInUserDetails}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <AddPlanModal
        setIsAddModal={setAddCustomerModal}
        addIsModal={addCustomerModal}
        proxySelect={proxySelect}
        viewUserDetails={viewUserDetails}
      />
    </>
  );
};

export default CustomerPlans;
