import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Grid, Select, MenuItem, Button, TextField, Menu, styled } from '@mui/material';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Tooltip from '@mui/material/Tooltip';
import { colors } from '../../../theme/colors';
import 'jspdf-autotable';
import SubscriptionDataTable from './subscriptiontable';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/dropdownDownArrow.svg';
import { ReactComponent as PrintIcon } from '../../../assets/images/Printer.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/Copy.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/Download.svg';
import { getCutomersubscriptionAPI } from '../../../redux/subscriptionAPI';
import { formatDate } from '../../../components/common/dateFormat';
import companyLogo from '../../../assets/images/Logo.png';
import { useGetUser } from '../../../context/userProfile';

const Subscription = () => {
  const tableRef = useRef();
  const { logInUserDetails } = useGetUser();
  const currentDate = new Date();
  const queryParams = new URLSearchParams(window.location.search);
  const customerId = queryParams.get('customerId');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const totalCountRef = useRef(0);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState();
  const [searchCustomer, setSearchCustomer] = useState('');
  const [DownloadMenu, setDownloadMenu] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paymentValue, setPaymentValue] = useState({
    owner: '',
    customer: '',
    planstatus: '',
  });
  const open = Boolean(DownloadMenu);

  const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
    color: '#686465',
    borderRadius: '6px',
    height: '48px',
    marginTop: '10px !important',
    width: '100% !important',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },

    '& .MuiSelect-select': {
      padding: '13px 14px',
      lineHeight: '1.5',
      fontSize: '18px !important',
    },
    '& .MuiSelect-select[aria-expanded="true"] ~ svg': {
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'})`,
    },
    '& .MuiSelect-icon': {
      display: 'block',
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'})`,
      transition: 'all .5s ease',
      cursor: 'pointer',
    },
  }));

  useEffect(() => {
    totalCountRef.current = totalCount;
  }, [totalCount]);

  useEffect(() => {
    try {
      setIsLoading(true);

      const getPaymentOption = async () => {
        const subscriptionObj = {
          ownerId: paymentValue?.owner,
          planStatus: paymentValue?.planstatus,
          userId: paymentValue?.customer ? paymentValue?.customer : customerId ? customerId : '',
          search: searchCustomer,
          limit: rowsPerPage,
          page: page,
        };

        setSubscriptionData();
        const payload = await dispatch(getCutomersubscriptionAPI(subscriptionObj));
        if (payload?.payload?.status === true) {
          const totalCount = payload.payload.data.paging.totalItems || 0;
          setSubscriptionData(payload?.payload?.data);
          setRowsPerPage(payload?.payload?.data?.paging.itemsPerPage);
          setTotalCount(totalCount);

          if (
            (subscriptionObj?.ownerId !== '' ||
              subscriptionObj?.planStatus !== undefined ||
              subscriptionObj?.userId !== '' ||
              subscriptionObj?.search !== '') &&
            totalCount !== totalCountRef.current
          ) {
            setPage(0);
          }

          setIsLoading(false);
        } else {
          toast.error(payload?.payload?.response?.message);
          setRowsPerPage(10);
          setTotalCount(0);
          setPage(0);
          setSubscriptionData([]);
          setIsLoading(false);
        }
      };

      getPaymentOption();
    } catch (error) {
      setRowsPerPage(10);
      setTotalCount(0);
      setPage(0);
      setSubscriptionData([]);
      setIsLoading(false);
    }
  }, [
    dispatch,
    page,
    rowsPerPage,
    paymentValue?.customer,
    paymentValue?.owner,
    paymentValue?.planstatus,
    searchCustomer,
    customerId,
  ]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const planStatus = [
    {
      name: 'All',
    },
    {
      id: 1,
      name: 'Active',
    },
    {
      id: 2,
      name: 'InActive',
    },
  ];

  const columnName = [
    {
      id: 1,
      name: 'Customer',
    },
    {
      id: 2,
      name: 'Plan',
    },
    {
      id: 3,
      name: 'Plan Added By',
    },
    {
      id: 4,
      name: 'Owner',
    },
    {
      id: 5,
      name: 'Product Type',
    },
    {
      id: 6,
      name: 'Price',
    },
    {
      id: 7,
      name: 'Unit Price',
    },
    {
      id: 8,
      name: 'Volume',
    },
    {
      id: 9,
      name: 'Start Date',
    },
    {
      id: 10,
      name: 'End Date',
    },
    {
      id: 11,
      name: 'Usage',
    },
    {
      id: 12,
      name: 'Transaction ID',
    },
    {
      id: 13,
      name: 'Method',
    },
    {
      id: 14,
      name: 'Is Active',
    },
    {
      id: 15,
      name: 'Auto Renewal',
    },
    {
      id: 16,
      name: 'Actions',
    },
  ];

  const handleCopyClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = document.querySelector('table').innerText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  const handlePrint = () => {
    const printWindow = window.open('Subscription', '_blank');
    const tableContent = tableRef.current.innerHTML;

    printWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            #printSection {
              width: 100% !important;
              box-shadow: none;
              border-radius: 12px;
            }
            .table-wrapper {
              border: 1px solid #dddddd;
              border-radius: 6px;
              margin-top: 20px; /* Add margin to create space for header */
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              text-align: left;
              padding: 8px;
              font-size: 18px
            }
            th, tr td {
              border-bottom: 1px solid #dddddd;
            }
            th:last-child, td:last-child,
            th svg {
              display: block;
            }
            @media print {
              th:last-child, td:last-child,
              th svg {
                display: block;
              }
              th, td {
                text-align: left;
                padding: 8px;
                font-size: 10px
              }
              .header {
                text-align: center;
                margin-bottom: 20px;
              }
              .header img {
                max-width: 100px; /* Adjust the max-width as needed */
              }
            }
          </style>
        </head>
        <body>
          <div class="header" id="header">
            <img src=${companyLogo} alt='Company Logo' />
            <h1>Subscription Details</h1>
          </div>
          <div class="table-wrapper">
            ${tableContent}
          </div>
          <script>
          window.onload = function() {
            document.getElementById('header').style.display = 'block';
            window.print();
          };
        </script>
        </body>
      </html>
    `);

    printWindow.document.close();
  };

  const downloadFile = (format) => {
    let filteredData;
    filteredData = subscriptionData?.subscriptions?.map((row) => ({
      Customer: row?.subscription?.p_login?.charAt(0).toUpperCase() + row?.subscription?.p_login?.slice(1),
      plan: row?.subscription?.planName,
      'Plan Added By': row?.subscription?.parentName ? row?.subscription?.parentName : 'Self',
      Owner: row?.subscription?.ownerName ? row?.subscription?.ownerName : 'Self',
      'Product Type': row?.subscription?.productTypeName ? row?.subscription?.productTypeName : '-',
      Price: row?.subscription?.price,
      UnitePrice: row?.subscription?.unitPrice,
      Volume: `${row?.subscription?.volume} GB`,
      'Start Date': formatDate(row?.subscription?.startDate),
      'End Date': formatDate(row?.subscription?.endDate),
      Usage: row?.subscription.gb_usage,
      'Transaction ID': row?.subscription?.transactionId ? row?.subscription?.transactionId : '-',
      paymentMethod: row?.subscription?.paymentMethod,
      planStatus: row?.subscription?.planStatus,
      'Auto Renewal': row?.subscription?.autoRenewal,
    }));

    switch (format) {
      case 'CSV':
        const csvHeaders = Object.keys(filteredData[0]);
        const csvContent = [
          csvHeaders.join(','),
          ...filteredData.map((row) => csvHeaders.map((header) => row[header]).join(',')),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `subscriptionDetails_${formattedDate}.csv`;
        link.click();
        break;

      case 'Excel':
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `subscriptionDetails_${formattedDate}.xlsx`);
        break;

      case 'PDF':
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const imgWidth = 30;
        const imgHeight = 10;

        const imgX = (pdfWidth - imgWidth) / 2;
        pdf.addImage(companyLogo, 'PNG', imgX, 10, imgWidth, imgHeight);

        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(16);

        pdf.text(
          'Subscription Report',
          (pdfWidth -
            (pdf.getStringUnitWidth('Subscription Report') * pdf.internal.getFontSize()) /
              pdf.internal.scaleFactor) /
            2,
          30
        );

        if (filteredData && filteredData.length > 0) {
          const tableData = filteredData.map((row) => Object.values(row));

          const headers = columnName.map((heading) => heading.name);

          pdf.autoTable({
            head: [headers],
            body: tableData,
            startY: imgHeight + 25,
            styles: {
              cellPadding: 2,
              valign: 'middle',
              fontSize: 6,
              fontStyle: 'normal',
              lineWidth: 0.5,
            },
            margin: { top: imgHeight + 30 },
            tableWidth: 'auto',
          });

          pdf.save(`subscriptionDetails_${formattedDate}.pdf`);
        } else {
          pdf.text('No data available', 10, imgHeight + 25);
        }

        break;

      default:
        console.error('Invalid format');
    }

    setDownloadMenu(null);
  };

  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };
  return (
    <>
      <Box>
        <Typography variant='h2' sx={{ mb: 3, color: colors?.neutral?.dark }}>
          Subscription
        </Typography>
        <Grid container spacing={'24px'}>
          <Grid item xs={12}>
            <Box className='table-body'>
              <Box
                sx={{
                  display: { lg: 'flex', xs: 'grid', flexWrap: 'wrap' },
                  justifyContent: 'space-between',
                  gap: '5px',
                }}
              >
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px' }}>
                    <Box
                      sx={{
                        width: { xl: '200px', sm: '240px', xs: '100%' },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          color: colors?.basics?.normal,
                        }}
                      >
                        Customer
                      </Typography>
                      <CustomStyledSelect
                        name='customer'
                        value={paymentValue?.customer ? paymentValue?.customer : customerId ? customerId : ''}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          const updatedValue = selectedValue === 'all' ? '' : selectedValue;
                          const queryParams = new URLSearchParams(window.location.search);
                          queryParams.delete('customerId');
                          queryParams.set('id', updatedValue);
                          window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
                          setPaymentValue({ ...paymentValue, customer: updatedValue });
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={(props) => (
                          <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                        )}
                      >
                        {(!paymentValue?.customer || !customerId) && (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            disabled
                            onClick={handleDropdownToggle}
                          >
                            <em> Select customer</em>
                          </MenuItem>
                        )}
                        {(paymentValue?.customer || customerId) && (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            onClick={handleDropdownToggle}
                          >
                            <em> All</em>
                          </MenuItem>
                        )}
                        {subscriptionData?.users?.map((ownerName, index) => (
                          <MenuItem
                            className='filter-box'
                            key={index}
                            value={ownerName?.p_customer_id}
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            {ownerName?.p_login}
                          </MenuItem>
                        ))}
                      </CustomStyledSelect>
                    </Box>
                    <Box sx={{ width: { xl: '200px', sm: '240px', xs: '100%' } }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          color: colors?.basics?.normal,
                        }}
                      >
                        Plan status
                      </Typography>
                      <CustomStyledSelect
                        name='planstatus'
                        value={paymentValue?.planstatus}
                        onChange={(e) => {
                          setPaymentValue({ ...paymentValue, planstatus: e.target.value });
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={(props) => (
                          <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                        )}
                      >
                        {!paymentValue?.planstatus && (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            disabled
                            onClick={handleDropdownToggle}
                          >
                            <em>Plan status</em>
                          </MenuItem>
                        )}
                        {planStatus?.map((planstatus, index) => (
                          <MenuItem
                            className='filter-box'
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            key={index}
                            value={planstatus?.id}
                          >
                            {planstatus?.name}
                          </MenuItem>
                        ))}
                      </CustomStyledSelect>
                    </Box>
                    <Box sx={{ width: { xl: '200px', sm: '240px', xs: '100%' } }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          color: colors?.basics?.normal,
                        }}
                      >
                        Owner
                      </Typography>
                      <CustomStyledSelect
                        name='owner'
                        value={paymentValue?.owner}
                        onChange={(e) => {
                          setPaymentValue({ ...paymentValue, owner: e.target.value });
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={(props) => (
                          <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                        )}
                      >
                        {!paymentValue?.owner ? (
                          <MenuItem value='' sx={{ color: colors.neutral.light + ' !important' }} disabled>
                            <em> Select owner</em>
                          </MenuItem>
                        ) : (
                          <MenuItem value='' sx={{ color: colors.neutral.light + ' !important' }}>
                            <em> All</em>
                          </MenuItem>
                        )}
                        {subscriptionData?.owners?.map((ownerName, index) => (
                          <MenuItem
                            className='filter-box'
                            key={index}
                            value={ownerName?.p_customer_id}
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            {ownerName?.p_login}
                          </MenuItem>
                        ))}
                      </CustomStyledSelect>
                    </Box>
                  </Box>
                </>
              </Box>

              <Box
                sx={{
                  backgroundColor: colors.background.formBg,
                  borderRadius: '16px',
                  paddingTop: '24px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    flexWrap: 'wrap',
                  }}
                >
                  <TextField
                    type='search'
                    placeholder='Search'
                    value={searchCustomer}
                    onChange={(e) => setSearchCustomer(e.target.value.trimLeft())}
                    sx={{
                      maxWidth: { lg: '408px', sm: '270px', md: '408px' },
                      minWidth: '204px',
                      width: '100%',
                      mb: '24px',
                      '& .MuiInputBase-root': {
                        height: '48px !important',
                      },
                      '&.MuiInputBase-root': {
                        height: '48px !important',
                      },
                      '& input': {
                        padding: '10px 14px',
                        fontSize: '18px',
                        lineHeight: '1.5',
                      },

                      '@media (min-width: 540px) and (max-width: 720px)': {
                        width: '100%',
                      },
                      '@media (min-width: 280px) and (max-width: 653px)': {
                        minWidth: '194px',
                      },
                      '@media (min-width: 601px) and (max-width: 960px)': {
                        width: '36%',
                      },
                    }}
                  />

                  <Box
                    sx={{
                      '& button': {
                        mr: '8px',
                        marginTop: { xs: '15px', lg: '0', sm: '0', md: '0' },
                        padding: { xl: '11px 19px', xs: '7px' },
                        maxHeight: '100%',
                        maxWidth: '100%',
                        border: '1px solid #CDCBCC',
                        height: '48px',
                        width: '64px',
                        '&:last-child': {
                          mr: '0px',
                        },
                        '& svg path': {
                          transition: 'all .5s ease',
                        },
                        '&:hover svg path': {
                          fill: colors.theme,
                        },
                      },
                      display: 'flex',

                      ml: { lg: 'auto', xs: '0' },
                      mr: 0,
                      '@media (max-width: 600px)': {
                        marginLeft: 0,
                      },
                      '@media (min-width: 601px) and (max-width: 960px)': {
                        marginLeft: 0,
                      },
                    }}
                  >
                    <Button
                      variant='grayOutlined'
                      type='button'
                      onClick={handleCopyClick}
                      disabled={!subscriptionData?.subscriptions?.length > 0}
                    >
                      <Tooltip
                        title='Copy to Clipboard'
                        arrow
                        open={tooltipOpen}
                        onClose={() => setTooltipOpen(false)}
                      >
                        <CopyIcon />
                      </Tooltip>
                    </Button>
                    <Button
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(event) => setDownloadMenu(event.currentTarget)}
                      variant='grayOutlined'
                      type='button'
                      disabled={!subscriptionData?.subscriptions?.length > 0}
                    >
                      <DownloadIcon />
                    </Button>
                    <Menu
                      id='basic-menu'
                      className='download-menu'
                      anchorEl={DownloadMenu}
                      open={open}
                      transformorigin={{ horizontal: 'right', vertical: 'top' }}
                      anchororigin={{ horizontal: 'right', vertical: 'bottom' }}
                      onClose={() => setDownloadMenu(null)}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => downloadFile('CSV')}>CSV</MenuItem>
                      <MenuItem onClick={() => downloadFile('Excel')}>Excel</MenuItem>
                      <MenuItem onClick={() => downloadFile('PDF')}>PDF</MenuItem>
                    </Menu>
                    <Button
                      variant='grayOutlined'
                      type='button'
                      onClick={handlePrint}
                      disabled={!subscriptionData?.subscriptions?.length > 0}
                    >
                      <PrintIcon />
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box>
                <SubscriptionDataTable
                  columnName={columnName}
                  logInUserDetails={logInUserDetails}
                  subscriptionData={subscriptionData}
                  tableRef={tableRef}
                  loading={loading}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Subscription;
