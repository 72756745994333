import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Button,
  Switch,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  MobileStepper,
  IconButton,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DataNotFound from '../../../assets/images/No Data Found.png';
import InvoiceModal from '../../admin/customerDetails/invoiceModal';

import { ReactComponent as True } from '../../../assets/images/true.svg';
import { ReactComponent as False } from '../../../assets/images/false.svg';
import { ReactComponent as PaySlip } from '../../../assets/images/PaySlip.svg';
import { colors } from '../../../theme/colors';
import { formatDate } from '../../../components/common/dateFormat';
import ActivePlanconfirmationModal from '../proxyPlans/activePlanconfirmationModal';
import { useTheme } from '@emotion/react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const BillingTable = ({
  displayedHistory,
  userplanInfo,
  handleInvoice,
  durationDay,
  handleAutoRenewal,
  columm,
  closeModal,
  billingData,
  setModalOpen,
  modalOpen,
  setPage,
  page,
  rowsPerPage,
}) => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const [displayActivePlan, setDisplayActivePlan] = useState({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (
      userplanInfo &&
      userplanInfo.data?.currentActivePlan &&
      userplanInfo.data?.currentActivePlan.length > 0
    ) {
      setDisplayActivePlan(userplanInfo.data?.currentActivePlan[activeStep]);
    }
  }, [activeStep, userplanInfo]);

  return (
    <Grid container spacing={{ md: '24px', xs: '20px' }}>
      <Grid item lg={4} xs={12}>
        <Box
          sx={{
            backgroundColor: colors.background.formBg,
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          {!!userplanInfo?.data?.currentActivePlan.length ? (
            <Box
              sx={{
                '& .MuiMobileStepper-root': {
                  backgroundColor: 'transparent !important',
                },
              }}
            >
              <>
                <Box
                  sx={{
                    borderBottom: `1px solid ${colors.input.border}`,
                    paddingBottom: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h4" color={colors?.neutral.dark}>
                      Current Plan
                    </Typography>
                    <Button
                      variant="filled"
                      type="submit"
                      sx={{
                        backgroundColor: `${colors.basics.green} !important`,
                        borderColor: colors.basics.green,
                        lineHeight: '1.2',
                        textTransform: 'capitalize',
                        fontSize: { lg: '18px' },
                        borderRadius: '6px !important',
                        width: '95px !important',
                        height: '35px !important',
                        '&:hover': {
                          color: 'white',
                        },
                        pointerEvents: 'none',
                      }}
                    >
                      Active
                    </Button>
                  </Box>
                  <Typography variant="subtitle2" pt="4px" color={colors.basics.normal}>
                    {`Expires on ${displayActivePlan?.p_end_date
                        ? new Date(displayActivePlan?.p_end_date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                        : '-'
                      }`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${colors.input.border}`,
                    paddingBottom: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <Typography
                    variant="h3"
                    fontSize={{ md: '28px' }}
                    fontWeight={'600'}
                    color={colors?.neutral.dark}
                  >
                    {displayActivePlan?.plan?.p_name}
                  </Typography>
                  <Typography variant="subtitle4" pb={'20px'} display={'block'} color={colors.neutral.dark}>
                    {displayActivePlan?.plan?.productType?.p_name}
                  </Typography>
                  <Grid container spacing={{ md: '16px', xs: '10px' }}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        variant="subtitle1"
                        color={colors.basics.normal}
                        sx={{
                          display: 'flex',
                          '& svg': {
                            marginRight: '8px',
                          },
                        }}
                      >
                        {displayActivePlan?.p_gb_total ? <True /> : <False />}
                        {displayActivePlan?.p_gb_total} GB
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        variant="subtitle1"
                        color={colors.basics.normal}
                        sx={{
                          display: 'flex',
                          '& svg': {
                            marginRight: '8px',
                          },
                        }}
                      >
                        {displayActivePlan?.plan?.p_duration ? <True /> : <False />}
                        {displayActivePlan?.plan?.p_duration} days duration
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        variant="subtitle1"
                        color={colors.basics.normal}
                        sx={{
                          display: 'flex',
                          '& svg': {
                            marginRight: '8px',
                          },
                        }}
                      >
                        {displayActivePlan?.plan?.p_unitPrice ? <True /> : <False />}
                        {displayActivePlan?.plan?.p_unitPrice}$ / per GB
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        variant="subtitle1"
                        color={colors.basics.normal}
                        sx={{
                          display: 'flex',
                          '& svg': {
                            marginRight: '8px',
                          },
                        }}
                      >
                        {displayActivePlan?.plan?.p_emailAndChatSupport ? <True /> : ''}
                        {displayActivePlan?.plan?.p_emailAndChatSupport === 1
                          ? 'Email support'
                          : displayActivePlan?.plan?.p_emailAndChatSupport === 2
                            ? 'Email & Chat support'
                            : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
              <MobileStepper
                variant="text"
                steps={userplanInfo?.data?.currentActivePlan?.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <IconButton
                    sx={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      border: '1px solid',
                    }}
                    onClick={handleNext}
                    disabled={activeStep === userplanInfo?.data?.currentActivePlan?.length - 1}
                  >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </IconButton>
                }
                backButton={
                  <IconButton
                    sx={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      border: '1px solid',
                    }}
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  </IconButton>
                }
              />
              {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: "16px"
                }}
              >
                <Typography variant='subtitle3' color={colors?.neutral.dark}>
                  Auto Renewal
                </Typography>
                <Switch
                  sx={{
                    '&.MuiSwitch-root ': {
                      borderRadius: '50px',
                      height: { sm: '30px', xs: '25px' },
                      width: { sm: '58px', xs: '50px' },
                      padding: '0',
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        transform: { sm: 'translateX(28px)', xs: 'translateX(25px)' },
                      },
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: colors.basics.white,
                      borderRadius: '50px',
                      border: `1px solid ${colors.input.border}`,
                      height: { sm: '28px', xs: '23px' },
                    },
                    '& .Mui-checked+.MuiSwitch-track': {
                      backgroundColor: `${colors.theme} !important`,
                      opacity: '1 !important',
                      borderColor: colors.theme,
                    },
                    '& .MuiSwitch-thumb ': {
                      backgroundColor: colors.input.border,
                      boxShadow: 'none',
                      width: { sm: '20px', xs: '15px' },
                      height: { sm: '20px', xs: '15px' },
                    },
                    '& .MuiSwitch-switchBase': {
                      padding: '5px',
                      '&.Mui-checked': {
                        '& .MuiSwitch-thumb ': {
                          backgroundColor: colors.basics.white,
                        },
                      },
                    },
                  }}
                  {...label}
                  checked={userplanInfo?.data?.p_autoRenew === 1}
                  onClick={handleAutoRenewal}
                />
              </Box> */}
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  fontSize: { md: '20px', sm: '18px', xs: '16px' },
                  width: '100%',
                  mt: '24px',
                  borderRadius: '8px !important',
                  fontWeight: 700,
                }}
                onClick={() => {
                  if (displayedHistory?.[0]?.p_active === 1) {
                    setIsModalOpen(true);
                  } else {
                    navigate('/buyplan');
                  }
                }}
              >
                Change Plan
              </Button>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', border: '0' }}>
              <img src={DataNotFound} alt="data not found" style={{ width: '80px', height: '80px' }} />
              <Typography sx={{ textAlign: 'center' }}>No Active Subscription</Typography>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Box
          sx={{
            backgroundColor: colors.background.formBg,
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <Typography variant="h4">Plans History</Typography>
          <TableContainer
            component={Paper}
            sx={{ width: '100% !important', boxShadow: 'none', borderRadius: '12px' }}
          >
            <Table
              aria-label="simple table"
              sx={{
                width: '100%',
                '& th, td': {
                  padding: '22px 22px',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  {columm?.map((item, index) => (
                    <TableCell
                      sx={{
                        fontWeight: '500',
                        fontSize: '18px',
                        whiteSpace: 'nowrap',
                        color: colors?.neutral.dark,
                      }}
                      key={index}
                    >
                      {item.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userplanInfo?.data?.billingHistory?.length > 0 ? (
                  displayedHistory?.map((row, index) => (
                    <>
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell className="edit-plan-label">
                          <Link
                            href={row.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            style={{
                              color: colors?.basics?.normal,
                              textDecorationColor: colors?.basics?.normal,
                            }}
                          >
                            {row.plan?.p_name}
                          </Link>
                        </TableCell>
                        <TableCell className="edit-plan-label">{row?.plan?.productType?.p_name}</TableCell>
                        <TableCell className="edit-plan-label">{formatDate(row?.p_start_date)}</TableCell>
                        <TableCell className="edit-plan-label">
                          {row.p_active === 1 ? 'Active' : row.p_active === 3 ? 'Stand By' : 'Expired'}
                        </TableCell>
                        <TableCell className="edit-plan-label">
                          $
                          {row?.p_billingChargesType === 2
                            ? row?.plan?.p_yearlyPrice
                            : row?.p_billingChargesType === 1
                              ? row?.plan?.p_price
                              : row?.p_billingChargesType === 0
                                ? row?.plan?.p_price
                                : ''}
                        </TableCell>
                        <TableCell className="edit-plan-label">
                          {row?.payment?.p_payment_mode === 1
                            ? 'Net Banking'
                            : row?.payment?.p_payment_mode === 2
                              ? 'Credit Card'
                              : row?.payment?.p_payment_mode === 3
                                ? 'Debit Card'
                                : row?.payment === null
                                  ? 'Admin'
                                  : ''}
                        </TableCell>
                        <Tooltip
                          title={!row?.payment && 'Invoice not generated to plan provided by the admin'}
                          cursor
                        >
                          <TableCell className="edit-plan-label">
                            <Button
                              variant="grayOutlined"
                              type="button"
                              disabled={!row?.payment}
                              sx={{
                                mr: '8px',
                                ml: '15px',
                                padding: '7px',
                                minWidth: 'auto',
                                '& svg path': { transition: 'all .5s ease' },
                                '&:hover svg path': { fill: colors.theme },
                              }}
                              onClick={() => {
                                handleInvoice(row?.payment?.p_payments_id);
                                setModalOpen(true);
                              }}
                            >
                              <PaySlip />
                            </Button>
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                      <InvoiceModal closeModal={closeModal} modalOpen={modalOpen} modalFile={billingData} />
                    </>
                  ))
                ) : (
                  <TableRow sx={{ width: '100%', height: '100%' }}>
                    <TableCell colSpan={10} sx={{ textAlign: 'center', border: '0' }}>
                      <img
                        src={DataNotFound}
                        alt="data not found"
                        style={{ width: '80px', height: '80px' }}
                      />
                      <Typography>Data not found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {userplanInfo?.data?.billingHistory?.length > 5 && (
            <Box className="pagination-block">
              <Pagination
                count={Math.ceil(userplanInfo?.data?.billingHistory?.length / rowsPerPage)}
                page={page}
                onChange={(event, value) => setPage(value)}
              />
            </Box>
          )}
        </Box>

        <ActivePlanconfirmationModal
          activePlanconfirmationModal={isModalOpen}
          setActivePlanconfirmationModal={setIsModalOpen}
        />
      </Grid>
    </Grid>
  );
};

export default BillingTable;
