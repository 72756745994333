import React from "react";
import { stringToCamelCase } from "../../utils/CommonFunctions/commonFile";
import { ReactComponent as SortNone } from "../../assets/images/sort-none.svg";
import { ReactComponent as SortTop } from "../../assets/images/sort-top.svg";
import { ReactComponent as SortBottom } from "../../assets/images/sort-bottom.svg";

const SortingHelper = ({
  item,
  sortConfig,
  keyName = "key",
  excludeTab = "action",
}) => {
  return stringToCamelCase(item[keyName])?.includes(
    excludeTab
  ) ? null : sortConfig.key === stringToCamelCase(item[keyName]) ? (
    sortConfig?.direction ? (
      <span>
        <SortBottom />
      </span>
    ) : (
      <span>
        <SortTop />
      </span>
    )
  ) : (
    <span>
      <SortNone />
    </span>
  );
};

export default SortingHelper;
