import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Header from './header';
import Sidebar from './sidebar';

const Layout = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  return (
    <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      {isMobile ? '' : <Sidebar />}
      <Box
        sx={{
          width: { xl: 'calc(100% - 315px)', md: 'calc(100% - 260px)', xs: '100%' },
          marginLeft: 'auto',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Header />
        <Box
          sx={{
            padding: '24px',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
