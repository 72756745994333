import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { formatDate } from '../../../components/common/dateFormat';
import { colors } from '../../../theme/colors';
import { ThreeDots } from 'react-loader-spinner';
import { getPaymentListAPI } from '../../../redux/paymentListAPI';

const UserPaymentDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [userPaymentData, setUserPaymnetData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const FormLays = styled(Box)(({ theme }) => ({
    borderRadius: '16px',
    backgroundColor: colors.background.formBg,
    height: '100%',
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: '#242323',
      textWrap: 'nowrap',
    },
    [`&.${tableCellClasses.body}`]: {
      color: '#686465',
      fontWeight: 400,
    },
  }));

  const TableRowStyle = {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '& td': {
      padding: '22px 20px',
      whiteSpace: 'nowrap',
    },
    '& a': {
      border: `1px solid ${colors.theme}`,
      padding: '7px',
      display: 'inline-flex',
      verticalAlign: 'middle',
      width: 'fit-content',
      alignItems: 'center',
      minWidth: 'auto',
      borderRadius: '4px',
      transition: 'all .5s ease',
      '& svg path': {
        transition: 'all .5s ease',
      },
      '&:hover': {
        backgroundColor: colors.theme,
      },
      '&:hover svg path': {
        fill: '#fff',
        stroke: colors.theme,
      },
    },
  };

  const columName = [
    {
      id: 1,
      name: 'Date',
    },
    {
      id: 2,
      name: 'Plan',
    },
    {
      id: 3,
      name: 'Status',
    },
    {
      id: 4,
      name: 'Amount',
    },
    {
      id: 5,
      name: 'Mode',
    },
    {
      id: 6,
      name: 'Type',
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    const getPaymentOption = async () => {
      const today = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);
      const paymentObj = {
        page: 0,
        limit: 2,
        customer: id,
        owner: '',
        productType: '',
        customerSource: '',
        search: '',
        filterDateRange: '',
        fromDate: '',
        toDate: '',
      };
      const payload = await dispatch(getPaymentListAPI(paymentObj));

      if (payload?.payload) {
        setUserPaymnetData(payload?.payload?.billing?.payments);
        setIsLoading(false);
      } else {
        toast(payload?.payload?.response?.message);
        setIsLoading(false);
      }
    };
    getPaymentOption();
  }, []);

  return (
    <Grid item lg='6' xs='12'>
      <FormLays>
        <Box sx={{ padding: { sm: '24px', xs: '20px' } }}>
          <Typography variant='h3' sx={{ color: colors.basics.normal, paddingBottom: '8px' }}>
            Payments
          </Typography>
          <TableContainer
            component={Paper}
            sx={{
              width: '100% !important',
              height: '300px',
              boxShadow: 'none',
              borderRadius: '12px',
              overflow: 'hidden',
              '&:hover': {
                overflow: 'auto',
              },
            }}
          >
            <Table
              aria-label='simple table'
              sx={{
                width: '100%',
                '& th, td': {
                  padding: '20px 11px',
                },
                position: 'relative',
              }}
            >
              <TableHead
                sx={{
                  position: 'sticky',
                  backgroundColor: '#ffffff',
                  top: 0,
                  zIndex: 1,
                }}
              >
                <TableRow>
                  {columName?.map((item) => (
                    <StyledTableCell sx={{ fontSize: '18px' }} key={item > id}>
                      {item?.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow sx={{ textAlign: 'center' }}>
                    <TableCell colSpan={16}>
                      <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                        <ThreeDots
                          visible={true}
                          height='80'
                          width='80'
                          color={colors.theme}
                          radius='9'
                          ariaLabel='three-dots-loading'
                          wrapperStyle={{}}
                          wrapperClass=''
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {userPaymentData?.length > 0 ? (
                      userPaymentData?.slice(0, 5)?.map((row) => (
                        <TableRow sx={TableRowStyle}>
                          <StyledTableCell sx={{ fontSize: '18px' }}>
                            {formatDate(row?.startDate)}
                          </StyledTableCell>
                          <StyledTableCell sx={{ fontSize: '18px' }}>{row?.planName}</StyledTableCell>
                          <StyledTableCell sx={{ fontSize: '18px' }}> {row?.paymentStatus}</StyledTableCell>
                          <StyledTableCell sx={{ fontSize: '18px' }}>{row?.paymentAmount}</StyledTableCell>
                          <StyledTableCell sx={{ fontSize: '18px' }}>{row?.paymentMethod}</StyledTableCell>
                          <StyledTableCell sx={{ fontSize: '18px' }}>{row?.productType}</StyledTableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow sx={{ width: '100%', height: '100%' }}>
                        <TableCell colSpan={16} sx={{ textAlign: 'center', border: '0' }}>
                          <img
                            src={DataNotFound}
                            alt='data not found'
                            style={{ width: '80px', height: '80px' }}
                          />
                          <Typography>Data not found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </FormLays>
    </Grid>
  );
};

export default UserPaymentDetails;
