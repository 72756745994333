import React from 'react';
import { Checkbox, Box, Typography, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as CheckedCheckBoxIcon } from '../../assets/images/checked.svg';
import { ReactComponent as UncheckedCheckBoxIcon } from '../../assets/images/unchecked.svg';

const CustomCheckBox = styled(({ label, isChecked, onChange, name, onClick, color, disabled }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <FormControlLabel
      sx={{ ml: '0' }}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isChecked ? <CheckedCheckBoxIcon /> : <UncheckedCheckBoxIcon />}
          <Typography variant='subtitle1' sx={{ ml: '8px' }} color={color || 'initial'}>
            {label}
          </Typography>
        </Box>
      }
      control={
        <Checkbox
          name={name}
          value={isChecked}
          checked={isChecked}
          onChange={onChange}
          sx={{ display: 'none' }}
          color='primary'
          onClick={onClick}
          disabled={disabled}
        />
      }
    />
  </Box>
))(({ theme }) => ({}));

export default CustomCheckBox;
