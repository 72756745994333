import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getKycDetailFileUser = createAsyncThunk('kyc-details-files-user', async (data) => {
  try {
    const response = await API.get(`kyc-details/files/user/${data.userId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  kycdetailsfilesuser: {},
};

const getKycdetailsfilesuserSlice = createSlice({
  name: 'kyc-details-files-user',
  initialState,
  reducers: {
    setKycdetailsfilesuser: (state, { payload }) => {
      state.kycdetailsfilesuser = payload;
    },
  },
});

export const { setKycdetailsfilesuser } = getKycdetailsfilesuserSlice.actions;
export default getKycdetailsfilesuserSlice.reducer;
