import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import * as yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { setError, setUserSignIn, userSignInAPI } from '../../../redux/userSignInAPI';
import CustomTextBox from '../../../components/common/customTextBox';
import CustomCheckBox from '../../../components/common/customCheckBox';
import CustomButton from '../../../components/common/customButton';
import { setSignAdmin } from '../../../redux/signUpApi';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';

const encryptAndSetItem = (key, data) => {
  const timestamp = new Date().getTime();
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ data, timestamp }), 'proxy').toString();
  localStorage.setItem(key, encryptedData);
};

const getItemAndDecrypt = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'proxy');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return { data: decryptedData.data, timestamp: decryptedData.timestamp };
  }
  return null;
};

const AdminLogin = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fornErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [_, setCookies] = useCookies(['admin_access_token_proxy']);
  const decryptedEmail = getItemAndDecrypt('adminEmailProxy');
  const decryptedPassword = getItemAndDecrypt('adminPasswordProxy');
  const [adminloginData, setAdminLoginData] = useState({
    p_contact_email: decryptedEmail?.data || '',
    p_password: decryptedPassword?.data || '',
    isRememberMe: JSON.parse(localStorage.getItem('adminRemberUserProxy') || false),
  });

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
    'in',
    'co',
    'io',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const validationSchema = yup.object().shape({
    p_contact_email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              username.includes('-') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    p_password: yup
      .string()
      // .matches(
      //   /^(?!.*[@#])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$!%*?&])[A-Za-z\d$!%*?&]+$/,
      //   "Passwords must be at least 8 characters long and include at least one uppercase, one lowercase, one numeric, and one special character. # and @ are not allowed"
      // )
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setFormErrors({});

    try {
      await validationSchema.validate(adminloginData, { abortEarly: false });
      const loginObj = {
        p_contact_email: adminloginData?.p_contact_email,
        p_password: adminloginData?.p_password,
      };

      const data = await dispatch(userSignInAPI(loginObj));

      await dispatch(setUserSignIn(data));
      const expirationTime = dayjs().add(data?.payload?.data?.expiresIn, 'seconds').toDate();
      if (data?.payload?.status === 200) {
        setCookies('admin_access_token_proxy', data?.payload?.data?.token, {
          path: '/',
          httpOnly: false,
          expires: expirationTime,
          secure: false,
          sameSite: 'strict',
        });
        if (data?.payload?.data?.p_web_access === 1) {
          if (adminloginData?.isRememberMe === true) {
            encryptAndSetItem('adminEmailProxy', adminloginData.p_contact_email);
            encryptAndSetItem('adminPasswordProxy', adminloginData.p_password);
            await dispatch(setSignAdmin(data?.payload?.data));
            await navigate('/admin/dashboard');
          } else if (adminloginData?.isRememberMe === false) {
            localStorage.removeItem('adminPasswordProxy');
            localStorage.removeItem('adminEmailProxy');

            await dispatch(setSignAdmin(data?.payload?.data));
            await navigate('/admin/dashboard');
          }
        } else if (data?.payload?.data.p_web_access === 0) {
          toast.error('Access Denied: Admin credentials required.');
        }
      } else {
        toast.error(data?.payload?.response?.data?.message || data?.payload?.message);
      }

      setIsLoading(false);
    } catch (err) {
      const errors = {};
      if (err.inner && err.inner.length > 0) {
        err.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
      } else {
        console.error(err);
      }

      dispatch(setError(err));
      setFormErrors(errors);
      setIsLoading(false);
    }
  };
  return (
    <AuthenticationWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <Typography variant="h1" sx={{ color: theme.palette.neutral.dark }}>
          Sign in
        </Typography>
        <Typography variant="subtitle5" sx={{ color: theme.palette.neutral.normal }}>
          Welcome Back!
        </Typography>
      </Box>
      <form style={{ width: '100%' }} onSubmit={handleLogin}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '20px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <CustomTextBox
              placeholder="Enter email id"
              icon
              iconType={'email'}
              value={adminloginData.p_contact_email}
              fornErrors={fornErrors}
              onChange={(e) => {
                setAdminLoginData({ ...adminloginData, p_contact_email: e.target.value.trim() });
                setFormErrors({ ...fornErrors, p_contact_email: '' });
              }}
            />
            {fornErrors?.p_contact_email && (
              <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                {fornErrors?.p_contact_email}
              </Typography>
            )}{' '}
          </Box>
          <Box sx={{ width: '100%' }}>
            <CustomTextBox
              placeholder="Password"
              icon
              type="password"
              iconType={'password'}
              value={adminloginData.p_password}
              onChange={(e) => {
                setAdminLoginData({ ...adminloginData, p_password: e.target.value });
                setFormErrors({ ...fornErrors, p_password: '' });
              }}
              fornErrors={fornErrors}
            />
            {fornErrors?.p_password && (
              <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                {fornErrors?.p_password}
              </Typography>
            )}{' '}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '10px',
              width: '100%',
              flexWrap: { sm: 'nowrap', xs: 'wrap' },
            }}
          >
            <CustomCheckBox
              label="Remember me"
              color="initial"
              isChecked={adminloginData.isRememberMe}
              sx={{ marginRight: 'auto' }}
              onChange={(e) => {
                setAdminLoginData({ ...adminloginData, isRememberMe: e.target.checked });
                setFormErrors({ ...fornErrors, isRememberMe: '' });
                localStorage.setItem('adminRemberUserProxy', e.target.checked.toString());
              }}
            />
            <Link to="/admin/forgetpassword">
              <Typography variant="subtitle2" sx={{ color: theme.palette.linkColor }}>
                Forgot password?
              </Typography>
            </Link>
          </Box>
          <CustomButton type="submit" onClick={handleLogin} disabled={isLoading}>
            Sign in
            {isLoading && (
              <ColorRing
                visible={true}
                height="30"
                width="30"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
              />
            )}
          </CustomButton>
        </Box>
      </form>
    </AuthenticationWrapper>
  );
};

export default AdminLogin;
