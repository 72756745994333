import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const countryCityAPI = createAsyncThunk('country-cities', async (data) => {
  try {
    const response = await API.get(`/authentication/country-cities?countryCode=${data?.countryCode}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  countryCity: {},
};

const countryCitesSlice = createSlice({
  name: 'country-cities',
  initialState,
  reducers: {
    setCountryStateSlice: (state, { payload }) => {
      state.countryCity = payload;
    },
  },
});

export const { setCountryStateSlice } = countryCitesSlice.actions;
export default countryCitesSlice.reducer;
