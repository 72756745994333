import React from 'react';
import { Typography, Box } from '@mui/material';
import { colors } from '../../../theme/colors';

function UserDashboardAccountActivity() {
  return (
    <Box
      sx={{
        padding: '24px',
      }}
    >
      <Box>
        <Typography sx={{ fontSize: '24px', fontWeight: 600, color: colors.neutral.dark }}>
          System customer support
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: '600px',
          '& div[role="tabpanel"] div': {
            padding: 0,
          },
        }}
      >
        <Box>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 400,
                  color: colors.basics.normal,
                  textAlign: 'justify',
                  lineHeight: '30px',
                }}
              >
                We’re here to solve your query. If you have any questions, please contact us on{' '}
                <a href='mailto:support@prox-e.io'>support@prox-e.io</a>.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UserDashboardAccountActivity;
