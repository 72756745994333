import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getCustomerDetailsAPI = createAsyncThunk('customer-details', async (data) => {
  try {
    const response = await API.get(
      `users/customer/details?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  customerDetails: {},
};

const getCustomerDetailsSlice = createSlice({
  name: 'customer-details',
  initialState,
  reducers: {
    setCustomerDetails: (state, { payload }) => {
      state.customerDetails = payload;
    },
  },
});

export const { setCustomerDetails } = getCustomerDetailsSlice.actions;
export default getCustomerDetailsSlice.reducer;
