export const isEmpty = (str) => {
  if (str !== '' && str !== 'null' && str !== undefined && str !== null && str !== 'undefined') {
    return str;
  } else {
    return '';
  }
};

export const IsCreditCardExpired = (cc_exp) => {
  const date = cc_exp || null;
  const expMonth = parseInt(date?.slice(0, 2), 10);
  const expYear = parseInt(date?.slice(2, 4), 10) + 2000;
  const expDate = new Date(expYear, expMonth, 0);
  const currentDate = new Date();
  return currentDate > expDate;
};

export const stringToCamelCase = (str) => {
  return str
    ?.toLowerCase()
    ?.split(/[^a-zA-Z0-9]+/)
    ?.map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    ?.join('');
};

export const convertStringtoCapitalize = (element) => {
  return element.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

export const convertHeader = (headerTitle) => {
  return headerTitle.split('_')[1];
};
