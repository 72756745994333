import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const getEditCustomerDetailAPI = createAsyncThunk('get-edit-customer-detail', async (data) => {
  try {
    const response = await API.get(`/users/admin/customer/details/${data?.customerId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  getEditCustomerDetail: {},
};

const editCustomerDetailSlice = createSlice({
  name: 'get-edit-customer-detail',
  initialState,
  reducers: {
    setEditCustomerDetails: (state, { payload }) => {
      state.getEditCustomerDetail = payload;
    },
  },
});

export const { setEditCustomerDetails } = editCustomerDetailSlice.actions;
export default editCustomerDetailSlice.reducer;
