import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { ReactComponent as SortTop } from '../../../assets/images/sort-top.svg';
import { ReactComponent as SortBottom } from '../../../assets/images/sort-bottom.svg';
import { ReactComponent as SortNone } from '../../../assets/images/sort-none.svg';
import { colors } from '../../../theme/colors';
import { styled } from '@mui/material/styles';
import { ReactComponent as EditIcon } from '../../../assets/images/Edit.svg';
import { formatDate } from '../../../components/common/dateFormat';
import { ThreeDots } from 'react-loader-spinner';

const RecentCustomersTable = ({ adminData, isLoading }) => {
  const navigate = useNavigate();
  const [customerDetailsData, setCustomerDetailsData] = useState();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: '#242323',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: '#686465',
      fontWeight: 400,
    },
  }));

  const tableHeading = [
    { name: 'Plan Name', sortName: 'planName' },
    { name: 'Bandwidth', sortName: 'planVolume' },
    { name: 'Type', sortName: 'productName' },
    { name: 'Start Date', sortName: 'planStart' },
    { name: 'End Date', sortName: 'planEnd' },
    { name: 'Active', sortName: 'planStatus' },
    { name: 'Usage', sortName: 'planUsage' },
    { name: 'Added By', sortName: 'addedBy' },
  ];

  useEffect(() => {
    setCustomerDetailsData(adminData?.customerUsage);
  }, [adminData?.customerUsage]);

  const sortData = (property) => {
    const newSortConfig = { ...sortConfig };

    if (newSortConfig.key === property) {
      newSortConfig.direction = newSortConfig.direction === 'asc' ? 'desc' : 'asc';
    } else {
      newSortConfig.key = property;
      newSortConfig.direction = 'asc';
    }

    const sortedData = [...customerDetailsData].sort((a, b) => {
      const valueA = String(a[property]).toLowerCase();
      const valueB = String(b[property]).toLowerCase();

      if (newSortConfig.direction === 'asc') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setSortConfig(newSortConfig);
    setCustomerDetailsData(sortedData);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Box>
        <Typography
          sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px', color: colors?.neutral?.dark }}
        >
          Recent Customer details
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          width: '100% !important',
          boxShadow: 'none',
          borderRadius: '12px',
          overflowX: 'hidden',
          '&:hover': {
            overflowX: 'auto',
          },
        }}
      >
        <Table
          sx={{
            width: '100%',
            '& th, td': {
              padding: '12px 20px',
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                ' & th': {
                  fontWeight: '500',
                  fontSize: '18px',
                  whiteSpace: 'nowrap',
                  color: colors.neutral.dark,
                  position: 'relative',
                  cursor: 'pointer',
                  '& .sort-arrow': {
                    position: 'relative',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                },
              }}
            >
              {tableHeading.map((item, index) => {
                return (
                  <StyledTableCell onClick={() => sortData(item.sortName)} key={index}>
                    <div className='sort-icon'>
                      <span>{item.name}</span>
                      {sortConfig.key === item.sortName ? (
                        sortConfig.direction === 'asc' ? (
                          item.name === 'Payment link' ? (
                            ' '
                          ) : (
                            <span>
                              <SortTop />
                            </span>
                          )
                        ) : (
                          <span>
                            <SortBottom />
                          </span>
                        )
                      ) : item.name === 'Payment link' ? null : (
                        <span>
                          <SortNone />
                        </span>
                      )}
                    </div>
                  </StyledTableCell>
                );
              })}
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow sx={{ textAlign: 'center' }}>
                <TableCell colSpan={16}>
                  <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                    <ThreeDots
                      visible={true}
                      height='80'
                      width='80'
                      color={colors.theme}
                      radius='9'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClass=''
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {customerDetailsData?.length > 0 ? (
                  customerDetailsData?.slice(0, 2)?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                          '& td': {
                            padding: '10px 20px 10px 20',
                            whiteSpace: 'nowrap',
                          },
                          '& button': {
                            minWidth: 'unset',
                            padding: ' 8px',
                            '&:hover svg path': {
                              fill: '#fff',
                            },
                          },
                        }}
                      >
                        <StyledTableCell className='recent-table-tr'>{item?.planName}</StyledTableCell>
                        <StyledTableCell className='recent-table-tr'>{item.planVolume}</StyledTableCell>
                        <StyledTableCell className='recent-table-tr'>
                          {item.productTypeId === 1
                            ? 'Datacenter Proxy'
                            : item.productTypeId === 2
                            ? 'Residential Proxy'
                            : item.productTypeId === 3
                            ? 'Mobile Proxy'
                            : item.productTypeId === 4
                            ? 'Custom'
                            : ''}
                        </StyledTableCell>
                        <StyledTableCell className='recent-table-tr'>
                          {formatDate(item.planStart)}
                        </StyledTableCell>
                        <StyledTableCell className='recent-table-tr'>
                          {formatDate(item.planEnd)}
                        </StyledTableCell>
                        <StyledTableCell className='recent-table-tr'>{item.planStatus}</StyledTableCell>
                        <StyledTableCell className='recent-table-tr'>
                          {isNaN(parseFloat(item.planUsage))
                            ? ''
                            : Math.round(parseFloat(item.planUsage)) + '%'}
                        </StyledTableCell>
                        <StyledTableCell className='recent-table-tr'>{item.addedBy}</StyledTableCell>
                        <StyledTableCell className='edit-button-dashboard'>
                          <Box>
                            <Button
                              sx={{
                                width: '48px',
                                background: '#ffffff',
                                height: '48px',
                                fontSize: '20px',
                                fontWeight: 700,
                                border: '1px solid #FD337E',
                                textTransform: 'capitalize',
                                borderRadius: '6px',
                                boxShadow: '0 0 0 0 !important',
                                color: '#FD337E ',
                                '&:hover': {
                                  backgroundColor: '#FD337E',
                                },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/admin/customer-details/edit/${item.customerId}`);
                              }}
                            >
                              <EditIcon />
                            </Button>
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow sx={{ width: '100%', height: '100%' }}>
                    <TableCell colSpan={16} sx={{ textAlign: 'center', border: '0' }}>
                      <img
                        src={DataNotFound}
                        alt='data not found'
                        style={{ width: '80px', height: '80px' }}
                      />
                      <Typography>Data not found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecentCustomersTable;
