import { Box, Typography, Tabs, Tab, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../theme/colors';
import ProxyPlanCard from './ProxyPlanCard';
import { ProductProxyPlanAPI } from '../../../redux/proxyplanAPI';
import Loader from '../../../components/common/Loding';
import { useGetUser } from '../../../context/userProfile';

const ProxyPlans = () => {
  const dispatch = useDispatch();
  const { logInUserDetails } = useGetUser();
  const proxySelect = useSelector((state) => state?.paymentListReducer?.paymentList);
  const [tabValue, setTabValue] = useState(1);
  const [planLoading, setPlanLoading] = useState(true);
  const [planType, setPanType] = useState(false);
  const [proxyPlan, setProxyPlan] = useState([]);

  useEffect(() => {
    if (proxySelect?.customerId || logInUserDetails?.p_customer_id) {
      const getProxyPlan = async () => {
        const paymentdata = {
          p_customer_id: proxySelect?.customerId ? proxySelect?.customerId : logInUserDetails?.p_customer_id,
        };
        const data = await dispatch(ProductProxyPlanAPI(paymentdata));

        if (data?.payload) {
          setProxyPlan(data?.payload);
          setPlanLoading(false);
        } else {
          console.error(data?.payload?.message);
          setPlanLoading(false);
        }
      };
      getProxyPlan();
    }
  }, [dispatch, logInUserDetails?.p_customer_id, proxySelect?.customerId]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const TabPanel = ({ children, value, index }) => (
    <Box key={index} role='tabpanel' hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );

  return (
    <>
      <Box>
        <Typography variant='h2' sx={{ mb: 3, color: colors?.neutral.dark, ml: 1 }}>
          Buy Plan
        </Typography>
        <Box
          sx={{
            backgroundColor: colors.background.formBg,
            borderRadius: '16px',
            padding: '24px',
          }}
        >
          <Box sx>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              sx={{
                backgroundColor: colors.basics.white,
                padding: '10px',
                borderRadius: '6px',
                maxWidth: '984px',
                margin: 'auto',
                minHeight: 'auto',
                '& .MuiTabs-scroller ': {
                  overflow: 'auto !important',
                },
                '& .MuiTabs-indicator ': {
                  display: 'none',
                },
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'space-evenly',
                },
                '& .MuiButtonBase-root': {
                  flexGrow: '1',
                  color: ` ${colors?.basics?.normal}`,
                  fontWeight: 400,
                  maxWidth: { xl: '25%' },
                  marginRight: { xl: '0', xs: '30px' },
                  '&:last-child': {
                    marginRight: 0,
                  },
                },
                '& .Mui-selected': {
                  backgroundColor: colors?.tabBackgroundColor,
                  color: ` ${colors?.basics?.white} !important`,
                  borderRadius: '8px',
                  fontWeight: 600,
                },
              }}
            >
              {proxyPlan?.map(
                (item, index) =>
                  (['Datacenter Proxy', 'Residential Proxy', 'Mobile Proxy'].includes(item?.p_name) ||
                    (item?.p_name === 'Custom' && item?.plans?.length > 0)) && (
                    <Tab
                      key={index}
                      label={item?.p_name}
                      sx={{
                        textTransform: 'capitalize',
                        minHeight: 'auto',
                        lineHeight: '1.5',
                        padding: '13px 24px',
                        fontSize: { lg: '20px', md: '18px', xs: '16px' },
                        fontWeight: 600,
                      }}
                      value={index}
                    />
                  )
              )}
            </Tabs>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: { lg: '48px 0 46px', xs: '30px 0' },
            }}
          >
            {proxyPlan?.length > 0 && proxyPlan?.[tabValue]?.p_name !== 'Custom' && (
              <>
                <Typography
                  variant='h4'
                  sx={{ color: planType ? colors.neutral.dark : colors.basics.normal }}
                >
                  Monthly
                </Typography>
                <Switch
                  sx={{
                    '&.MuiSwitch-root ': {
                      borderRadius: '50px',
                      height: { sm: '30px', xs: '25px' },
                      width: { sm: '58px', xs: '50px' },
                      padding: '0',
                      margin: '0 26px',
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        transform: { sm: 'translateX(28px)', xs: 'translateX(25px)' },
                      },
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: `${colors.theme} !important`,
                      opacity: '1 !important',
                      borderRadius: '50px',
                      border: `1px solid ${colors.theme}`,
                      height: { sm: '28px', xs: '23px' },
                    },
                    '& .MuiSwitch-thumb ': {
                      backgroundColor: colors.input.white,
                      boxShadow: 'none',
                      width: { sm: '20px', xs: '15px' },
                      height: { sm: '20px', xs: '15px' },
                    },
                    '& .MuiSwitch-switchBase': {
                      padding: '5px',
                      '&.Mui-checked': {
                        '& .MuiSwitch-thumb ': {
                          backgroundColor: colors.basics.white,
                        },
                      },
                    },
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={planType}
                  onChange={(e) => {
                    setPanType(e.target.checked);
                  }}
                  defaultChecked
                />
                <Typography
                  variant='h4'
                  sx={{ color: !planType ? colors.neutral.dark : colors.basics.normal }}
                >
                  Yearly
                </Typography>
              </>
            )}
          </Box>

          {planLoading ? (
            <Loader />
          ) : (
            proxyPlan &&
            proxyPlan?.length > 0 &&
            proxyPlan?.map((item, index) => (
              <TabPanel key={index} value={tabValue} index={index}>
                <ProxyPlanCard
                  products={item.plans}
                  plansType={planType}
                  values={index}
                  tabValue={tabValue}
                  proxyPlan={proxyPlan}
                />
              </TabPanel>
            ))
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProxyPlans;
