import React from 'react';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const CustomStyledButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  width: '100%',
  backgroundColor: theme.palette.accent1,
  color: theme.palette.neutral.white,
  textTransform: 'initial',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '30px',
  borderRadius: '8px',
  padding: '13px 0',
  '&.MuiButtonBase-root.MuiButton-root:hover': {
    backgroundColor: theme.palette.accent1,
  },
}));

const CustomButton = ({ width, type, onKeyDown, disabled, onClick, ...props }) => {
  const theme = useTheme();

  return (
    <CustomStyledButton
      {...props}
      type={type}
      theme={theme}
      onClick={onClick}
      disabled={disabled}
      sx={{
        width,
        fontSize: {
          xs: '18px',
          md: '20px',
        },
      }}
    />
  );
};

export default CustomButton;
