import { combineReducers } from '@reduxjs/toolkit';
import registerUser from './signUpApi';
import OtpVerificationAPI from './otpVerificationCodeAPI/index';
import userSignInReducer from './userSignInAPI';
import resetPawordEmail from './resetPasswordEmailSentAPI';
import resetPassword from './forgotPasswordAPI';
import changePasswordAPI from './changePasswordAPI';
import KycRegisterAPI from './kycFormAPI';
import userProfileReducer from './userProfileAPI';
import kycDetailGet from './kycDetailGetAPI';
import deleteFileAPI from './deleteFileAPI';
import countryStateReducer from './stateAPI';
import stateCityReducer from './cityAPI';
import countryCityReducer from './countryToCityAPI';
import accountBillingInfoReducer from './accountInformationAPI';
import billingDetailReducer from './billingInfoGetAPI';
import updateBillingInfoReducer from './updateAccountInfoAPI';
import traficUsageReducer from './traficUsageAPI';
import DashBoardChartReducer from './dashBoardChartAPI';
import ProductProxyPlanReducer from './proxyplanAPI';
import userBillingInfoReducer from './userBillingInformationAPI';
import autoRenalReducer from './autoRenewalAPI';
import useracitivityReducer from './useractivityAPI';
import userRequestReducer from './userRequestAPI';
import singleUserGetReducer from './getUserByIdAPI';
import addPlanReducer from './addPlanAPI';
import getSigndleFileReducer from './getsingleFileAPI';
import paymentListReducer from './paymentListAPI';
import planListReducer from './planListAPI';
import customerDetailsReducer from './customerDetailsAPI';
import getIdWiseCustomerPlanDetailsReducer from './idWiseCustomerPlanGetAPI';
import editCustomerPlanReducer from './editCustomerPlanAPI';
import searchInputReducer from './searchInput';
import subscriptionReducer from './subscriptionAPI';
import adminProfileReducer from './adminProfileAPI';
import adminDashBoradReducer from './adminDashboardAPI';
import getEditCustomerDetailReducer from './getEditCustomerDetailAPI';
import getOwnerReducer from './getOwnerAPI';
import getKycDetailFileUserReducer from './userActivityKycAccountInfo';
import editCustomerDetailReducer from './editCustomerDetails';
import addCustomerReducer from './addCustomerAPI';
import updateContactNumbrReducer from './updateContactNumber';
import adminPaymentReducer from './adminpaymentAPI';
import getCustomerReducer from './getCustomerAPI';
import editPlanWiseCustomerReducer from './editPlanCustomerAPI';
import getCustomCustomerPlansReducer from './getCustomCustomerPlansAPI';
import getUsedBandwidthReducer from './usedBandwidthAPI';
import EmailconfirmationReducer from './emailConfiramationAPI';
import EmailOTPverificationReducer from './emailVerificationOtpAPI';
import customerEditEmailReducer from './customerEditEmailAPI';
import CustomersplansSubscribeReducer from './tranzillaTokenWisePaymnet';

const rootReducer = combineReducers({
  userSignInReducer: userSignInReducer,
  registerReducer: registerUser,
  OtpVerificationReducer: OtpVerificationAPI,
  resetPawwordEmailReducer: resetPawordEmail,
  resetPasswordReducer: resetPassword,
  changePasswordReducer: changePasswordAPI,
  KycRegisterReducer: KycRegisterAPI,
  userProfileReducer: userProfileReducer,
  kycDetailGetReducer: kycDetailGet,
  deleteKycFileReducer: deleteFileAPI,
  countryStateReducer: countryStateReducer,
  stateCityReducer: stateCityReducer,
  countryCityReducer: countryCityReducer,
  accountBillingInfoReducer: accountBillingInfoReducer,
  billingDetailReducer: billingDetailReducer,
  updateBillingInfoReducer: updateBillingInfoReducer,
  traficUsageReducer: traficUsageReducer,
  DashBoardChartReducer: DashBoardChartReducer,
  ProductProxyPlanReducer: ProductProxyPlanReducer,
  userBillingInfoReducer: userBillingInfoReducer,
  autoRenalReducer: autoRenalReducer,
  useracitivityReducer: useracitivityReducer,
  userRequestReducer: userRequestReducer,
  singleUserGetReducer: singleUserGetReducer,
  addPlanReducer: addPlanReducer,
  getSigndleFileReducer: getSigndleFileReducer,
  paymentListReducer: paymentListReducer,
  planListReducer: planListReducer,
  customerDetailsReducer: customerDetailsReducer,
  getIdWiseCustomerPlanDetailsReducer: getIdWiseCustomerPlanDetailsReducer,
  editCustomerPlanReducer: editCustomerPlanReducer,
  searchInputReducer: searchInputReducer,
  subscriptionReducer: subscriptionReducer,
  adminProfileReducer: adminProfileReducer,
  adminDashBoradReducer: adminDashBoradReducer,
  getEditCustomerDetailReducer: getEditCustomerDetailReducer,
  getOwnerReducer: getOwnerReducer,
  getKycDetailFileUserReducer: getKycDetailFileUserReducer,
  editCustomerDetailReducer: editCustomerDetailReducer,
  addCustomerReducer: addCustomerReducer,
  updateContactNumbrReducer: updateContactNumbrReducer,
  adminPaymentReducer: adminPaymentReducer,
  getCustomerReducer: getCustomerReducer,
  editPlanWiseCustomerReducer: editPlanWiseCustomerReducer,
  getCustomCustomerPlansReducer: getCustomCustomerPlansReducer,
  getUsedBandwidthReducer: getUsedBandwidthReducer,
  EmailconfirmationReducer: EmailconfirmationReducer,
  EmailOTPverificationReducer: EmailOTPverificationReducer,
  customerEditEmailReducer: customerEditEmailReducer,
  CustomersplansSubscribeReducer: CustomersplansSubscribeReducer,
});

export default rootReducer;
