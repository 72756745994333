import React, { useEffect, useState } from 'react';
import { colors } from '../../../theme/colors';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';

const UserDetails = () => {
  const userSelector = useSelector((state) => state);
  const useData = userSelector?.singleUserGetReducer?.userById?.payload;
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(useData);
    setLoading(false);
  }, [useData]);

  return (
    <Box
      className='kyc-updation-form'
      sx={{
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '12px',
      }}
    >
      {loading ? (
        <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
          <ThreeDots
            visible={true}
            height='80'
            width='80'
            color={colors.theme}
            radius='9'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClass=''
          />
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              color: colors?.useractivitylabelcolor,
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            User Details
          </Typography>
          <Box borderBottom='1px solid #E5E5E5' sx={{ paddingTop: '15px' }}></Box>
          <Box
            sx={{
              display: 'flex',
              rowGap: '24px',
              justifyContent: 'space-between',
              marginTop: '16px',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box sx={{ width: 'fit-content' }}>
                <Typography
                  sx={{
                    color: colors?.useractivitylabelcolor,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Username
                </Typography>
                <Typography
                  sx={{
                    color: colors?.detailcolor,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 400,
                  }}
                >
                  {data?.p_customer_name ? data?.p_customer_name : data?.p_login}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box sx={{ width: 'fit-content', marginTop: { lg: '0', xs: '5px' } }}>
                <Typography
                  sx={{
                    color: colors?.useractivitylabelcolor,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    color: colors?.detailcolor,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 400,
                  }}
                >
                  {data?.p_contact_email ? data?.p_contact_email : ''}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box sx={{ width: 'fit-content', marginTop: { lg: '0', xs: '5px' } }}>
                <Typography
                  sx={{
                    color: colors?.useractivitylabelcolor,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Signup Date
                </Typography>
                <Typography
                  sx={{
                    color: colors?.detailcolor,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 400,
                  }}
                >
                  {data?.p_added_date
                    ? new Date(data.p_added_date)
                        .toLocaleString('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })
                        .replace(/\//g, '-')
                    : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserDetails;
