import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import DatePicker from 'react-datepicker';
import { useCookies } from 'react-cookie';
import { colors } from '../../../theme/colors';
import CustomTextBox from '../../../components/common/customTextBox';
import CustomButton from '../../../components/common/customButton';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/dropdownDownArrow.svg';
import { countryStateAPI } from '../../../redux/stateAPI';
import { stateCityAPI } from '../../../redux/cityAPI';
import { countryCityAPI } from '../../../redux/countryToCityAPI';
import { setUpdateAccountInfo, updateAccountInfo } from '../../../redux/updateAccountInfoAPI';
import { setSignUpUserdata } from '../../../redux/signUpApi';
import { accountInformationAPI, setAccountBllinginfo } from '../../../redux/accountInformationAPI';
import { getKycDetails } from '../../../redux/kycDetailGetAPI';
import { getBillingInfoDetails, setBillingDetails } from '../../../redux/billingInfoGetAPI';
import Loader from '../../../components/common/Loding';
import Calendar from '../../../assets/images/Calendar.svg';
import { CountryListOptions } from '../../../components/common/country';

const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
  '& .MuiSelect-select[aria-expanded="true"] ~ svg ': {
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
    fontSize: '16px',
  },
  '& .MuiSelect-icon': {
    display: 'block',
  },
  '& svg': {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
    transition: 'all .5s ease',
  },
  '&.MuiOutlinedInput-root ': {
    borderRadius: '8px !important',
  },
  '& .MuiSelect-select  em': {
    fontSize: '18px !important',
  },
  '& .MuiSelect-select': {
    fontSize: '18px !important',
  },
}));

const AccountInformation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSelector = useSelector((state) => state);
  const [_, __, removeToken] = useCookies(['access_token_proxy']);
  const userdata = userSelector?.userProfileReducer?.userProfileData;
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fornErrors, setFormErrors] = useState('');
  const [radiovalue, setRadioValue] = useState(null);
  const [billingData, setBilingData] = useState({});
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [startDate, setStartDate] = useState();
  const [dobDate, setDobDate] = useState();
  const [countryData, setCountryData] = useState([]);
  const [countryChanged, setCountryChanged] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [customerData, setCustomerData] = useState({
    p_firstName: '',
    p_lastName: '',
    p_address: '',
    p_taxId: '',
    p_contact_email: '',
    p_country: '',
    p_city: '',
    p_state: '',
    p_zipCode: '',
    p_estabilishedDate: startDate,
    p_companyType: '',
    p_passport: '',
    p_nationality: '',
    p_citizenship: '',
    p_accountType: radiovalue?.p_accountType,
    p_companyName: '',
    p_DOB: dobDate,
  });

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const customerType = [
    { Name: 'Patnership', Code: '0' },
    { Name: 'Company', Code: '1' },
  ];

  useEffect(() => {
    const sortedCountries = CountryListOptions?.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setCountryData(sortedCountries);
  }, []);

  useEffect(() => {
    const getState = async () => {
      if (customerData?.p_country) {
        const countryCode = {
          countryCode: customerData?.p_country,
        };
        const data = await dispatch(countryStateAPI(countryCode));
        setStateData(data?.payload);
        setCountryChanged(true);
      } else {
        console.error('error');
      }
    };
    getState();
  }, [customerData?.p_country, dispatch]);

  useEffect(() => {
    const getCity = async () => {
      if (customerData?.p_country === 'US' && customerData?.p_state) {
        const cityCode = {
          countryCode: customerData?.p_country,
          statcode: customerData?.p_state,
        };
        const data = await dispatch(stateCityAPI(cityCode));

        if (data?.payload) {
          setCityData(data?.payload);
        } else {
          toast.error('error');
        }
      } else if (customerData?.p_country !== 'US' && customerData?.p_country) {
        const cityCode = {
          countryCode: customerData?.p_country,
        };
        const data = await dispatch(countryCityAPI(cityCode));
        if (data?.payload) {
          setCityData(data?.payload);
        } else {
          toast.error('error');
        }
      }
    };
    getCity();
  }, [customerData?.p_country, customerData?.p_state, dispatch]);

  const getbillingInfo = async () => {
    setDataLoading(true);
    const billingId = {
      userId: userdata?.p_customer_id,
    };
    const kycdata = await dispatch(getKycDetails(billingId));
    setRadioValue(kycdata?.payload?.data);
    const data = await dispatch(getBillingInfoDetails(billingId));
    await dispatch(setBillingDetails(data?.payload));
    if (data?.payload?.response?.data?.message === 'Unauthorized') {
      sessionStorage.removeItem('access_token_proxy_session');

      await dispatch(setSignUpUserdata({}));
      await removeToken('access_token_proxy', { path: '/' });
      navigate('/login');
    } else if (data?.payload) {
      setBilingData(data?.payload);
      setCustomerData({
        p_firstName: kycdata?.payload?.data?.p_firstName || '',
        p_lastName: kycdata?.payload?.data?.p_lastName || '',
        p_address: kycdata?.payload?.data?.p_address || '',
        p_taxId: data?.payload?.p_taxId || '',
        p_contact_email: data?.payload?.p_contact_email || '',
        p_country: data?.payload?.p_country || '',
        p_city: data?.payload?.p_city || '',
        p_state: data?.payload?.p_state ? data?.payload?.p_state : '',
        p_zipCode: data?.payload?.p_zipCode || '',
        p_estabilishedDate:
          new Date(data?.payload?.p_estabilishedDate)
            .toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
            .replace(/\//g, '-') || startDate,
        p_companyType: data.payload.p_companyType !== undefined ? String(data.payload.p_companyType) : '',
        p_passport: data?.payload?.p_passport || '',
        p_nationality: data?.payload?.p_nationality || '',
        p_citizenship: data?.payload?.p_citizenship || '',
        p_accountType: kycdata?.payload?.data !== undefined ? String(kycdata?.payload?.data) : '',
        p_companyName: kycdata?.payload?.data?.p_companyName ? kycdata?.payload?.data?.p_companyName : '',
        p_DOB:
          new Date(data?.payload?.p_DOB)
            .toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
            .replace(/\//g, '-') || dobDate,
      });
      setStartDate(data?.payload?.p_estabilishedDate ? new Date(data?.payload?.p_estabilishedDate) : '');
      setDobDate(data?.payload?.p_DOB ? new Date(data?.payload?.p_DOB) : '');
    } else {
      toast.error(data?.payload?.response?.data?.message);
    }

    setDataLoading(false);
  };

  useEffect(() => {
    getbillingInfo();
  }, [dispatch, userdata?.p_customer_id]);

  const handleChange = (field, value) => {
    setFieldsChanged(true);

    setCustomerData({ ...customerData, [field]: value });
  };

  function isOverEighteen(year, month, day) {
    var now = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
    var dob = year * 10000 + month * 100 + day * 1;
    return now - dob >= 180000;
  }
  const validation = () => {
    const errors = {};

    const minAge = 18;
    if (radiovalue?.p_accountType === 1 && customerData?.p_country === 'US') {
      if (customerData?.p_state === '') {
        errors.p_state = 'State  required';
      }
      if (customerData?.p_firstName === '') {
        errors.p_firstName = 'First name is required';
      }
      if (customerData?.p_lastName === '') {
        errors.p_lastName = 'Last name is required';
      }
      if (customerData?.p_address === '') {
        errors.p_address = 'Address required';
      }
      if (customerData?.p_country === '') {
        errors.p_country = 'Country required';
      }
      if (customerData?.p_city === '') {
        errors.p_city = 'City required';
      }
      if (!customerData?.p_zipCode) {
        errors.p_zipCode = 'Zipcode required';
      }
      if (customerData?.p_nationality === '') {
        errors.p_nationality = 'Nationality  required';
      }
      if (customerData?.p_citizenship === '') {
        errors.p_citizenship = 'Citizenship  required';
      }
      if (customerData?.p_passport === '') {
        errors.p_passport = 'Passport Id number  required';
      }

      if (!dobDate) {
        errors.p_DOB = 'Date of birth is required';
      } else {
        const dob = new Date(dobDate);
        const year = dob.getFullYear();
        const month = dob.getMonth() + 1;
        const day = dob.getDate();
        if (isOverEighteen(year, month, day) === false) {
          errors.p_DOB = `You must be at least ${minAge} years old`;
        }
      }
    } else if (radiovalue?.p_accountType === 1) {
      if (customerData?.p_firstName === '') {
        errors.p_firstName = 'First name is required';
      }
      if (customerData?.p_lastName === '') {
        errors.p_lastName = 'last name is required';
      }
      if (customerData?.p_address === '') {
        errors.p_address = 'Address required';
      }
      if (customerData?.p_country === '') {
        errors.p_country = 'Country required';
      }
      if (customerData?.p_city === '') {
        errors.p_city = 'City required';
      }
      if (!customerData?.p_zipCode) {
        errors.p_zipCode = 'Zipcode required';
      }
      if (customerData?.p_nationality === '') {
        errors.p_nationality = 'Nationality  required';
      }
      if (customerData?.p_citizenship === '') {
        errors.p_citizenship = 'Citizenship  required';
      }
      if (customerData?.p_passport === '') {
        errors.p_passport = 'Passport Id number  required';
      }
      if (!dobDate) {
        errors.p_DOB = 'Date of birth is required';
      } else {
        const dob = new Date(dobDate);
        const year = dob.getFullYear();
        const month = dob.getMonth() + 1;
        const day = dob.getDate();

        if (isOverEighteen(year, month, day) === false) {
          errors.p_DOB = `You must be at least ${minAge} years old`;
        }
      }
    } else if (radiovalue?.p_accountType === 0 && customerData?.p_country === 'US') {
      if (customerData?.p_state === '') {
        errors.p_state = 'State  required';
      }
      if (customerData?.p_companyName === '') {
        errors.p_companyName = 'Company name is required';
      }
      if (customerData?.p_address === '') {
        errors.p_address = 'Address required';
      }
      if (customerData?.p_taxId === '') {
        errors.p_taxId = 'TaxId required';
      }

      if (customerData?.p_country === '') {
        errors.p_country = 'Country required';
      }
      if (customerData?.p_city === '') {
        errors.p_city = 'City required';
      }
      if (!customerData?.p_zipCode) {
        errors.p_zipCode = 'Zipcode required';
      }
      if (customerData?.p_companyType === '') {
        errors.p_companyType = 'Company Type required';
      }
      if (!startDate) {
        errors.p_estabilishedDate = 'Established date required';
      }
    } else if (radiovalue?.p_accountType === 0) {
      if (customerData?.p_companyName === '') {
        errors.p_companyName = 'Company name is required';
      }
      if (customerData?.p_address === '') {
        errors.p_address = 'Address required';
      }
      if (customerData?.p_taxId === '') {
        errors.p_taxId = 'TaxId required';
      }

      if (customerData?.p_country === '') {
        errors.p_country = 'Country required';
      }
      if (customerData?.p_city === '') {
        errors.p_city = 'City required';
      }
      if (!customerData?.p_zipCode) {
        errors.p_zipCode = 'Zipcode required';
      }
      if (customerData?.p_companyType === '') {
        errors.p_companyType = 'Company Type required';
      }
      if (!startDate) {
        errors.p_estabilishedDate = 'Established date required';
      }
    } else {
      return errors;
    }

    setFormErrors(errors);
    setIsLoading(false);
    return errors;
  };

  const handleSubmit = async () => {
    try {
      setFormErrors('');
      setIsLoading(true);
      const error = await validation();
      if (Object.keys(error).length !== 0) {
        validation();
        setIsLoading(false);
      } else {
        let customerObject = {};
        if (radiovalue?.p_accountType === 0) {
          customerObject = {
            p_userId: userdata?.p_customer_id,
            p_taxId: customerData?.p_taxId,
            p_companyName: customerData?.p_companyName,
            p_city: customerData?.p_city,
            p_accountType: Number(radiovalue?.p_accountType),
            p_address: customerData?.p_address,
            p_country: customerData?.p_country,
            p_zipCode: customerData?.p_zipCode,
            p_companyType: Number(customerData?.p_companyType),
            p_estabilishedDate:
              startDate instanceof Date ? startDate.toISOString() : new Date(startDate)?.toISOString(),
            p_contact_email: userdata?.p_contact_email,
            p_state: '',
          };

          if (radiovalue?.p_accountType === 0 && customerData?.p_country === 'US') {
            customerObject = {
              p_userId: userdata?.p_customer_id,
              p_taxId: customerData?.p_taxId,
              p_companyName: customerData?.p_companyName,
              p_city: customerData?.p_city,
              p_accountType: Number(radiovalue?.p_accountType),
              p_address: customerData?.p_address,
              p_country: customerData?.p_country,
              p_zipCode: customerData?.p_zipCode,
              p_companyType: Number(customerData?.p_companyType),
              p_estabilishedDate:
                startDate instanceof Date ? startDate.toISOString() : new Date(startDate)?.toISOString(),
              p_contact_email: userdata?.p_contact_email,
              p_state: customerData?.p_state,
            };
          }
        } else if (radiovalue?.p_accountType === 1 && customerData?.p_country === 'US') {
          customerObject = {
            p_userId: userdata?.p_customer_id,
            p_firstName: customerData?.p_firstName,
            p_lastName: customerData?.p_lastName,
            p_city: customerData?.p_city,
            p_accountType: Number(radiovalue?.p_accountType),
            p_address: customerData?.p_address,
            p_country: customerData?.p_country,
            p_zipCode: customerData?.p_zipCode,
            p_DOB: dobDate instanceof Date ? dobDate.toISOString() : new Date(dobDate)?.toISOString(),
            p_nationality: customerData?.p_nationality,
            p_citizenship: customerData?.p_citizenship,
            p_passport: customerData?.p_passport,
            p_state: customerData?.p_state,
          };
        } else if (radiovalue?.p_accountType === 1) {
          customerObject = {
            p_userId: userdata?.p_customer_id,
            p_firstName: customerData?.p_firstName,
            p_lastName: customerData?.p_lastName,
            p_city: customerData?.p_city,
            p_accountType: Number(radiovalue?.p_accountType),
            p_address: customerData?.p_address,
            p_country: customerData?.p_country,
            p_zipCode: customerData?.p_zipCode,
            p_DOB: dobDate instanceof Date ? dobDate.toISOString() : new Date(dobDate)?.toISOString(),
            p_nationality: customerData?.p_nationality,
            p_citizenship: customerData?.p_citizenship,
            p_passport: customerData?.p_passport,
            p_state: '',
          };
        }
        if (isEmptyObject(billingData)) {
          const data = await dispatch(accountInformationAPI(customerObject));
          await dispatch(setAccountBllinginfo(data?.payload?.data));
          if (data?.payload?.status === true) {
            getbillingInfo();
            toast.success(data?.payload?.message);
          } else {
            toast.error(data?.payload?.message);
          }
        } else {
          const updateObj = {
            customerObject,
          };
          const idObj = {
            customerId: billingData?.p_billing_address_id,
          };

          const onj = {
            updateObj,
            idObj,
          };

          const data = await dispatch(updateAccountInfo(onj));
          await dispatch(setUpdateAccountInfo(data?.payload?.data));
          if (data?.payload?.status === true) {
            getbillingInfo();
            toast.success(data?.payload?.message);
          } else {
            toast.error(data?.payload?.response?.data?.message);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (fieldsChanged) {
      handleSubmit();
      setFieldsChanged(false);
    }
  };

  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };
  useEffect(() => {
    setFieldsChanged(false);
  }, []);

  return (
    <>
      {dataLoading ? (
        <Loader />
      ) : (
        <>
          <Box>
            <Typography variant="h2" sx={{ mb: 3, color: colors?.neutral?.dark, ml: 1 }}>
              Account Information
            </Typography>
            <Box container spacing={'24px'} sx={{ maxWidth: '100%' }}>
              <Box
                className="kyc-updation-form"
                sx={{
                  backgroundColor: theme.palette.neutral.background,
                  padding: '24px',
                  borderRadius: '16px',
                  maxWidth: '908px',
                }}
              >
                <Box
                  className="account-type"
                  sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: '24px' }}
                >
                  <Typography variant="h5" sx={{ mr: '24px' }}>
                    Account Type :
                  </Typography>
                  <Box>
                    <RadioGroup
                      row
                      name="p_accountType"
                      onChange={(e) => handleChange('p_accountType', e.target.value)}
                      defaultValue={radiovalue?.p_accountType}
                      sx={{
                        marginTop: { sm: '5px', xs: '15px' },
                        display: 'flex',
                        '& .MuiRadio-root': {
                          padding: '0',
                          marginRight: '8px',
                        },
                        '& .MuiFormControlLabel-root ': {
                          ml: '0',
                        },
                        '& .MuiFormControlLabel-label': {
                          fontSize: '20px',
                        },

                        '& .Mui-checked input + span svg:last-child': {
                          fill: colors.theme,
                        },
                        '& .Mui-checked input + span svg:first-child': {
                          fill: colors.basics.normal,
                        },
                      }}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        disabled={radiovalue?.p_accountType !== undefined}
                        label="Corporate"
                        sx={{
                          color: colors?.basics?.normal,
                          display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                          marginBottom: { xs: '5px', sm: '0', lg: '0' },
                        }}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        disabled={radiovalue?.p_accountType !== undefined}
                        label="Freelancer"
                        sx={{
                          color: colors?.basics?.normal,
                          display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                          marginBottom: { xs: '5px', sm: '0', lg: '0' },
                        }}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                <Grid container spacing={'20px'}>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{ mr: '24px', color: colors?.neutral?.dark }}>
                      Billing Information
                    </Typography>
                  </Grid>
                  {radiovalue?.p_accountType === 0 ? (
                    <Grid item xs={12}>
                      <Box sx={{ width: '100%' }}>
                        <CustomTextBox
                          disabled
                          placeholder="Company name*"
                          type="text"
                          value={customerData?.p_companyName}
                          onChange={(e) => {
                            setCustomerData({ ...customerData, p_companyName: e.target.value });
                            setFormErrors({ ...fornErrors, p_companyName: '' });
                            setFieldsChanged(true);
                          }}
                        />
                      </Box>
                      {fornErrors.p_companyName && (
                        <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                          {fornErrors.p_companyName}
                        </Typography>
                      )}
                    </Grid>
                  ) : (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <CustomTextBox
                            disabled
                            placeholder="First Name*"
                            type="text"
                            value={customerData?.p_firstName}
                            onChange={(e) => {
                              setCustomerData({ ...customerData, p_firstName: e.target.value });
                              setFormErrors({ ...fornErrors, p_firstName: '' });
                              setFieldsChanged(true);
                            }}
                          />
                        </Box>
                        {fornErrors.p_firstName && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_firstName}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <CustomTextBox
                            disabled
                            placeholder="Last Name*"
                            type="text"
                            value={customerData?.p_lastName}
                            onChange={(e) => {
                              setCustomerData({ ...customerData, p_lastName: e.target.value });
                              setFormErrors({ ...fornErrors, p_lastName: '' });
                              setFieldsChanged(true);
                            }}
                          />
                        </Box>
                        {fornErrors.p_lastName && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_lastName}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                      <CustomTextBox
                        disabled
                        placeholder="Address*"
                        type="text"
                        value={customerData?.p_address}
                        onChange={(e) => {
                          setCustomerData({ ...customerData, p_address: e.target.value });
                          setFormErrors({ ...fornErrors, p_address: '' });
                          setFieldsChanged(true);
                        }}
                      />
                    </Box>
                    {fornErrors.p_address && (
                      <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                        {fornErrors.p_address}
                      </Typography>
                    )}
                  </Grid>

                  {radiovalue?.p_accountType === 0 ? (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <CustomTextBox
                            placeholder="Tax ID*"
                            type="text"
                            value={customerData?.p_taxId}
                            onChange={(e) => {
                              setCustomerData({ ...customerData, p_taxId: e.target.value });
                              setFormErrors({ ...fornErrors, p_taxId: '' });
                              setFieldsChanged(true);
                            }}
                          />
                        </Box>
                        {fornErrors.p_taxId && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_taxId}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <CustomTextBox value={userdata?.p_contact_email} disabled />
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_country"
                              value={customerData?.p_country}
                              onChange={(e) => {
                                setCustomerData((prevData) => ({
                                  ...prevData,
                                  p_country: e.target.value,
                                  p_city: '',
                                  p_state: '',
                                }));
                                setFormErrors({ ...fornErrors, p_country: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_country && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>Select your country*</em>
                                </MenuItem>
                              )}

                              {countryData?.map((countryName, index) => (
                                <MenuItem
                                  className="filter-box"
                                  anchorEl={anchorEl}
                                  open={isDropdownOpen}
                                  onClose={handleClose}
                                  anchororigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformorigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  value={countryName?.Code}
                                  key={index}
                                >
                                  {countryName?.Name}
                                </MenuItem>
                              ))}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>
                        {fornErrors.p_country && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_country}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_city"
                              value={customerData?.p_city}
                              disabled={!countryChanged || cityData.length === 0}
                              className={
                                !countryChanged || cityData.length === 0 ? 'disable-option' : 'enalble-option'
                              }
                              onChange={(e) => {
                                setCustomerData({ ...customerData, p_city: e.target.value });
                                setFormErrors({ ...fornErrors, p_city: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_city && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  onClick={handleDropdownToggle}
                                  disabled
                                >
                                  <em>City*</em>
                                </MenuItem>
                              )}

                              {cityData.length === 0 ? (
                                <MenuItem value="" disabled>
                                  City not available
                                </MenuItem>
                              ) : (
                                cityData?.map((cityName, index) => (
                                  <MenuItem
                                    className="filter-box"
                                    anchorEl={anchorEl}
                                    open={isDropdownOpen}
                                    onClose={handleClose}
                                    anchororigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformorigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    value={cityName?.name}
                                    key={index}
                                  >
                                    {cityName?.name}
                                  </MenuItem>
                                ))
                              )}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>

                        {fornErrors.p_city && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_city}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  {radiovalue?.p_accountType === 0 ? (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_country"
                              value={customerData?.p_country}
                              onChange={(e) => {
                                setCustomerData((prevData) => ({
                                  ...prevData,
                                  p_country: e.target.value,
                                  p_city: '',
                                  p_state: '',
                                }));
                                setFormErrors({ ...fornErrors, p_country: '' });
                                setFieldsChanged(true);
                                setCountryChanged(true);
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_country && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>Select your country*</em>
                                </MenuItem>
                              )}
                              {countryData?.map((countryName, index) => (
                                <MenuItem
                                  className="filter-box"
                                  anchorEl={anchorEl}
                                  open={isDropdownOpen}
                                  onClose={handleClose}
                                  anchororigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformorigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  value={countryName?.Code}
                                  key={index}
                                >
                                  {countryName?.Name}
                                </MenuItem>
                              ))}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>
                        {fornErrors.p_country && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_country}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_city"
                              value={customerData?.p_city}
                              disabled={!countryChanged || cityData.length === 0}
                              className={
                                !countryChanged || cityData.length === 0 ? 'disable-option' : 'enalble-option'
                              }
                              onChange={(e) => {
                                setCustomerData({ ...customerData, p_city: e.target.value });
                                setFormErrors({ ...fornErrors, p_city: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_city && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>City*</em>
                                </MenuItem>
                              )}

                              {cityData.length === 0 ? (
                                <MenuItem value="" disabled>
                                  City not available
                                </MenuItem>
                              ) : (
                                cityData?.map((cityName, index) => (
                                  <MenuItem
                                    className="filter-box"
                                    anchorEl={anchorEl}
                                    open={isDropdownOpen}
                                    onClose={handleClose}
                                    anchororigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformorigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    value={cityName?.name}
                                    key={index}
                                  >
                                    {cityName?.name}
                                  </MenuItem>
                                ))
                              )}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>

                        {fornErrors.p_city && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_city}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_state"
                              value={customerData?.p_state ? customerData?.p_state : ''}
                              onChange={(e) => {
                                setCustomerData({ ...customerData, p_state: e.target.value });
                                setFormErrors({ ...fornErrors, p_state: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              disabled={customerData?.p_country !== 'US' || !countryChanged}
                              className={
                                customerData?.p_country !== 'US' ? 'disable-option' : 'enalble-option'
                              }
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_state && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>State* (If US)</em>
                                </MenuItem>
                              )}

                              {Array.isArray(stateData) ? (
                                stateData.map((stateName, index) => (
                                  <MenuItem
                                    className="filter-box"
                                    anchorEl={anchorEl}
                                    open={isDropdownOpen}
                                    onClose={handleClose}
                                    anchororigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformorigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    value={stateName?.isoCode}
                                    key={index}
                                  >
                                    {stateName?.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="State" disabled>
                                  No states available
                                </MenuItem>
                              )}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>
                        {fornErrors.p_state && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_state}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <CustomTextBox
                            placeholder="Zip code*"
                            type="text"
                            value={customerData?.p_zipCode}
                            onChange={(e) => {
                              setCustomerData({ ...customerData, p_zipCode: e.target.value });
                              setFormErrors({ ...fornErrors, p_zipCode: '' });
                              setFieldsChanged(true);
                            }}
                          />
                        </Box>
                        {fornErrors.p_zipCode && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_zipCode}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  {radiovalue?.p_accountType === 0 ? (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_state"
                              value={customerData?.p_state ? customerData?.p_state : ''}
                              onChange={(e) => {
                                setCustomerData({ ...customerData, p_state: e.target.value });
                                setFormErrors({ ...fornErrors, p_state: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              disabled={customerData?.p_country !== 'US' || !countryChanged}
                              className={
                                customerData?.p_country !== 'US' ? 'disable-option' : 'enalble-option'
                              }
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_state && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>State* (If US)</em>
                                </MenuItem>
                              )}

                              {Array.isArray(stateData) ? (
                                stateData.map((stateName, index) => (
                                  <MenuItem
                                    className="filter-box"
                                    anchorEl={anchorEl}
                                    open={isDropdownOpen}
                                    onClose={handleClose}
                                    anchororigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformorigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    value={stateName?.isoCode}
                                    key={index}
                                  >
                                    {stateName?.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  No states available
                                </MenuItem>
                              )}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>
                        {fornErrors.p_state && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_state}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <CustomTextBox
                            placeholder="Zip code*"
                            type="text"
                            value={customerData?.p_zipCode}
                            onChange={(e) => {
                              setCustomerData({ ...customerData, p_zipCode: e.target.value });
                              setFormErrors({ ...fornErrors, p_zipCode: '' });
                              setFieldsChanged(true);
                            }}
                          />
                        </Box>
                        {fornErrors.p_zipCode && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_zipCode}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box
                          sx={{
                            '& .react-datepicker-wrapper': {
                              width: '100%',
                              '& svg': {
                                display: 'none',
                              },
                              '& input': {
                                padding: '16.5px 14px',
                                paddingRight: '46px',
                                fontSize: '18px',
                                fontWeight: 400,
                                width: '-webkit-fill-available',
                                borderRadius: '8px',
                                border: `1px solid ${theme.palette.neutral.lighter}`,
                                backgroundImage: `url("${Calendar}")`,
                                backgroundPosition: 'right 14px center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '26px 26px',
                                transition: 'all .5s ease',
                                '&:hover, &:focus-visible': {
                                  borderColor: colors.theme,
                                  outline: 'none',
                                },
                              },
                            },
                            '& .react-datepicker-popper .react-datepicker__year-dropdown': {
                              overflow: 'auto',
                              '-webkit-tap-highlight-color': 'transparent',
                              '& .react-datepicker__navigation--years-upcoming': {
                                position: 'relative',
                                height: '20px',
                                top: 0,
                                width: '20px',
                                '&::before ': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: '8px',
                                  left: '3px',
                                  width: '10px',
                                  height: '10px',
                                  borderWidth: '2px 2px 0 0',
                                  borderStyle: 'solid',
                                  borderColor: '#a4a4a4',
                                  transform: 'rotate(-45deg)',
                                },
                              },
                              '& .react-datepicker__navigation--years-previous': {
                                position: 'relative',
                                height: '20px',
                                top: 0,
                                width: '20px',
                                '&::before ': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: '0',
                                  left: '4px',
                                  width: '10px',
                                  height: '10px',
                                  borderWidth: '0 0 2px 2px',
                                  borderStyle: 'solid',
                                  borderColor: '#a4a4a4',
                                  transform: 'rotate(-45deg)',
                                },
                              },
                              '& .react-datepicker-popper .react-datepicker__year-dropdown': {
                                // height: '150px',
                                overflow: 'auto',
                                '& .react-datepicker__navigation--years-upcoming': {
                                  position: 'relative',
                                  height: '20px',
                                  top: 0,
                                  width: '20px',
                                  '&::before ': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '3px',
                                    width: '10px',
                                    height: '10px',
                                    borderWidth: '2px 2px 0 0',
                                    borderStyle: 'solid',
                                    borderColor: '#a4a4a4',
                                    transform: 'rotate(-45deg)',
                                  },
                                },
                                '& .react-datepicker__navigation--years-previous': {
                                  position: 'relative',
                                  height: '20px',
                                  top: 0,
                                  width: '20px',
                                  '&::before ': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: '0',
                                    left: '4px',
                                    width: '10px',
                                    height: '10px',
                                    borderWidth: '0 0 2px 2px',
                                    borderStyle: 'solid',
                                    borderColor: '#a4a4a4',
                                    transform: 'rotate(-45deg)',
                                  },
                                },
                              },
                            },
                          }}
                        >
                          <DatePicker
                            showIcon
                            selected={dobDate}
                            onChange={(p_estabilishedDate) => {
                              setDobDate(p_estabilishedDate);
                              setFieldsChanged(true);
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Date of birth*"
                          />
                        </Box>
                        {fornErrors.p_DOB && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_DOB}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_nationality"
                              value={customerData?.p_nationality}
                              onChange={(e) => {
                                setCustomerData({ ...customerData, p_nationality: e.target.value });
                                setFormErrors({ ...fornErrors, p_nationality: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              sx={{
                                '& .MuiSelect-select[aria-expanded="true"] ~ svg ': {
                                  transform: 'translateY(-50%) rotate(180deg)',
                                  fontSize: '16px',
                                },
                                '& svg': {
                                  position: 'absolute',
                                  top: '50%',
                                  right: '10px',
                                  transform: 'translateY(-50%) rotate(0deg)',
                                  transition: 'all .5s ease',
                                },
                                '&.MuiOutlinedInput-root ': {
                                  borderRadius: '8px !important',
                                },
                                '& .MuiSelect-select  em': {
                                  fontSize: '18px !important',
                                },
                                '& .MuiSelect-select': {
                                  fontSize: '18px !important',
                                },
                              }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_nationality && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                >
                                  <em>Nationality*</em>
                                </MenuItem>
                              )}

                              {countryData?.map((countryName, index) => (
                                <MenuItem className="filter-box" value={countryName?.Code} key={index}>
                                  {countryName?.Name}
                                </MenuItem>
                              ))}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>
                        {fornErrors.p_nationality && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_nationality}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  {radiovalue?.p_accountType === 0 ? (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box
                          sx={{
                            '& .react-datepicker-wrapper': {
                              width: '100%',
                              '& svg': {
                                display: 'none',
                              },
                              '& input': {
                                padding: '16.5px 14px',
                                paddingRight: '46px',
                                fontSize: '18px',
                                fontWeight: 400,
                                width: '-webkit-fill-available',
                                borderRadius: '8px',
                                border: `1px solid ${theme.palette.neutral.lighter}`,
                                backgroundImage: `url("${Calendar}")`,
                                backgroundPosition: 'right 14px center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '26px 26px',
                                transition: 'all .5s ease',
                                '&:hover, &:focus-visible': {
                                  borderColor: colors.theme,
                                  outline: 'none',
                                },
                              },
                            },
                            '& .react-datepicker-popper .react-datepicker__year-dropdown': {
                              '-webkit-tap-highlight-color': 'transparent',
                              overflow: 'auto',
                              '& .react-datepicker__navigation--years-upcoming': {
                                position: 'relative',
                                height: '20px',
                                top: 0,
                                width: '20px',
                                '&::before ': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: '8px',
                                  left: '3px',
                                  width: '10px',
                                  height: '10px',
                                  borderWidth: '2px 2px 0 0',
                                  borderStyle: 'solid',
                                  borderColor: '#a4a4a4',
                                  transform: 'rotate(-45deg)',
                                },
                              },
                              '& .react-datepicker__navigation--years-previous': {
                                position: 'relative',
                                height: '20px',
                                top: 0,
                                width: '20px',
                                '&::before ': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: '0',
                                  left: '4px',
                                  width: '10px',
                                  height: '10px',
                                  borderWidth: '0 0 2px 2px',
                                  borderStyle: 'solid',
                                  borderColor: '#a4a4a4',
                                  transform: 'rotate(-45deg)',
                                },
                              },
                              '& .react-datepicker-popper .react-datepicker__year-dropdown': {
                                overflow: 'auto',
                                '& .react-datepicker__navigation--years-upcoming': {
                                  position: 'relative',
                                  height: '20px',
                                  top: 0,
                                  width: '20px',
                                  '&::before ': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: '8px',
                                    left: '3px',
                                    width: '10px',
                                    height: '10px',
                                    borderWidth: '2px 2px 0 0',
                                    borderStyle: 'solid',
                                    borderColor: '#a4a4a4',
                                    transform: 'rotate(-45deg)',
                                  },
                                },
                                '& .react-datepicker__navigation--years-previous': {
                                  position: 'relative',
                                  height: '20px',
                                  top: 0,
                                  width: '20px',
                                  '&::before ': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: '0',
                                    left: '4px',
                                    width: '10px',
                                    height: '10px',
                                    borderWidth: '0 0 2px 2px',
                                    borderStyle: 'solid',
                                    borderColor: '#a4a4a4',
                                    transform: 'rotate(-45deg)',
                                  },
                                },
                              },
                            },
                          }}
                        >
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(p_estabilishedDate) => {
                              setStartDate(p_estabilishedDate);
                              setFieldsChanged(true);
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Established Date*"
                          />
                        </Box>
                        {fornErrors.p_estabilishedDate && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_estabilishedDate}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_companyType"
                              value={customerData?.p_companyType}
                              onChange={(e) => {
                                setCustomerData({ ...customerData, p_companyType: e.target.value });
                                setFormErrors({ ...fornErrors, p_companyType: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_companyType && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>Type*</em>
                                </MenuItem>
                              )}

                              {customerType?.map((countryName, index) => (
                                <MenuItem
                                  className="filter-box"
                                  anchorEl={anchorEl}
                                  open={isDropdownOpen}
                                  onClose={handleClose}
                                  anchororigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformorigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  value={countryName?.Code}
                                  key={index}
                                >
                                  {countryName?.Name}
                                </MenuItem>
                              ))}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>
                        {fornErrors.p_companyType && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_companyType}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <CustomTextBox
                            placeholder="Passport ID number*"
                            type="text"
                            value={customerData?.p_passport}
                            onChange={(e) => {
                              setCustomerData({ ...customerData, p_passport: e.target.value });
                              setFormErrors({ ...fornErrors, p_passport: '' });
                              setFieldsChanged(true);
                            }}
                          />
                        </Box>
                        {fornErrors.p_passport && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_passport}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="p_citizenship"
                              value={customerData?.p_citizenship}
                              onChange={(e) => {
                                setCustomerData({ ...customerData, p_citizenship: e.target.value });
                                setFormErrors({ ...fornErrors, p_citizenship: '' });
                                setFieldsChanged(true);
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!customerData?.p_citizenship && (
                                <MenuItem
                                  value=""
                                  sx={{
                                    color: colors.neutral.light + ' !important',
                                    fontSize: '16px',
                                  }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>Citizenship*</em>
                                </MenuItem>
                              )}

                              {countryData?.map((countryName, index) => (
                                <MenuItem
                                  className="filter-box"
                                  anchorEl={anchorEl}
                                  open={isDropdownOpen}
                                  onClose={handleClose}
                                  anchororigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformorigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  value={countryName?.Code}
                                  key={index}
                                >
                                  {countryName?.Name}
                                </MenuItem>
                              ))}
                            </CustomStyledSelect>
                          </FormControl>
                        </Box>
                        {fornErrors.p_citizenship && (
                          <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                            {fornErrors.p_citizenship}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box sx={{ width: { sm: '320px', xs: '100%' } }}>
                      <CustomButton
                        type="onclick"
                        onClick={handleButtonClick}
                        disabled={!fieldsChanged || isLoading}
                      >
                        {isEmptyObject(billingData) ? 'Save' : 'Edit'}

                        {isLoading && (
                          <ColorRing
                            visible={true}
                            height='30'
                            width='30'
                            ariaLabel='blocks-loading'
                            wrapperStyle={{}}
                            wrapperClass='blocks-wrapper'
                            colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                          />
                        )}
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default AccountInformation;
