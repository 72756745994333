import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Grid, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Table,
  Paper,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Checkbox,
  Button,
  Menu,
  FormControlLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ReactComponent as FilterFunnel } from '../../../assets/images/FilterFunnel.svg';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/dropdownDownArrow.svg';
import { getAdminTraficUsageAPI, setTraficUsageDetails } from '../../../redux/traficUsageAPI';
import DatePicker from 'react-datepicker';
import Calendar from '../../../assets/images/Calendar.svg';
import { formatDate, formatMonth } from '../../../components/common/dateFormat';
import { colors } from '../../../theme/colors';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { ThreeDots } from 'react-loader-spinner';
import Pagination from '../../../components/common/Pagination';
import SortingHelper from '../../../components/common/sortingHelper';
import { convertHeader, stringToCamelCase } from '../../../utils/CommonFunctions/commonFile';

const Usage = () => {
  const dispatch = useDispatch();
  const { adminFilterCustomers, isLoading: userListLoading } = useSelector(
    (state) => state?.traficUsageReducer
  );
  const [page, setPage] = useState(0);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [traficUsageData, setTraficUsageData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(currentYear, currentMonth, new Date().getDate()));
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 1 }); // 1: "asc", 0: "desc"
  const [dateFiterOption, setDateFilterOption] = useState('this month');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [trafficUsageHeaders, setTrafficUsageHeaders] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    errors: false,
    countries: false,
    domains: false,
    apply: {
      errors: false,
      countries: false,
      domains: false,
    },
  });
  const [usageFilter, setUsageFilter] = useState({
    customer: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const filterDandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterHandleClose = (key) => {
    if (key === 0) {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        apply: prevOptions,
      }));
    } else if (key === 1) {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions.apply,
        apply: {
          ...prevOptions.apply,
        },
      }));
    }
    setAnchorEl(null);
  };

  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: '#242323',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: '#686465',
      fontWeight: 400,
    },
  }));

  const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
    color: '#686465',
    borderRadius: '6px',
    height: '53px',
    width: '100% !important',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },

    '& .MuiSelect-select': {
      padding: '13px 14px',
      lineHeight: '1.5',
      fontSize: '18px !important',
    },
    '& .MuiSelect-select[aria-expanded="true"] ~ svg': {
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'})`,
    },
    '& .MuiSelect-icon': {
      display: 'block',
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'})`,
      transition: 'all .5s ease',
      cursor: 'pointer',
    },
  }));

  const dateFilter = [
    { Name: 'last 7 days', Code: 'Last 7 Days' },
    { Name: 'last 30 days', Code: 'Last 30 Days' },
    { Name: 'this month', Code: 'This Month' },
    { Name: 'last month', Code: 'Last Month' },
    { Name: 'this year', Code: 'This Year' },
    { Name: 'custom date', Code: 'Custom Date' },
  ];
  const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    '& .react-datepicker-wrapper': {
      width: '100%',
      '& svg': {
        display: 'none',
      },
      '& input': {
        padding: '15px 14px',
        paddingRight: '46px',
        height: '21px',
        fontSize: '18px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: '180px',
        },
        [theme.breakpoints.up('xs')]: {
          maxWidth: '-webkit-fill-available',
        },
        borderRadius: '6px',
        color: '#686465',
        border: `1px solid ${theme.palette.neutral.lighter}`,
        backgroundColor: colors.basics.white,
        backgroundImage: `url("${Calendar}")`,
        backgroundPosition: 'right 14px center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '26px 26px',
        transition: 'all .5s ease',
        '&:hover, &:focus-visible': {
          borderColor: colors.theme,
          outline: 'none',
        },
      },
    },
  }));

  const getDateRange = (name, customStartDate, customEndDate) => {
    setDateFilterOption(name);
    const currentDate = new Date();
    let startDate, endDate;

    switch (name) {
      case 'last 7 days':
        endDate = new Date(currentDate);
        endDate.setDate(currentDate?.getDate() - 1);
        startDate = new Date(endDate);
        startDate.setDate(endDate?.getDate() - 6);
        break;

      case 'last 30 days':
        endDate = new Date(currentDate);
        endDate.setDate(currentDate?.getDate() - 1);
        startDate = new Date(endDate);
        startDate.setDate(endDate?.getDate() - 29);
        break;

      case 'this month':
        startDate = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), 1);
        startDate.setHours(23, 59, 0, 0);
        endDate = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), currentDate?.getDate());
        endDate.setHours(23, 59, 0, 0);
        break;

      case 'last month':
        startDate = new Date(currentDate?.getFullYear(), currentDate?.getMonth() - 1, 1);
        endDate = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), 0);
        startDate.setHours(23, 59, 0, 0);
        endDate.setHours(23, 59, 0, 0);
        break;

      case 'this year':
        startDate = new Date(currentDate?.getFullYear(), 0, 1);
        startDate.setHours(23, 59, 0, 0);
        endDate = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), currentDate?.getDate());
        endDate.setHours(23, 59, 0, 0);
        break;

      case 'custom date':
        if (customStartDate && customEndDate) {
          startDate = new Date(customStartDate);
          endDate = new Date(customEndDate);
        } else {
          return null;
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    setStartDate(startDate);
    setEndDate(endDate);

    return { startDate, endDate };
  };

  const handleCheckboxChange = (name) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [name]: !prevOptions[name],
    }));
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const usageObj = {
      ...(usageFilter.customer ? { customer: usageFilter?.customer } : {}),
      limit: rowsPerPage || 25,
      page: page,
      orderby: sortConfig.key,
      order: sortConfig.direction ? 'ASC' : 'DESC',
      trafficDateFrom: formatMonth(startDate),
      trafficDateTo: formatMonth(endDate),
      statucode: 503,
    };

    const data = await dispatch(getAdminTraficUsageAPI(usageObj));
    if (data?.payload) {
      setRowsPerPage(data?.payload?.meta?.itemCount);
      setTraficUsageData(data?.payload?.trafficStastics);
      setTotalCount(data?.payload?.meta?.totalItems);
      await dispatch(setTraficUsageDetails(data?.payload));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [dispatch, endDate, page, rowsPerPage, startDate, usageFilter.customer, sortConfig]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filterOptions = [
    { Name: 'errors', Code: 'Errors' },
    { Name: 'countries', Code: 'Countries' },
    { Name: 'domains', Code: 'Domains' },
  ];

  const isDatePickerDisabled = dateFiterOption !== 'custom date';

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const columnName = [
    {
      id: 1,
      name: 'Date',
      checkKey: true,
    },
    {
      id: 2,
      name: 'Customer',
      checkKey: true,
    },
    {
      id: 3,
      name: 'Total Requests',
      tableKey: 'totalRequest',
      checkKey: true,
    },
    {
      id: 4,
      name: 'Successful Requests',
      tableKey: 'successfullReq',
      checkKey: true,
    },
    {
      id: 5,
      name: 'Success Rate',
      checkKey: true,
    },
    {
      id: 6,
      name: 'Total Data',
      checkKey: true,
    },
    // ...(selectedOptions.apply.errors
    //   ? [
    {
      id: 7,
      name: 'Total Error Requests',
      tableKey: 'totalErrorReq',
      headerKey: 'errors',
      extraHeader: true,
    },
    //   ]
    // : []),
    // ...(selectedOptions.apply.countries
    //   ? [
    {
      id: 8,
      name: 'Country',
      headerKey: 'countries',
      extraHeader: true,
    },
    //   ]
    // : []),
    // ...(selectedOptions.apply.domains
    //   ? [
    {
      id: 9,
      name: 'Domains',
      headerKey: 'domains',
      extraHeader: true,
    },
    //   ]
    // : []),
  ];

  useEffect(() => {
    if (!!traficUsageData?.length) {
      const keysWithStatus = Object?.keys(traficUsageData?.slice(0, 1)?.map((usage) => usage)[0])
        ?.filter((status) => status.includes('status'))
        .map((key, index) => ({
          id: index + 10,
          name: convertHeader(key),
          checkKey: selectedOptions.apply.errors,
          tableKey: key,
        }));

      columnName.splice(6, 0, ...keysWithStatus);
    }
    return setTrafficUsageHeaders(columnName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traficUsageData, selectedOptions.apply.errors]);

  return (
    <>
      <Box>
        <Typography variant="h2" sx={{ mb: 3, color: colors?.neutral?.dark }}>
          Usage
        </Typography>
        <Grid container spacing={'24px'}>
          <Grid item xs={12}>
            <Box className="table-body">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  gap: '10px',
                }}
              >
                <StyledBox>
                  <DatePicker
                    showIcon
                    placeholderText="Start Date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    disabled={isDatePickerDisabled}
                    dateFormat="MM-dd-yyyy"
                    className={isDatePickerDisabled ? 'disabled-datepicker' : ''}
                  />
                </StyledBox>
                <StyledBox>
                  <DatePicker
                    showIcon
                    selected={endDate}
                    placeholderText="End Date"
                    onChange={(date) => {
                      if (date >= startDate) {
                        setEndDate(date);
                      } else {
                        toast.error('Error: End date cannot be less than start date');
                      }
                    }}
                    disabled={isDatePickerDisabled}
                    dateFormat="MM-dd-yyyy"
                    className={isDatePickerDisabled ? 'disabled-datepicker' : ''}
                  />
                </StyledBox>
                <Box
                  sx={{
                    width: { xl: '248px', sm: '250px', xs: '100%' },
                    maxWidth: {
                      lg: '248px',
                      sm: '249px',
                      xs: '-webkit-fill-available',
                    },
                    mr: { lg: 'auto' },
                    height: '48px',
                  }}
                >
                  <CustomStyledSelect
                    name="entryType"
                    // className="entryType-menu"
                    className="date-change-filter"
                    value={dateFiterOption}
                    onChange={(event) => getDateRange(event?.target?.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={(props) => (
                      <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                    )}
                  >
                    {dateFilter?.map((datefilterop, index) => (
                      <MenuItem
                        className="filter-box"
                        open={isDropdownOpen}
                        onClose={handleClose}
                        anchororigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformorigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        key={index}
                        value={datefilterop?.Name}
                        onClick={handleDropdownToggle}
                      >
                        {datefilterop?.Code}
                      </MenuItem>
                    ))}
                  </CustomStyledSelect>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: {
                      lg: '248px',
                      sm: '249px',
                      xs: '-webkit-fill-available',
                    },
                  }}
                >
                  <CustomStyledSelect
                    name="customer"
                    value={usageFilter?.customer}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const updatedValue = selectedValue === 'all' ? '' : selectedValue;
                      setPage(0);
                      setUsageFilter({
                        ...usageFilter,
                        customer: updatedValue,
                      });
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={(props) => (
                      <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                    )}
                  >
                    {!usageFilter?.customer ? (
                      <MenuItem
                        value=""
                        sx={{ color: colors.neutral.light + ' !important' }}
                        disabled
                        onClick={handleDropdownToggle}
                      >
                        <em>Select Customer</em>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        value=""
                        sx={{ color: colors.neutral.light + ' !important' }}
                        onClick={handleDropdownToggle}
                      >
                        <em>All</em>
                      </MenuItem>
                    )}
                    {!userListLoading ? (
                      !!adminFilterCustomers?.length &&
                      adminFilterCustomers?.map((item, index) => (
                        <MenuItem
                          className="filter-box"
                          key={index}
                          value={item}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          anchororigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformorigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          {item}
                        </MenuItem>
                      ))
                    ) : (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color={colors.theme}
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ padding: ' 10px' }}
                        wrapperClass=""
                      />
                    )}
                  </CustomStyledSelect>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: {
                      lg: '248px',
                      sm: '249px',
                      xs: '-webkit-fill-available',
                    },
                  }}
                >
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'filterMenu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={filterDandleClick}
                    sx={{
                      backgroundColor: `${colors.basics.white} !important`,
                      boxShadow: 'none !important',
                      border: `1px solid ${colors.input.border}`,
                      borderRadius: '6px',
                      color: `${colors.basics.normal}!important`,
                      width: '100%',
                      fontSize: '18px',
                      fontWeight: '400',
                      display: 'flex',
                      justifyContent: 'space-between',
                      textTransform: 'capitalize',
                      padding: '12px 12px',
                      lineHeight: '1.5',
                    }}
                  >
                    Filter
                    <FilterFunnel />
                  </Button>
                  <Menu
                    id="filterMenu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => filterHandleClose(1)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: '232px',
                        width: '200px !important',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {filterOptions?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          value={item.Name}
                          control={
                            <Checkbox
                              onChange={() => handleCheckboxChange(item.Name)}
                              checked={selectedOptions[item.Name]}
                            />
                          }
                          label={item?.Code}
                          sx={{
                            margin: '0 0 12px 0',
                            '& .MuiCheckbox-root': {
                              padding: 0,
                              marginRight: '12px',
                            },
                            '& .MuiTypography-root ': {
                              fontSize: '16px',
                              color: colors.basics.normal,
                              fontWeight: 600,
                            },
                          }}
                        />
                      ))}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderTop: `1px solid ${colors.input.border}`,
                        paddingTop: '12px',
                        maxWidth: '200px',
                      }}
                    >
                      <Button
                        variant="text"
                        sx={{
                          padding: 0,
                          fontSize: '16px',
                          fontWeight: 400,
                          textTransform: 'capitalize',
                          lineHeight: '24px',
                        }}
                        onClick={() => filterHandleClose(1)} // 1: cancel, 0: apply
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="text"
                        sx={{
                          color: colors.theme,
                          padding: 0,
                          fontSize: '16px',
                          fontWeight: 400,
                          textTransform: 'capitalize',
                          lineHeight: '24px',
                        }}
                        onClick={() => filterHandleClose(0)}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Menu>
                </Box>
              </Box>

              <Box>
                <TableContainer
                  sx={{
                    width: '100% !important',
                    boxShadow: 'none',
                    borderRadius: '12px',
                  }}
                  component={Paper}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow sx={{ '& th': { whiteSpace: 'nowrap' } }}>
                        {(!!trafficUsageHeaders.length ? trafficUsageHeaders : columnName)?.map(
                          (item, index) =>
                            (item.extraHeader && selectedOptions.apply[item.headerKey]) || item.checkKey ? (
                              <StyledTableCell
                                align="start"
                                sx={{
                                  fontSize: '18px',
                                  cursor: 'pointer',
                                  pointerEvents: isLoading && 'none',
                                }}
                                key={index}
                                onClick={() =>
                                  setSortConfig((prev) => ({
                                    key: item.tableKey ? item.tableKey : stringToCamelCase(item.name),
                                    direction: !prev.direction,
                                  }))
                                }
                              >
                                {item?.name}
                                <SortingHelper item={item} sortConfig={sortConfig} keyName="name" />
                              </StyledTableCell>
                            ) : null
                        )}
                      </TableRow>
                    </TableHead>
                    {isLoading ? (
                      <TableBody>
                        <TableRow sx={{ textAlign: 'center' }}>
                          <TableCell colSpan={7}>
                            <Box
                              sx={{
                                textAlign: 'center',
                                '& > div': { justifyContent: 'center' },
                              }}
                            >
                              <ThreeDots
                                visible={true}
                                height="80"
                                width="80"
                                color={colors.theme}
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : traficUsageData?.length > 0 ? (
                      <TableBody
                        sx={{
                          '& tr:last-child td': {
                            borderBottom: 0,
                          },
                          color: colors?.basics?.normal,
                        }}
                      >
                        {traficUsageData?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              textAlign: 'center',
                              color: colors?.basics?.normal,
                              '& td': { whiteSpace: 'nowrap' },
                            }}
                          >
                            <StyledTableCell>{formatDate(row?.date)}</StyledTableCell>
                            <StyledTableCell>{row?.customer}</StyledTableCell>
                            <StyledTableCell>{row?.totalRequest}</StyledTableCell>
                            <StyledTableCell>{row?.successfullReq}</StyledTableCell>
                            <StyledTableCell>{row?.successRate.toFixed(2)} %</StyledTableCell>
                            <StyledTableCell>{row?.totalData}</StyledTableCell>
                            {selectedOptions.apply.errors &&
                              Object.keys(row)
                                ?.filter((status) => status.includes('status'))
                                ?.map((rows) => {
                                  return <StyledTableCell>{row[rows]}</StyledTableCell>;
                                })}
                            {selectedOptions?.apply.errors === true && (
                              <StyledTableCell>{row?.totalErrorReq}</StyledTableCell>
                            )}
                            {selectedOptions?.apply.countries === true && (
                              <StyledTableCell variant="body1" sx={{ textTransform: 'uppercase' }}>
                                {row?.country?.join(', ')}
                              </StyledTableCell>
                            )}
                            {selectedOptions?.apply.domains === true && (
                              <StyledTableCell variant="body1">{row?.domain?.join(', ')}</StyledTableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableRow sx={{ width: '100%', height: '100%' }}>
                        <TableCell colSpan={6} sx={{ textAlign: 'center', border: '0' }}>
                          <img
                            src={DataNotFound}
                            alt="data not found"
                            style={{ width: '80px', height: '80px' }}
                          />
                          <Typography>Data not found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>
                <Box>
                  <Pagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    totalCount={totalCount}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Usage;
