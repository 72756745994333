import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal, Typography, Box, Button } from "@mui/material";
import { setSignAdmin, setSignUpUserdata } from "../../../redux/signUpApi";
import { useCookies } from "react-cookie";
import { colors } from "../../../theme/colors";
import { setUserSignIn } from "../../../redux/userSignInAPI";
import { setUserprofile } from "../../../redux/userProfileAPI";
import { setCustomCustomerDetails } from "../../../redux/getCustomCustomerPlansAPI";
import { setPaymentList } from "../../../redux/paymentListAPI";
import { useGetUser } from "../../../context/userProfile";

const LogoutModal = ({ setIsLogoutModal, logoutModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const adminPath = currentPath.match(/^\/admin\b/);
  const { setLogInUserDetails } = useGetUser();
  const [_, __, removeToken] = useCookies(["access_token_proxy"]);

  const handleLogout = async () => {
    await sessionStorage.removeItem("access_token_proxy_session");
    await localStorage.removeItem("persist:root");
    await dispatch(setSignUpUserdata({}));
    await dispatch(setSignAdmin({}));
    await dispatch(setUserSignIn({}));
    await dispatch(setUserprofile({}));
    await dispatch(setCustomCustomerDetails({}));
    await dispatch(setPaymentList({}));
    await setLogInUserDetails({})
    await removeToken(
      adminPath && adminPath[0]
        ? "admin_access_token_proxy"
        : "access_token_proxy",
      { path: "/" }
    );

    if (adminPath ? adminPath[0] : null) {
      navigate("/admin/login");
    } else {
      navigate("/login");
    }
  };

  return (
    <Modal
      open={logoutModal}
      onClose={() => setIsLogoutModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        '& [tabindex="-1"]': {
          height: "fit-content",
          margin: "auto",
          borderRadius: "12px",
        },
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
          width: {
            xs: "250px",
            sm: "320px",
            md: "508px",
            lg: "508px",
            xl: "508px",
          },
          maxHeight: "100%",
          height: "158px",
          bgcolor: "background.paper",
          boxShadow: 24,
          outline: "none",
        }}
      >
        <Box sx={{ padding: "24px" }}>
          <Typography
            sx={{
              fontSize: "20px",
              color: colors?.neutral?.dark,
              textAlign: "center",
            }}
          >
            Are you sure you want to logout?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "0px 20px 20px 20px",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <Button
            variant="#ff4c6a"
            sx={{
              maxHeight: "100%",
              maxWidth: "100%",
              borderRadius: "12px",
              border: "1px solid gray",
              background: "#FFFFFF",
              color: "#686465",
              "&:hover": {
                backgroundColor: "transparent",
              },
              width: "224px",
              height: "56px",
              fontSize: { xs: "20px" },
              fontWeight: 700,
              textTransform: "capitalize",
            }}
            onClick={() => setIsLogoutModal(false)}
          >
            Cancel
          </Button>

          <Button
            variant="#ff4c6a"
            sx={{
              backgroundColor: colors?.theme,
              maxHeight: "100%",
              maxWidth: "100%",
              width: "224px",
              height: "56px",
              borderRadius: "12px",
              color: "#ffffff",
              fontWeight: 700,
              fontSize: { xs: "20px" },
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#ff4c6a",
                color: "#ffffff",
              },
            }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
