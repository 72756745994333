import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const userAPI = createAsyncThunk('user-profile', async () => {
  try {
    const response = await API.get(`/authentication/`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  userProfileData: {},
};

const userProfileDetailSlice = createSlice({
  name: 'kycRegister',
  initialState,
  reducers: {
    setUserprofile: (state, { payload }) => {
      state.userProfileData = payload;
    },
  },
});

export const { setUserprofile } = userProfileDetailSlice.actions;
export default userProfileDetailSlice.reducer;
