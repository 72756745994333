import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoutes = ({ children }) => {
  const { signUpUser } = useSelector((state) => state?.registerReducer);
  const shouldRenderChildren = !signUpUser?.token;
  return shouldRenderChildren ? children : <Navigate to='/' />;
};

export default PublicRoutes;
