import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api/services';

const OtpVerificationAPI = createAsyncThunk('OtpVerificationCode', async (data) => {
  try {
    const response = await API.post(`/sms/check-verification-code`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

export default OtpVerificationAPI;
