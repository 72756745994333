import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getCustomerAPI = createAsyncThunk('get-customer', async () => {
  try {
    const response = await API.get(`/users/customers`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  getCustomerList: {},
  usersList: [],
  isLoading: false
};

const getCustomerSlice = createSlice({
  name: 'get-customer',
  initialState,
  reducers: {
    setCutsomerList: (state, { payload }) => {
      state.getCustomerList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerAPI.fulfilled, (state, actions) => {
        return {
          ...state,
          usersList: actions.payload || [],
          isLoading: false,
        };
      })
      .addCase(getCustomerAPI.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getCustomerAPI.rejected, (state, actions) => {
        return {
          ...state,
          usersList: [],
          isLoading: false,
        };
      });
  },
});

export const { setCutsomerList } = getCustomerSlice.actions;
export default getCustomerSlice.reducer;
