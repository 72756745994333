import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Tooltip from '@mui/material/Tooltip';
import { colors } from '../../../theme/colors';
import { Box, Button, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { ReactComponent as PrintIcon } from '../../../assets/images/Printer.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/Copy.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/Download.svg';
import companyLogo from '../../../assets/images/Logo.png';
import AdminPlanTable from './planTable';
import AddPlanModal from './addPlan';
import { getPlanListAPI } from '../../../redux/planListAPI';

const Plans = () => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const totalCountRef = useRef(0);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState();
  const [DownloadMenu, setDownloadMenu] = useState(null);
  const [CustomerDetailsData, setCustomerDetailsData] = useState([]);
  const [addIsModal, setIsAddModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  useEffect(() => {
    getPlanData();
  }, [dispatch, page, rowsPerPage, searchCustomer]);

  useEffect(() => {
    totalCountRef.current = totalCount;
  }, [totalCount]);

  const getPlanData = async () => {
    setIsLoading(true);
    const planObject = {
      page: page,
      limit: rowsPerPage,
      search: searchCustomer,
    };
    await setCustomerDetailsData([]);
    const planData = await dispatch(getPlanListAPI(planObject));

    if (planData?.payload?.plans?.length) {
      const totalCount = planData.payload.paging.totalItems || 0;
      setCustomerDetailsData(planData?.payload?.plans);
      setTotalCount(totalCount);

      if (searchCustomer !== '' && totalCount !== totalCountRef.current) {
        setPage(0);
      }

      setIsLoading(false);
    } else if (planData?.payload?.response?.data?.statusCode === 404) {
      toast.error(planData?.payload?.response?.data?.error);
      setIsLoading(false);
    } else {
      toast.error(planData?.payload?.message);
      setIsLoading(false);
      setTotalCount(0);
      setPage(0);
      setCustomerDetailsData([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const tableHeading = [
    { name: 'UID', sortName: 'p_planCode' },
    { name: 'Plan Name', sortName: 'p_name' },
    { name: 'Product Type', sortName: 'productTypeName' },
    { name: 'Charge Type', sortName: 'p_chargesType' },
    { name: 'Volume', sortName: 'p_volume' },
    { name: 'Unit Price', sortName: 'p_unitPrice' },
    { name: 'Duration', sortName: 'p_duration' },
    { name: 'Total Price', sortName: 'p_price' },
    { name: 'Action', sortName: 'Action' },
  ];

  const sortData = (property) => {
    const newSortConfig = { ...sortConfig };

    if (newSortConfig.key === property) {
      newSortConfig.direction = newSortConfig.direction === 'asc' ? 'desc' : 'asc';
    } else {
      newSortConfig.key = property;
      newSortConfig.direction = 'asc';
    }

    const sortedData = [...CustomerDetailsData].sort((a, b) => {
      const valueA = String(a[property]).toLowerCase();
      const valueB = String(b[property]).toLowerCase();

      if (newSortConfig.direction === 'asc') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setSortConfig(newSortConfig);
    setCustomerDetailsData(sortedData);
  };

  const handleCopyClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = document.querySelector('table').innerText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  const open = Boolean(DownloadMenu);
  const handleClick = (event) => {
    setDownloadMenu(event.currentTarget);
  };

  const handleClose = () => {
    setDownloadMenu(null);
  };

  const downloadFile = (format) => {
    let filteredData;
    filteredData = CustomerDetailsData.map((item) => ({
      UID: item?.p_planCode,
      'Plan Name': item?.p_name,
      'Product Type': item.productTypeName,
      'Charge Type':
        item?.p_chargesType === 1
          ? 'Bandwidth'
          : item?.p_chargesType === 2
          ? 'Requests'
          : item?.p_chargesType === 3
          ? 'OneTime'
          : '',
      Volume: `${item.p_volume}GB`,
      'Unit Price': `$${item.p_unitPrice}`,
      Duration: item.p_duration,
      'Total Price': `$${item.p_price}`,
    }));

    switch (format) {
      case 'CSV':
        const csvHeaders = Object.keys(filteredData[0]);
        const csvContent = [
          csvHeaders.join(','),
          ...filteredData.map((row) => csvHeaders.map((header) => row[header]).join(',')),
        ].join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv' });
        const csvLink = document.createElement('a');
        csvLink.href = URL.createObjectURL(csvBlob);
        csvLink.download = `Plans_${formattedDate}.csv`;
        csvLink.click();
        break;

      case 'Excel':
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `Plans_${formattedDate}.xlsx`);
        break;

      case 'PDF':
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const imgWidth = 30;
        const imgHeight = 10;

        const imgX = (pdfWidth - imgWidth) / 2;
        pdf.addImage(companyLogo, 'PNG', imgX, 10, imgWidth, imgHeight);

        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(16);

        pdf.text(
          'Plan Report',
          (pdfWidth -
            (pdf.getStringUnitWidth('Plan Report') * pdf.internal.getFontSize()) / pdf.internal.scaleFactor) /
            2,
          30
        );

        if (filteredData && filteredData.length > 0) {
          const tableData = filteredData.map((row) => Object.values(row));

          const headers = tableHeading
            .filter((heading) => heading.name !== 'Action')
            .map((heading) => heading.name);
          pdf.autoTable({
            head: [headers],
            body: tableData,
            startY: imgHeight + 25,
            styles: {
              cellPadding: 2,
              valign: 'middle',
              fontSize: 9,
              fontStyle: 'normal',
              lineWidth: 0.5,
            },
            margin: { top: imgHeight + 30 },
            tableWidth: 'auto',
          });

          pdf.save(`Plans_${formattedDate}.pdf`);
        } else {
          pdf.text('No data available', 10, imgHeight + 25);
        }

        break;

      default:
        console.error('Invalid format');
    }

    handleClose();
  };

  const handlePrint = () => {
    const printWindow = window.open('plan', '_blank');
    const tableContent = tableRef.current.innerHTML;

    printWindow.document.write(`
    <html>
      <head>
        <style>
          body {
            font-family: 'Arial', sans-serif;
          }
          #printSection {
            width: 100% !important;
            box-shadow: none;
            border-radius: 12px;
          }
          .table-wrapper {
            border: 1px solid #dddddd;
            border-radius: 6px;
            margin-top: 20px; /* Add margin to create space for header */
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            text-align: left;
            padding: 8px;
            font-size: 18px
          }
          th, tr:not(:last-child) td {
            border-bottom: 1px solid #dddddd;
          }
          th:last-child, td:last-child,
          th svg {
            display: none;
          }
          @media print {
            th:last-child, td:last-child,
            th svg {
              display: none;
            }
            th, td {
              text-align: left;
              padding: 8px;
              font-size: 12px
            }
          }
          .header {
            text-align: center;
            margin-bottom: 20px;
          }
          .header img {
            max-width: 100px; /* Adjust the max-width as needed */
          }
        </style>
      </head>
      <body>
        <div class="header" id="header">
          <img src=${companyLogo} alt='Company Logo' />
          <h1>Plan Details</h1>
        </div>
        <div class="table-wrapper">
          ${tableContent}
        </div>
        <script>
        window.onload = function() {
          document.getElementById('header').style.display = 'block';
          window.print();
        };
      </script>
      </body>
    </html>
  `);

    printWindow.document.close();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '24px',
        }}
      >
        <Typography variant='h2' color={colors?.neutral?.dark}>
          Plans
        </Typography>
        <Button
          type='button'
          variant='filled'
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            textTransform: 'capitalize',
            borderRadius: '8px',
            width: '200px',
          }}
          onClick={() => setIsAddModal(true)}
        >
          Add Plan
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.background.formBg,
          borderRadius: '16px',
          padding: '24px',
          minHeight: 'calc(100vh - 260px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <TextField
            type='search'
            placeholder='Search'
            value={searchCustomer}
            onChange={(e) => setSearchCustomer(e.target.value.trimLeft())}
            sx={{
              maxWidth: '480px',
              width: '100%',
              mb: '24px',
              '& input': {
                padding: '10px 14px',

                height: 'auto',
                fontSize: '18px',
                lineHeight: '1.5',
              },
              '@media (max-width: 600px)': {
                width: '100%',
              },
              '@media (min-width: 601px) and (max-width: 960px)': {
                width: '50%',
              },
            }}
          />
          <Box
            sx={{
              '& button': {
                mr: '8px',
                padding: { xl: '11px 19px', xs: '7px' },
                minWidth: 'auto',
                border: '1px solid #CDCBCC',
                '&:last-child': {
                  mr: '0px',
                },
                '& svg path': {
                  transition: 'all .5s ease',
                },
                '&:hover svg path': {
                  fill: colors.theme,
                },
              },
            }}
          >
            <Button
              variant='grayOutlined'
              type='button'
              onClick={handleCopyClick}
              disabled={!CustomerDetailsData?.length > 0}
            >
              <Tooltip
                title='Copy to Clipboard'
                arrow
                open={tooltipOpen}
                onClose={() => setTooltipOpen(false)}
              >
                <CopyIcon />
              </Tooltip>
            </Button>
            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant='grayOutlined'
              type='button'
              disabled={!CustomerDetailsData?.length > 0}
            >
              <DownloadIcon />
            </Button>
            <Menu
              id='basic-menu'
              className='download-menu'
              anchorEl={DownloadMenu}
              open={open}
              transformorigin={{ horizontal: 'right', vertical: 'top' }}
              anchororigin={{ horizontal: 'right', vertical: 'bottom' }}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => downloadFile('CSV')}>CSV</MenuItem>
              <MenuItem onClick={() => downloadFile('Excel')}>Excel</MenuItem>
              <MenuItem onClick={() => downloadFile('PDF')}>PDF</MenuItem>
            </Menu>
            <Button
              variant='grayOutlined'
              disabled={!CustomerDetailsData?.length > 0}
              onClick={handlePrint}
              type='button'
            >
              <PrintIcon />
            </Button>
          </Box>
        </Box>
        <AdminPlanTable
          tableRef={tableRef}
          tableHeading={tableHeading}
          sortData={sortData}
          sortConfig={sortConfig}
          CustomerDetailsData={CustomerDetailsData}
          isLoading={isLoading}
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          getPlanData={getPlanData}
        />
      </Box>
      {addIsModal === true && (
        <AddPlanModal setIsAddModal={setIsAddModal} addIsModal={addIsModal} getPlanData={getPlanData} />
      )}
    </>
  );
};

export default Plans;
