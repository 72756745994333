import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const deleteKycFileAPI = createAsyncThunk('delete-file', async (data) => {
  try {
    const response = await API.delete(`/local-files/${data.deleteId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  deleteFile: {},
};

const DeleteKycFilelSlice = createSlice({
  name: 'delete-file',
  initialState,
  reducers: {
    setKycFileDeleteData: (state, { payload }) => {
      state.deleteFile = payload;
    },
  },
});

export const { setKycFileDeleteData } = DeleteKycFilelSlice.actions;
export default DeleteKycFilelSlice.reducer;
