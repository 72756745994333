import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getOwnerAPI = createAsyncThunk('get-owner', async () => {
  try {
    const response = await API.get(`/users/admin/owners`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  getOwnerList: {},
};

const getOnwerSlice = createSlice({
  name: 'get-owner',
  initialState,
  reducers: {
    setOwner: (state, { payload }) => {
      state.getOwnerList = payload;
    },
  },
});

export const { setOwner } = getOnwerSlice.actions;
export default getOnwerSlice.reducer;
