import React, { useState, useEffect } from 'react';
import { Box, Typography, Modal, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { baseURL } from '../../../api/url';
import Loader from '../../../components/common/Loding';

const InvoiceModal = ({ closeModal, modalOpen, modalFile }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, [modalOpen]);

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      className='modal-paper'
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Box className='modal-box'>
        <IconButton onClick={closeModal} className='modal-button' aria-label='close'>
          <CloseIcon />
        </IconButton>
        <Box className='invoice-box'>
          {modalFile ? (
            <object
              data={`${baseURL}${
                modalFile?.p_invoice_link
                  ? modalFile?.p_invoice_link
                  : modalFile?.p_invoice_link || modalFile?.invoiceLink
                  ? modalFile?.invoiceLink
                  : modalFile?.invoiceLink
              }`}
              type='application/pdf'
              onLoad={handleLoad}
              className='invoice-paper'
            >
              <embed
                src={`${baseURL}${
                  modalFile?.p_invoice_link
                    ? modalFile?.p_invoice_link
                    : modalFile?.p_invoice_link || modalFile?.invoiceLink
                    ? modalFile?.invoiceLink
                    : modalFile?.invoiceLink
                }`}
                type='application/pdf'
                onLoad={handleLoad}
                className='invoice-paper'
              />
            </object>
          ) : (
            <Typography variant='body1'>Unsupported file type</Typography>
          )}
        </Box>
        {loading && <Loader />}
      </Box>
    </Modal>
  );
};

export default InvoiceModal;
