import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, Typography } from '@mui/material';
import { colors } from '../../../theme/colors';
import { Chart } from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

import theme from '../../../theme';

const CustomerplanUseChart = () => {
  const defaultColor = 'gray';
  const planUsageData = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [planProgressData, setPlanProgressData] = useState([]);

  useEffect(() => {
    setPlanProgressData(planUsageData?.adminDashBoradReducer?.adminDashboard?.planUsageDetails);
    setLoading(false);
  }, [planUsageData?.adminDashBoradReducer?.adminDashboard?.planUsageDetails]);

  const colorArray = [
    {
      id: 1,
      colorCode: '#E1407C',
    },
    {
      id: 2,
      colorCode: '#4AC336',
    },
    {
      id: 3,
      colorCode: '#2973EF',
    },
  ];

  const chartData = {
    labels: [],
    datasets: [
      {
        data: planProgressData?.length > 0 ? planProgressData?.map((data) => data?.usage) : '1',
        backgroundColor:
          planProgressData?.length > 0 ? colorArray?.map((data) => data?.colorCode) : [defaultColor],
        radius: 110,
        cutout: 80,
      },
    ],
  };

  return (
    <Box sx={{ display: 'grid', gap: '24px' }}>
      <Typography sx={{ fontSize: '24px', fontWeight: 600, color: colors.neutral.dark }}>
        Customer plan usage
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { lg: 'center', md: 'space-around', xs: 'center' },
          flexWrap: 'wrap',
          gap: '30px',
        }}
      >
        {loading ? (
          'loading'
        ) : (
          <Box
            position='relative'
            display='inline-flex'
            sx={{
              maxWidth: '240px',
              height: 'auto',
              width: '100%',
            }}
          >
            <Doughnut data={chartData} />
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '23.17px',
                lineHeight: '34.76px',
                fontWeight: 600,
                color: colors.basics.normal,
              }}
            >
              Usage
            </Typography>
          </Box>
        )}
        <Box
          sx={{ display: { xs: 'grid', sm: 'flex', md: 'grid' }, gap: { xs: '20px' }, alignItems: 'center' }}
        >
          <Box sx={{ minWidth: '233px', gap: '12px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: theme.fontFamily,
                  color: colors.neutral.dark,
                }}
              >
                Plan name
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: theme.fontFamily,
                  color: colors.neutral.dark,
                }}
              >
                Added by
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 400, fontFamily: theme.fontFamily, color: '#686465' }}
              >
                Prox-E 1TB
              </Typography>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 400, fontFamily: theme.fontFamily, color: '#686465' }}
              >
                System
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 400, fontFamily: theme.fontFamily, color: '#686465' }}
              >
                Trial
              </Typography>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 400, fontFamily: theme.fontFamily, color: '#686465' }}
              >
                Eli_Prox-E
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {planProgressData?.map((item, index) => {
              const colorObject = colorArray[index % colorArray?.length];

              return (
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }} key={index}>
                  <Typography
                    sx={{
                      height: '24px',
                      width: '24px',
                      background: colorObject?.colorCode,
                      borderRadius: '2px',
                    }}
                  ></Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      fontFamily: theme.fontFamily,
                      color: '#686465',
                    }}
                  >
                    {item?.usage ? item?.usage : '0'}% {item?.productType ? item?.productType : '-'}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerplanUseChart;
