import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const KycRegisterDetailAPI = createAsyncThunk('kyc-detail', async (data) => {
  try {
    const response = await API.post(`/kyc-details/create`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  kycRegister: {},
};

const kycRegisterDetailSlice = createSlice({
  name: 'edit-customer-details',
  initialState,
  reducers: {
    setKycDetailRegister: (state, { payload }) => {
      state.kycRegister = payload;
    },
  },
});

export const { setKycDetailRegister } = kycRegisterDetailSlice.actions;
export default kycRegisterDetailSlice.reducer;
