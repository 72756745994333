import React, { useState } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';
import { ColorRing } from 'react-loader-spinner';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';
import CustomButton from '../../../components/common/customButton';
import { userAPI } from '../../../redux/userProfileAPI';
import { useGetUser } from '../../../context/userProfile';
import { customerEditEmailAPI } from '../../../redux/customerEditEmailAPI';
import { EmailconfirmationAPI } from '../../../redux/emailConfiramationAPI';
import CustomTextBox from '../../../components/common/customTextBox';

const CustomerContactEmail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLogInUserDetails } = useGetUser();
  const { isLoading } = useSelector((state) => state?.customerEditEmailReducer);
  const { emailIsLoading } = useSelector((state) => state?.EmailconfirmationReducer);
  const accessToken = JSON.parse(sessionStorage.getItem('access_token_proxy_session'));
  const [emailData, setEmail] = useState('');
  const [error, setError] = useState('');

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
    'in',
    'co',
    'io',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const validationSchema = yup.object().shape({
    emailData: yup
      .string()
      .test('valid-emailData', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              username.includes('-') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
  });

  const updatedEmail = async (event) => {
    event.preventDefault();
    setError('');
    try {
      await validationSchema.validate({ emailData });
      const payload = {
        email: emailData,
        id: accessToken?.p_customer_id,
      };
      const data = await dispatch(customerEditEmailAPI(payload));
      if (data?.payload.status === true) {
        const emailObject = {
          p_type: 4,
          p_contact_email: emailData,
          isCheckTime: 0,
        };
        await dispatch(EmailconfirmationAPI(emailObject));
        if (data?.payload?.status === true) {
          toast.success(data?.payload?.message);
          const updatedata = await dispatch(userAPI());
          setLogInUserDetails(updatedata?.payload);
          await navigate('/emailverification');
        } else {
          toast.error(data?.payload?.response?.message);
        }
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errorMessage = error.errors[0];
        setError(errorMessage);
      } else {
        toast.error('An unexpected error occurred.');
      }
    }
  };

  return (
    <AuthenticationWrapper>
      <Box className="signup-box">
        <Typography variant="h1" sx={{ color: theme.palette.neutral.dark }}>
          {accessToken?.p_phoneNumber === '' ? ' Add Your Email' : ' Edit Your Email'}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: theme.palette.neutral.normal }}>
          We send a one-time password to your email for verification.
        </Typography>
      </Box>
      <form style={{ width: '100%' }} onSubmit={updatedEmail}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <CustomTextBox
              placeholder="Enter email id"
              icon
              iconType={'email'}
              value={emailData}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {error && (
              <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                {error}
              </Typography>
            )}{' '}
          </Box>

          <Box sx={{ width: '100%' }}>
            <CustomButton type="submit" disabled={isLoading || emailIsLoading}>
              Send
              {isLoading && (
                <ColorRing
                  visible={true}
                  height='30'
                  width='30'
                  ariaLabel='blocks-loading'
                  wrapperStyle={{}}
                  wrapperClass='blocks-wrapper'
                  colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                />
              )}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </AuthenticationWrapper>
  );
};

export default CustomerContactEmail;
