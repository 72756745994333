import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';

import { useTheme } from '@emotion/react';
import { Images } from '../../assets/images';
import { colors } from '../../theme/colors';

const CustomTextBox = ({
  icon = false,
  iconType,
  type = 'text',
  value,
  error,
  fornErrors,
  onChange,
  customSx,
  ...rest
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const getIcon = () => {
    switch (iconType) {
      case 'email':
        return Images.Email_Icon;
      case 'password':
        return Images.Password_Icon;
      case 'username':
        return Images.Username_Icon;
      case 'search':
        return Images.Search_Icon;
      default:
        return null;
    }
  };

  return (
    <TextField
      type={showPassword ? 'text' : type}
      value={value}
      onChange={onChange}
      fullWidth
      required
      sx={{
        color: theme.palette.neutral.light,
        backgroundColor: colors.basics.white,
        '& .Mui-focused fieldset': {
          border: `1px solid ${theme.palette.neutral.dark} !important`,
        },
        '& fieldset': {
          border: `1px solid ${theme.palette.neutral.lighter}`,
        },
        '& .MuiInputBase-root': {
          borderRadius: '8px',
        },
        '& input::placeholder': {
          fontStyle: 'normal',
          fontWeight: 400,
          opacity: 1,
        },
        '& .MuiInputBase-input': {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          opacity: 1,
        },
        '& .input': {
          fontSize: '18px',
        },
        ...customSx,
      }}
      {...rest}
      InputProps={{
        startAdornment: icon ? (
          <InputAdornment position='start'>
            <img src={getIcon()} className='image-box' alt='EmailIcon' />
          </InputAdornment>
        ) : null,
        endAdornment:
          iconType === 'password' ? (
            <InputAdornment position='end'>
              <IconButton onClick={togglePasswordVisibility} edge='end'>
                {showPassword ? (
                  <img src={Images.VisibilityPassword_Icon} className='image-box' alt='EmailIcon' />
                ) : (
                  <img src={Images.VisibilityOffPassword_Icon} className='image-box' alt='EmailIcon' />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    />
  );
};

export default CustomTextBox;
