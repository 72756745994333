import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const userautoRenewalAPI = createAsyncThunk('autoRenew', async (data) => {
  try {
    const response = await API.post(`/users/autoRenew/${data?.userId}`, { autoRenew: data?.autoRenew });
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  autorenewalplan: {},
};

const autoRenewalPlanSlice = createSlice({
  name: 'autorenewal',
  initialState,
  reducers: {
    setAutoRenewasl: (state, { payload }) => {
      state.autorenewalplan = payload;
    },
  },
});

export const { setAutoRenewasl } = autoRenewalPlanSlice.actions;
export default autoRenewalPlanSlice.reducer;
