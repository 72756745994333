import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import { colors } from '../../../theme/colors';

const ActionModal = ({
  setIsacceptconfirmModal,
  acceptconfirmModal,
  EditUser,
  handleSubmit,
  loadingUsers,
}) => {
  return (
    <Modal
      open={acceptconfirmModal}
      onClose={() => setIsacceptconfirmModal(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      hideBackdrop
      sx={{
        overflow: 'auto',
        display: 'flex',
        backdropFilter: 'blur(0.5px)',
        justifyContent: 'center',
        '& [tabindex="-1"]': {
          height: 'fit-content',
          margin: 'auto',
          borderRadius: '12px',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          width: {
            xs: '250px',
            sm: '320px',
            md: '508px',
            lg: '508px',
            xl: '508px',
          },
          maxHeight: '100%',
          borderRadius: '12px',
          height: '188px',
          bgcolor: 'background.paper',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        }}
      >
        <Box sx={{ padding: '24px', textAlign: 'center' }}>
          <Typography sx={{ fontSize: '20px', color: colors?.neutral?.dark }}>
            Are you sure you want to accept the request?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '0px 20px 20px 20px',
            justifyContent: 'space-between',
            gap: '15px',
          }}
        >
          <Button
            variant='#ff4c6a'
            disabled={loadingUsers['ACCEPT'][EditUser[0]?.p_contact_email] === true}
            sx={{
              backgroundColor: 'gray',
              maxHeight: '100%',
              height: '56px',
              maxWidth: '100%',
              borderRadius: '12px',
              width: '224px',
              fontSize: '20px',
              border: '1px solid gray',
              background: '#FFFFFF',
              color: '#686465',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
            onClick={() => setIsacceptconfirmModal(false)}
          >
            Cancel
          </Button>

          <Button
            variant='#ff4c6a'
            disabled={loadingUsers['ACCEPT'][EditUser[0]?.p_contact_email] === true}
            sx={{
              backgroundColor: colors?.theme,
              maxHeight: '100%',
              height: '56px',
              borderRadius: '12px',
              maxWidth: '100%',
              width: '224px',
              fontSize: '20px',
              color: '#ffffff',
              fontWeight: 700,
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#ff4c6a',
                color: '#ffffff',
              },
            }}
            onClick={() => {
              handleSubmit(EditUser[0]?.p_contact_email, 'ACCEPT', 'message');
            }}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActionModal;
