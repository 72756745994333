import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { colors } from '../../theme/colors';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
import { Images } from '../../assets/images';
import { ReactComponent as DownArrow } from '../../assets/images/dropdownDownArrow.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

export const AdminSideMenuListItem = [
  {
    title: 'Dashboard',
    slug: '/admin/dashboard',
  },
  {
    title: 'User Activity',
    slug: '/admin/user-activity',
  },
  {
    title: 'Usage',
    slug: '/admin/traffic-usage',
  },
  {
    title: 'Customers',
    slug: '/admin/customer-details',
  },
  {
    title: 'Management',
    submenu: true,
    menu: [
      {
        title: 'Subscription',
        slug: '/admin/subscription',
      },
      {
        title: 'Plans',
        slug: '/admin/plans',
      },
      {
        title: 'Payment',
        slug: '/admin/payment',
      },
    ],
  },
];

const AdminSidebar = ({ isMobile = false, isClick, onClick }) => {
  const theme = useTheme();
  const location = useLocation();
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.accent4,
          width: { xl: '315px', xs: '260px' },
          height: '100vh',
          position: 'fixed',
          // padding: { md: '24px', xs: '0' },
          top: '0',
          left: { md: '0' },
          right: { xs: '0' },
          display: isMobile ? 'block' : { md: 'block', xs: 'none' },
          overflow: 'auto',
        }}
      >
        <Box
          onClick={onClick}
          sx={{
            display: { md: 'none', xs: 'flex' },
            position: 'absolute',
            top: '30px',
            right: { sm: '25px', xs: '30px' },
          }}
        >
          {isClick ? (
            <CloseIcon sx={{ height: '30px', width: '30px' }} />
          ) : (
            <img src={Images.SidebarIcon} alt='Profile' height='30px' width='30px' />
          )}
        </Box>
        <Box
          sx={{
            padding: { md: '24px', xs: '0' },
          }}
        >
          {!isMobile && (
            <Box
              sx={{
                textAlign: 'center',
                paddingBottom: '58px',
                paddingTop: { md: '0', xs: '24px' },
                '& svg': {
                  width: {
                    xl: '155px',
                    lg: '180px',
                    sm: '150px',
                    xs: '120px',
                  },
                  height: 'auto',
                },
              }}
            >
              <Link to='/admin/login'>
                <Logo />
              </Link>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              height: '100%',
            }}
          >
            {AdminSideMenuListItem?.map((menuItem, index) => {
              if (menuItem?.submenu) {
                return (
                  <>
                    <Accordion
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                      sx={{
                        width: '100% !important',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        margin: 0,
                      }}
                      key={index}
                    >
                      <AccordionSummary
                        sx={{
                          minHeight: 'auto !important',
                          padding: { md: '10.5px 16px', xs: '10px' },
                          borderRadius: '4px',
                          '& .MuiAccordionSummary-content': {
                            margin: '0 !important',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          },
                          '&.Mui-expanded': {
                            backgroundColor: '#FFF2F6',
                            '& svg': {
                              transform: `rotate(180deg)`,
                            },
                          },
                          '& svg': {
                            transform: `rotate(0deg)`,
                            transition: 'all .5s ease',
                          },
                        }}
                        aria-controls='panel1d-content'
                        id='panel1d-header'
                      >
                        <Typography
                          variant='subtitle1'
                          color={colors.basics.normal}
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          {menuItem?.title}{' '}
                        </Typography>
                        <DownArrow />
                      </AccordionSummary>
                      <AccordionDetails
                        key={index}
                        sx={{
                          padding: '16px 13px',
                          '& h6:not(:last-child)': {
                            mb: '16px',
                          },
                        }}
                      >
                        <Box>
                          {menuItem?.menu?.map((item, index) => (
                            <Typography
                              key={index}
                              variant='subtitle1'
                              sx={{
                                '& a': {
                                  padding: { md: '10.5px 16px', xs: '10px' },
                                  textDecoration: 'none',
                                  display: 'block',
                                  color: theme.palette.neutral.normal,
                                  borderRadius: '4px',
                                  '&:not(.active):hover': {
                                    color: theme.palette.accent1,
                                    backgroundColor: theme.palette.accent3,
                                  },
                                  '&.active': {
                                    backgroundColor: '#FD337E !important',
                                    color: 'white',
                                  },
                                },
                              }}
                            >
                              <Link
                                className={`${
                                  menuItem.slug !== '/admin/' && location.pathname.startsWith(item.slug)
                                    ? 'active'
                                    : ''
                                }`}
                                to={item.slug}
                              >
                                {item.title}
                              </Link>
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              } else {
                return (
                  <Typography
                    key={index}
                    variant='subtitle1'
                    sx={{
                      '& a': {
                        padding: { md: '10.5px 16px', xs: '10px' },
                        textDecoration: 'none',
                        display: 'block',
                        color: theme.palette.neutral.normal,
                        borderRadius: '4px',
                        '&:not(.active):hover': {
                          color: theme.palette.accent1,
                          backgroundColor: theme.palette.accent3,
                        },
                        '&.active': {
                          backgroundColor: '#FD337E !important',
                          color: 'white',
                        },
                      },
                    }}
                  >
                    <Link
                      className={`${
                        (menuItem.slug !== '/admin/' && location.pathname.startsWith(menuItem.slug)) ||
                        (location.pathname.startsWith('/admin/subscription?customerId') &&
                          menuItem.slug === '/admin/subscription') ||
                        (location.pathname.startsWith('/admin/buyplan') &&
                          menuItem.slug === '/admin/customer-details')
                          ? 'active'
                          : ''
                      }`}
                      to={menuItem.slug}
                    >
                      {menuItem.title}
                    </Link>
                  </Typography>
                );
              }
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminSidebar;
