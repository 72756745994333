import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../theme/colors';
import { toast } from 'react-toastify';
import { getUserBillingInfoDetails } from '../../../redux/userBillingInformationAPI';
import { userautoRenewalAPI } from '../../../redux/autoRenewalAPI';
import Loader from '../../../components/common/Loding';
import BillingTable from './billingTable';
import { useGetUser } from '../../../context/userProfile';

const BillingInformation = () => {
  const dispatch = useDispatch();
  const rowsPerPage = 5;
  const userSelector = useSelector((state) => state);
  const [billingLoading, setBillingLoading] = useState(true);
  const [durationDay, setDurationDay] = useState(0);
  const [page, setPage] = useState(1);
  const { logInUserDetails } = useGetUser();
  const [userplanInfo, setUserPlanInfo] = useState([]);
  const [billingData, setBillingData] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  const getUserBillingInfo = async () => {
    try {
      const data = await dispatch(getUserBillingInfoDetails(logInUserDetails?.p_customer_id));
      if (data?.payload?.status === true) {
        setUserPlanInfo(data?.payload);
        setBillingLoading(false);
      } else {
        toast.error(data?.message);
        setBillingLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setBillingLoading(false);
    }
  };

  useEffect(() => {
    const startDateString = userplanInfo?.data?.currentActivePlan?.p_start_date;
    const endDateString = userplanInfo?.data?.currentActivePlan?.p_end_date;

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const differenceInMilliseconds = endDate - startDate;

    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsInOneDay);
    setDurationDay(differenceInDays);
  }, [
    userplanInfo?.data?.currentActivePlan?.p_end_date,
    userplanInfo?.data?.currentActivePlan?.p_start_date,
  ]);

  useEffect(() => {
    if (logInUserDetails && logInUserDetails.p_customer_id) {
      getUserBillingInfo();
    }
  }, [logInUserDetails]);

  const columm = [
    {
      id: 1,
      name: 'Plan',
    },
    {
      id: 2,
      name: 'Type',
    },
    {
      id: 3,
      name: 'Billed Date',
    },
    {
      id: 4,
      name: 'Status',
    },
    {
      id: 5,
      name: 'Amount',
    },
    {
      id: 6,
      name: 'Mode',
    },
    {
      id: 7,
      name: 'Invoice',
    },
  ];

  const handleAutoRenewal = async () => {
    const renwalObj = {
      userId: userSelector?.userProfileReducer?.userProfileData?.p_customer_id,
      autoRenew: userplanInfo?.data?.p_autoRenew === 1 ? 0 : 1,
    };
    const data = await dispatch(userautoRenewalAPI(renwalObj));
    if (data?.payload?.status === true) {
      toast.success(data?.payload?.message);
      getUserBillingInfo();
    } else {
      toast.error('error');
    }
  };

  const handleInvoice = async (id) => {
    const invoiceData = userplanInfo?.data?.billingHistory?.find(
      (item) => item?.payment?.p_payments_id === id
    );
    setBillingData(invoiceData?.payment);
    setModalOpen(true);
  };
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedHistory = userplanInfo?.data?.billingHistory?.slice(startIndex, endIndex);

  return (
    <>
      <Box>
        <Typography variant="h2" sx={{ mb: 3, color: colors?.neutral.dark, ml: 1 }}>
          Billing Information
        </Typography>
        {billingLoading ? (
          <Loader />
        ) : (
          <>
            <BillingTable
              displayedHistory={displayedHistory}
              userplanInfo={userplanInfo}
              handleInvoice={handleInvoice}
              durationDay={durationDay}
              handleAutoRenewal={handleAutoRenewal}
              columm={columm}
              closeModal={closeModal}
              billingData={billingData}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              setPage={setPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default BillingInformation;
