import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const adminPaymentAPI = createAsyncThunk('admin-payment-api', async (data) => {
  try {
    const response = await API.post(`/customersplans/admin`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  adminPayment: {},
};

const adminPaymentSlice = createSlice({
  name: 'adminPayment',
  initialState,
  reducers: {
    setAdminPaymnet: (state, { payload }) => {
      state.adminPayment = payload;
    },
  },
});

export const { setAdminPaymnet } = adminPaymentSlice.actions;
export default adminPaymentSlice.reducer;
