import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const editPlanWiseCustomerAPI = createAsyncThunk('edit-customer-plan-api', async (data) => {
  try {
    const response = await API.put(`/plans/update`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  editPlanCustomer: {},
};

const editPlanCustomerSlice = createSlice({
  name: 'edit-customer-plan-api',
  initialState,
  reducers: {
    setEditPlanCustomerDetails: (state, { payload }) => {
      state.editPlanCustomer = payload;
    },
  },
});

export const { setEditPlanCustomerDetails } = editPlanCustomerSlice.actions;
export default editPlanCustomerSlice.reducer;
