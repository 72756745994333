import { Typography, Box, Button, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../../theme/colors';
import { ReactComponent as True } from '../../../assets/images/true.svg';
import { ReactComponent as False } from '../../../assets/images/false.svg';
import { formatNumberWithCommas } from '../../../components/common/formatNumberWithCommas';
import TranzilaPaymentModal from './tranzilaPaymentModal';
import { baseURL } from '../../../api/url';
import { useGetUser } from '../../../context/userProfile';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { adminPaymentAPI } from '../../../redux/adminpaymentAPI';
import { toast } from 'react-toastify';
import { setCustomCustomerDetails } from '../../../redux/getCustomCustomerPlansAPI';
import RollOverInformationModal from './rolloverInformationModal';
import { IsCreditCardExpired } from '../../../utils/CommonFunctions/commonFile';
import { CustomersplansSubscribe } from '../../../redux/tranzillaTokenWisePaymnet';

const ProxyPlanCard = ({ plansType, products, proxyPlan, tabValue }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logInUserDetails } = useGetUser();
  const proxySelect = useSelector((state) => state?.paymentListReducer?.paymentList);
  const currentPath = location.pathname;
  const adminPath = currentPath.match(/^\/admin\b/);
  const [openModal, setOpenModal] = useState(false);
  const [rolloverModal, setRollOverModal] = useState(false);
  const [modalContentUrl, setModalContentUrl] = useState('');
  const [isloading, setIsloading] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState(
    products.reduce((acc, prod) => {
      acc[prod.p_plan_id] = true;
      return acc;
    }, {})
  );
  const [paymnet, setPayment] = useState({});
  const [planDetails, setPlanDetails] = useState({});

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'retry') {
        handleCloseModal();
      } else if (event.data === 'dashboard') {
        navigate('/');
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleClick = async (id) => {
    try {
      setIsloading(true);
      const plan = products?.find((plan) => plan.p_plan_id === id);
      if (plan) {
        const { p_name, p_yearlyPrice, p_price, p_volume } = plan;
        setPlanDetails(plan);
        const price = !plansType ? p_price : p_yearlyPrice;
        const p_billingChargesType = plansType ? 2 : 1;
        const p_payment_mode = 2;
        const successUrl = `${baseURL}customersplans`;
        const failiurUrl = `${baseURL}customersplans/transaction-failure`;
        const paymnetPaylaod = {
          p_plan_id: plan?.p_plan_id,
          p_customer_id: proxySelect?.customerId || logInUserDetails?.p_customer_id,
          p_created_by: logInUserDetails?.p_customer_id || logInUserDetails?.p_customer_id,
          p_billingChargesType: p_billingChargesType,
          p_ownerId: proxySelect.ownerId || logInUserDetails?.p_ownerId,
          p_customerSource: proxySelect?.customerSourceId || logInUserDetails?.p_customerSource,
          p_payment_mode: p_payment_mode,
          p_payment_amount: price,
          p_tax: 5,
          ...(adminPath && adminPath.length > 0 && adminPath[0]
            ? {}
            : { p_autoRenew: autoRenewal[plan?.p_plan_id] }),
          p_duration: plan?.p_duration,
          cc_token: proxySelect?.cc_token || logInUserDetails?.p_tranzilaTK,
          cc_exp: proxySelect?.cc_exp || logInUserDetails?.p_ccExp,
          amount: price,
          p_contact_email: proxySelect?.userEmail || logInUserDetails?.p_contact_email,
          p_isCustom: plan?.p_isCustom,
          p_is3DSEnabled: proxySelect?.p_is3DSEnabled || logInUserDetails?.p_is3DSEnabled,
        };
        setPayment(paymnetPaylaod);

        const is3DSEnabled = logInUserDetails?.p_is3DSEnabled;
        const isTokenPresent = logInUserDetails?.p_tranzilaTK !== null || '';
        const isCardExpired = IsCreditCardExpired(paymnetPaylaod?.cc_exp);

        if (adminPath && adminPath.length > 0 && adminPath[0]) {
          if (proxySelect?.isRollover === 1 && p_volume < proxySelect?.volume) {
            setRollOverModal(true);
          } else {
            const data = await dispatch(adminPaymentAPI(paymnetPaylaod));
            if (data?.payload?.response?.status === 500) {
              toast.success(data?.payload?.response.message);
              setIsloading(false);
            } else if (data?.payload?.status === true) {
              toast.success(data?.payload?.messgae);
              setIsloading(false);
              await dispatch(setCustomCustomerDetails(data?.payload?.data?.p_customer_id));
              const url = `/admin/subscription?customerId=${data?.payload?.data?.p_customer_id}`;
              window.location.href = url;
            }
          }
        } else {
          if (logInUserDetails?.isRollover === 1 && p_volume < logInUserDetails?.volume) {
            setRollOverModal(true);
          } else {
            let url = '';
            if (is3DSEnabled) {
            // if (is3DSEnabled && (!isTokenPresent || isCardExpired)) {
              url = `https://direct.tranzila.com/proxe/iframenew.php?name=${p_name}&sum=${price}&currency=${2}&cred_type=${1}&tranmode=${
                autoRenewal[plan?.p_plan_id] ? 'AK' : 'A'
              }&fail_url_address=${failiurUrl}&success_url_address=${successUrl}&p_plan_id=${
                plan?.p_plan_id
              }&p_autoRenew=${autoRenewal[plan?.p_plan_id]}&p_created_by=${
                logInUserDetails?.p_customer_id
              }&p_customer_id=${
                logInUserDetails?.p_customer_id
              }&p_billingChargesType=${p_billingChargesType}&p_ownerId=${
                logInUserDetails?.p_ownerId
              }&p_customerSource=${
                logInUserDetails?.p_customerSource
              }&p_payment_mode=${p_payment_mode}&p_payment_amount=${price}&p_tax=5&p_duration=${
                plan?.p_duration
              }&p_isCustom=${plan?.p_isCustom}&p_contact_email=${
                logInUserDetails?.p_contact_email
              }&force_challenge=${1}&force_txn_on_3ds_fail=Y`;

              setModalContentUrl(url);
              setOpenModal(true);
              setIsloading(false);
            } else if (!is3DSEnabled) {
            // } else if (!is3DSEnabled && (!isTokenPresent || isCardExpired)) {
              url = `https://direct.tranzila.com/proxetok/iframenew.php?name=${p_name}&sum=${price}&currency=${2}&cred_type=${1}&tranmode=${
                autoRenewal[plan?.p_plan_id] ? 'AK' : 'A'
              }&fail_url_address=${failiurUrl}&success_url_address=${successUrl}&p_plan_id=${
                plan?.p_plan_id
              }&p_autoRenew=${autoRenewal[plan?.p_plan_id]}&p_created_by=${
                logInUserDetails?.p_customer_id
              }&p_customer_id=${
                logInUserDetails?.p_customer_id
              }&p_billingChargesType=${p_billingChargesType}&p_ownerId=${
                logInUserDetails?.p_ownerId
              }&p_customerSource=${
                logInUserDetails?.p_customerSource
              }&p_payment_mode=${p_payment_mode}&p_payment_amount=${price}&p_tax=5&p_duration=${
                plan?.p_duration
              }&p_isCustom=${plan?.p_isCustom}&p_contact_email=${logInUserDetails?.p_contact_email}`;
              setModalContentUrl(url);
              setOpenModal(true);
              setIsloading(false);
            }
            // else if (isTokenPresent && !isCardExpired) {
            //   const data = await dispatch(CustomersplansSubscribe(paymnetPaylaod));
            //   if (data?.payload?.message === 'Network Error') {
            //     toast.success(data?.payload?.message);
            //     setIsloading(false);
            //   } else if (data?.payload?.status === true) {
            //     toast.success(data?.payload?.messgae);
            //     setIsloading(false);
            //     navigate('/billingInformation');
            //   }
            // }
          }
        }
      }
    } catch (error) {
      console.error(error);
      setIsloading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box className="Plan-card-wrapper">
        {Array.isArray(products) &&
          (products?.length > 0 ? (
            products?.map((items, index) => (
              <React.Fragment key={index}>
                <Box
                  key={index}
                  sx={{
                    padding: { xl: '24px', xs: '12px' },
                    backgroundColor: items?.popular ? colors.themeBg : colors.basics.white,
                    border: items?.popular ? `1px solid ${colors.theme}` : `1px solid ${colors.basics.white}`,
                    borderRadius: '12px',
                    position: 'relative',
                    overflow: 'hidden',
                    flexShrink: '0',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  {items?.popular && (
                    <Typography
                      variant="subtitle2"
                      className="label-stipe"
                      sx={{
                        color: 'white',
                        backgroundColor: '#FD337E',
                        textAlign: 'center',
                        fontSize: { lg: '18px', md: '16px', sm: '12px', xs: '10px' },
                        lineHeight: { sm: '1.5', xs: '1.4' },
                        padding: '4px 10px',
                        position: 'absolute',
                        left: { md: '80px', xs: '70px' },
                        top: { md: '30px', sm: '25px', xs: '20px' },
                        width: '100%',
                        transform: 'rotate(45deg)',
                      }}
                    >
                      Most Popular
                    </Typography>
                  )}
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      sx={{
                        padding: { xl: '0 0 16px', xs: ' 0 0 10px' },
                        borderBottom: `1px solid ${colors.input.border}`,
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          color: colors.tabBackgroundColor,
                          lineHeight: { md: '1.5', xs: '1' },
                          fontWeight: 600,
                          textTransform: 'capitalize',
                          fontSize: { xl: '18px', xs: '16px' },
                        }}
                      >
                        {items?.p_name}
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: { xl: '32px', sm: '24px', xs: '18px' },
                          color: colors.neutral.dark,
                          fontWeight: 600,
                          marginTop: '8px',
                          lineHeight: 1,
                        }}
                      >
                        $
                        {!plansType ? (
                          <>
                            <span className="price-box">{formatNumberWithCommas(items?.p_price)}</span>
                            <span className="day-box">
                              {proxyPlan.length > 0 && proxyPlan[tabValue]?.p_name === 'Custom'
                                ? ''
                                : 'Monthly'}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="price-box">{formatNumberWithCommas(items?.p_yearlyPrice)}</span>
                            <span className="day-box">
                              {proxyPlan.length > 0 && proxyPlan[tabValue]?.p_name === 'Custom'
                                ? ''
                                : 'Yearly'}
                            </span>
                          </>
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: { xl: '24px 0', xs: '20px 0' },
                        '& h6:not(:last-child)': {
                          marginBottom: { md: '20px', xs: '10px' },
                        },
                      }}
                    >
                      <Typography
                        key={index}
                        variant="subtitle1"
                        sx={{
                          display: 'flex',
                          fontWeight: 400,
                          color: colors.basics.normal,
                          fontSize: { xl: '16px', xs: '14px' },
                          '& svg': {
                            marginRight: { xl: '12px', xs: '8px' },
                            width: { xl: '28px', xs: '20px' },
                            flexShrink: 0,
                          },
                        }}
                      >
                        {items?.p_volume ? <True /> : <False />}
                        {!plansType ? `${items?.p_volume}` : `${items?.p_yearlyVolume}`}
                        GB Bandwidth
                      </Typography>
                      <Typography
                        key={index}
                        variant="subtitle1"
                        sx={{
                          display: 'flex',
                          fontWeight: 400,
                          color: colors.basics.normal,
                          fontSize: { xl: '16px', xs: '14px' },
                          '& svg': {
                            marginRight: { xl: '12px', xs: '8px' },
                            width: { xl: '28px', xs: '20px' },
                            flexShrink: 0,
                          },
                        }}
                      >
                        {items?.p_unitPrice ? <True /> : <False />}${' '}
                        {formatNumberWithCommas(items?.p_unitPrice)} per GB Rate
                      </Typography>
                      <Typography
                        key={index}
                        variant="subtitle1"
                        sx={{
                          display: 'flex',
                          fontWeight: 400,
                          color: colors.basics.normal,
                          fontSize: { xl: '16px', xs: '14px' },
                          '& svg': {
                            marginRight: { xl: '12px', xs: '8px' },
                            width: { xl: '28px', xs: '20px' },
                            flexShrink: 0,
                          },
                        }}
                      >
                        {items?.p_emailAndChatSupport ? <True /> : ''}
                        {items?.p_emailAndChatSupport === 1
                          ? 'Email support'
                          : items?.p_emailAndChatSupport === 2
                          ? 'Email & Chat support'
                          : ''}
                      </Typography>
                    </Box>
                    {adminPath && adminPath.length > 0 && adminPath[0] ? null : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: '16px',
                        }}
                      >
                        <Typography variant="subtitle3" color={colors?.neutral.dark}>
                          Auto Renewal
                        </Typography>

                        <Switch
                          checked={autoRenewal[items.p_plan_id]}
                          sx={{
                            '&.MuiSwitch-root ': {
                              borderRadius: '50px',
                              height: { sm: '30px', xs: '25px' },
                              width: { sm: '58px', xs: '50px' },
                              padding: '0',
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                transform: { sm: 'translateX(28px)', xs: 'translateX(25px)' },
                              },
                            },
                            '& .MuiSwitch-track': {
                              backgroundColor: colors.basics.white,
                              borderRadius: '50px',
                              border: `1px solid ${colors.input.border}`,
                              height: { sm: '28px', xs: '23px' },
                            },
                            '& .Mui-checked+.MuiSwitch-track': {
                              backgroundColor: `${colors.theme} !important`,
                              opacity: '1 !important',
                              borderColor: colors.theme,
                            },
                            '& .MuiSwitch-thumb ': {
                              backgroundColor: colors.input.border,
                              boxShadow: 'none',
                              width: { sm: '20px', xs: '15px' },
                              height: { sm: '20px', xs: '15px' },
                            },
                            '& .MuiSwitch-switchBase': {
                              padding: '5px',
                              '&.Mui-checked': {
                                '& .MuiSwitch-thumb ': {
                                  backgroundColor: colors.basics.white,
                                },
                              },
                            },
                          }}
                          onClick={(e) =>
                            setAutoRenewal((prev) => ({ ...prev, [items.p_plan_id]: e.target.checked }))
                          }
                        />
                      </Box>
                    )}
                    <Box>
                      <Button
                        variant={items?.popular ? 'filled' : 'outlined'}
                        sx={{
                          width: '100%',
                          fontSize: { lg: '16px', md: '12px' },
                          fontWeight: '700',
                          borderRadius: '6px',
                          textTransform: 'capitalize',
                        }}
                        onClick={() => handleClick(items?.p_plan_id)}
                      >
                        {adminPath && adminPath.length > 0 && adminPath[0] ? 'Add Now' : 'Buy Now'}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </React.Fragment>
            ))
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
                minHeight: '200px',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xl: '24px', lg: '20px', sm: '18px', xs: '16px' },
                  color: colors.neutral.dark,
                  fontWeight: 600,
                  marginTop: '20px',
                }}
              >
                Product not available
              </Typography>
            </Box>
          ))}
      </Box>
      <Box>
        <TranzilaPaymentModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          modalContentUrl={modalContentUrl}
        />
      </Box>

      <Box>
        <RollOverInformationModal
          openModal={rolloverModal}
          setRollOverModal={setRollOverModal}
          setOpenModal={setOpenModal}
          payment={paymnet}
          setIsloading={setIsloading}
          planDetails={planDetails}
          setModalContentUrl={setModalContentUrl}
          plansType={plansType}
          logInUserDetails={logInUserDetails}
        />
      </Box>
    </>
  );
};

export default ProxyPlanCard;
