import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const editCustomerDetailAPI = createAsyncThunk('edit-customer-details', async (data) => {
  try {
    const response = await API.post(`/users/admin/customer/edit/${data.p_customer_id}`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  editCustomerDetails: {},
};

const editCustomerDetailSlice = createSlice({
  name: 'edit-customer-details',
  initialState,
  reducers: {
    setEditCustomerDetails: (state, { payload }) => {
      state.editCustomerDetails = payload;
    },
  },
});

export const { setEditCustomerDetails } = editCustomerDetailSlice.actions;
export default editCustomerDetailSlice.reducer;
