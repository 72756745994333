import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Button,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../theme/colors';
import { Images } from '../../assets/images';
import CustomDropdown from '../../components/common/customDropDown';
import { ReactComponent as DownArrow } from '../../assets/images/dropdownDownArrow.svg';
import { ReactComponent as MobileLogo } from '../../assets/images/mobileLogo.svg';
import LogoutModal from '../../pages/user/logoutModal';
import { useGetUser } from '../../context/userProfile';
import { baseURL } from '../../api/url';
import { CountryListOptions } from '../../components/common/country';

export const AdminSideMenuListItem = [
  {
    title: 'Dashboard',
    slug: '/admin/dashboard',
  },
  {
    title: 'User Activity',
    slug: '/admin/user-activity',
  },
  {
    title: 'Usage',
    slug: '/admin/traffic-usage',
  },
  {
    title: 'Customers',
    slug: '/admin/customer-details',
  },
  {
    title: 'Management',
    submenu: true,
    menu: [
      {
        title: 'Subscription',
        slug: '/admin/subscription',
      },
      {
        title: 'Plans',
        slug: '/admin/plans',
      },
      {
        title: 'Payment',
        slug: '/admin/payment',
      },
    ],
  },
];

export const adminProfile = [
  {
    title: 'Profile',
    slug: '/admin/profile',
  },
  {
    title: 'Change password',
    slug: '/admin/changepassword',
  },
  {
    title: 'Logout',
    slug: `/logout`,
  },
];
const AdminHeader = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { logInUserDetails } = useGetUser();
  const [logoutModal, setIsLogoutModal] = useState(false);
  const [country, setCountry] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [SidebarMenu, setSidebarMenu] = useState(false);
  const [expanded, setExpanded] = useState('');

  useEffect(() => {
    const sortedCountries = CountryListOptions?.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setCountryData(sortedCountries);
  }, []);

  useEffect(() => {
    setCountry(logInUserDetails?.p_countryName ? logInUserDetails?.p_countryName : 'AL');
  }, [logInUserDetails?.p_countryName]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: '0',
          zIndex: '10',
          backgroundColor: colors.basics.white,
          borderBottom: '1px solid #0000001A',
          padding: { md: '21px 24px', xs: '20px 10px' },
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: { lg: '20px', md: '2px', sm: '2px', xs: '0px' },
          boxShadow: { md: 'none', xs: '0px 2px 5px #00000050' },
        }}
      >
        <Box
          onClick={() => {
            setSidebarMenu(!SidebarMenu);
          }}
          sx={{
            display: { md: 'none', xs: 'flex' },
            right: { sm: '25px', xs: '30px' },
          }}
        >
          <img src={Images.SidebarIcon} alt='Profile' height='30px' width='30px' />
        </Box>
        <Box
          sx={{
            display: { md: 'none' },
            '& svg': {
              width: '48px',
              height: 'auto',
            },
          }}
        >
          <Link to='/admin/login'>
            <MobileLogo />
          </Link>
        </Box>

        <Box
          className='searchBox'
          sx={{
            position: 'relative',
            maxWidth: { md: '354px' },
            marginLeft: { md: 'unset', xs: 'auto' },
            width: { md: '100%' },
            ' svg': {
              position: { md: 'absolute' },
              top: { md: '50%' },
              left: { md: '10px' },
              width: { md: '20px', xs: '28px' },
              height: { md: '20px', xs: '28px' },
              objectFit: 'contain',
              transform: { md: 'translateY(-50%)' },
              zIndex: '1',
              padding: '0px',
              borderRadius: '50%',
            },
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexShrink: 0,
            gap: { md: '25px', xs: '12px' },
            alignItems: 'center',
            borderRadius: '9px',
          }}
        >
          <CustomDropdown
            width='auto'
            height='42px'
            borderRadius='9px'
            customSx={{
              borderRadius: '9px !important',
              outline: '0',
              border: '0',
              '& fieldset': {
                border: '0',
              },
              className: 'language-select',
            }}
            icon
            removeIconOnSelectValue={true}
            iconType='country'
            iconColor={'dark'}
            value={country}
            onChange={(value) => setCountry(value)}
            options={countryData}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&::before': {
                content: '""',
                display: 'inline',
                width: '1px',
                height: '100%',
                padding: ' 12px 0',
                paddingLeft: { md: '24px', xs: '12px' },
                borderLeft: `2px solid ${theme.palette.neutral.lighter}`,
              },
            }}
          >
            <Button
              id='basic-button'
              aria-controls={anchorEl ? 'filterMenu' : undefined}
              aria-haspopup='true'
              aria-expanded={anchorEl ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                backgroundColor: `${colors.basics.white} !important`,
                boxShadow: 'none !important',
                minWidth: { md: '64px', xs: 'auto' },
                padding: '0',
                color: `${colors.basics.normal}!important`,
                fontSize: '18px',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                '& .profile-img': {
                  width: '42px',
                  height: '42px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  borderRadius: '50%',
                },
                '& .dropdown-arrow': {
                  display: { md: 'block', xs: 'none' },
                },
              }}
            >
              {logInUserDetails?.p_profilePicName ? (
                <img
                  src={
                    logInUserDetails?.p_profilePicName
                      ? `${baseURL}public/profile/${logInUserDetails?.p_profilePicName}`
                      : Images.AdminProfile
                  }
                  alt='Profile'
                  height='42'
                  width='42'
                  className='profile-img'
                />
              ) : (
                <img
                  src={Images.Username_Icon}
                  alt='Profile'
                  height='42'
                  width='42'
                  className='profile-img'
                />
              )}
              <Box sx={{ width: 'max-content', display: { md: 'block', xs: 'none' } }}>
                <Typography
                  variant='subtitle6'
                  sx={{
                    overflow: 'hidden',
                    WebkitLineClamp: '1',
                    display: 'block',
                    maxWidth: '190px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                    color: colors?.neutral?.dark,
                  }}
                >
                  {logInUserDetails?.p_login ? logInUserDetails?.p_login : 'Admin'}
                </Typography>
              </Box>
              <img
                src={Images.DropdownDownArrow_Icon}
                className='dropdown-arrow'
                alt='Dropdown'
                height='24'
                width='21'
              />
            </Button>
            <Menu
              id='filterMenu'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              right
              onClose={handleClose}
              transformorigin={{ horizontal: 'right', vertical: 'top' }}
              anchororigin={{ horizontal: 'right', vertical: 'bottom' }}
              sx={{
                '& ul li:last-child': {
                  paddingBottom: '0',
                },
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                style: {
                  maxHeight: '232px',
                  width: '200px !important',
                },
              }}
            >
              {adminProfile.map((menuItem, index) => {
                const isLastItem = index === adminProfile.length - 1;
                return (
                  <MenuItem
                    onClick={async () => {
                      if (menuItem.slug === '/logout') {
                        setIsLogoutModal(true);
                        setAnchorEl(null);
                      } else {
                        navigate(menuItem.slug);
                        setAnchorEl(null);
                      }
                    }}
                    variant={isLastItem ? 'tertiary2' : 'tertiary1'}
                    key={index}
                    sx={{
                      color: isLastItem
                        ? `${theme.palette.accent1} !important`
                        : theme.palette.neutral.normal,
                      fontSize: '16px',
                      fontWeight: `${isLastItem ? 600 : 400} !important`,
                      borderTop: isLastItem && `1px solid ${theme.palette.neutral.lighter}`,
                    }}
                  >
                    {menuItem.title}
                  </MenuItem>
                );
              })}
            </Menu>
            <LogoutModal setIsLogoutModal={setIsLogoutModal} logoutModal={logoutModal} />
          </Box>
        </Box>
      </Box>

      <Box
        className='modalBox'
        sx={{
          position: 'fixed',
          zIndex: 9999,
          top: '0',
          left: '0',
          bottom: '0',
          width: '100%',
          height: '100vh',
          padding: '0',
          // overflow: "auto",
          display: { md: 'none' },
          opacity: `${!SidebarMenu ? '0' : '1'} `,
          visibility: `${!SidebarMenu ? 'hidden' : 'visible'}`,
          transition: 'all .5s ease',
          transitionDelay: `${SidebarMenu ? '.1s' : '.6s'} `,
        }}
      >
        <Box
          onClick={() => {
            setSidebarMenu(!SidebarMenu);
          }}
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            bottom: '0',
            width: '100%',
            height: '100%',
            backgroundColor: '#00000050',
            boxShadow: '0px 5px 6px 0px #00000050',
            zIndex: '1',
          }}
        ></Box>
        <Box
          sx={{
            padding: '24px',
            maxWidth: '350px',
            height: '100%',
            position: 'relative',
            backgroundColor: colors.themeBg,
            transition: 'all .5s ease',
            transform: `translateX(${SidebarMenu ? '0%' : '-100%'} )`,
            transitionDelay: `${SidebarMenu ? '0.6s' : '.1s'} `,
            zIndex: '2',
          }}
        >
          <Box
            sx={{
              paddingTop: '20px',
              paddingBottom: '30px',
              '& img': {
                width: {
                  xl: '155px',
                  lg: '180px',
                  sm: '150px',
                  xs: '120px',
                },
                height: 'auto',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Link to='/admin/login'>
                <img src={Images.Logo} alt='Logo' />
              </Link>
              <Box
                onClick={() => {
                  setSidebarMenu(!SidebarMenu);
                }}
                sx={{
                  display: { md: 'none', xs: 'flex' },
                  right: { sm: '25px', xs: '30px' },
                }}
              >
                <CloseIcon sx={{ height: '30px', width: '30px' }} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              height: '100%',
            }}
          >
            {AdminSideMenuListItem.map((menuItem, index) => {
              if (menuItem?.submenu) {
                return (
                  <>
                    <Accordion
                      key={index}
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                      sx={{
                        width: '100% !important',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        margin: 0,
                      }}
                    >
                      <AccordionSummary
                        key={index}
                        sx={{
                          minHeight: 'auto !important',
                          padding: { md: '10.5px 16px', xs: '10px' },
                          borderRadius: '4px',
                          '& .MuiAccordionSummary-content': {
                            margin: '0 !important',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          },
                          '&.Mui-expanded': {
                            backgroundColor: '#FFF2F6',
                            '& svg': {
                              transform: `rotate(180deg)`,
                            },
                          },
                          '& svg': {
                            transform: `rotate(0deg)`,
                            transition: 'all .5s ease',
                          },
                        }}
                        aria-controls='panel1d-content'
                        id='panel1d-header'
                      >
                        <Typography
                          key={index}
                          variant='subtitle1'
                          color={colors.basics.normal}
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          {menuItem?.title}{' '}
                        </Typography>
                        <DownArrow />
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: '16px 13px',
                          '& h6:not(:last-child)': {
                            mb: '16px',
                          },
                        }}
                      >
                        <Box>
                          {menuItem?.menu?.map((item, index) => (
                            <Typography
                              key={index}
                              variant='subtitle1'
                              sx={{
                                '& a': {
                                  padding: { md: '10.5px 16px', xs: '10px' },
                                  textDecoration: 'none',
                                  display: 'block',
                                  color: theme.palette.neutral.normal,
                                  borderRadius: '4px',
                                  '&:not(.active):hover': {
                                    color: theme.palette.accent1,
                                    backgroundColor: theme.palette.accent3,
                                  },
                                  '&.active': {
                                    backgroundColor: '#ff4c6a !important',
                                    color: 'white',
                                  },
                                },
                              }}
                            >
                              <Link
                                className={`${location.pathname === item.slug ? 'active' : ''}`}
                                to={item.slug}
                                onClick={() => {
                                  setSidebarMenu(!SidebarMenu);
                                }}
                              >
                                {item.title}
                              </Link>
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              } else {
                return (
                  <Typography
                    key={index}
                    variant='subtitle1'
                    sx={{
                      '& a': {
                        padding: { md: '10.5px 16px', xs: '10px' },
                        textDecoration: 'none',
                        display: 'block',
                        color: theme.palette.neutral.normal,
                        borderRadius: '4px',
                        '&:not(.active):hover': {
                          color: theme.palette.accent1,
                          backgroundColor: theme.palette.accent3,
                        },
                        '&.active': {
                          backgroundColor: '#ff4c6a !important',
                          color: 'white',
                        },
                      },
                    }}
                  >
                    <Link
                      className={`${location.pathname === menuItem.slug ? 'active' : ''}`}
                      to={menuItem.slug}
                      onClick={() => {
                        setSidebarMenu(!SidebarMenu);
                      }}
                    >
                      {menuItem.title}
                    </Link>
                  </Typography>
                );
              }
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminHeader;
