import React, { useEffect, useState } from 'react';
import { colors } from '../../../theme/colors';
import { useDispatch } from 'react-redux';
import { Box, Typography, Tabs, Tab, MobileStepper, useTheme, IconButton } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { getUsedBandwidthAPI } from '../../../redux/usedBandwidthAPI';
import { useGetUser } from '../../../context/userProfile';
import { ThreeDots } from 'react-loader-spinner';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const TabPanel = ({ children, value, index }) => (
  <div role='tabpanel' hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

const colorArray = [
  {
    key: 1,
    colorCode: colors.themeBoxColor,
  },
  {
    key: 2,
    colorCode: colors.input.border,
  },
];

const DashBoardProgressChart = ({ standByPlans, isLoading }) => {
  const disPatch = useDispatch();
  const defaultColor = colors.input.border;
  const { logInUserDetails } = useGetUser();
  const [loading, setLoading] = useState(true);
  const [doughnuData, setDoughnuData] = useState([]);
  const [displayStandByPlan, setDisplayStandByPlan] = useState({})
  const [displayActivePlan, setDisplayActivePlan] = useState({})
  const [standByActiveStep, setStandByActiveStep] = useState(0)
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();


  const handleNextStandBy = () => {
    setStandByActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStandBy = () => {
    setStandByActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNextActive = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
  const handleBackActive = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  useEffect(() => {
    if (standByPlans && standByPlans.length > 0) {
      setDisplayStandByPlan(standByPlans[standByActiveStep])
    }
  }, [standByActiveStep, standByPlans])

  useEffect(() => {
    if (doughnuData && doughnuData.length > 0) {
      setDisplayActivePlan(doughnuData[activeStep])
    }
  }, [activeStep, doughnuData])

  useEffect(() => {
    const fetchData = async () => {
      const userPayload = {
        id: logInUserDetails?.p_customer_id,
      };
      const data = await disPatch(getUsedBandwidthAPI(userPayload));
      await setDoughnuData(data?.payload?.active_plan);
      await setLoading(false);
    };

    if (logInUserDetails?.p_customer_id) {
      const initialTimer = setTimeout(() => {
        fetchData();
      }, 1000);

      const intervalTimer = setInterval(() => {
        fetchData();
      }, 30000);

      return () => {
        clearTimeout(initialTimer);
        clearInterval(intervalTimer);
      };
    } else {
      setLoading(false);
    }
  }, [disPatch, logInUserDetails?.p_customer_id]);

  const chartData = {
    labels: [],
    datasets: [
      {
        data: [
          doughnuData?.length > 0 && doughnuData?.[activeStep]?.gb_used_percentage
            ? doughnuData?.[activeStep]?.gb_used_percentage
            : '100',
          doughnuData?.length > 0 && doughnuData?.[activeStep]?.gb_remaining_percentage
            ? doughnuData?.[activeStep]?.gb_remaining_percentage
            : '0',
        ],
        backgroundColor:
          doughnuData?.length > 0 ? colorArray?.map((data) => data?.colorCode) : [defaultColor],
        radius: 120,
        cutout: 80,
      },
    ],
  };
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ padding: '24px' }}>
      {loading ? (
        <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
          <ThreeDots
            visible={true}
            height='80'
            width='80'
            color={colors.theme}
            radius='9'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClass=''
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '30px',
          }}
        >
          <Box
            position='relative'
            display='inline-flex'
            sx={{
              maxWidth: '290px',
              height: '250px',
              width: '100%',
              '& .MuiCircularProgress-root ': {
                maxWidth: { xl: '290px', sm: '210px', xs: '150px' },
                width: '100% !important',
                height: 'auto !important',
                display: 'flex',
                justifyContent: 'center',
                '& svg': {
                  width: '100%',
                },
              },
            }}
          >
            <Doughnut data={chartData} options={chartOptions} loading={loading} />
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: { xl: '48px', lg: '35px', md: '30px', xs: '25px' },
                fontWeight: 600,
              }}
            >
              {doughnuData && doughnuData?.length > 0 && doughnuData?.[activeStep]?.gb_used_percentage
                ? doughnuData?.[activeStep]?.gb_used_percentage?.replace('.00', '')
                : '0'}
              %
            </Typography>
          </Box>

          <Box
            sx={{
              minWidth: '233px',
              flexGrow: '1',
              '& div[role="tabpanel"] div': {
                padding: 0,
              },
            }}
          >
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                sx={{
                  backgroundColor: colors.basics.white,
                  padding: '4px',
                  borderRadius: '6px',
                  '& .MuiTabs-indicator ': {
                    display: 'none',
                  },
                  '& .MuiButtonBase-root': {
                    flexGrow: '1',
                    maxWidth: '50%',
                  },
                  '& .Mui-selected': {
                    backgroundColor: colors?.tabBackgroundColor,
                    color: colors?.basics?.white,
                    borderRadius: '4px',
                  },
                }}
              >
                <Tab
                  label='Active'
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: colors?.tabBackgroundColor,
                      color: colors?.basics?.white,
                      borderRadius: '4px',
                    },
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                />
                <Tab
                  label='Standby'
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: colors?.tabBackgroundColor,
                      color: colors?.basics?.white,
                      borderRadius: '4px',
                    },
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              {loading && <Box
                sx={{
                  textAlign: "center",
                  "& > div": { justifyContent: "center" },
                }}
              >
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color={colors.theme}
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </Box>}
              {
                !loading && doughnuData?.length === 0 ?
                  <Box sx={{
                    mt: "24px",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: "column"
                  }}>
                    <img
                      src={DataNotFound}
                      alt='data not found'
                      style={{ width: '80px', height: '80px' }}
                    />
                    <Typography>No active plans available.</Typography>
                  </Box>
                  :
                  <Box sx={{
                    "& .MuiMobileStepper-root": {
                      backgroundColor: "transparent !important"
                    }
                  }}>
                    <Box sx={{
                      mt: "24px",
                      paddingTop: "12px !important",
                      paddingBottom: "24px !important",
                      paddingX: "6px !important",
                      borderBottom: "1px solid #D9D9D9"
                    }}>
                      <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '18px', fontWeight: 600, color: colors.neutral.dark }}>
                            Product:
                          </Typography>
                          <Typography sx={{ fontSize: '16px', fontWeight: 400, color: colors.basics.normal }}>
                            {displayActivePlan?.Product}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                          <Typography sx={{ fontSize: '18px', fontWeight: 600, color: colors.neutral.dark }}>
                            Bandwidth:
                          </Typography>
                          <Typography sx={{ fontSize: '16px', fontWeight: 400, color: colors.basics.normal }}>
                            {displayActivePlan.Bandwidth} GB
                          </Typography>
                        </Box>
                      </>
                      <Box sx={{ display: 'flex', mt: '28px', justifyContent: 'flex-start' }}>
                        <Typography
                          sx={{
                            height: '24px',
                            width: '24px',
                            background: colors.themeBoxColor,
                            borderRadius: '2px',
                          }}
                        ></Typography>
                        <Typography sx={{ fontSize: '16px', ml: 2, fontWeight: 400, color: colors.basics.normal }}>
                          {displayActivePlan.Used?.toFixed(3)} GB used
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-start' }}>
                        <Typography
                          sx={{
                            height: '24px',
                            width: '24px',
                            background: colors.input.border,
                            borderRadius: '2px',
                          }}
                        ></Typography>
                        <Typography sx={{ fontSize: '16px', ml: 2, fontWeight: 400, color: colors.basics.normal }}>
                          {displayActivePlan?.Remaining?.toFixed(2)} GB Remaining
                        </Typography>
                      </Box>
                    </Box>
                    <MobileStepper
                      variant='text'
                      steps={doughnuData?.length}
                      position="static"
                      activeStep={activeStep}
                      nextButton={
                        <IconButton
                          sx={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            border: "1px solid"
                          }}
                          onClick={handleNextActive}
                          disabled={activeStep === doughnuData?.length - 1}
                        >
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </IconButton>
                      }
                      backButton={
                        <IconButton
                          sx={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            border: "1px solid"
                          }}
                          onClick={handleBackActive} disabled={activeStep === 0}>
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                        </IconButton>
                      }
                    />
                  </Box>
              }
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box sx={{
                mt: '24px', '& > :not(:last-child)': {
                  borderBottom: "1px solid #666"
                },
              }}
              >
                {
                  isLoading ? <Box
                    sx={{
                      textAlign: "center",
                      "& > div": { justifyContent: "center" },
                    }}
                  >
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color={colors.theme}
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </Box>
                    :
                    !isLoading && standByPlans?.length === 0 ? (
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: "column"
                      }}>
                        <img
                          src={DataNotFound}
                          alt='data not found'
                          style={{ width: '80px', height: '80px' }}
                        />
                        <Typography>No stand by plans available.</Typography>
                      </Box>
                    ) : (
                      <Box sx={{
                        "& .MuiMobileStepper-root": {
                          backgroundColor: "transparent !important"
                        }
                      }}>
                        <Box sx={{
                          paddingTop: "12px !important",
                          paddingBottom: "24px !important",
                          paddingX: "6px !important",
                          borderBottom: "1px solid #D9D9D9"
                        }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ fontSize: '18px', fontWeight: 600, color: colors.neutral.dark }}>
                              Product:
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: colors.basics.normal }}>
                              {displayStandByPlan.Product}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Typography sx={{ fontSize: '18px', fontWeight: 600, color: colors.neutral.dark }}>
                              Plan:
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: colors.basics.normal }}>
                              {displayStandByPlan.PlanName}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Typography
                              sx={{ fontSize: '18px', fontWeight: 600, color: colors.neutral.dark }}
                            >Bandwidth:</Typography>
                            <Typography sx={{ fontSize: '16px', ml: 2, fontWeight: 400, color: colors.basics.normal }}>
                              {displayStandByPlan.Remaining} GB
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Typography
                              sx={{ fontSize: '18px', fontWeight: 600, color: colors.neutral.dark }}
                            >Unit Price:</Typography>
                            <Typography sx={{ fontSize: '16px', ml: 2, fontWeight: 400, color: colors.basics.normal }}>
                              {displayStandByPlan.UnitPrice} $
                            </Typography>
                          </Box>
                        </Box>
                        <MobileStepper
                          variant='text'
                          steps={standByPlans?.length}
                          position="static"
                          activeStep={standByActiveStep}
                          nextButton={
                            <IconButton
                              sx={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                                border: "1px solid"
                              }}
                              onClick={handleNextStandBy}
                              disabled={standByActiveStep === standByPlans?.length - 1}
                            >
                              {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                              ) : (
                                <KeyboardArrowRight />
                              )}
                            </IconButton>
                          }
                          backButton={
                            <IconButton
                              sx={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                                border: "1px solid"
                              }}
                              onClick={handleBackStandBy} disabled={standByActiveStep === 0}>
                              {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                              ) : (
                                <KeyboardArrowLeft />
                              )}
                            </IconButton>
                          }
                        />
                      </Box>
                    )}
              </Box>
            </TabPanel>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashBoardProgressChart;
