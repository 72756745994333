import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { colors } from '../../../theme/colors';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { ReactComponent as SortNone } from '../../../assets/images/sort-none.svg';
import { ReactComponent as SortTop } from '../../../assets/images/sort-top.svg';
import { ReactComponent as SortBottom } from '../../../assets/images/sort-bottom.svg';
import { ReactComponent as Edit } from '../../../assets/images/Edit.svg';
import { ThreeDots } from 'react-loader-spinner';
import Pagination from '../../../components/common/Pagination';
import AddPlanModal from './addPlan';

const AdminPlanTable = ({
  totalCount,
  rowsPerPage,
  tableRef,
  tableHeading,
  sortData,
  sortConfig,
  CustomerDetailsData,
  isLoading,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  getPlanData,
}) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [editPlanModal, setEditPlanModal] = useState(false);
  const [editPlanData, setEditPlanData] = useState({});

  const handlePointerDown = (event) => {
    setIsMouseDown(true);
    setStartX(event.clientX || (event.touches && event.touches[0]?.clientX));
    document.body.style.cursor = 'grabbing';
  };

  const handlePointerMove = (event) => {
    if (isMouseDown && tableRef.current) {
      const scrollX = startX - (event.clientX || (event.touches && event.touches[0]?.clientX));
      tableRef.current.scrollLeft += scrollX;
      setStartX(event.clientX || (event.touches && event.touches[0]?.clientX));
    }
  };

  const handlePointerUp = () => {
    setIsMouseDown(false);
    document.body.style.cursor = 'default';
  };

  const editPlan = async (id) => {
    const editData = CustomerDetailsData.find((row) => row?.p_plan_id === id);
    setEditPlanData(editData);
  };

  return (
    <Box id='printSection' className='print-section'>
      <TableContainer
        component={Paper}
        sx={{
          width: '100% !important',
          boxShadow: 'none',
          borderRadius: '12px',
          cursor: 'pointer',
          overflowX: 'auto',

          touchAction: 'none',
        }}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onTouchStart={handlePointerDown}
        onTouchMove={handlePointerMove}
        onTouchEnd={handlePointerUp}
        ref={tableRef}
      >
        <Table
          aria-label='simple table'
          sx={{
            width: '100%',

            '& th, td': {
              padding: '22px 22px',
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                ' & th': {
                  fontWeight: '500',
                  fontSize: '18px',
                  whiteSpace: 'nowrap',
                  color: colors.neutral.dark,
                  position: 'relative',
                  cursor: 'pointer',
                  '& .sort-arrow': {
                    position: 'relative',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                },
              }}
            >
              {tableHeading.map((item) => {
                return (
                  <>
                    <TableCell onClick={() => sortData(item.sortName)}>
                      <div className='sort-icon '>
                        <span>{item.name}</span>
                        {sortConfig.key === item.sortName ? (
                          sortConfig.direction === 'asc' ? (
                            item.name === 'Action' ? (
                              ' '
                            ) : (
                              <span>
                                <SortTop />
                              </span>
                            )
                          ) : (
                            <span>
                              <SortBottom />
                            </span>
                          )
                        ) : item.name === 'Action' ? null : (
                          <span>
                            <SortNone />
                          </span>
                        )}
                      </div>
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow sx={{ textAlign: 'center' }}>
                <TableCell colSpan={10}>
                  <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                    <ThreeDots
                      visible={true}
                      height='80'
                      width='80'
                      color={colors.theme}
                      radius='9'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClass=''
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {CustomerDetailsData?.length > 0 ? (
                CustomerDetailsData?.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                        '& td': {
                          padding: '12px 20px 12px 20',
                          whiteSpace: 'nowrap',
                        },
                        '& button': {
                          minWidth: 'unset',
                          padding: ' 8px',
                          '&:hover svg path': {
                            fill: '#fff',
                          },
                        },
                      }}
                    >
                      <TableCell className='recent-table-tr'>{item?.p_planCode}</TableCell>
                      <TableCell>
                        <Typography className='recent-table'>{item?.p_name}</Typography>
                      </TableCell>
                      <TableCell className='recent-table-tr'>{item?.productTypeName}</TableCell>
                      <TableCell className='recent-table-tr'>
                        {item?.p_chargesType === 1
                          ? 'Bandwidth'
                          : item?.p_chargesType === 2
                          ? 'Requests'
                          : item?.p_chargesType === 3
                          ? 'OneTime'
                          : ''}
                      </TableCell>
                      <TableCell className='recent-table-tr'>{item.p_volume} GB</TableCell>
                      <TableCell className='recent-table-tr'>${item.p_unitPrice}</TableCell>
                      <TableCell className='recent-table-tr'>{item.p_duration}</TableCell>
                      <TableCell className='recent-table-tr'>${item.p_price}</TableCell>

                      <TableCell>
                        <Button
                          sx={{
                            width: '48px',
                            background: '#ffffff',
                            height: '48px',
                            fontSize: '20px',
                            fontWeight: 700,
                            border: '1px solid #FD337E',
                            textTransform: 'capitalize',
                            borderRadius: '6px',
                            boxShadow: '0 0 0 0 !important',
                            color: '#FD337E ',
                            '&:hover': {
                              backgroundColor: '#FD337E',
                            },
                          }}
                          onClick={() => {
                            editPlan(item?.p_plan_id);
                            setEditPlanModal(true);
                          }}
                        >
                          <Edit />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow sx={{ width: '100%', height: '100%' }}>
                  <TableCell colSpan={10} sx={{ textAlign: 'center', border: '0' }}>
                    <img src={DataNotFound} alt='data not found' style={{ width: '80px', height: '80px' }} />
                    <Typography>Data not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        />
      </Box>
      <AddPlanModal
        setIsAddModal={setEditPlanModal}
        addIsModal={editPlanModal}
        editPlanData={editPlanData}
        getPlanData={getPlanData}
      />
    </Box>
  );
};

export default AdminPlanTable;
