import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api/services';

const changePasswordAPI = createAsyncThunk('changepassword', async (data) => {
  try {
    const response = await API.post(`/authentication/change-password`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

export default changePasswordAPI;
