import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const userSignInAPI = createAsyncThunk(
  'userSignIn',
  async (data) => {
    try {
      const response = await API.post(`/authentication/log-in`, data);
      return response;
    } catch (error) {
      return error;
    }
  },
  {
    rejectWithValue: (error, thunkAPI) => {
      return error.response?.data;
    },
  }
);

const initialState = {
  userSignIn: {},
  signInIsLoading: false,
  error: null,
};

const userSignInSlice = createSlice({
  name: 'userSignIn',
  initialState,
  reducers: {
    setUserSignIn: (state, { payload }) => {
      state.userSignIn = payload;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(userSignInAPI.fulfilled, (state, action) => {
        state.userSignIn = action.payload;
        state.error = null;
        state.signInIsLoading = false;
      })
      .addCase(userSignInAPI.rejected, (state, action) => {
        state.error = action;
        state.signInIsLoading = false;
      })
      .addCase(userSignInAPI.pending, (state, action) => {
        state.error = action;
        state.signInIsLoading = true;
      });
  },
});

export const { setUserSignIn, setError } = userSignInSlice.actions;
export default userSignInSlice.reducer;
