import React, { useState } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Images } from '../../assets/images';
import { useLocation } from 'react-router-dom';

const CustomDropdown = ({
  icon = false,
  iconType = '',
  iconColor = '',
  options,
  value,
  onChange,
  placeholder,
  width = '100%',
  height = '100%',
  customSx,
}) => {
  const theme = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const isSignupPage = location.pathname === '/signup';

  const getIcon = () => {
    switch (iconType) {
      case 'country':
        return iconColor === 'dark' ? Images.Dark_Country_Icon : Images.Country_Icon;
      default:
        return null;
    }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    onChange(selectedValue);
  };
  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(true);
  };

  const customSelectStyle = {
    position: 'relative',
    width: width ? width : '100%',
    height: height ? height : '100%',
    borderRadius: '8px',
    backgroundColor: 'transparent !important',
    display: 'flex',
    paddingLeft: '30px',
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      right: iconColor ? '12px' : '20px',
      transform: 'translateY(-50%) rotate(0deg)',
      border: '0',
      width: iconColor ? '11px' : '17px',
      height: iconColor ? '9px' : '10px',
      transition: 'all .5s ease',
      backgroundImage: `url('${Images.DropdownDownArrow_Icon}')`,
      fill: iconColor ? iconColor : 'inherit',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      pointerEvents: 'none',
    },
    "& [aria-expanded='true'] ~ :after": {
      transform: 'translateY(-50%) rotate(180deg)',
    },
    '& .MuiSelect-select em': {
      color: `${theme.palette.neutral.light} !important`,
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: 400,
      paddingLeft: '14px',
    },
    '&.language-select': {
      padding: '4px 14px !important',
      color: '#686465 !important',
    },
    '& .MuiSelect-select[aria-expanded="true"] ~ svg': {
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'}) !important`,
    },
    '& .MuiSelect-icon': {
      display: 'block',
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'}) !important`,
      transition: 'all .5s ease',
      cursor: 'pointer',
    },

    ...customSx,
  };

  return (
    <FormControl fullWidth disabled>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: iconColor && `${theme.palette.neutral.background} !important`,
          cursor: 'pointer',
          borderRadius: '6px',
        }}
        disabled={!isSignupPage}
      >
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={value}
          onChange={handleSelectChange}
          displayEmpty
          sx={customSelectStyle}
          disabled={!isSignupPage}
          open={isDropdownOpen}
          onClose={() => setIsDropdownOpen(false)}
          onOpen={handleDropdownToggle}
          IconComponent={(props) => (
            <img
              src={getIcon()}
              alt='Icon'
              className='dropdown-icon'
              onClick={() => {
                if (!isSignupPage) {
                } else {
                  handleDropdownToggle();
                }
              }}
              disabled={!isSignupPage}
            />
          )}
        >
          {placeholder && (
            <MenuItem
              value=''
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.neutral.light,
              }}
              onClick={(event) => handleDropdownToggle(event)}
            >
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options?.map((option, index) => (
            <MenuItem key={index} value={option?.Code}>
              {option.Name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </FormControl>
  );
};

export default CustomDropdown;
