import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import styled from '@emotion/styled';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { formatDate } from '../../../components/common/dateFormat';
import { colors } from '../../../theme/colors';
import { getIdWiseCustomerPlanDetils } from '../../../redux/idWiseCustomerPlanGetAPI';
import { ThreeDots } from 'react-loader-spinner';

const CustomerPlanDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [customerDetailsData, setCustomerDetailsData] = useState([]);

  const [loading, setIsLoading] = useState(false);
  const FormLays = styled(Box)(({ theme }) => ({
    borderRadius: '16px',
    backgroundColor: colors.background.formBg,
    height: '100%',
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: '#242323',
      textWrap: 'nowrap',
    },
    [`&.${tableCellClasses.body}`]: {
      color: '#686465',
      fontWeight: 400,
    },
  }));

  const TableRowStyle = {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '& td': {
      padding: '22px 20px',
      whiteSpace: 'nowrap',
    },
    '& a': {
      border: `1px solid ${colors.theme}`,
      padding: '7px',
      display: 'inline-flex',
      verticalAlign: 'middle',
      width: 'fit-content',
      alignItems: 'center',
      minWidth: 'auto',
      borderRadius: '4px',
      transition: 'all .5s ease',
      '& svg path': {
        transition: 'all .5s ease',
      },
      '&:hover': {
        backgroundColor: colors.theme,
      },
      '&:hover svg path': {
        fill: '#fff',
        stroke: colors.theme,
      },
    },
  };

  const columName = [
    {
      id: 1,
      name: 'Plan name',
    },
    {
      id: 2,
      name: 'Type',
    },
    {
      id: 3,
      name: 'Start Date',
    },
    {
      id: 4,
      name: 'End Date',
    },
    {
      id: 5,
      name: 'Active',
    },
    {
      id: 6,
      name: 'Usage%',
    },
    {
      id: 7,
      name: 'Added by',
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    const customerPlanData = async () => {
      const userWiseData = {
        userId: id,
        page: 0,
        limit: 10,
        search: '',
      };
      await setCustomerDetailsData([]);
      const userData = await dispatch(getIdWiseCustomerPlanDetils(userWiseData));
      if (userData?.payload?.status === true) {
        setCustomerDetailsData(userData?.payload?.data?.customerUsage);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    customerPlanData();
  }, [dispatch, id]);

  return (
    <Grid item lg='6' xs='12'>
      <FormLays>
        <Box sx={{ padding: { sm: '24px', xs: '20px' } }}>
          <Typography variant='h3' sx={{ color: colors.basics.normal, paddingBottom: '8px' }}>
            Customer Plans
          </Typography>
          <TableContainer
            component={Paper}
            sx={{
              width: '100% !important',
              height: '300px',
              boxShadow: 'none',
              borderRadius: '12px',
              overflow: 'hidden',
              '&:hover': {
                overflow: 'auto',
              },
            }}
          >
            <Table
              aria-label='simple table'
              sx={{
                width: '100%',
                '& th, td': {
                  padding: '20px 11px',
                },
                position: 'relative',
              }}
            >
              <TableHead
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#ffffff',
                  zIndex: 1,
                }}
              >
                <TableRow>
                  {columName?.map((item) => (
                    <StyledTableCell sx={{ fontSize: '18px' }} key={item?.id}>
                      {item?.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <TableBody>
                  <TableRow sx={{ textAlign: 'center' }}>
                    <TableCell colSpan={16}>
                      <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                        <ThreeDots
                          visible={true}
                          height='80'
                          width='80'
                          color={colors.theme}
                          radius='9'
                          ariaLabel='three-dots-loading'
                          wrapperStyle={{}}
                          wrapperClass=''
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {customerDetailsData?.length > 0 ? (
                    customerDetailsData?.map((item, index) => (
                      <TableRow sx={TableRowStyle} key={index}>
                        <StyledTableCell sx={{ fontSize: '16px' }}>{item?.planName}</StyledTableCell>
                        <StyledTableCell sx={{ fontSize: '16px' }}> {item?.productName}</StyledTableCell>
                        <StyledTableCell sx={{ fontSize: '16px' }}>
                          {formatDate(item?.planStart)}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontSize: '16px' }}>
                          {formatDate(item?.planEnd)}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontSize: '16px' }}> {item?.planStatus}</StyledTableCell>

                        <StyledTableCell sx={{ fontSize: '16px' }}>
                          {' '}
                          {isNaN(parseFloat(item?.planUsage))
                            ? ''
                            : Math.round(parseFloat(item?.planUsage)) + '%'}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontSize: '16px' }}> {item?.addedBy}</StyledTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow sx={{ width: '100%', height: '100%' }}>
                      <TableCell colSpan={16} sx={{ textAlign: 'center', border: '0' }}>
                        <img
                          src={DataNotFound}
                          alt='data not found'
                          style={{ width: '80px', height: '80px' }}
                        />
                        <Typography>Data not found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </FormLays>
    </Grid>
  );
};

export default CustomerPlanDetails;
