import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../theme/colors';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { ThreeDots } from 'react-loader-spinner';

function DashBoardLineChart({ chartData, isLoading }) {
  const options = {
    chart: {
      type: 'spline',
    },
    title: {
      text: null,
    },
    xAxis: {
      title: false,
      categories: chartData?.chartData?.map((data) => data?.daysAgo),
      color: colors.basics.normal,
      labels: {
        style: {
          color: colors.basics.normal,
          fontSize: '14px ',
        },
      },
    },
    yAxis: {
      title: false,
      labels: {
        formatter: function () {
          return this.value + ' GB';
        },
        style: {
          color: colors.basics.normal,
          fontSize: '14px ',
        },
      },
    },
    series: [
      {
        name: 'Total Usage',
        data: chartData?.chartData?.map((data) => data?.totalUsage),
        color: colors.theme,
      },
      // {
      //   name: 'Series 2',
      //   data: chartData?.chartData?.map((data) => data?.successfullRequests),
      //   color: colors.theme,
      // },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{ width: '100%', '& svg .highcharts-background': { fill: 'transparent' } }}>
      {isLoading ? (
        <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color={colors.theme}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Box>
      ) : Object.keys(chartData).length > 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <Box sx={{ textAlign: 'center', border: '0', marginTop: '50px' }}>
          <img src={DataNotFound} alt="Data not found" style={{ width: '80px', height: '80px' }} />
          <Typography sx={{ textAlign: 'center' }}>Data not found</Typography>
        </Box>
      )}
    </Box>
  );
}

export default DashBoardLineChart;
