export const colors = {
  basics: {
    white: '#ffffff',
    black: '#000000',
    danger: '#CC0202',
    green: '#4AC336',
    normal: '#686465',
    transparent: 'transparent',
  },
  theme: '#FD337E',
  secondary: '#D40955',
  accent2: '#DD7396',
  accent3: '#FFF2F6',
  themeBg: '#FFE5ED',
  neutral: {
    dark: '#242323',
    light: '#8E8A8B',
    lightest: '#F5F5F5',
  },
  background: {
    formBg: '#F5F7FA',
  },
  input: {
    placeholder: '#8E8A8B',
    border: '#CDCBCC',
    disabled: '#B2ADAE',
  },
  linkColor: '#2974EF',
  buttoncolor: '#4AC336',
  tabBackgroundColor: '#364866',
  themeBoxColor: '#FD7EAE',
  useractivitylabelcolor: '#7F7F84',
  detailcolor: '#2D2D2D',
};
