import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const SignUpOTPvarificationAPI = createAsyncThunk('OTPverification', async (data) => {
  try {
    const response = await API.post(`/sms/initiate-verification`, data);
    return response;
  } catch (error) {
    return error;
  }
});
