import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const updateContactNumberAPI = createAsyncThunk('users-customer-editPhoneNumber', async (data) => {
  try {
    const response = await API.put(`users/customer/editPhoneNumber/${data?.id}`, {
      phoneNumber: data?.phoneNumber,
      countryCode: data?.countryCode,
    });
    return response?.data;
  } catch (error) {
    return error;
  }
});
const initialState = {
  updateContactNumber: {},
};

const updateContactSlice = createSlice({
  name: 'users-customer-editPhoneNumber',
  initialState,
  reducers: {
    setUpdateContactInfo: (state, { payload }) => {
      state.updateContactNumber = payload;
    },
  },
});

export const { setUpdateContactInfo } = updateContactSlice.actions;
export default updateContactSlice.reducer;
