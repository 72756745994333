import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { colors } from '../../../theme/colors';
import AddCustomerModal from './addCustomerModal';
import { getCustomerDetailsAPI } from '../../../redux/customerDetailsAPI';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { ThreeDots } from 'react-loader-spinner';
import CustomerDetailsRow from './customerDetailsRow';
import Pagination from '../../../components/common/Pagination';

const CustomerDetails = () => {
  const totalCountRef = useRef(0);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState();
  const [searchCustomer, setSearchCustomer] = useState('');
  const [formErros, setFormErrors] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);

  useEffect(() => {
    getCustomerDetails();
  }, [dispatch, page, rowsPerPage, searchCustomer]);

  useEffect(() => {
    totalCountRef.current = totalCount;
  }, [totalCount]);

  const getCustomerDetails = async () => {
    setIsLoading(true);
    const customerObject = {
      page: page,
      limit: rowsPerPage,
      search: searchCustomer,
    };
    setCustomerData([]);
    const payload = await dispatch(getCustomerDetailsAPI(customerObject));
    if (payload?.payload?.status === true) {
      const totalCount = payload?.payload?.data?.paging.totalItems || 0;
      setCustomerData(payload?.payload?.data?.customers);
      setRowsPerPage(totalCount > 0 ? payload.payload.data.paging.itemsPerPage : 0);
      setTotalCount(totalCount);

      if (customerObject?.search !== '' && totalCount !== totalCountRef.current) {
        setPage(0);
      }

      setIsLoading(false);
    } else {
      console.error(payload?.payload?.response?.message);
      setRowsPerPage(10);
      setTotalCount(0);
      setPage(0);
      setCustomerData([]);
      setIsLoading(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const columName = [
    {
      key: 'customer',
      value: 'Customer',
    },
    {
      key: 'parent',
      value: 'Parent',
    },
    {
      key: 'source',
      value: 'Source',
    },
    {
      key: 'registerDate',
      value: 'Register Date',
    },
    {
      key: 'activePlan',
      value: 'Active Plan',
    },
    {
      key: 'filledKYC',
      value: 'Filled KYC',
    },
    {
      key: 'actions',
      value: 'Actions',
    },
  ];

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '24px',
          }}
        >
          <Typography variant='h2' sx={{ color: colors.neutral.dark }}>
            Customer Details
          </Typography>
          <Button
            type='button'
            variant='filled'
            onClick={() => {
              setAddCustomerModal(true);
              setFormErrors({});
            }}
            sx={{
              fontSize: { lg: '20px', xs: '15px', md: '20px', sm: '20px' },
              fontWeight: '700',
              textTransform: 'capitalize',
              width: { lg: '213px', xs: '139px', md: '213px', sm: '213px' },
              height: '56px',
              borderRadius: { md: '8px', xs: '6px' },
            }}
          >
            Add Customer
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: colors.background.formBg,
            borderRadius: '16px',
            padding: '24px',
            minHeight: 'calc(100vh - 260px)',
          }}
        >
          <Typography variant='h4' sx={{ paddingBottom: '8px', color: colors.neutral.dark }}>
            Select Customer:
          </Typography>
          <TextField
            type='search'
            placeholder='Search'
            value={searchCustomer}
            onChange={(e) => setSearchCustomer(e.target.value.trimLeft())}
            sx={{
              maxWidth: '480px',
              width: '100%',
              mb: '14px',
              '& input': {
                padding: '10px 14px',
                height: 'auto',
                fontSize: '18px',
                lineHeight: '1.5',
              },
            }}
          />
          <Box>
            <TableContainer
              component={Paper}
              sx={{ width: '100% !important', boxShadow: 'none', borderRadius: '12px' }}
            >
              <Table
                aria-label='simple table'
                sx={{
                  width: '100%',
                  '& th, td': {
                    padding: '22px 22px',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {columName?.map((item, index) => (
                      <TableCell
                        sx={{
                          fontWeight: '500',
                          fontSize: '18px',
                          whiteSpace: 'nowrap',
                          color: colors.neutral.dark,
                        }}
                        key={index}
                      >
                        {item?.value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow sx={{ textAlign: 'center' }}>
                      <TableCell colSpan={7}>
                        <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                          <ThreeDots
                            visible={true}
                            height='80'
                            width='80'
                            color={colors.theme}
                            radius='9'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{}}
                            wrapperClass=''
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {customerData?.length > 0 ? (
                      customerData?.map((row) => <CustomerDetailsRow item={row} />)
                    ) : (
                      <TableRow sx={{ width: '100%', height: '100%' }}>
                        <TableCell colSpan={6} sx={{ textAlign: 'center', border: '0' }}>
                          <img
                            src={DataNotFound}
                            alt='data not found'
                            style={{ width: '80px', height: '80px' }}
                          />
                          <Typography>Data not found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Box>
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                totalCount={totalCount}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {addCustomerModal === true && (
        <AddCustomerModal
          setAddCustomerModal={setAddCustomerModal}
          addCustomerModal={addCustomerModal}
          formErros={formErros}
          setFormErrors={setFormErrors}
          getCustomerDetails={getCustomerDetails}
        />
      )}
    </>
  );
};

export default CustomerDetails;
