import { createSlice } from '@reduxjs/toolkit';

export const inputSearchValues = {
  inputValue: {},
};

const inputSearchValue = createSlice({
  name: 'inputValue',
  initialState: inputSearchValues,
  reducers: {
    setInputsearchAction: (state, { payload }) => {
      state.inputValue = payload;
    },
  },
});

export const { setInputsearchAction } = inputSearchValue.actions;

export default inputSearchValue.reducer;
