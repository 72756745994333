import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getUserByID = createAsyncThunk('userById', async (data) => {
  try {
    const response = await API.get(`/users/single/user/${data.userId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  userById: {},
};

const singleUserGetSlice = createSlice({
  name: 'userById',
  initialState,
  reducers: {
    setUserByIdDetails: (state, { payload }) => {
      state.userById = payload;
    },
  },
});

export const { setUserByIdDetails } = singleUserGetSlice.actions;
export default singleUserGetSlice.reducer;
