import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid } from '@mui/material';
import { colors } from '../../../theme/colors';
import EditForm from './editForm';
import CustomerPlanDetails from './userPlanDetails';
import UserPaymentDetails from './userPaymentDetails';

const CustomerDetailsEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editConfirmModal, setEditConfirmModal] = useState(false);

  return (
    <>
      <form action=''>
        <Box className='edit-header'>
          <Typography variant='h2' sx={{ color: colors.neutral.dark }}>
            Edit Customer Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              '& > button ': {
                fontSize: { xl: '20px' },
                fontWeight: '700',
              },
            }}
          >
            <Button
              variant='text'
              color='primary'
              type='button'
              onClick={() => {
                navigate('/admin/customer-details');
              }}
              sx={{
                padding: '0',
                color: colors.basics.normal,
                backgroundColor: 'transparent !important',
                textTransform: 'capitalize',
              }}
            >
              Cancel
            </Button>
            <Button
              variant='filled'
              color='primary'
              type='button'
              sx={{
                minWidth: { lg: '160px', md: '140px', sm: '100px', xs: '80px' },
                borderRadius: { md: '8px', xs: '6px' },
                ml: { lg: '20px', sx: '15px', xs: '10px' },
                textTransform: 'capitalize',
              }}
              onClick={() => {
                setEditConfirmModal(true);
              }}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Grid container spacing='24px'>
          <EditForm id={id} setEditConfirmModal={setEditConfirmModal} editConfirmModal={editConfirmModal} />
          <CustomerPlanDetails />
          <UserPaymentDetails />
        </Grid>
      </form>
    </>
  );
};

export default CustomerDetailsEdit;
