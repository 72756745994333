import { Box, Typography } from '@mui/material';
import { Images } from '../assets/images';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/images/logo.svg';

export const SideMenuListItem = [
  {
    title: 'Dashboard',
    slug: '/',
  },
  {
    title: 'Usage',
    slug: '/usage',
  },
  {
    title: 'Buy a Plan',
    slug: '/buyplan',
  },
  {
    title: 'Billing',
    slug: '/billingInformation',
  },
];

const Sidebar = ({ isMobile = false, isClick, onClick }) => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.accent4,
          width: { xl: '315px', xs: '260px' },
          height: '100vh',
          position: 'fixed',
          top: '0',
          left: { md: '0' },
          right: { xs: '0' },
          display: isMobile ? 'block' : { md: 'block', xs: 'none' },
          overflow: 'auto',
        }}
      >
        <Box
          onClick={onClick}
          sx={{
            display: { md: 'none', xs: 'flex' },
            position: 'absolute',
            top: '30px',
            right: { sm: '25px', xs: '30px' },
          }}
        >
          {isClick ? (
            <CloseIcon sx={{ height: '30px', width: '30px' }} />
          ) : (
            <img src={Images.SidebarIcon} alt='Profile' height='30px' width='30px' />
          )}
        </Box>
        <Box
          sx={{
            // height: '100%',
            padding: { md: '24px', xs: '0' },
          }}
        >
          {!isMobile && (
            <Box
              sx={{
                textAlign: 'center',
                paddingBottom: '58px',
                paddingTop: { md: '0', xs: '24px' },
                '& svg': {
                  width: {
                    xl: '155px',
                    lg: '180px',
                    sm: '150px',
                    xs: '120px',
                  },
                  height: 'auto',
                },
              }}
            >
              <Link to='/'>
                <Logo />
              </Link>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              height: '100%',
            }}
          >
            {SideMenuListItem.map((menuItem, index) => {
              return (
                <Typography
                  key={index}
                  variant='subtitle1'
                  sx={{
                    '& a': {
                      padding: { md: '10.5px 16px', xs: '10px' },
                      textDecoration: 'none',
                      display: 'block',
                      color: theme.palette.neutral.normal,
                      '&:not(.active):hover': {
                        color: theme.palette.accent1,
                        backgroundColor: theme.palette.accent3,
                      },
                      '&.active': {
                        backgroundColor: '#FD337E !important',
                        color: 'white',
                        borderRadius: '4px',
                      },
                    },
                    '&:active': {
                      backgroundColor: theme.palette.accent1,
                      '& a': {
                        color: theme.palette.neutral.white,
                      },
                    },
                  }}
                >
                  <Link
                    className={`${location.pathname === menuItem.slug ? 'active' : ''}`}
                    to={menuItem.slug}
                  >
                    {menuItem.title}
                  </Link>
                </Typography>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
