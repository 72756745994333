import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const updateAccountInfo = createAsyncThunk('billing-address', async (data) => {
  try {
    const response = await API.patch(
      `billing-address/${data?.idObj?.customerId}`,
      data?.updateObj?.customerObject
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  updateAccountInfo: {},
};

const updateAccountInfoSlice = createSlice({
  name: 'billing-address',
  initialState,
  reducers: {
    setUpdateAccountInfo: (state, { payload }) => {
      state.updateAccountInfo = payload;
    },
  },
});

export const { setUpdateAccountInfo } = updateAccountInfoSlice.actions;
export default updateAccountInfoSlice.reducer;
