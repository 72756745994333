import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import AdminDashboard from "../../pages/admin/adminDashboard";
import CustomerDetails from "../../pages/admin/customerDetails";
import CustomerDetailsEdit from "../../pages/admin/customerDetailsEdit";
import Plans from "../../pages/admin/plans";
import CustomerPlans from "../../pages/admin/customerPlans";
import AccountDeactivate from "../../pages/user/accountDeactivate";
import AccountInformation from "../../pages/user/accountInformation";
import BillingInformation from "../../pages/user/billingInformation";
import ChangePassword from "../../pages/user/changePassword";
import ForgetResetPassword from "../../pages/user/forgetResetpassword";
import Home from "../../pages/user/home";
import Login from "../../pages/user/login";
import LogoutModal from "../../pages/user/logoutModal";
import OTPVerification from "../../pages/user/otpVerification";
import ProxyPlans from "../../pages/user/proxyPlans";
import SetNewPassword from "../../pages/user/setnewPassword";
import SignUp from "../../pages/user/signup";
import Usage from "../../pages/user/usage";
import AdminProtectedRoute from "./adminProtectedRoute";
import AdminPublicRoutes from "./adminPublicRoute";
import ProtectedRoute from "./protectedRoute";
import PublicRoutes from "./publicRoutes";
import Payment from "../../pages/admin/payment";
import UserActivity from "../../pages/admin/userActivity";
import ThankYouPage from "../../pages/user/thankyoupage";
import Subscription from "../../pages/admin/subscription";
import ReviewData from "../../pages/admin/userActivity/reviewData";
import AdminLogin from "../../pages/admin/adminSignIN";
import AdminProfile from "../../pages/admin/adminProfile";
import CustomerContactNumber from "../../pages/user/customerContact";
import Page404 from "./page404";
import { useEffect } from "react";
import { getCookie } from "../../utils/getCookie";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { setSignAdmin, setSignUpUserdata } from "../../redux/signUpApi";
import { setUserSignIn } from "../../redux/userSignInAPI";
import { setUserprofile } from "../../redux/userProfileAPI";
import EmailVerification from "../../pages/user/emailVerification";
import CustomerContactEmail from "../../pages/user/editEmail";
import AdminUsage from "../../pages/admin/usage";

export const Rounting = [
  {
    path: "/changepassword",
    component: ChangePassword,
    protected: true,
    title: "Change Password",
    description: "Change your account password",
    userType: "customer",
  },
  {
    path: "/usage",
    component: Usage,
    protected: true,
    title: "Usage",
    description: "Usage",
    userType: "customer",
  },
  {
    path: "/logout",
    component: LogoutModal,
    protected: true,
    title: "logout",
    description: "logout",
    userType: "customer",
  },
  {
    path: "/",
    component: Home,
    protected: true,
    title: "Home",
    description: "Home",
    userType: "customer",
  },
  {
    path: "/accountinformation",
    component: AccountInformation,
    protected: true,
    title: "Account Information",
    description: "AccountInformation",
    userType: "customer",
  },
  {
    path: "/billingInformation",
    component: BillingInformation,
    protected: true,
    title: "Billing Information",
    description: "BillingInformation",
    userType: "customer",
  },
  {
    path: "/buyplan",
    component: ProxyPlans,
    protected: true,
    title: "Buy Plan",
    description: "ProxyPlans",
    userType: "customer",
  },
  {
    path: "/login",
    component: Login,
    protected: false,
    title: "Login",
    description: "user login",
    userType: "customer",
  },
  {
    path: "/signup",
    component: SignUp,
    protected: false,
    title: "SignUp",
    description: "SignUp",
    userType: "customer",
  },
  {
    path: "/otpverification",
    component: OTPVerification,
    protected: false,
    title: "OTP Verification",
    description: "otpverification",
    userType: "customer",
  },
  {
    path: "/emailverification",
    component: EmailVerification,
    protected: false,
    title: "Email Verification",
    description: "emailVerification",
    userType: "customer",
  },
  {
    path: "/editemailverification",
    component: CustomerContactEmail,
    protected: false,
    title: "Customer Contact Email",
    description: "Customer Contact Email",
    userType: "customer",
  },
  {
    path: "/forgetpassword",
    component: ForgetResetPassword,
    protected: false,
    title: "Forget Password",
    description: "ForgetResetPassword",
    userType: "customer",
  },
  {
    path: "/accountdeactivate",
    component: AccountDeactivate,
    protected: false,
    title: "Account Deactivate",
    description: "AccountDeactivate",
    userType: "customer",
  },
  {
    path: "/verifycontact-number",
    component: CustomerContactNumber,
    protected: false,
    title: "Customer ContactNumber",
    description: "CustomerContactNumber",
    userType: "customer",
  },

  {
    path: "/thankyou-page",
    component: ThankYouPage,
    protected: false,
    title: "ThankYouPage",
    description: "ThankYouPage",
    userType: "customer",
  },
  {
    path: "/set-new-password",
    component: SetNewPassword,
    protected: false,
    title: "Set New Password",
    description: "SetNewPassword",
    userType: "customer",
  },
  {
    path: "/admin/customer-details",
    component: CustomerDetails,
    protected: true,
    title: "CustomerDetails",
    description: "CustomerDetails",
    userType: "admin",
  },
  {
    path: "/admin/subscription",
    component: Subscription,
    protected: true,
    title: "Subscription",
    description: "Subscription",
    userType: "admin",
  },
  {
    path: "/admin/login",
    component: AdminLogin,
    protected: false,
    title: "Admin Login",
    description: "AdminLogin",
    userType: "admin",
  },
  {
    path: "admin/buyplan",
    component: ProxyPlans,
    protected: true,
    title: "Buy Plan",
    description: "ProxyPlans",
    userType: "admin",
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
    protected: true,
    title: "Admin Dashboard",
    description: "AdminDashboard",
    userType: "admin",
  },
  {
    path: "/admin/profile",
    component: AdminProfile,
    protected: true,
    title: "Admin Profile",
    description: "AdminProfile",
    userType: "admin",
  },
  {
    path: "/admin/plans",
    component: Plans,
    protected: true,
    title: "Plans",
    description: "Plans",
    userType: "admin",
  },
  {
    path: "/admin/changepassword",
    component: ChangePassword,
    protected: true,
    title: "Change Password",
    description: "ChangePassword",
    userType: "admin",
  },
  {
    path: "/admin/user-activity/:id",
    component: ReviewData,
    protected: true,
    title: "ReviewData",
    description: "ReviewData",
    userType: "admin",
  },

  {
    path: "/admin/forgetpassword",
    component: ForgetResetPassword,
    protected: false,
    title: "Forget Password",
    description: "ForgetResetPassword",
    userType: "admin",
  },
  {
    path: "/admin/customer-details/customer-plan/:id",
    component: CustomerPlans,
    protected: true,
    title: "Customer Plans",
    description: "CustomerPlans",
    userType: "admin",
  },
  {
    path: "/admin/customer-details/edit/:id",
    component: CustomerDetailsEdit,
    protected: true,
    title: "Customer Details Edit",
    description: "CustomerDetailsEdit",
    userType: "admin",
  },
  {
    path: "/admin/payment",
    component: Payment,
    protected: true,
    title: "Payment",
    description: "Payment",
    userType: "admin",
  },
  {
    path: "/admin/user-activity",
    component: UserActivity,
    protected: true,
    title: "User Activity",
    description: "UserActivity",
    userType: "admin",
  },
  {
    path: "/admin/traffic-usage",
    component: AdminUsage,
    protected: true,
    title: "Usage",
    description: "Usage",
    userType: "admin",
  },
];
const generateRoutes = (config) => {
  const routes = config.map((route, index) => {
    const RouteComponent =
      route.userType === "admin"
        ? route.protected
          ? AdminProtectedRoute
          : AdminPublicRoutes
        : route.protected
        ? ProtectedRoute
        : PublicRoutes;
    return (
      <Route
        key={index}
        path={route.path}
        element={
          <>
            <Helmet>
              <title>{route.title}</title>
              <meta name="description" content={route.description} />
            </Helmet>
            <RouteComponent>
              <route.component />
            </RouteComponent>
          </>
        }
      />
    );
  });

  routes.push(<Route key="404" path="*" element={<Page404 />} />);
  return routes;
};

const Routing = () => {
  const settoken = getCookie("access_token_proxy");
  const admintoken = getCookie("admin_access_token_proxy");
  const dispatch = useDispatch();
  const [_, __, removeToken] = useCookies([
    "access_token_proxy",
    "admin_access_token_proxy",
  ]);
  // const [_, __, removeToken] = useCookies(["access_token_proxy"]);
  const currentPath = window.location.pathname;
  const adminPath = currentPath.match(/^\/admin\b/);

  useEffect(() => {
    const handleSession = async () => {
      if (!settoken) {
        // if user token is logging out
        
        await sessionStorage.removeItem("access_token_proxy_session");
        await localStorage.removeItem("persist:root");
        await dispatch(setSignUpUserdata({}));
        await dispatch(setUserSignIn({}));
        await dispatch(setUserprofile({}));
        await removeToken("access_token_proxy", { path: "/" });
      } else if (!admintoken) {
        // if admin token is logging out
        await dispatch(setSignAdmin({}));
        if (adminPath ? adminPath?.[0] : null) {
          window.history.pushState(null, "", "/admin/login");
        }
        await removeToken("admin_access_token_proxy", { path: "/" });
      }
    };

    handleSession();
  }, [settoken, adminPath, dispatch]);
  return <Routes>{generateRoutes(Rounting)}</Routes>;
};

export default Routing;
