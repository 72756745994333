import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/common/customButton';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';

const AccountDeactivate = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <AuthenticationWrapper maxWidth={'817px'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.accent4,
          padding: '48px',
          borderRadius: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Typography variant='h1' sx={{ color: theme.palette.neutral.dark }}>
            Oops! We’re Sorry..
          </Typography>
          <Typography variant='subtitle5' sx={{ color: theme.palette.neutral.normal, textAlign: 'center' }}>
            Your account is currently under review. It will be active in 24 hours.
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '24px',
            marginBottom: '40px',
            textAlign: 'center',
          }}
        >
          <Typography variant='subtitle4' sx={{ color: theme.palette.neutral.normal }}>
            We've detected suspicious activity in your account. We’ll get back to you soon. If you have any
            questions, please contact
            <Typography variant='subtitle4'>
              <Link to='mailto:prox-e.io@gmail.com' className='email-link-box'>
                support@prox-e.io
              </Link>
            </Typography>
          </Typography>
        </Box>

        <CustomButton onClick={() => navigate('/login')}>Go Back to Home</CustomButton>
      </Box>
    </AuthenticationWrapper>
  );
};

export default AccountDeactivate;
