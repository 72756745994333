import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Grid, Select, MenuItem, Button, TextField, Menu, styled } from '@mui/material';
import { useTheme } from '@emotion/react';
import Calendar from '../../../assets/images/Calendar.svg';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/dropdownDownArrow.svg';
import { colors } from '../../../theme/colors';
import companyLogo from '../../../assets/images/Logo.png';
import { ReactComponent as PrintIcon } from '../../../assets/images/Printer.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/Copy.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/Download.svg';
import PaymentTable from './paymentTable';
import { getPaymentListAPI } from '../../../redux/paymentListAPI';
import { formatDate } from '../../../components/common/dateFormat';

const Payment = () => {
  const theme = useTheme();
  const totalCountRef = useRef(0);
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1));
  const [endDate, setEndDate] = useState(new Date(currentYear, 11, 31));
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState();
  const [searchCustomer, setSearchCustomer] = useState('');
  const [dateFiterOption, setDateFilterOption] = useState('This Year');
  const [DownloadMenu, setDownloadMenu] = useState(null);
  const [paymentOptionData, setPaymentOptionData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paymentValue, setPaymentValue] = useState({
    owner: '',
    customer: '',
    productType: '',
    customersource: '',
  });
  const open = Boolean(DownloadMenu);
  const isDatePickerDisabled = dateFiterOption !== 'Custom Date';

  const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
    color: '#686465',
    borderRadius: '6px',
    height: '48px',
    marginTop: '10px !important',
    width: '100% !important',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },

    '& .MuiSelect-select': {
      padding: '13px 14px',
      lineHeight: '1.5',
      fontSize: '18px !important',
    },
    '& .MuiSelect-select[aria-expanded="true"] ~ svg': {
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'}) !important`,
    },
    '& .MuiSelect-icon': {
      display: 'block',
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'}) !important`,
      transition: 'all .5s ease',
      cursor: 'pointer',
    },
  }));

  useEffect(() => {
    totalCountRef.current = totalCount;
  }, [totalCount]);

  useEffect(() => {
    setIsLoading(true);
    const getPaymentOption = async () => {
      const paymentObj = {
        page: page,
        limit: rowsPerPage || 10,
        customer: paymentValue?.customer || '',
        owner: paymentValue?.owner || '',
        productType: paymentValue?.productType || '',
        customerSource: paymentValue?.customersource || '',
        search: searchCustomer || '',
        filterDateRange: dateFiterOption,
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
      };
      const payload = await dispatch(getPaymentListAPI(paymentObj));
      if (payload?.payload) {
        const totalCount = payload?.payload?.billing?.paging?.totalItems || 0;
        setPaymentOptionData(payload?.payload);
        setRowsPerPage(payload?.payload?.billing?.paging?.itemsPerPage);
        setTotalCount(totalCount);

        if (
          (paymentObj.owner !== '' ||
            paymentObj.customer !== '' ||
            paymentObj.productType !== '' ||
            paymentObj.search !== '' ||
            paymentObj.filterDateRange !== '' ||
            paymentObj.fromDate !== '' ||
            paymentObj.toDate !== '') &&
          totalCount !== totalCountRef.current
        ) {
          setPage(0);
        }
        setIsLoading(false);
      } else {
        toast(payload?.payload?.response?.message);
        setRowsPerPage(10);
        setTotalCount(0);
        setPage(0);
        setIsLoading(false);
      }
    };

    getPaymentOption();
  }, [
    dateFiterOption,
    dispatch,
    endDate,
    paymentValue?.customer,
    paymentValue?.customersource,
    paymentValue?.owner,
    paymentValue?.productType,
    startDate,
    page,
    rowsPerPage,
    searchCustomer,
  ]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const customerSource = [
    {
      id: 0,
      name: 'All',
    },
    {
      id: 1,
      name: 'Inbound',
    },
    {
      id: 2,
      name: 'Outbound',
    },
  ];

  const getDateRange = (name, customStartDate, customEndDate) => {
    setDateFilterOption(name);
    const currentDate = new Date();
    let startDate, endDate;

    switch (name) {
      case '7 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;

      case '30 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'This Month':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;

      case 'Last Month':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;

      case 'This Year':
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 11, 31);
        break;

      case 'Custom Date':
        if (customStartDate && customEndDate) {
          startDate = new Date(customStartDate);
          endDate = new Date(customEndDate);
        } else {
          return null;
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    setStartDate(startDate);
    setEndDate(endDate);

    return { startDate, endDate };
  };

  const dateFilter = [
    { Name: '7 Days', Code: 'Last 7 days' },
    { Name: '30 Days', Code: 'Last 30 days' },
    { Name: 'This Month', Code: 'This month' },
    { Name: 'Last Month', Code: 'Last month' },
    { Name: 'This Year', Code: 'This year' },
    { Name: 'Custom Date', Code: 'Custom date' },
  ];

  const columnName = [
    {
      id: 1,
      name: 'Date',
    },
    {
      id: 2,
      name: 'Customer',
    },
    {
      id: 3,
      name: 'Owner',
    },
    {
      id: 4,
      name: 'Customer Source',
    },
    {
      id: 5,
      name: 'Status',
    },
    {
      id: 6,
      name: 'Amount',
    },
    {
      id: 7,
      name: 'Plan Amount',
    },
    {
      id: 8,
      name: 'Tax',
    },
    {
      id: 9,
      name: 'Transaction ID',
    },
    {
      id: 10,
      name: 'Method',
    },
    {
      id: 11,
      name: 'Volume',
    },
    {
      id: 12,
      name: 'Start Date',
    },
    {
      id: 13,
      name: 'End Date',
    },
    {
      id: 14,
      name: 'Type',
    },
  ];

  const handleCopyClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = document.querySelector('table').innerText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  const handlePrint = () => {
    const printWindow = window.open('payment', '_blank');
    const tableContent = tableRef.current.innerHTML;

    printWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            #printSection {
              width: 100% !important;
              box-shadow: none;
              border-radius: 12px;
            }
            .table-wrapper {
              border: 1px solid #dddddd;
              border-radius: 6px;
              margin-top: 20px; /* Add margin to create space for header */
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              text-align: left;
              padding: 8px;
              font-size: 18px
            }
            th, tr td {
              border-bottom: 1px solid #dddddd;
            }
            th:last-child, td:last-child,
            th svg {
              display: block;
            }
            @media print {
              th:last-child, td:last-child,
              th svg {
                display: block;
              }
              th, td {
                text-align: left;
                padding: 8px;
                font-size: 10px
              }
              .header {
                text-align: center;
                margin-bottom: 20px;
              }
              .header img {
                max-width: 100px; /* Adjust the max-width as needed */
              }
            }
          </style>
        </head>
        <body>
          <div class="header" id="header">
            <img src=${companyLogo} alt='Company Logo' />
            <h1>Payment Details</h1>
          </div>
          <div class="table-wrapper">
            ${tableContent}
          </div>
          <script>
          window.onload = function() {
            document.getElementById('header').style.display = 'block';
            window.print();
          };
        </script>
        </body>
      </html>
    `);

    printWindow.document.close();
  };

  const downloadFile = (format) => {
    let filteredData;
    filteredData = paymentOptionData?.billing?.payments?.map((row) => ({
      Date: formatDate(row?.startDate),
      Customer: row?.userName,
      Owner: row?.OwnerName ? row?.OwnerName : '-',
      'Customer Source': row?.customerSource,
      Status: row?.paymentStatus,
      Amount: row?.paymentAmount,
      'Plan Amount': row?.price,
      Tax: row?.tax ? row?.tax : 50,
      TranId: row?.transactionID,
      Method: row?.paymentMethod,
      Volume: row?.volume,
      'Start Date': formatDate(row?.startDate),
      'End Date': formatDate(row?.endDate),
      Type: row?.productType,
    }));

    switch (format) {
      case 'CSV':
        const csvHeaders = Object?.keys(filteredData[0]);
        const csvFileName = `customerPaymentDetails_${formattedDate}.csv`;
        const csvContent = [
          csvHeaders.join(','),
          ...filteredData.map((row) => csvHeaders.map((header) => row[header]).join(',')),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvFileName;
        link.click();
        break;

      case 'Excel':
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `customerPaymentDetails_${formattedDate}.xlsx`);
        break;

      case 'PDF':
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const imgWidth = 30;
        const imgHeight = 10;

        const imgX = (pdfWidth - imgWidth) / 2;
        pdf.addImage(companyLogo, 'PNG', imgX, 10, imgWidth, imgHeight);

        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(16);

        pdf.text(
          'Payment Report',
          (pdfWidth -
            (pdf.getStringUnitWidth('Payment Report') * pdf.internal.getFontSize()) /
              pdf.internal.scaleFactor) /
            2,
          30
        );

        if (filteredData && filteredData.length > 0) {
          const tableData = filteredData.map((row) => Object.values(row));

          const headers = columnName.map((heading) => heading.name);

          pdf.autoTable({
            head: [headers],
            body: tableData,
            startY: imgHeight + 25,
            styles: {
              cellPadding: 2,
              valign: 'middle',
              fontSize: 5,
              fontStyle: 'normal',
              lineWidth: 0.5,
            },
            margin: { top: imgHeight + 30 },
            tableWidth: 'auto',
          });

          pdf.save(`customerPaymentDetails_${formattedDate}.pdf`);
        } else {
          pdf.text('No data available', 10, imgHeight + 25);
        }
        break;

      default:
        console.error('Invalid format');
    }

    setDownloadMenu(null);
  };

  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <Typography variant='h2' sx={{ mb: 3, color: colors?.neutral?.dark }}>
          Payment
        </Typography>
        <Grid container spacing={'24px'}>
          <Grid item xs={12}>
            <Box className='table-body'>
              <Box
                sx={{
                  display: { lg: 'flex', xs: 'grid', flexWrap: 'wrap' },
                  justifyContent: 'space-between',
                  gap: '5px',
                }}
              >
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '20px',
                    }}
                  >
                    <Box sx={{ width: { xl: '200px', sm: '240px', xs: '100%' } }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          color: colors?.basics?.normal,
                        }}
                      >
                        Customer
                      </Typography>
                      <CustomStyledSelect
                        name='customer'
                        value={paymentValue?.customer}
                        onChange={(e) => {
                          setPaymentValue({ ...paymentValue, customer: e.target.value });
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={(props) => (
                          <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                        )}
                      >
                        {!paymentValue?.customer ? (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            disabled
                            onClick={handleDropdownToggle}
                          >
                            <em>Select customer</em>
                          </MenuItem>
                        ) : (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            onClick={handleDropdownToggle}
                          >
                            <em>All</em>
                          </MenuItem>
                        )}
                        {paymentOptionData?.users?.map((ownerName, index) => (
                          <MenuItem
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            className='filter-box'
                            key={index}
                            value={ownerName?.p_customer_id}
                          >
                            {ownerName?.p_login}
                          </MenuItem>
                        ))}
                      </CustomStyledSelect>
                    </Box>
                    <Box sx={{ width: { xl: '200px', sm: '240px', xs: '100%' } }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          color: colors?.basics?.normal,
                        }}
                      >
                        Owner
                      </Typography>
                      <CustomStyledSelect
                        name='owner'
                        value={paymentValue?.owner}
                        onChange={(e) => {
                          setPaymentValue({ ...paymentValue, owner: e.target.value });
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={(props) => (
                          <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                        )}
                      >
                        {!paymentValue?.owner ? (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            disabled
                            onClick={handleDropdownToggle}
                          >
                            <em>Select owner</em>
                          </MenuItem>
                        ) : (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            onClick={handleDropdownToggle}
                          >
                            <em>All</em>
                          </MenuItem>
                        )}
                        {Array.isArray(paymentOptionData?.owners)
                          ? paymentOptionData?.owners?.map((ownerName, index) => (
                              <MenuItem
                                className='filter-box'
                                key={index}
                                value={ownerName?.p_customer_id}
                                anchorEl={anchorEl}
                                open={isDropdownOpen}
                                onClose={handleClose}
                                anchororigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformorigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                {ownerName?.p_login}
                              </MenuItem>
                            ))
                          : null}
                      </CustomStyledSelect>
                    </Box>
                    <Box sx={{ width: { xl: '200px', sm: '240px', xs: '100%' } }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          color: colors?.basics?.normal,
                        }}
                      >
                        Product Type
                      </Typography>
                      <CustomStyledSelect
                        name='productType'
                        value={paymentValue?.productType}
                        onChange={(e) => {
                          setPaymentValue({ ...paymentValue, productType: e.target.value });
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={(props) => (
                          <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                        )}
                      >
                        {!paymentValue?.productType ? (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            disabled
                            onClick={handleDropdownToggle}
                          >
                            <em> Select type</em>
                          </MenuItem>
                        ) : (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            onClick={handleDropdownToggle}
                          >
                            <em> All</em>
                          </MenuItem>
                        )}
                        {paymentOptionData?.productTypes?.map((producttype, index) => (
                          <MenuItem
                            className='filter-box'
                            key={index}
                            value={producttype?.p_products_id}
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            {producttype?.p_name}
                          </MenuItem>
                        ))}
                      </CustomStyledSelect>
                    </Box>
                    <Box sx={{ width: { xl: '200px', sm: '240px', xs: '100%' } }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          color: colors?.basics?.normal,
                        }}
                      >
                        Customer Source
                      </Typography>
                      <CustomStyledSelect
                        name='customersource'
                        value={paymentValue?.customersource}
                        onChange={(e) => {
                          setPaymentValue({ ...paymentValue, customersource: e.target.value });
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={(props) => (
                          <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                        )}
                      >
                        {!paymentValue?.customersource && (
                          <MenuItem
                            value=''
                            sx={{ color: colors.neutral.light + ' !important' }}
                            disabled
                            onClick={handleDropdownToggle}
                          >
                            <em>Select source</em>
                          </MenuItem>
                        )}
                        {customerSource?.map((customersource, index) => (
                          <MenuItem
                            className='filter-box'
                            key={index}
                            value={customersource?.id}
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            {customersource?.name}
                          </MenuItem>
                        ))}
                      </CustomStyledSelect>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: '100%',
                      maxWidth: { lg: '200px', sm: '240px', xs: '-webkit-fill-available' },
                      marginTop: '20px',
                    }}
                  >
                    <CustomStyledSelect
                      name='dateFiterOption'
                      value={dateFiterOption}
                      onChange={(event) => getDateRange(event?.target?.value)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={(props) => (
                        <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                      )}
                    >
                      {dateFilter?.map((datefilterop, index) => (
                        <MenuItem
                          key={index}
                          value={datefilterop?.Name}
                          className='filter-box'
                          anchorEl={anchorEl}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          anchororigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformorigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          {datefilterop?.Code}
                        </MenuItem>
                      ))}
                    </CustomStyledSelect>
                  </Box>
                </>
              </Box>

              <Box
                sx={{
                  backgroundColor: colors.background.formBg,
                  borderRadius: '16px',
                  paddingTop: '24px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <TextField
                    type='search'
                    placeholder='Search'
                    value={searchCustomer}
                    onChange={(e) => setSearchCustomer(e.target.value)}
                    sx={{
                      maxWidth: { xl: '420px', sm: '245px', md: '245px' },
                      minWidth: { xl: '204px', xs: '195px' },
                      width: '100%',
                      mb: '24px',
                      '& input': {
                        padding: '10px 14px',
                        height: 'auto',
                        fontSize: '18px',
                        lineHeight: '27px',
                        fontWeight: 400,
                      },
                      '@media (max-width: 600px)': {
                        width: '100%',
                      },
                      '@media (min-width: 601px) and (max-width: 960px)': {
                        width: '45%',
                      },
                    }}
                  />
                  <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    {!isDatePickerDisabled ? (
                      <Box
                        sx={{
                          width: { sm: 'auto', xs: '100%' },
                          height: '23px !important',
                          '& .react-datepicker-wrapper': {
                            width: '100%',
                            height: '23px !important',
                            '& svg': {
                              display: 'none',
                            },
                            '& input': {
                              padding: '12px 14px',
                              height: '23px !important',
                              paddingLeft: '46px',
                              textAlign: { lg: '-webkit-center !important', xs: 'end', sm: 'end' },
                              fontSize: '18px',
                              width: { sm: 'auto', xs: '100%' },
                              maxWidth: { xl: 'auto', sm: '140px', xs: '-webkit-fill-available' },
                              borderRadius: '6px',
                              color: '#686465',
                              border: `1px solid ${theme.palette.neutral.lighter}`,
                              backgroundColor: colors.basics.white,
                              backgroundImage: `url("${Calendar}")`,
                              backgroundPosition: 'left 14px center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: '26px 26px',
                              transition: 'all .5s ease',
                              '&:hover, &:focus-visible': {
                                borderColor: colors.theme,
                                outline: 'none',
                              },
                            },
                          },
                        }}
                      >
                        <DatePicker
                          showIcon
                          placeholderText='Start Date'
                          selected={startDate}
                          onChange={(date) => {
                            if (date <= endDate) {
                              setStartDate(date);
                            } else {
                              toast.error('From date must not be grater to end date');
                            }
                          }}
                          disabled={isDatePickerDisabled}
                          dateFormat='dd-MM-yyyy'
                          className={isDatePickerDisabled ? 'disabled-datepicker' : ''}
                        />
                      </Box>
                    ) : (
                      <Tooltip title="Select the 'custom date' filter to do a calendar search." arrow>
                        <Box
                          sx={{
                            width: { sm: 'auto', xs: '100%' },
                            height: '23px !important',
                            '& .react-datepicker-wrapper': {
                              width: '100%',
                              height: '23px !important',
                              '& svg': {
                                display: 'none',
                              },
                              '& input': {
                                padding: '12px 14px',
                                height: '23px !important',
                                paddingLeft: '46px',
                                textAlign: { lg: '-webkit-center !important', xs: 'end', sm: 'end' },
                                fontSize: '18px',
                                width: { sm: 'auto', xs: '100%' },
                                maxWidth: { xl: 'auto', sm: '140px', xs: '-webkit-fill-available' },
                                borderRadius: '6px',
                                color: '#686465',
                                border: `1px solid ${theme.palette.neutral.lighter}`,
                                backgroundColor: colors.basics.white,
                                backgroundImage: `url("${Calendar}")`,
                                backgroundPosition: 'left 14px center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '26px 26px',
                                transition: 'all .5s ease',
                                '&:hover, &:focus-visible': {
                                  borderColor: colors.theme,
                                  outline: 'none',
                                },
                              },
                            },
                          }}
                        >
                          <DatePicker
                            showIcon
                            placeholderText='Start Date'
                            selected={startDate}
                            onChange={(date) => {
                              if (date <= endDate) {
                                setStartDate(date);
                              } else {
                                toast.error('From date must not be grater to end date');
                              }
                            }}
                            disabled={isDatePickerDisabled}
                            dateFormat='dd-MM-yyyy'
                            className={isDatePickerDisabled ? 'disabled-datepicker' : ''}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    {!isDatePickerDisabled ? (
                      <Box
                        sx={{
                          width: { sm: 'auto', xs: '100%' },
                          marginTop: { xs: '15px', lg: '0', sm: '0', md: '0' },
                          height: '23px !important',
                          '& .react-datepicker-wrapper': {
                            width: '100%',
                            height: '23px !important',
                            '& svg': {
                              display: 'none',
                            },
                            '& input': {
                              padding: '12px 14px',
                              height: '23px !important',
                              paddingLeft: '46px',
                              textAlign: { lg: '-webkit-center !important', xs: 'end', sm: 'end' },
                              fontSize: '18px',
                              width: { sm: 'auto', xs: '100%' },
                              maxWidth: { xl: 'auto', sm: '140px', xs: '-webkit-fill-available' },
                              borderRadius: '6px',
                              color: '#686465',
                              border: `1px solid ${theme.palette.neutral.lighter}`,
                              backgroundColor: colors.basics.white,
                              backgroundImage: `url("${Calendar}")`,
                              backgroundPosition: 'left 14px center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: '26px 26px',
                              transition: 'all .5s ease',
                              '&:hover, &:focus-visible': {
                                borderColor: colors.theme,
                                outline: 'none',
                              },
                            },
                          },
                        }}
                      >
                        <DatePicker
                          showIcon
                          placeholderText='Start Date'
                          selected={endDate}
                          onChange={(date) => {
                            if (date >= startDate) {
                              setEndDate(date);
                            } else {
                              toast.error('End date must not be earlier than the start date');
                            }
                          }}
                          disabled={isDatePickerDisabled}
                          dateFormat='dd-MM-yyyy'
                          className={isDatePickerDisabled ? 'disabled-datepicker' : ''}
                        />
                      </Box>
                    ) : (
                      <Tooltip title="Select the 'custom date' filter to do a calendar search." arrow>
                        <Box
                          sx={{
                            width: { sm: 'auto', xs: '100%' },
                            marginTop: { xs: '15px', lg: '0', sm: '0', md: '0' },
                            height: '23px !important',
                            '& .react-datepicker-wrapper': {
                              width: '100%',
                              height: '23px !important',
                              '& svg': {
                                display: 'none',
                              },
                              '& input': {
                                padding: '12px 14px',
                                height: '23px !important',
                                paddingLeft: '46px',
                                textAlign: { lg: '-webkit-center !important', xs: 'end', sm: 'end' },
                                fontSize: '18px',
                                width: { sm: 'auto', xs: '100%' },
                                maxWidth: { xl: 'auto', sm: '140px', xs: '-webkit-fill-available' },
                                borderRadius: '6px',
                                color: '#686465',
                                border: `1px solid ${theme.palette.neutral.lighter}`,
                                backgroundColor: colors.basics.white,
                                backgroundImage: `url("${Calendar}")`,
                                backgroundPosition: 'left 14px center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '26px 26px',
                                transition: 'all .5s ease',
                                '&:hover, &:focus-visible': {
                                  borderColor: colors.theme,
                                  outline: 'none',
                                },
                              },
                            },
                          }}
                        >
                          <DatePicker
                            showIcon
                            placeholderText='Start Date'
                            selected={endDate}
                            onChange={(date) => {
                              if (date >= startDate) {
                                setEndDate(date);
                              } else {
                                toast.error('End date cannot be less than start date');
                              }
                            }}
                            disabled={isDatePickerDisabled}
                            dateFormat='dd-MM-yyyy'
                            className={isDatePickerDisabled ? 'disabled-datepicker' : ''}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>

                  <Box
                    sx={{
                      '& button': {
                        mr: '8px',
                        marginTop: { xs: '15px', lg: '0', sm: '0', md: '0' },
                        padding: { xl: '11px 19px', xs: '7px' },
                        maxHeight: '100%',
                        minWidth: 'auto',
                        maxWidth: '100%',
                        border: '1px solid #CDCBCC',
                        height: '48px',
                        width: '64px',
                        '&:last-child': {
                          mr: '0px',
                        },
                        '& svg path': {
                          transition: 'all .5s ease',
                        },
                        '&:hover svg path': {
                          fill: colors.theme,
                        },
                      },
                      display: 'flex',

                      ml: { lg: 'auto', xs: '0' },
                      mr: 0,
                      '@media (max-width: 600px)': {
                        marginLeft: 0,
                      },
                      '@media (min-width: 601px) and (max-width: 960px)': {
                        marginLeft: 0,
                      },
                    }}
                  >
                    <Button
                      variant='grayOutlined'
                      type='button'
                      onClick={handleCopyClick}
                      disabled={!(paymentOptionData?.billing?.payments?.length > 0)}
                    >
                      <Tooltip
                        title='Copy to Clipboard'
                        arrow
                        open={tooltipOpen}
                        onClose={() => setTooltipOpen(false)}
                      >
                        <CopyIcon />
                      </Tooltip>
                    </Button>
                    <Button
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(event) => setDownloadMenu(event.currentTarget)}
                      variant='grayOutlined'
                      type='button'
                      disabled={!(paymentOptionData?.billing?.payments?.length > 0)}
                    >
                      <DownloadIcon />
                    </Button>
                    <Menu
                      id='basic-menu'
                      className='download-menu'
                      anchorEl={DownloadMenu}
                      open={open}
                      transformorigin={{ horizontal: 'right', vertical: 'top' }}
                      anchororigin={{ horizontal: 'right', vertical: 'bottom' }}
                      onClose={() => setDownloadMenu(null)}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => downloadFile('CSV')}>CSV</MenuItem>
                      <MenuItem onClick={() => downloadFile('Excel')}>Excel</MenuItem>
                      <MenuItem onClick={() => downloadFile('PDF')}>PDF</MenuItem>
                    </Menu>
                    <Button
                      variant='grayOutlined'
                      type='button'
                      onClick={handlePrint}
                      disabled={!(paymentOptionData?.billing?.payments?.length > 0)}
                    >
                      <PrintIcon />
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box>
                <PaymentTable
                  columnName={columnName}
                  tableRef={tableRef}
                  paymentOptionData={paymentOptionData}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Payment;
