import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminPublicRoutes = ({ children }) => {
  const { signInUser } = useSelector((state) => state?.registerReducer);
  const shouldRenderChildren = !signInUser?.token;
  return shouldRenderChildren ? children : <Navigate to='/admin/dashboard' />;
};

export default AdminPublicRoutes;
