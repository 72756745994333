import { Box, Table, TableBody, Paper, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import theme from '../../../theme';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { colors } from '../../../theme/colors';
import RecentPaymentsRow from './recentPaymentsRow';
import { getPaymentListAPI } from '../../../redux/paymentListAPI';

const RecentPayments = () => {
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const [recentpaymentData, setRecentPaymentData] = useState([]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: '#242323',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: '#686465',
      fontWeight: 400,
      padding: '10px 20px 10px 20',
    },
  }));

  useEffect(() => {
    setIsLoading(true);
    const paymentData = async () => {
      const paymentObj = {
        page: 0,
        limit: 10,
        customer: '',
        owner: '',
        productType: '',
        customerSource: '',
        search: '',
        filterDateRange: '',
        fromDate: '',
        toDate: '',
      };
      const payload = await dispatch(getPaymentListAPI(paymentObj));
      if (payload?.payload) {
        setRecentPaymentData(payload?.payload?.billing?.payments);
        setIsLoading(false);
      }
    };
    paymentData();
  }, []);

  const columnName = [
    {
      id: 1,
      name: 'Customer',
    },
    {
      id: 2,
      name: 'Status',
    },
    {
      id: 3,
      name: 'Amount',
    },
    {
      id: 4,
      name: 'Volume',
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography
        sx={{ fontSize: '24px', fontWeight: 600, color: colors.neutral.dark, fontFamily: theme.fontFamily }}
      >
        Recent Payments
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ width: '100% !important', boxShadow: 'none', borderRadius: '12px' }}
      >
        <Table
          sx={{
            width: '100%',
            '& th, td': {
              padding: '22px 20px',
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                ' & th': {
                  fontWeight: '500',
                  fontSize: '18px',
                  whiteSpace: 'nowrap',
                  color: colors.neutral.dark,
                  position: 'relative',
                  cursor: 'pointer',
                  '& .sort-arrow': {
                    position: 'relative',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                },
              }}
            >
              {columnName?.map((item, index) => (
                <StyledTableCell
                  sx={{
                    fontSize: '18px',
                    fontWeight: 500,
                    fontFamily: theme.fontFamily,
                    color: colors.neutral.dark,
                  }}
                  key={index}
                >
                  {item.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow sx={{ textAlign: 'center' }}>
                <TableCell colSpan={16}>
                  <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                    <ThreeDots
                      visible={true}
                      height='80'
                      width='80'
                      color={colors.theme}
                      radius='9'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClass=''
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {recentpaymentData?.length > 0 ? (
                recentpaymentData
                  .slice(0, 5)
                  .map((row, index) => <RecentPaymentsRow key={index} item={row} index={index} />)
              ) : (
                <TableRow sx={{ width: '100%', height: '100%' }}>
                  <TableCell colSpan={16} sx={{ textAlign: 'center', border: '0' }}>
                    <img src={DataNotFound} alt='data not found' style={{ width: '80px', height: '80px' }} />
                    <Typography>Data not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecentPayments;
