import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Box, Typography, useTheme } from '@mui/material';
import codes from 'country-calling-code';
import {
  registerAPI,
  setCountryCode,
  setLoggedInEmailVerification,
  setSignUpUserdata,
} from '../../../redux/signUpApi';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';
import CustomTextBox from '../../../components/common/customTextBox';
import CustomDropdown from '../../../components/common/customDropDown';
import CustomCheckBox from '../../../components/common/customCheckBox';
import CustomButton from '../../../components/common/customButton';
import TermsAndConditions from '../termsandconditions';
import { CountryListOptions } from '../../../components/common/country';
import { EmailconfirmationAPI } from '../../../redux/emailConfiramationAPI';
import { ColorRing } from "react-loader-spinner";

const SignUp = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { emailIsLoading } = useSelector((state) => state?.EmailconfirmationReducer);
  const [registerData, setRegisterData] = useState({
    p_login: '',
    p_contact_email: '',
    p_password: '',
    p_countryName: '',
    isAcceptTermsAndConditions: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [termsModal, setIsTermsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState([]);

  const [_, setCookies] = useCookies(['access_token_proxy']);

  useEffect(() => {
    const sortedCountries = CountryListOptions?.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setCountry(sortedCountries);
  }, []);

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
    'in',
    'co',
    'io',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const validationSchema = yup.object().shape({
    p_login: yup
      .string()
      .min(3, 'Name is too short, minimum length is 3 characters')
      .max(20, 'Name is too long, maximum length is 20 characters')
      .required('User name is required'),
    p_contact_email: yup
      .string()
      .test('valid-p_contact_email', 'Invalid contact email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const p_login = parts[0];
            const domain = parts[1];

            if (
              p_login === '' ||
              p_login.startsWith('.') ||
              domain.startsWith('.') ||
              p_login.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              p_login.endsWith('.') ||
              p_login.includes('-') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    p_countryName: yup.string().required('Please select countryName'),
    p_password: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?!.*[@#])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$!%*?&])[A-Za-z\d$!%*?&]+$/,
        'Passwords must be at least 8 characters long and include at least one uppercase, one lowercase, one numeric, and one special character. # and @ are not allowed'
      )
      .required('Password is required'),

    isAcceptTermsAndConditions: yup
      .boolean()
      .test('is-true', 'Please accept terms and conditions', (value) => value === true),
  });

  const handleChange = (field, value) => {
    let updatedValue = value;

    if (field === 'p_login') {
      updatedValue = value.replace(/[^a-zA-Z0-9]/g, '');
    }

    setRegisterData((prevData) => ({
      ...prevData,
      [field]: updatedValue,
    }));
  };

  const handleRegister = async (event) => {
    try {
      setFormErrors({});
      event.preventDefault();
      setIsLoading(true);

      await validationSchema.validate(registerData, { abortEarly: false });
      const registerObject = {
        p_contact_email: registerData?.p_contact_email,
        p_login: registerData?.p_login,
        p_countryName: registerData?.p_countryName,
        p_password: registerData?.p_password,
      };

      const payload = await dispatch(registerAPI(registerObject));
      if (payload?.payload?.token) {
        setCookies('access_token_proxy', payload?.payload?.token, {
          path: '/',
          httpOnly: false,
          secure: false,
          sameSite: 'strict',
        });
        sessionStorage.setItem('access_token_proxy_session', JSON.stringify(payload?.payload));
        dispatch(setLoggedInEmailVerification(payload?.payload));
        dispatch(setSignUpUserdata(payload?.payload?.p_customer_id));
        await setIsLoading(false);
        const emailObject = {
          p_type: 4,
          p_contact_email: registerData?.p_contact_email,
          isCheckTime: 0,
        };
        const data = await dispatch(EmailconfirmationAPI(emailObject));
        dispatch(setCountryCode(codes?.find((code) => code.isoCode2 === registerData?.p_countryName)));
        if (data?.payload?.status === true) {
          toast.success(data?.payload?.message);
          await navigate('/emailverification');
        } else {
          toast.error(data?.payload?.message);
          await setIsLoading(false);
        }
      } else if (payload?.payload?.status === false) {
        toast.error(payload?.payload?.message);
        await setIsLoading(false);
      } else {
        toast.error(payload?.payload?.response?.data?.message);
        setIsLoading(false);
      }

      setFormErrors({
        p_login: false,
        enail: false,
        p_password: false,
        countryName: false,
        isAcceptTermsAndConditions: false,
      });
    } catch (err) {
      try {
        const errors = {};
        setIsLoading(false);

        if (err && err.inner) {
          err.inner.forEach((e) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
        } else {
          console.error('Unexpected error:', err);
        }
      } catch (error) {
        console.error('Error handling failed:', error);
      }
    }
  };

  return (
    <AuthenticationWrapper>
      <Box className="signup-box">
        <Typography variant="h1" sx={{ color: theme.palette.neutral.dark }}>
          Sign up
        </Typography>
        <Typography variant="subtitle5" sx={{ color: theme.palette.neutral.normal }}>
          Create your account
        </Typography>
      </Box>
      <form style={{ width: '100%' }} onSubmit={handleRegister}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <CustomTextBox
              placeholder="Enter username"
              icon
              iconType={'username'}
              value={registerData.p_login}
              onChange={(e) => {
                handleChange('p_login', e.target.value);
                setFormErrors({ ...formErrors, p_login: '' });
              }}
            />
            {formErrors?.p_login && (
              <Typography
                sx={{
                  color: theme?.palette?.danger,
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                {formErrors?.p_login}
              </Typography>
            )}{' '}
          </Box>
          <Box sx={{ width: '100%' }}>
            <CustomTextBox
              placeholder="Enter email id"
              icon
              iconType={'email'}
              value={registerData.p_contact_email}
              onChange={(e) => {
                handleChange('p_contact_email', e.target.value.trim());
                setFormErrors({ ...formErrors, p_contact_email: '' });
              }}
            />
            {formErrors?.p_contact_email && (
              <Typography
                sx={{
                  color: theme?.palette?.danger,
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                {formErrors?.p_contact_email}
              </Typography>
            )}{' '}
          </Box>
          <Box sx={{ width: '100%' }}>
            <CustomDropdown
              icon
              iconType="country"
              value={registerData.p_countryName}
              onChange={(value) => {
                handleChange('p_countryName', value);
                setFormErrors({ ...formErrors, p_countryName: '' });
              }}
              options={country}
              placeholder={'Select your country'}
            />
            {formErrors?.p_countryName && (
              <Typography
                sx={{
                  color: theme?.palette?.danger,
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                {formErrors?.p_countryName}
              </Typography>
            )}
          </Box>
          <Box sx={{ width: '100%' }}>
            <CustomTextBox
              placeholder="Password"
              icon
              type="password"
              iconType={'password'}
              value={registerData.p_password}
              onChange={(e) => {
                handleChange('p_password', e.target.value);
                setFormErrors({ ...formErrors, p_password: '' });
              }}
            />
            {formErrors?.p_password && (
              <Typography
                sx={{
                  color: theme?.palette?.danger,
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                {formErrors?.p_password}
              </Typography>
            )}{' '}
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
              }}
            >
              <CustomCheckBox
                isChecked={registerData.isAcceptTermsAndConditions}
                onChange={(e) => {
                  if (e.target.name === 'isAcceptTermsAndConditions') {
                    handleChange('isAcceptTermsAndConditions', !registerData.isAcceptTermsAndConditions);
                    setFormErrors({
                      ...formErrors,
                      isAcceptTermsAndConditions: '',
                    });
                  }
                }}
                name="isAcceptTermsAndConditions"
              />
              <Typography
                component="span"
                variant="subtitle1"
                sx={{ color: '#242323', '&:hover': { cursor: 'pointer' } }}
                onClick={() => {
                  handleChange('isAcceptTermsAndConditions', !registerData.isAcceptTermsAndConditions);
                  setFormErrors({
                    ...formErrors,
                    isAcceptTermsAndConditions: '',
                  });
                }}
              >
                I accept the
                <span className="terms-condition-section" onClick={() => setIsTermsModal(true)}>
                  Terms and Conditions
                </span>
              </Typography>
            </Box>
            {formErrors?.isAcceptTermsAndConditions && (
              <Typography
                sx={{
                  color: theme?.palette?.danger,
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                {formErrors?.isAcceptTermsAndConditions}
              </Typography>
            )}{' '}
          </Box>
          <Box sx={{ width: '100%' }}>
            <CustomButton type="submit" onClick={handleRegister} disabled={isLoading || emailIsLoading}>
              Sign up
              {isLoading && (
                <ColorRing
                  visible={true}
                  height="30"
                  width="30"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                  ]}
                />
              )}
            </CustomButton>
          </Box>
        </Box>
      </form>
      <Typography
        variant="subtitle3"
        sx={{
          color: theme.palette.neutral.light,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          margin: '24px 0',
          '&::before, &::after ': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '4px',
            background: 'radial-gradient(50% 50% at 50% 50%, #B2B2B2 0%, rgba(178, 178, 178, 0.00) 100%)',
          },
        }}
      >
        or
      </Typography>

      <Typography variant="subtitle1" sx={{ color: theme.palette.neutral.normal, display: 'flex' }}>
        Already have an account?&nbsp;
        <Link to="/login">
          <Typography variant="subtitle2" sx={{ color: theme.palette.linkColor }}>
            Sign in
          </Typography>
        </Link>
      </Typography>

      <Box>
        <TermsAndConditions termsModal={termsModal} setIsTermsModal={setIsTermsModal} />
      </Box>
    </AuthenticationWrapper>
  );
};

export default SignUp;
