import React, { useEffect, useState } from 'react';
import { Grid, Button, FormControl, Select, MenuItem, Typography, Box } from '@mui/material';
import { colors } from '../../../theme/colors';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/CaretDown.svg';
import CustomTextBox from '../../../components/common/customTextBox';
import CustomButton from '../../../components/common/customButton';
import DashBoardProgressChart from '../dashboardProgressChart';
import DashBoardLineChart from '../dashboardLineChart';
import UserDashboardAccountActivity from '../userdashboardAccountActivity';
import { dashBoardChartAPI, setDashBoardChart } from '../../../redux/dashBoardChartAPI';
import { ThreeDots } from 'react-loader-spinner';
import { CountryListOptions } from '../../../components/common/country';
import { useGetUser } from '../../../context/userProfile';
import GeneratorModal from './generatorModal';

const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
  '& .MuiSelect-select[aria-expanded="true"] ~ svg ': {
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
  },
  '& .MuiSelect-icon': {
    display: 'block',
  },
  '& svg': {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
    transition: 'all .5s ease',
  },
  '& .MuiSelect-select': {
    padding: '12px 16px',
  },
  '& .MuiSelect-select, .MuiSelect-select  em': {
    fontSize: '16px',
  },
}));

const Home = () => {
  const dispatch = useDispatch();
  const { logInUserDetails } = useGetUser();
  const name = useSelector((state) => state?.userProfileReducer?.userProfileData?.p_login);
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [generatorModal, setGeneratorModal] = useState(false);
  const [proxyGenerateValue, setProxyGenerateValue] = useState({
    productType: 1,
    country: '',
    targetUrl: '',
  });
  const [standByPlans, setStandByPlans] = useState([]);

  const productTypeArray = [
    {
      product_id: 1,
      product_name: 'Residential',
    },
    {
      product_id: 2,
      product_name: 'Datacenter',
    },
    {
      product_id: 3,
      product_name: 'Mobile',
    },
  ];
  useEffect(() => {
    const sortedCountries = CountryListOptions?.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setCountryData(sortedCountries);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (name) {
      const getChartData = async () => {
        const dashboardchartOBJ = {
          customer: name,
        };
        const payload = await dispatch(dashBoardChartAPI(dashboardchartOBJ));
        if (payload?.payload?.response?.status) {
          setIsLoading(false);
        } else {
          setChartData(payload?.payload);
          setStandByPlans(payload?.payload?.standby_plan);
          await dispatch(setDashBoardChart(payload?.payload));
          setIsLoading(false);
        }
      };
      getChartData();
    }
  }, [dispatch, name]);

  const handleChange = (field, value) => {
    setProxyGenerateValue({ ...proxyGenerateValue, [field]: value });
  };

  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  const handleClickGeneratorButton = () => {
    setGeneratorModal(true);
  };

  return (
    <>
      <Box className="heading-text">
        <Typography variant="h2" pb="20px" color={colors?.neutral?.dark}>
          Dashboard
        </Typography>
      </Box>
      <Box className="grid-box" sx={{ width: '100%' }}>
        <Grid container spacing={'24px'}>
          <Grid
            item
            xl={9}
            xs={12}
            sx={{
              padding: '20px',
              '@media (min-width: 600px)': {
                borderBottom: '1px solid #7a7474',
              },
              '@media (min-width: 1280px)': {
                borderRight: '1px solid #7a7474',
                borderBottom: 'none',
              },
            }}
          >
            <Grid container spacing={'24px'}>
              <Grid item sm={5} xs={12}>
                <Box
                  sx={{
                    backgroundColor: colors.background.formBg,
                    borderRadius: '16px',
                    padding: '24px',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h4" sx={{ color: colors?.neutral?.dark }}>
                      Proxy Generator
                    </Typography>
                    <Button
                      sx={{
                        background: colors?.buttoncolor,
                        color: colors?.basics?.white,
                        fontSize: '14px',
                        fontWeight: 600,
                        borderRadius: '4px',
                        height: 'auto',
                        lineHeight: '1.5',
                        padding: '4px 12px',
                        textTransform: 'capitalize',
                        boxShadow: 'none',
                        '&:hover': {
                          background: colors?.buttoncolor,
                        },
                      }}
                    >
                      New Proxy
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '20px 0 24px',
                      flexDirection: { lg: 'row', xs: 'column' },
                      maxWidth: '390px',
                      margin: 'auto',
                      '& > div:not(:last-child)': {
                        borderBottom: {
                          lg: 'none',
                          xs: `1px solid ${colors.input.border}`,
                        },
                        paddingBottom: { lg: '0', xs: '15px' },
                        marginBottom: { lg: '0', xs: '15px' },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        padding: '0 20px',
                      }}
                    >
                      <Box sx={{ width: 'fit-content' }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: colors?.basics?.normal,
                            textAlign: 'center',
                          }}
                        >
                          Proxy Port
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: colors?.neutral?.dark,
                            textAlign: 'center',
                          }}
                        >
                          {proxyGenerateValue?.productType === 1
                            ? '5100'
                            : proxyGenerateValue?.productType === 2
                            ? '5040'
                            : proxyGenerateValue?.productType === 3 && '5030'}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      orientation={'vertical'}
                      sx={{ display: { lg: 'block', xs: 'none' } }}
                      variant="middle"
                      flexItem
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        padding: '0 20px',
                      }}
                    >
                      <Box sx={{ width: 'fit-content' }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: colors?.basics?.normal,
                            textAlign: 'center',
                          }}
                        >
                          Proxy Server
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: colors?.neutral?.dark,
                            textAlign: 'center',
                          }}
                        >
                          {proxyGenerateValue?.productType === 1
                            ? 'rotating.prox-e.io'
                            : proxyGenerateValue?.productType === 2
                            ? 'dc.prox-e.io'
                            : proxyGenerateValue?.productType === 3 && 'mob.prox-e.io'}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      orientation={'vertical'}
                      sx={{ display: { lg: 'block', xs: 'none' } }}
                      variant="middle"
                      flexItem
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        padding: '0 20px',
                      }}
                    >
                      <Box sx={{ width: 'fit-content' }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: colors?.basics?.normal,
                            textAlign: 'center',
                          }}
                        >
                          Proxy User
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: colors?.neutral?.dark,
                            textAlign: 'center',
                          }}
                        >
                          {logInUserDetails?.p_login ? logInUserDetails?.p_login : 'Username'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      flexWrap: 'wrap',
                      justifyContent: { lg: 'center', xs: 'flex-start' },
                      margin: 'auto',
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: '1',
                        '&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                          height: '48px',
                        },
                      }}
                    >
                      <FormControl sx={{ width: '100%' }}>
                        <CustomStyledSelect
                          name="productType"
                          value={proxyGenerateValue?.productType}
                          onChange={(e) => handleChange('productType', e.target.value)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          IconComponent={(props) => (
                            <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                          )}
                        >
                          {!proxyGenerateValue?.productType && (
                            <MenuItem
                              value=""
                              sx={{
                                color: colors.input.placeholder + ' !important',
                              }}
                              disabled
                              onClick={handleDropdownToggle}
                            >
                              <em>Product Type</em>
                            </MenuItem>
                          )}

                          {productTypeArray?.map((product, index) => (
                            <MenuItem
                              value={product?.product_id}
                              key={index}
                              className="filter-box"
                              anchorEl={anchorEl}
                              open={isDropdownOpen}
                              onClose={handleClose}
                              anchororigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformorigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              {product?.product_name}
                            </MenuItem>
                          ))}
                        </CustomStyledSelect>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: '1',
                      }}
                    >
                      <FormControl sx={{ width: '100%' }}>
                        <CustomStyledSelect
                          name="country"
                          value={proxyGenerateValue?.country}
                          onChange={(e) => handleChange('country', e.target.value)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          IconComponent={(props) => (
                            <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                          )}
                        >
                          {!proxyGenerateValue?.country && (
                            <MenuItem
                              value=""
                              sx={{
                                color: colors.input.placeholder + ' !important',
                              }}
                              disabled
                              onClick={handleDropdownToggle}
                            >
                              <em>Select Country</em>
                            </MenuItem>
                          )}

                          {countryData?.map((country, index) => (
                            <MenuItem
                              value={country?.Code}
                              key={index}
                              className="filter-box"
                              anchorEl={anchorEl}
                              open={isDropdownOpen}
                              onClose={handleClose}
                              anchororigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformorigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              {country?.Name}
                            </MenuItem>
                          ))}
                        </CustomStyledSelect>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: '16px' }}>
                    <CustomTextBox
                      name="targetUrl"
                      placeholder="Target URL"
                      customSx={{
                        '& input.MuiInputBase-input': {
                          fontSize: '16px',
                          padding: '12px 20px',
                        },
                      }}
                      value={proxyGenerateValue?.targetUrl}
                      onChange={(e) => handleChange('targetUrl', e.target.value)}
                    />
                  </Box>

                  <Box sx={{ marginTop: '16px' }}>
                    <CustomButton type={'submit'} onClick={() => handleClickGeneratorButton()}>
                      Generate
                    </CustomButton>
                    <GeneratorModal
                      generatorModal={generatorModal}
                      setGeneratorModal={setGeneratorModal}
                      generatorDetail={proxyGenerateValue}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={7} xs={12}>
                <Box
                  sx={{
                    backgroundColor: colors.background.formBg,
                    borderRadius: '16px',
                    height: '100%',
                  }}
                >
                  <DashBoardProgressChart standByPlans={standByPlans} isLoading={isLoading} />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: colors.background.formBg,
                    borderRadius: '16px',
                    padding: '25px',
                  }}
                >
                  {isLoading ? (
                    <Box
                      sx={{
                        textAlign: 'center',
                        '& > div': { justifyContent: 'center' },
                      }}
                    >
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color={colors.theme}
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: { md: '30px', xs: '30px' },
                      }}
                    >
                      <Box
                        sx={{
                          width: { md: 'calc(100% - 370px)' },
                        }}
                      >
                        <DashBoardLineChart chartData={chartData} isLoading={isLoading} />
                      </Box>
                      <Box sx={{ flexShrink: 0, maxWidth: { sm: '350px' } }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '15px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '24px',
                              fontWeight: 600,
                              color: colors.neutral.dark,
                            }}
                          >
                            System Usage
                          </Typography>
                          <Typography sx={{ ml: 2, mr: 2 }}>-</Typography>
                          <Typography
                            sx={{
                              fontSize: '20px',
                              fontWeight: 400,
                              color: colors.basics.normal,
                            }}
                          >
                            Last 7 days
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            mt: '24px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              color: colors.neutral.dark,
                            }}
                          >
                            Average response time
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 400,
                              color: colors.basics.normal,
                            }}
                          >
                            {isNaN(chartData?.avarageResponseTime) ? '0' : chartData?.avarageResponseTime}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            mt: 2,
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              color: colors.neutral.dark,
                            }}
                          >
                            Success Rate
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 400,
                              color: colors.basics.normal,
                            }}
                          >
                            {isNaN(parseFloat(chartData?.successRate)) ? '0.00%' : chartData?.successRate}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            mt: 2,
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              color: colors.neutral.dark,
                            }}
                          >
                            Total Data
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 400,
                              color: colors.basics.normal,
                            }}
                          >
                            {chartData?.totalData || '0'}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            marginTop: '24px',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '24px',
                              width: '24px',
                              background: colors.theme,
                              borderRadius: '2px',
                            }}
                          ></Typography>
                          <Typography
                            sx={{
                              fontSize: '18px',
                              ml: 2,
                              fontWeight: 600,
                              color: colors.basics.normal,
                            }}
                          >
                            Total Usage
                          </Typography>
                        </Box>
                        {/* <Box
                          sx={{
                            display: 'flex',
                            mt: 2,
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '24px',
                              width: '24px',
                              background: colors.theme,
                              borderRadius: '2px',
                            }}
                          ></Typography>
                          <Typography
                            sx={{
                              fontSize: '18px',
                              ml: 2,
                              fontWeight: 600,
                              color: colors.basics.normal,
                            }}
                          >
                            Successful Requests
                          </Typography>
                        </Box> */}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xl={3} xs={12}>
            <Box
              sx={{
                backgroundColor: colors.background.formBg,
                borderRadius: '16px',
                height: '100%',
              }}
            >
              <UserDashboardAccountActivity />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Home;
