import './App.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import RevokeToken from './components/common/revokeToken';
import Routing from '../src/components/common/routing';
import { GetUserContextProvider } from './context/userProfile';

function App() {
  return (
    <>
      <Router basename='/'>
        <GetUserContextProvider>
          <RevokeToken />
          <Routing />
        </GetUserContextProvider>
      </Router>

      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme='light'
      />
    </>
  );
}

export default App;
