import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import styled from '@emotion/styled';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { Box, Grid, Typography, TextField, Select, MenuItem, Button, Modal, useTheme } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/dropdownDownArrow.svg';
import { colors } from '../../../theme/colors';
import { addCustomerAPI } from '../../../redux/addCustomerAPI';
import { countryStateAPI } from '../../../redux/stateAPI';
import { stateCityAPI } from '../../../redux/cityAPI';
import { countryCityAPI } from '../../../redux/countryToCityAPI';
import { useGetUser } from '../../../context/userProfile';
import { CountryListOptions } from '../../../components/common/country';

const customInputStyle = {
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #ccc',
  fontSize: '16px',
  color: '#333',
  height: 'auto',
  minHeight: '56px',
  '& .react-tel-input .country-list': {
    width: '100% !important',
  },
  '& .react-tel-input .flag-dropdown.open': {
    width: '100% !important',
    background: 'red',
  },
};

const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
  '& .MuiSelect-select[aria-expanded="true"] ~ svg ': {
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
  },
  '& .MuiSelect-icon': {
    display: 'block',
  },
  '& svg': {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
    transition: 'all .5s ease',
  },
  '& .MuiSelect-select': {
    padding: '12px 16px',
  },
  '& .MuiSelect-select, .MuiSelect-select  em': {
    fontSize: '16px',
  },
}));

const AddCustomerModal = ({
  setAddCustomerModal,
  addCustomerModal,
  formErros,
  setFormErrors,
  getCustomerDetails,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { logInUserDetails } = useGetUser();
  const [selectedCountry, setSelectedCountry] = useState('');

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [addcustomerData, setAddCustomerData] = useState({
    username: '',
    proxyusername: '',
    emailId: '',
    accountType: '',
    source: 2,
    companyName: '',
    billingAddress: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    contactNumber: '',
    firstname: '',
    lastName: '',
  });

  const AccountType = [
    {
      id: 0,
      type: 'Company',
    },
    {
      id: 1,
      type: 'Freelancers',
    },
  ];

  const sourceArray = [
    {
      id: 1,
      sourceName: 'Inbound',
    },
    {
      id: 2,
      sourceName: 'Outbound',
    },
  ];

  useEffect(() => {
    const sortedCountries = CountryListOptions?.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setCountryData(sortedCountries);
  }, []);

  useEffect(() => {
    const getState = async () => {
      if (addcustomerData?.country) {
        const countryCode = {
          countryCode: addcustomerData?.country,
        };
        const data = await dispatch(countryStateAPI(countryCode));
        setStateData(data?.payload);
      } else {
        console.error('error');
      }
    };
    getState();
  }, [addcustomerData?.country, dispatch]);

  useEffect(() => {
    const getCity = async () => {
      if (addcustomerData?.country === 'US' && addcustomerData?.state) {
        const cityCode = {
          countryCode: addcustomerData?.country,
          statcode: addcustomerData?.state,
        };
        const data = await dispatch(stateCityAPI(cityCode));

        if (data?.payload) {
          setCityData(data?.payload);
        } else {
          toast.error('error');
        }
      } else if (addcustomerData?.country !== 'US' && addcustomerData?.country) {
        const cityCode = {
          countryCode: addcustomerData?.country,
        };
        const data = await dispatch(countryCityAPI(cityCode));
        if (data?.payload) {
          setCityData(data?.payload);
        } else {
          toast.error('error');
        }
      }
    };
    getCity();
  }, [addcustomerData?.country, addcustomerData?.state, dispatch]);

  useEffect(() => {
    setAddCustomerData((prevData) => ({
      ...prevData,
      country: addcustomerData?.country || '',
    }));
  }, [addcustomerData?.country, setAddCustomerData]);

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .min(3, 'Username must be at least 3 character')
      .max(15, 'Username cannot exceed 15 character')
      .required('Username is required'),
    proxyusername: yup
      .string()
      .min(3, 'Proxy username must be at least 3 character')
      .max(15, 'Proxy username cannot exceed 15 character')
      .required('Proxy name is required'),
    emailId: yup.string().email('Invalid email format').required('Email is required'),
    accountType: yup.string().required('Account type required'),
    zipcode: yup.lazy((value) => {
      if (value && value.length > 1) {
        return yup
          .string()
          .min(5, 'ZIP code must be at least 5 character')
          .max(15, 'ZIP code cannot exceed 15 character')
          .required('ZIP code is required');
      }
      return yup.string().notRequired();
    }),
  });
  const handleChange = (name, value) => {
    setAddCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(addcustomerData, { abortEarly: false });
      const countryCodeLength = selectedCountry?.toString().length;

      const countryCode1 = addcustomerData?.contactNumber?.substring(0, countryCodeLength);
      const contactNumber = addcustomerData?.contactNumber?.substring(countryCodeLength);
      const customerObj = {
        p_login: addcustomerData?.username || '',
        proxyUserName: addcustomerData?.proxyusername || '',
        emailId: addcustomerData?.emailId || '',
        phoneNumber: contactNumber || '',
        countryCode: Number(countryCode1) || 1,
        firstName: addcustomerData?.firstname || '',
        lastName: addcustomerData?.lastName || '',
        companyName: addcustomerData?.companyName || '',
        address: addcustomerData?.billingAddress || '',
        city: addcustomerData?.city || '',
        state: addcustomerData?.state || '',
        country: addcustomerData?.country || '',
        zipCode: addcustomerData?.zipcode || '',
        accountType: addcustomerData?.accountType,
        customerSource: addcustomerData?.source,
        ownerId: logInUserDetails?.p_customer_id || '1',
      };
      const paylod = await dispatch(addCustomerAPI(customerObj));
      if (paylod?.payload?.status) {
        toast.success(paylod?.payload?.message);
        getCustomerDetails();
      } else {
        toast.error(paylod?.payload?.message);
      }
      setAddCustomerData({
        username: '',
        proxyusername: '',
        emailId: '',
        accountType: '',
        source: 2,
        companyName: '',
        billingAddress: '',
        country: '',
        state: '',
        city: '',
        zipcode: '',
        contactNumber: '',
        firstname: '',
        lastName: '',
      });
      setAddCustomerModal(false);
    } catch (err) {
      try {
        const errors = {};

        if (err && err.inner) {
          err.inner.forEach((e) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
        } else {
          console.error('Unexpected error:', err);
        }
      } catch (error) {
        console.error('Error handling failed:', error);
      }
    }
  };

  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Modal
        open={addCustomerModal}
        onClose={async () => {
          await setAddCustomerData({
            username: '',
            proxyusername: '',
            emailId: '',
            accountType: '',
            source: 2,
            companyName: '',
            billingAddress: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            contactNumber: '',
            firstname: '',
            lastName: '',
          });
          await setAddCustomerModal(false);
        }}
        className='edit-modal'
        aria-labelledby='edit-modal'
        aria-describedby='modal-modal-description'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: { sm: '30px', xs: '15px' },
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.basics.white,
            padding: { md: '24px', xs: '15px' },
            borderRadius: '16px',
            maxWidth: { md: '688px', xs: '90%' },
            width: '100%',
            height: 'auto',
            margin: 'auto',
            outline: 'none',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '24px',
              }}
            >
              <Typography variant='h2'>Add Customer</Typography>
              <Box
                onClick={() => {
                  setAddCustomerModal(false);
                  setAddCustomerData({
                    username: '',
                    proxyusername: '',
                    emailId: '',
                    accountType: '',
                    source: 2,
                    companyName: '',
                    billingAddress: '',
                    country: '',
                    state: '',
                    city: '',
                    zipcode: '',
                    contactNumber: '',
                    firstname: '',
                    lastName: '',
                  });
                }}
                sx={{ cursor: 'pointer' }}
              >
                <CloseIcon />
              </Box>
            </Box>
            <form action=''>
              <Grid
                container
                spacing={'20px'}
                sx={{
                  '& .MuiSelect-select[aria-expanded="true"] ~ svg ': {
                    transform: 'translateY(-50%) rotate(180deg)',
                  },
                  '& svg': {
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%) rotate(0deg)',
                    transition: 'all .5s ease',
                  },
                  '& input, .MuiSelect-select': {
                    fontWeight: '400',
                  },
                }}
              >
                <Grid item md='6' xs='12'>
                  <TextField
                    name='username'
                    id='username'
                    placeholder='Username'
                    value={addcustomerData?.username}
                    onChange={(event) => handleChange('username', event.target.value)}
                  />
                  {formErros?.username && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErros?.username}
                    </Typography>
                  )}
                </Grid>
                <Grid item md='6' xs='12'>
                  <TextField
                    name='proxyusername'
                    id='proxyusername'
                    placeholder='Proxy username'
                    value={addcustomerData?.proxyusername}
                    onChange={(event) => handleChange('proxyusername', event.target.value)}
                  />
                  {formErros?.proxyusername && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErros?.proxyusername}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs='12'>
                  <TextField
                    name='emailId'
                    id='emailId'
                    placeholder='Email ID'
                    value={addcustomerData?.emailId}
                    onChange={(event) => handleChange('emailId', event.target.value)}
                  />
                  {formErros?.emailId && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErros?.emailId}
                    </Typography>
                  )}
                </Grid>
                <Grid item md='6' xs='12'>
                  <CustomStyledSelect
                    name='accountType'
                    id='accountType'
                    placeholder='accountType'
                    value={addcustomerData?.accountType}
                    onChange={(event) => handleChange('accountType', event.target.value)}
                    displayEmpty
                    IconComponent={(props) => (
                      <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                    )}
                  >
                    {addcustomerData?.accountType === '' && (
                      <MenuItem
                        value=''
                        sx={{
                          textTransform: 'capitalize !important',
                          margin: '0 !important',
                        }}
                        disabled
                        onClick={handleDropdownToggle}
                      >
                        <em>Account type</em>
                      </MenuItem>
                    )}
                    {AccountType?.map((item) => (
                      <MenuItem
                        value={item?.id}
                        key={item?.id}
                        className='filter-box'
                        anchorEl={anchorEl}
                        open={isDropdownOpen}
                        onClose={handleClose}
                        anchororigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformorigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{
                          margin: '0 !important',
                        }}
                      >
                        {item?.type}
                      </MenuItem>
                    ))}
                  </CustomStyledSelect>

                  {formErros?.accountType && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErros?.accountType}
                    </Typography>
                  )}
                </Grid>
                <Grid item md='6' xs='12'>
                  <Select
                    disabled
                    name='source'
                    id='source'
                    placeholder='source'
                    value={addcustomerData?.source}
                    onChange={(event) => handleChange('source', event.target.value)}
                    displayEmpty
                  >
                    <MenuItem
                      value={addcustomerData?.source}
                      sx={{
                        textTransform: 'capitalize',
                      }}
                    >
                      <em>Source*</em>
                    </MenuItem>
                    {sourceArray?.map((item) => (
                      <MenuItem value={item?.id} key={item?.id}>
                        {item?.sourceName}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErros?.source && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErros?.source}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs='12'>
                  <PhoneInput
                    country={addcustomerData.country?.toLowerCase() || 'us'}
                    value={addcustomerData.contactNumber}
                    rules={{ required: true }}
                    onChange={(value, country) => {
                      setSelectedCountry(country?.dialCode);
                      handleChange('country', country?.countryCode?.toUpperCase());
                      handleChange('contactNumber', value);
                    }}
                    inputExtraProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                    }}
                    inputStyle={customInputStyle}
                  />
                </Grid>
                {addcustomerData?.accountType === 0 ? (
                  <Grid item xs='12'>
                    <TextField
                      name='companyName'
                      id='companyName'
                      placeholder='Billing Name'
                      value={addcustomerData?.companyName}
                      onChange={(event) => handleChange('companyName', event.target.value)}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xl='6' xs='12'>
                      <TextField
                        name='firstname'
                        id='firstname'
                        placeholder='First Name'
                        value={addcustomerData?.firstname}
                        onChange={(event) => handleChange('firstname', event.target.value)}
                      />
                    </Grid>
                    <Grid item xl='6' xs='12'>
                      <TextField
                        name='lastName'
                        id='lastName'
                        placeholder='Last Name'
                        value={addcustomerData?.lastName}
                        onChange={(event) => handleChange('lastName', event.target.value)}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs='12'>
                  <TextField
                    name='billingAddress'
                    id='billingAddress'
                    placeholder='Billing Address'
                    value={addcustomerData?.billingAddress}
                    onChange={(event) => handleChange('billingAddress', event.target.value)}
                  />
                </Grid>
                <Grid item xl='6' xs='12'>
                  <CustomStyledSelect
                    name='country'
                    id='country'
                    placeholder='country'
                    value={addcustomerData?.country}
                    onChange={(event) => handleChange('country', event.target.value)}
                    displayEmpty
                    IconComponent={(props) => (
                      <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                    )}
                  >
                    {!addcustomerData?.country && (
                      <MenuItem
                        value={addcustomerData?.country}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                        disabled
                        onClick={handleDropdownToggle}
                      >
                        <em>Country</em>
                      </MenuItem>
                    )}

                    {countryData?.map((countryName, index) => (
                      <MenuItem
                        value={countryName?.Code}
                        key={index}
                        className='filter-box'
                        anchorEl={anchorEl}
                        open={isDropdownOpen}
                        onClose={handleClose}
                        anchororigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformorigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        {countryName?.Name}
                      </MenuItem>
                    ))}
                  </CustomStyledSelect>
                </Grid>
                <Grid item xl='6' xs='12'>
                  <CustomStyledSelect
                    name='state'
                    id='state'
                    placeholder='state'
                    value={addcustomerData?.state}
                    onChange={(event) => handleChange('state', event.target.value)}
                    disabled={addcustomerData?.country !== 'US'}
                    className={addcustomerData?.country !== 'US' ? 'disable-option' : 'enalble-option'}
                    displayEmpty
                    IconComponent={(props) => (
                      <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                    )}
                  >
                    {!addcustomerData?.state && (
                      <MenuItem
                        value={addcustomerData?.state}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                        disabled
                        onClick={handleDropdownToggle}
                      >
                        <em>State (If US)</em>
                      </MenuItem>
                    )}

                    {Array.isArray(stateData) ? (
                      stateData.map((stateName, index) => (
                        <MenuItem
                          value={stateName?.isoCode}
                          key={index}
                          className='filter-box'
                          anchorEl={anchorEl}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          anchororigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformorigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          {stateName?.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value='State' disabled>
                        No states available
                      </MenuItem>
                    )}
                  </CustomStyledSelect>
                </Grid>
                <Grid item xl='6' xs='12'>
                  <CustomStyledSelect
                    name='city'
                    id='city'
                    placeholder='city'
                    value={addcustomerData?.city}
                    onChange={(event) => handleChange('city', event.target.value)}
                    displayEmpty
                    IconComponent={(props) => (
                      <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                    )}
                  >
                    {!addcustomerData?.city && (
                      <MenuItem
                        value=''
                        sx={{
                          textTransform: 'capitalize',
                        }}
                        disabled
                        onClick={handleDropdownToggle}
                      >
                        <em>City</em>
                      </MenuItem>
                    )}

                    {Array.isArray(cityData) && cityData.length > 0 ? (
                      cityData?.map((cityName, index) => (
                        <MenuItem
                          value={cityName?.name}
                          key={index}
                          className='filter-box'
                          anchorEl={anchorEl}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          anchororigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformorigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          {cityName?.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value='city' disabled>
                        No city available
                      </MenuItem>
                    )}
                  </CustomStyledSelect>
                </Grid>
                <Grid item xl='6' xs='12'>
                  <TextField
                    name='zipcode'
                    id='zipcode'
                    placeholder='Zipcode'
                    value={addcustomerData?.zipcode}
                    onChange={(event) => handleChange('zipcode', event.target.value)}
                    sx={{ lineHeight: '25px' }}
                  />
                  {formErros?.zipcode && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErros?.zipcode}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs='12'>
                  <Button
                    variant='filled'
                    onClick={handleSubmit}
                    sx={{
                      fontSize: '20px',
                      fontWeight: '700',
                      textTransform: 'capitalize',
                      width: '100%',
                      borderRadius: { md: '8px', xs: '6px' },
                    }}
                  >
                    Create Customer
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
