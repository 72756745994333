import React, { useState } from 'react';
import { Modal, Typography, Box, Button, Grid, TextField } from '@mui/material';
import { colors } from '../../../theme/colors';

const RejectModal = ({
  setRejectModal,
  rejectModdal,
  EditUser,
  handleSubmit,
  loadingUsers,
  errorMessage,
}) => {
  const [message, setMessage] = useState('');

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <Modal
      open={rejectModdal}
      onClose={() => setRejectModal(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      hideBackdrop
      sx={{
        overflow: 'auto',
        display: 'flex',
        backdropFilter: 'blur(0.5px)',
        justifyContent: 'center',
        '& [tabindex="-1"]': {
          height: 'fit-content',
          margin: 'auto',
          borderRadius: '12px',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          padding: '24px',
          width: {
            xs: '250px',
            sm: '320px',
            md: '508px',
            lg: '508px',
            xl: '508px',
          },
          maxHeight: '100%',
          borderRadius: '12px',
          height: '188px',
          bgcolor: 'background.paper',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        }}
      >
        <Box sx={{ padding: '0px 20px 20px 20px', textAlign: 'center' }}>
          <Typography sx={{ fontSize: '20px', color: colors?.neutral?.dark }}>
            Are you sure you want to reject the request?
          </Typography>
        </Box>

        <Grid item lg={6} md={12} sm={6} xs={12} sx={{ margin: '15px' }}>
          <TextField
            id='reason'
            name='reason'
            placeholder='Reason*'
            value={message}
            onChange={handleInputChange}
          />
          <Box sx={{ marginTop: '5px' }}>
            {errorMessage && (
              <Typography variant='caption' sx={{ color: colors.basics.danger, fontSize: '16px' }}>
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Grid>
        <Box sx={{ display: 'flex', padding: '10px', justifyContent: 'space-between', gap: '15px' }}>
          <Button
            variant='#ff4c6a'
            disabled={loadingUsers['REJECT'][EditUser[0]?.p_contact_email] === true}
            sx={{
              backgroundColor: 'gray',
              maxHeight: '100%',
              height: '56px',
              maxWidth: '100%',
              borderRadius: '12px',
              width: '224px',
              fontSize: '20px',
              border: '1px solid gray',
              background: '#FFFFFF',
              color: '#686465',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
            onClick={() => setRejectModal(false)}
          >
            Cancel
          </Button>

          <Button
            variant='#ff4c6a'
            disabled={loadingUsers['REJECT'][EditUser[0]?.p_contact_email] === true}
            sx={{
              backgroundColor: colors?.theme,
              maxHeight: '100%',
              height: '56px',
              borderRadius: '12px',
              maxWidth: '100%',
              width: '224px',
              fontSize: '20px',
              color: '#ffffff',
              fontWeight: 700,
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#ff4c6a',
                color: '#ffffff',
              },
            }}
            onClick={() => {
              handleSubmit(EditUser[0]?.p_contact_email, 'REJECT', message);
            }}
          >
            {loadingUsers['REJECT'][EditUser[0]?.p_contact_email] ? 'Loading...' : 'Reject'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RejectModal;
