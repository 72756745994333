import React, { useState } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';
import { ColorRing } from 'react-loader-spinner';
import PhoneInput from 'react-phone-input-2';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';
import CustomButton from '../../../components/common/customButton';
import { colors } from '../../../theme/colors';
import { updateContactNumberAPI } from '../../../redux/updateContactNumber';
import { SignUpOTPvarificationAPI } from '../../../redux/signUpOtpVerificationAPI';
import { userAPI } from '../../../redux/userProfileAPI';
import { useGetUser } from '../../../context/userProfile';

const CustomerContactNumber = () => {
  const customInputStyle = {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #ccc',
    fontSize: '16px',
    color: '#333',
    height: 'auto',
    minHeight: '56px',
    '& .react-tel-input .country-list': {
      width: '100% !important',
    },
    '& .react-tel-input .flag-dropdown.open': {
      width: '100% !important',
      background: 'red',
    },
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLogInUserDetails, logInUserDetails } = useGetUser();
  const { countryCode, phoneNumberState } = useSelector((state) => state?.registerReducer);
  const accessToken = JSON.parse(sessionStorage.getItem('access_token_proxy_session'));
  const [selectedCountry, setSelectedCountry] = useState(countryCode?.isoCode2?.toLowerCase());
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCode?.countryCodes[0].replace(/-/g, '')
  );
  const [phoneNumber, setPhoneNumber] = useState(logInUserDetails?.p_phoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const phoneNumberSchema = yup
    .string()
    .required('Phone number is required')
    .matches(/^\+?[0-9]+$/, 'Invalid phone number');

  const updatedNumber = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    try {
      await phoneNumberSchema.validate(phoneNumber, { context: { selectedCountry: selectedCountry } });

      const payload = {
        phoneNumber: phoneNumber,
        countryCode: selectedCountryCode,
        id: accessToken?.p_customer_id,
      };

      const data = await dispatch(updateContactNumberAPI(payload));
      if (data?.payload.status === true) {
        toast.success(data?.payload?.message);
        const otpObj = {
          isCheckTime: 0,
        };
        const otpData = await dispatch(SignUpOTPvarificationAPI(otpObj));
        if (otpData?.payload?.data?.status === true) {
          toast.success(otpData?.payload?.data?.message);
          const data = await dispatch(userAPI());
          setLogInUserDetails(data?.payload);
          await navigate('/otpverification');
        } else {
          toast.error(otpData?.payload?.message);
        }
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errorMessage = error.errors[0];
        setPhoneNumberError(errorMessage);
      } else {
        console.error('Unexpected error:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthenticationWrapper>
      <Box className="signup-box">
        <Typography variant="h1" sx={{ color: theme.palette.neutral.dark }}>
          {phoneNumberState === 0 ? ' Add Your Phone Number' : ' Edit Your Phone Number'}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: theme.palette.neutral.normal }}>
          We send a one-time password to your phone number for verification.
        </Typography>
      </Box>
      <form style={{ width: '100%' }} onSubmit={updatedNumber}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              '& .react-tel-input ': {
                display: 'flex',
                alignItems: 'stretch',
                '& .selected-flag': {
                  width: '100%',
                },
                '& .country-list': {
                  width: '100%',
                },
                '& .flag-dropdown': {
                  position: 'unset',
                  order: '1',
                  width: '90px',
                  borderRadius: '8px !important',
                  marginRight: '12px',
                  overflow: 'hidden',
                  transition: 'all .5s ease',
                  backgroundColor: 'transparent',

                  '&.open, &:hover, &:focus-within': {
                    borderColor: colors.theme,
                  },
                },
              },
              '& input.form-control ': {
                order: '2',
                borderRadius: '8px',
                p: '14px',
                transition: 'all .5s ease',
                '&:hover': {
                  borderColor: colors.theme + '!important',
                },
              },
              '& .flag-dropdown': {
                position: 'unset',
                order: '1',
                width: '90px',
                borderRadius: '8px !important',
                marginRight: '12px',
                overflow: 'hidden',
                backgroundColor: 'transparent',
              },

              '& input:focus': {
                borderColor: colors.theme + '!important',
              },
              '& .selected-flag': {
                padding: '0px 0px 0px 25px !important',
              },
            }}
          >
            <PhoneInput
              country={selectedCountry || 'us'}
              value={`+${countryCode?.countryCodes[0] || logInUserDetails?.p_countryCode || ''}${
                logInUserDetails?.p_phoneNumber || phoneNumber
              }`}
              rules={{ required: true }}
              onChange={(value, p_countryName) => {
                setSelectedCountry(p_countryName?.dialCode);
                setSelectedCountryCode(p_countryName?.dialCode);
                setPhoneNumber(value?.substring(p_countryName?.dialCode.length));
                setPhoneNumberError('');
              }}
              inputExtraProps={{
                name: 'phone',
                required: true,
                autoFocus: true,
              }}
              inputStyle={customInputStyle}
            />
            {phoneNumberError && (
              <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                {phoneNumberError}
              </Typography>
            )}{' '}
          </Box>

          <Box sx={{ width: '100%' }}>
            <CustomButton type="submit" disabled={isLoading}>
              {phoneNumberState === 0 ? 'Send' : 'Edit number'}
              {isLoading && (
                <ColorRing
                  visible={true}
                  height='30'
                  width='30'
                  ariaLabel='blocks-loading'
                  wrapperStyle={{}}
                  wrapperClass='blocks-wrapper'
                  colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                />
              )}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </AuthenticationWrapper>
  );
};

export default CustomerContactNumber;
