import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const stateCityAPI = createAsyncThunk('state-cities', async (data) => {
  try {
    const response = await API.get(
      `/authentication/state-cities?countryCode=${data?.countryCode}&statcode=${data?.statcode}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  stateCity: {},
};

const citySlice = createSlice({
  name: 'state-cities',
  initialState,
  reducers: {
    setStateCitySlice: (state, { payload }) => {
      state.stateCity = payload;
    },
  },
});

export const { setStateCitySlice } = citySlice.actions;
export default citySlice.reducer;
