import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const getUserBillingInfoDetails = createAsyncThunk('billing-findByUserId', async (data) => {
  try {
    const response = await API.get(`/billing/findByUserId/${data}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  userBillingDetails: {},
};

const getUserBillingDetailSlice = createSlice({
  name: 'userBillingDetails',
  initialState,
  reducers: {
    setuserBillingDetails: (state, { payload }) => {
      state.userBillingDetails = payload;
    },
  },
});

export const { setuserBillingDetails } = getUserBillingDetailSlice.actions;
export default getUserBillingDetailSlice.reducer;
