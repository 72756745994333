import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const resetPasswordAPI = createAsyncThunk('forgetpassword', async (newPasswordObject) => {
  try {
    const response = await API.post(`/authentication/forget-password?token=${newPasswordObject.token}`, {
      newPasssword: newPasswordObject.newPasssword,
      confirmPassword: newPasswordObject.confirmPassword,
    });

    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  resetPassword: {},
};

const resetPasswordSlice = createSlice({
  name: 'forgetpassword',
  initialState,
  reducers: {
    setResetPassword: (state, { payload }) => {
      state.resetPassword = payload;
    },
  },
});

export const { setResetPassword } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
