import { colors } from '../../../theme/colors';
import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Button, FormControl, Select, MenuItem, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/CaretDown.svg';
import { useSelector } from 'react-redux';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/prism';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import php from 'react-syntax-highlighter/dist/esm/languages/prism/php';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import go from 'react-syntax-highlighter/dist/esm/languages/prism/go';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import clike from 'react-syntax-highlighter/dist/esm/languages/prism/clike';
SyntaxHighlighter.registerLanguage('javascript', jsx);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('go', go);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('clike', clike);

const CustomStyledSelect = styled(Select)(({ isDropdownOpen }) => ({
  '& .MuiSelect-select[aria-expanded="true"] ~ svg ': {
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
  },
  '& .MuiSelect-icon': {
    display: 'block',
  },
  '& svg': {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
    transition: 'all .5s ease',
  },
  '& .MuiSelect-select': {
    padding: '12px 16px',
  },
  '& .MuiSelect-select, .MuiSelect-select  em': {
    fontSize: '16px',
  },
}));

const GeneratorModal = ({ generatorModal, setGeneratorModal, generatorDetail }) => {
  const username = useSelector((state) => state?.userProfileReducer?.userProfileData?.p_login);
  const [generatorData, setGeneratorData] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeType, setCodeType] = useState('bash');
  const [generatedCode, setGeneratedCode] = useState('');

  const LANGUAGE_SYNTAX = [
    { id: 'bash', syntax: 'Curl' },
    { id: 'python', syntax: 'Python' },
    { id: 'php', syntax: 'PHP' },
    { id: 'csharp', syntax: 'C#' },
    { id: 'javascript', syntax: 'Node.js' },
    { id: 'go', syntax: 'Go' },
  ];

  useEffect(() => {
    setErrorMessage('');

    let targetUrl = generatorDetail.targetUrl ? generatorDetail.targetUrl : 'https://ipinfo.io';
    if (targetUrl.startsWith('https://')) {
      // Do nothing, it's already HTTPS
    } else if (targetUrl.startsWith('http://')) {
      targetUrl = targetUrl.replace('http://', 'https://');
    } else {
      targetUrl = 'https://' + targetUrl;
    }

    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    if (!urlPattern.test(targetUrl)) {
      setErrorMessage('Target URL is not valid.');
    }

    const proxyUrl =
      generatorDetail?.productType === 1
        ? 'rotating.prox-e.io'
        : generatorDetail?.productType === 2
        ? 'dc.prox-e.io'
        : generatorDetail?.productType === 3 && 'mob.prox-e.io';

    const proxyPort =
      generatorDetail?.productType === 1
        ? '5100'
        : generatorDetail?.productType === 2
        ? '5040'
        : generatorDetail?.productType === 3 && '5030';

    setGeneratorData({
      proxyUrl: proxyUrl,
      proxyPort: proxyPort,
      country: generatorDetail.country ? generatorDetail.country : 'US',
      targetUrl: targetUrl,
      username: username,
    });
  }, [generatorDetail, username]);

  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleChange = useCallback(
    (value) => {
      setCodeType(value);

      let code = '';
      switch (value) {
        case 'bash':
          code =
            `curl -x '${generatorData?.username}-${generatorData?.country}:` +
            `your_password'@${generatorData?.proxyUrl}:${generatorData?.proxyPort}` +
            ` ${generatorData?.targetUrl}`;
          break;
        case 'python':
          code = `# to use this example you must have 'requests' installed: pip install requests
import requests

username = '${generatorData?.username}-${generatorData?.country}'
password = 'your_password'
server = '${generatorData?.proxyUrl}'
port = '${generatorData?.proxyPort}'
proxy = {
  'http': f'http://{username}:{password}@{server}:{port}',
  'https': f'https://{username}:{password}@{server}:{port}'
}

print(requests.get('${generatorData?.targetUrl}', proxies=proxy).text)`;
          break;
        case 'php':
          code = `function get_file_curl($url,$proxy_host,$proxy_port,$loginpassw)
    {
    $ch = curl_init();
    curl_setopt($ch, CURLOPT_URL, $url);
    curl_setopt($ch, CURLOPT_HEADER, 0);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
    curl_setopt($ch, CURLOPT_PROXYPORT, $proxy_port);
    curl_setopt($ch, CURLOPT_PROXYTYPE, 'HTTP');
    curl_setopt($ch, CURLOPT_PROXY, $proxy_host);
    curl_setopt($ch, CURLOPT_PROXYUSERPWD, $loginpassw);
    curl_setopt($ch, CURLOPT_HTTPPROXYTUNNEL, 1);
    $data = curl_exec($ch);
    curl_close($ch);
    return $data;
    }
var_dump(get_file_curl("${generatorData?.targetUrl}", "${generatorData?.proxyUrl}", "${generatorData?.proxyPort}", "${generatorData?.username}-${generatorData?.country}:your_password"));`;
          break;
        case 'csharp':
          code = `HttpWebRequest myReq = (HttpWebRequest)WebRequest.Create("${generatorData?.targetUrl}");

var proxyURI = new Uri("${generatorData?.proxyUrl}:${generatorData?.proxyPort}");
myReq.Proxy = new WebProxy(proxyURI);
myReq.Proxy.Credentials = new NetworkCredential("${generatorData?.username}-${generatorData?.country}", "your_password");
myReq.Credentials = new NetworkCredential("${generatorData?.username}-${generatorData?.country}", "your_password");
HttpWebResponse myResp = (HttpWebResponse)myReq.GetResponse();`;
          break;
        case 'javascript':
          code = `const https = require("https");

const options = {
  host: "${generatorData?.proxyUrl}",
  port: ${generatorData?.proxyPort},
  path: "${generatorData?.targetUrl}",
  headers: {
    Host: "${generatorData?.targetUrl.replace(/^(https?:\/\/)/, '')}",
    'Proxy-Authorization': 'Basic ' + Buffer.from('${generatorData?.username}-${
            generatorData?.country
          }' + ':' + 'your_password').toString('base64'),
    "Connection": "keep-alive",
    "User-Agent": "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36",
  }
};

https.get(options, function(response) {
  console.log(response.statusCode);
  response.setEncoding('utf8');
  response.on('data', function(chunk) {
    console.log(chunk);
  });
}).on("error", function(e) {
  console.log('error :(', e);
}); `;
          break;
        case 'go':
          code = `package main

import (
  "fmt"
  "io"
  "log"
  "net/http"
  "os"
)

func main() {
  os.Setenv("HTTP_PROXY", "${generatorData?.username}-${generatorData?.country}:your_password@${generatorData?.proxyUrl}:${generatorData?.proxyPort}")
  resp, err := http.Get("${generatorData?.targetUrl}")
  fmt.Println(resp) // Print the response object (useful for debugging)

  if err != nil {
    log.Fatal(err)
  }
  defer resp.Body.Close()

  if resp.StatusCode != http.StatusOK { // Improved error handling
    log.Fatalf("Request failed with status code: %d", resp.StatusCode)
  }

  bodyBytes, err := io.ReadAll(resp.Body) // Use io.ReadAll
  if err != nil {
    log.Fatal(err)
  }
  bodyString := string(bodyBytes)
  fmt.Println(bodyString)
}`;
          break;
        default:
          console.error(` Undefined for ${value}.`);
          break;
      }

      setGeneratedCode(code);
    },
    [generatorData]
  );

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    handleChange(codeType);
  }, [handleChange, codeType]);

  return (
    <Modal
      open={generatorModal}
      onClose={() => setGeneratorModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        '& [tabindex="-1"]': {
          height: 'fit-content',
          margin: 'auto',
          borderRadius: '12px',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          outline: 'none',
          padding: '24px',
        }}
      >
        <Typography variant="h4" sx={{ color: colors?.neutral?.dark }}>
          Proxy Generator
        </Typography>

        {errorMessage ? (
          <Typography sx={{ color: colors.basics.danger }}>{errorMessage}</Typography>
        ) : (
          <>
            <Box
              sx={{
                flexGrow: '1',
                '&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                  height: '48px',
                },
              }}
            >
              <FormControl sx={{ width: '100%' }}>
                <CustomStyledSelect
                  name="codeType"
                  value={codeType}
                  onChange={(e) => handleChange(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  IconComponent={(props) => (
                    <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                  )}
                >
                  {!codeType && (
                    <MenuItem
                      value=""
                      sx={{
                        color: colors.input.placeholder + ' !important',
                      }}
                      disabled
                      onClick={handleDropdownToggle}
                    >
                      <em>Code</em>
                    </MenuItem>
                  )}

                  {LANGUAGE_SYNTAX.map((language) => (
                    <MenuItem
                      value={language.id}
                      key={language.id}
                      className="filter-box"
                      anchorel={anchorEl}
                      open={isDropdownOpen}
                      onClose={handleClose}
                      anchororigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformorigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      {language.syntax}
                    </MenuItem>
                  ))}
                </CustomStyledSelect>
              </FormControl>
            </Box>

            <SyntaxHighlighter language={codeType} style={prism}>
              {generatedCode}
            </SyntaxHighlighter>

            <Box
              sx={{
                display: 'flex',
                padding: '0px 20px 20px 20px',
                justifyContent: 'space-between',
                gap: '15px',
              }}
            >
              <Button
                variant="#ff4c6a"
                sx={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  borderRadius: '12px',
                  border: '1px solid gray',
                  background: '#FFFFFF',
                  color: '#686465',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  width: '224px',
                  height: '56px',
                  fontSize: { xs: '20px' },
                  fontWeight: 700,
                  textTransform: 'capitalize',
                }}
                onClick={() => setGeneratorModal(false)}
              >
                Close
              </Button>

              <Button
                variant="#ff4c6a"
                sx={{
                  backgroundColor: colors?.theme,
                  maxHeight: '100%',
                  maxWidth: '100%',
                  width: '224px',
                  height: '56px',
                  borderRadius: '12px',
                  color: '#ffffff',
                  fontWeight: 700,
                  fontSize: { xs: '20px' },
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#ff4c6a',
                    color: '#ffffff',
                  },
                }}
                onClick={() => {
                  navigator.clipboard.writeText(generatedCode);
                }}
              >
                Copy to Clipboard
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default GeneratorModal;
