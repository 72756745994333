import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { colors } from '../../../theme/colors';
import { useTheme } from '@emotion/react';
import UserDetails from './userDetails';
import KycDetail from './kycDetail';
import AccountType from './accountType';

const ReviewData = () => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant='h2' sx={{ mb: 3, color: colors?.neutral.dark, ml: 1 }}>
        Review Data
      </Typography>
      <Box
        className='kyc-updation-form'
        sx={{
          backgroundColor: theme.palette.neutral.background,
          padding: '24px',
          borderRadius: '16px',
          width: '80%',
        }}
      >
        <Grid container spacing={'24px'}>
          <Grid item lg={6} xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <UserDetails />
              <KycDetail />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <AccountType />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReviewData;
