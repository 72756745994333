import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getPlanListAPI = createAsyncThunk('plans-list', async (data) => {
  try {
    const response = await API.get(
      `/plans/list?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  planList: {},
};

const planlistSlice = createSlice({
  name: 'plans-list',
  initialState,
  reducers: {
    setPlanListDetails: (state, { payload }) => {
      state.planList = payload;
    },
  },
});

export const { setPlanListDetails } = planlistSlice.actions;
export default planlistSlice.reducer;
