import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getUserAcitivityAPI = createAsyncThunk('activity-usage', async (data) => {
  try {
    const response = await API.get(
      `users/signinup/activity?page=${data?.page}&limit=${data?.limit}&search=${data?.search}&fromDate=${data?.fromDate}&toDate=${data?.toDate}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  userActivity: {},
};

const getUserAcitivitySlice = createSlice({
  name: 'activity-usage',
  initialState,
  reducers: {
    setUserActivityDetails: (state, { payload }) => {
      state.userActivity = payload;
    },
  },
});

export const { setUserActivityDetails } = getUserAcitivitySlice.actions;
export default getUserAcitivitySlice.reducer;
