import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getPaymentListAPI = createAsyncThunk('payment-list', async (data) => {
  try {
    const response = await API.get(
      `/payment?page=${data?.page}&limit=${data?.limit}&customer=${data?.customer}&owner=${data?.owner}&productType=${data?.productType}&customerSource=${data?.customerSource}&search=${data?.search}&filterDateRange=${data?.filterDateRange}&fromDate=${data?.fromDate}&toDate=${data?.toDate}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  paymentList: {},
};

const getPaymentListSlice = createSlice({
  name: 'payment-list',
  initialState,
  reducers: {
    setPaymentList: (state, { payload }) => {
      state.paymentList = payload;
    },
  },
});

export const { setPaymentList } = getPaymentListSlice.actions;
export default getPaymentListSlice.reducer;
