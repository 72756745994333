import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const dashBoardChartAPI = createAsyncThunk('dashboard', async (data) => {
  try {
    const response = await API.get(`/dashboard?customer=${data?.customer}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  dashboardChart: {},
};

const dashBoardChartSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashBoardChart: (state, { payload }) => {
      state.dashboardChart = payload;
    },
  },
});

export const { setDashBoardChart } = dashBoardChartSlice.actions;
export default dashBoardChartSlice.reducer;
