import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import Calendar from '../../../assets/images/Calendar.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { colors } from '../../../theme/colors';
import { toast } from 'react-toastify';
import { formatMonth } from '../../../components/common/dateFormat';
import { updateCustomerPlanStatus } from '../../../redux/subscriptionAPI';
import { useDispatch } from 'react-redux';
import { ColorRing } from 'react-loader-spinner';

const validationSchema = Yup.object().shape({
  startDate: Yup.string().required('Start date is required'),
  endDate: Yup.string().required('End date is required'),
  status: Yup.number().min(1, 'Please Select Status'),
});

const EditSubscriptionModal = ({ isOpen, onClose, subscriptionData }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const res = await dispatch(
        updateCustomerPlanStatus({
          customerPlanId: subscriptionData.p_customers_plan_id,
          data: {
            ...values,
            customerId: subscriptionData.customerId,
            productId: subscriptionData.productId,
            startDate: formatMonth(values.startDate),
            endDate: formatMonth(values.endDate),
          },
        })
      );
      if (res) {
        if (res.payload.status === false) {
          toast.error(res.payload.message);
        } else if (res.payload.data.status === true) {
          toast.success(res.payload.data.message);
        }
        setLoading(false);
        onClose();
      }
    } catch (err) {
      setLoading(false);
      toast.error('Something went wrong!');
    }
  };

  const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    '& .react-datepicker-wrapper': {
      width: '100%',
      '& svg': {
        display: 'none',
      },
      '& input': {
        padding: '15px 14px',
        paddingRight: '46px',
        height: '21px',
        fontSize: '18px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: '180px',
        },
        [theme.breakpoints.up('xs')]: {
          maxWidth: '-webkit-fill-available',
        },
        borderRadius: '6px',
        color: '#686465',
        border: `1px solid ${theme.palette.neutral.lighter}`,
        backgroundColor: colors.basics.white,
        backgroundImage: `url("${Calendar}")`,
        backgroundPosition: 'right 14px center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '26px 26px',
        transition: 'all .5s ease',
        '&:hover, &:focus-visible': {
          borderColor: colors.theme,
          outline: 'none',
        },
      },
    },
  }));

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      startDate: new Date(subscriptionData.startDate),
      endDate: new Date(subscriptionData.endDate),
      status: +subscriptionData.p_active,
    },
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        className="add-plan-modal"
        aria-labelledby="add-plan-modal"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: { sm: '30px', xs: '15px' },
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.basics.white,
            padding: { md: '24px', xs: '15px' },
            borderRadius: '16px',
            maxWidth: { md: '688px', xs: '90%' },
            width: '100%',
            height: 'auto',
            margin: 'auto',
            outline: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}
          >
            <Typography variant="h2" color={colors?.neutral?.dark}>
              Edit Plan
            </Typography>
            <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
              <CloseIcon />
            </Box>
          </Box>
          <form action="" onSubmit={handleSubmit}>
            <Grid container spacing={'24px'}>
              <Grid item xs={12}>
                <Box className="table-body" sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '10px',
                    }}
                  >
                    <StyledBox
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <FormLabel id="status">Start Date</FormLabel>
                      <DatePicker
                        showIcon
                        placeholderText="Start Date"
                        selected={values.startDate}
                        name={'startDate'}
                        onChange={(date) => setFieldValue('startDate', date)}
                        dateFormat="dd-MM-yyyy"
                      />
                      {touched.startDate && errors.startDate ? (
                        <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                          {errors.startDate}
                        </Typography>
                      ) : null}
                    </StyledBox>
                    <StyledBox
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <FormLabel id="status">End Date</FormLabel>
                      <DatePicker
                        showIcon
                        selected={values.endDate}
                        placeholderText="End Date"
                        onChange={(date) => {
                          if (date >= values.startDate) {
                            setFieldValue('endDate', date);
                          } else {
                            toast.error('Error: End date cannot be less than start date');
                          }
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                      {touched.endDate && errors.endDate ? (
                        <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                          {errors.endDate}
                        </Typography>
                      ) : null}
                    </StyledBox>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '10px',
                    }}
                  >
                    <FormControl fullWidth>
                      <FormLabel id="status">Status</FormLabel>
                      <RadioGroup
                        aria-labelledby="status"
                        defaultValue={'0'}
                        defaultChecked={values.status}
                        value={values.status}
                        onChange={(e) => setFieldValue('status', e.target.value)}
                        name="status"
                        row
                      >
                        <FormControlLabel value="1" control={<Radio />} label="Active" />
                        <FormControlLabel value="2" control={<Radio />} label="In-Active" />
                        <FormControlLabel value="3" control={<Radio />} label="Stand By" />
                      </RadioGroup>
                      {touched.status && errors.status ? (
                        <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                          {errors.status}
                        </Typography>
                      ) : null}
                    </FormControl>
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="filled"
                      disabled={loading}
                      sx={{
                        width: '100%',
                        fontSize: { xl: '20px' },
                        textTransform: 'capitalize',
                      }}
                    >
                      Save
                      {loading && (
                        <ColorRing
                          visible={true}
                          height="30"
                          width="30"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                        />
                      )}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default EditSubscriptionModal;
