import React, { useEffect, useState } from 'react';
import { colors } from '../../../theme/colors';
import { Box, Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import DocumentModal from './documentModal';

const AccountType = () => {
  const kycSelector = useSelector((state) => state);
  const accountData = kycSelector?.kycDetailGetReducer?.kycDetail?.payload?.data;
  const [isAccountData, setIsAccountData] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalFile, setModalFile] = useState(null);

  const handleFileClick = (file) => {
    setModalFile(file);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalFile(null);
    setModalOpen(false);
  };

  useEffect(() => {
    setIsAccountData(accountData);
    setLoading(false);
  }, [accountData]);

  return (
    <Box
      className='kyc-updation-form'
      sx={{
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '12px',
      }}
      onClose={closeModal}
    >
      {loading ? (
        <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
          <ThreeDots
            visible={true}
            height='80'
            width='80'
            color={colors.theme}
            radius='9'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClass=''
          />
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              color: colors?.useractivitylabelcolor,
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            Account Details
          </Typography>
          <Box borderBottom='1px solid #E5E5E5' sx={{ paddingTop: '15px' }}></Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { lg: 'row', sm: 'column', xs: 'column' },
              marginTop: '16px',
            }}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Account type
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {isAccountData?.kycDetails?.p_accountType === 1
                      ? 'Freelancer'
                      : isAccountData?.kycDetails?.p_accountType === 0
                      ? 'Corporate'
                      : isAccountData?.kycDetails?.p_accountType === null
                      ? 'None'
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Name
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {isAccountData?.kycDetails?.p_firstName
                      ? isAccountData?.kycDetails?.p_firstName + ' ' + isAccountData?.kycDetails?.p_lastName
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { lg: 'row', sm: 'column', xs: 'column' },
              marginTop: '16px',
            }}
          >
            {isAccountData?.kycDetails?.p_accountType === 0 && (
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Box item sx={{ width: 'fit-content' }}>
                    <Typography
                      sx={{
                        color: colors?.useractivitylabelcolor,
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Company name
                    </Typography>
                    <Typography
                      sx={{
                        color: colors?.detailcolor,
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: 400,
                      }}
                    >
                      {isAccountData?.kycDetails?.p_companyName
                        ? isAccountData?.kycDetails?.p_companyName
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box item sx={{ width: 'fit-content' }}>
                  <Typography
                    sx={{
                      color: colors?.useractivitylabelcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    {isAccountData?.kycDetails?.p_accountType === 1 ? 'Website' : 'Company’s website'}
                  </Typography>
                  <Typography
                    sx={{
                      color: colors?.detailcolor,
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={
                        isAccountData?.kycDetails?.p_website
                          ? isAccountData?.kycDetails?.p_website.startsWith('http')
                            ? isAccountData?.kycDetails?.p_website
                            : 'http://' + isAccountData?.kycDetails?.p_website
                          : '-'
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      alt='not found'
                      className='website-display'
                    >
                      {isAccountData?.kycDetails?.p_website ? isAccountData?.kycDetails?.p_website : '-'}
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          {isAccountData?.kycDetails?.p_accountType === 1 ? (
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { lg: 'row', sm: 'column', xs: 'column' },
                  marginTop: '16px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Box item sx={{ width: 'fit-content' }}>
                    <Typography
                      sx={{
                        color: colors?.useractivitylabelcolor,
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      KYC document pdf or image
                    </Typography>
                    <Typography
                      sx={{
                        color: colors?.detailcolor,
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: 400,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gap: '16px 16px',
                          padding: '20px 0',
                        }}
                      >
                        {isAccountData?.kycDetails?.localFiles?.length > 0 ? (
                          isAccountData.kycDetails.localFiles.map((file, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                padding: '16px 12px',
                                border: `1px solid ${colors.input.border}`,
                                borderRadius: 2,
                                backgroundColor: colors.basics.white,
                                cursor: 'pointer',
                              }}
                              onClick={() => handleFileClick(file)}
                            >
                              <Box
                                sx={{
                                  '& svg': {
                                    flexShrink: '0',
                                    width: '28px',
                                    height: 'auto',
                                  },
                                }}
                              ></Box>
                              <Box sx={{ paddingLeft: '12px' }}>
                                <Typography
                                  variant='body2'
                                  sx={{
                                    mr: '8px',
                                    fontWeight: '400',
                                    color: colors.basics.normal,
                                    wordBreak: 'break-word',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  <Typography
                                    variant='caption'
                                    fontSize='inherit'
                                    sx={{
                                      display: '-webkit-box',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 1,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {file.p_filename}
                                  </Typography>
                                  <Typography
                                    variant='caption'
                                    fontSize='inherit'
                                    flexShrink={0}
                                  ></Typography>
                                </Typography>

                                <Typography
                                  variant='body2'
                                  sx={{
                                    mr: '8px',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    color: colors.input.placeholder,
                                  }}
                                  title={file.p_size + 'Byte'}
                                >
                                  {(file.p_size / (1024 * 1024)).toFixed(2)}mb
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  cursor: 'pointer',
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  transform: 'translate(50%, -50%)',
                                  '& svg': {
                                    width: '24px',
                                    height: 'auto',
                                  },
                                }}
                              ></Box>
                            </Box>
                          ))
                        ) : (
                          <Typography variant='body1'>File not found</Typography>
                        )}
                        <DocumentModal closeModal={closeModal} modalOpen={modalOpen} modalFile={modalFile} />
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ) : (
            ''
          )}
        </Box>
      )}
    </Box>
  );
};

export default AccountType;
