import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../../layout/layout';

const ProtectedRoute = ({ children }) => {
  const { signUpUser } = useSelector((state) => state?.registerReducer);
  return signUpUser?.token ? <Layout>{children}</Layout> : <Navigate to='/login' replace />;
};

export default ProtectedRoute;
