import React, { useState } from 'react';
import * as yup from 'yup';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { colors } from '../../../theme/colors';
import { ReactComponent as WaringIcon } from '../../../assets/images/WarningCircle.svg';
import CustomButton from '../../../components/common/customButton';
import CustomTextBox from '../../../components/common/customTextBox';
import 'react-phone-input-2/lib/style.css';
import changePasswordAPI from '../../../redux/changePasswordAPI';
import { ColorRing } from "react-loader-spinner";

const ChangePassword = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fornErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({
    currentpassword: '',
    newpassword: '',
    confirempassword: '',
  });

  const validationSchema = yup.object().shape({
    currentpassword: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Current password is required'),
    newpassword: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?!.*[@#])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$!%*?&])[A-Za-z\d$!%*?&]+$/,
        'New password must be at least 8 characters with at least one uppercase, lowercase, numeric, and special character. # and @ are not allowed'
      )
      .required('New password is required'),
    confirempassword: yup
      .string()
      .required('Confirm new password is required')
      .oneOf([yup.ref('newpassword'), ''], 'Confirm new password must match with new password'),
  });

  const hadleSubmit = async (event) => {
    try {
      event?.preventDefault();
      setFormErrors('');
      await validationSchema.validate(changePasswordData, {
        abortEarly: false,
      });
      const changepasswordObject = {
        currentPassword: changePasswordData?.currentpassword,
        newPasssword: changePasswordData?.newpassword,
        confirmPassword: changePasswordData?.confirempassword,
      };
      const payload = await dispatch(changePasswordAPI(changepasswordObject));
      if (payload?.payload?.status === true) {
        toast.success(payload?.payload?.message);
        setChangePasswordData({
          currentpassword: '',
          newpassword: '',
          confirempassword: '',
        });
      } else if (payload?.payload?.response?.data?.message === 'Unauthorized') {
        navigate('/login');
      } else {
        toast.error(payload?.payload?.message);
      }
    } catch (err) {
      const errors = {};
      err.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFormErrors(errors);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h2" sx={{ mb: 3, color: colors?.neutral.dark, ml: 1 }}>
          Change password
        </Typography>
        <Grid container spacing={'24px'}>
          <Grid item lg={7} xs={12}>
            <Box className="table-body">
              <Typography
                variant="h6"
                sx={{
                  mb: '20px',
                  color: colors?.basics?.normal,
                  fontWeight: 500,
                  fontSize: '24px',
                }}
              >
                Create a new password for your account.
              </Typography>
              <Grid container spacing={'20px'}>
                <Grid item xs={12}>
                  <Box sx={{ width: '100%', marginBottom: '20px' }}>
                    <CustomTextBox
                      placeholder="Enter current password"
                      type="password"
                      iconType={'password'}
                      value={changePasswordData?.currentpassword}
                      onChange={(e) => {
                        setChangePasswordData({
                          ...changePasswordData,
                          currentpassword: e.target.value,
                        });
                        setFormErrors({ ...fornErrors, currentpassword: '' });
                      }}
                    />
                    {fornErrors?.currentpassword && (
                      <Typography
                        sx={{
                          color: theme?.palette?.danger,
                          width: '100%',
                          marginTop: '5px',
                        }}
                      >
                        {fornErrors?.currentpassword}
                      </Typography>
                    )}{' '}
                  </Box>
                  <Box sx={{ width: '100%', marginBottom: '20px' }}>
                    <CustomTextBox
                      placeholder="New password"
                      type="password"
                      iconType={'password'}
                      value={changePasswordData?.newpassword}
                      onChange={(e) => {
                        setChangePasswordData({
                          ...changePasswordData,
                          newpassword: e.target.value,
                        });
                        setFormErrors({ ...fornErrors, newpassword: '' });
                      }}
                    />
                    {fornErrors?.newpassword && (
                      <Typography
                        sx={{
                          color: theme?.palette?.danger,
                          width: '100%',
                          marginTop: '5px',
                        }}
                      >
                        {fornErrors?.newpassword}
                      </Typography>
                    )}{' '}
                  </Box>
                  <Box sx={{ width: '100%', marginBottom: '20px' }}>
                    <CustomTextBox
                      placeholder="Confirm new password"
                      type="password"
                      iconType={'password'}
                      value={changePasswordData?.confirempassword}
                      onChange={(e) => {
                        setChangePasswordData({
                          ...changePasswordData,
                          confirempassword: e.target.value,
                        });
                        setFormErrors({ ...fornErrors, confirempassword: '' });
                      }}
                    />
                    {fornErrors?.confirempassword && (
                      <Typography
                        sx={{
                          color: theme?.palette?.danger,
                          width: '100%',
                          marginTop: '5px',
                        }}
                      >
                        {fornErrors?.confirempassword}
                      </Typography>
                    )}{' '}
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: '10px',
                        color: colors?.neutral?.normal,
                        '& svg': { flexShrink: '0' },
                      }}
                    >
                      <WaringIcon />
                      <Typography
                        sx={{
                          marginLeft: '10px',
                          color: colors.basics?.normal,
                        }}
                      >
                        Password must be at least 8 characters with at least one uppercase, lowercase,
                        numeric, and special character.
                      </Typography>
                    </Box>
                  </Box>
                  <Grid item xs={12}>
                    <Box sx={{ width: { sm: '320px', xs: '100%' } }}>
                      <CustomButton type="onclick" onClick={hadleSubmit} disabled={isLoading}>
                        {' '}
                        Save
                        {isLoading && (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={[
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                            ]}
                          />
                        )}
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChangePassword;
