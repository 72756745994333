import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { colors } from '../../../theme/colors';

const RecentPaymentsRow = ({ item, index }) => {
  return (
    <TableRow
      key={index}
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        '& td': {
          padding: '10px 20px 10px 20',
          whiteSpace: 'nowrap',
        },
        '& a': {
          border: `1px solid ${colors.theme}`,
          padding: '7px',
          display: 'inline-flex',
          verticalAlign: 'middle',
          width: 'fit-content',
          alignItems: 'center',
          minWidth: 'auto',
          borderRadius: '4px',
          transition: 'all .5s ease',
          '& svg path': {
            transition: 'all .5s ease',
          },
          '&:hover': {
            backgroundColor: colors.theme,
          },
          '&:hover svg path': {
            fill: '#fff',
          },
        },
        padding: '20px',
      }}
    >
      <TableCell className='recent-table'>{item?.userName ? item?.userName : '-'}</TableCell>
      <TableCell className='recent-table-tr'>{item?.paymentStatus}</TableCell>
      <TableCell className='recent-table-tr'>{item?.paymentAmount}</TableCell>
      <TableCell className='recent-table-tr'>{item?.volume}</TableCell>
    </TableRow>
  );
};

export default RecentPaymentsRow;
