import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { colors } from '../../../theme/colors';
import Pagination from '../../../components/common/Pagination';

const SpecificCustomerPlanDetails = ({
  CustomerDetailsData,
  sortConfig,
  tableRef,
  tableHeading,
  sortData,
  isLoading,
  viewUserDetails,
  totalCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  logInUserDetails,
}) => {
  const [customerData, setCustomerDetailsData] = useState([]);

  useEffect(() => {
    setCustomerDetailsData(CustomerDetailsData);
  }, [CustomerDetailsData]);

  return (
    <Box id='printSection' className='print-section'>
      <TableContainer
        component={Paper}
        sx={{ width: '100% !important', boxShadow: 'none', borderRadius: '12px' }}
        ref={tableRef}
      >
        <Table
          aria-label='simple table'
          sx={{
            width: '100%',
            '& th, td': {
              padding: '22px 22px',
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                ' & th': {
                  fontWeight: '500',
                  fontSize: '18px',
                  whiteSpace: 'nowrap',
                  color: colors.neutral.dark,
                  position: 'relative',
                  cursor: 'pointer',
                  '& .sort-arrow': {
                    position: 'relative',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                },
              }}
            >
              {tableHeading.map((item) => (
                <TableCell key={item.sortName}>{item.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableBody>
              <TableRow sx={{ textAlign: 'center' }}>
                <TableCell colSpan={8}>
                  <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                    <ThreeDots
                      visible={true}
                      height='80'
                      width='80'
                      color={colors.theme}
                      radius='9'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClass=''
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {customerData?.length > 0 ? (
                customerData?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                      '& td': {
                        padding: '12px 20px',
                        whiteSpace: 'nowrap',
                      },
                      '& button': {
                        minWidth: 'unset',
                        padding: ' 8px',
                        '&:hover svg path': {
                          fill: '#fff',
                        },
                      },
                    }}
                  >
                    <TableCell className='recent-table-tr' sx={{ textTransform: 'capitalize' }}>
                      {item?.p_name}
                    </TableCell>
                    <TableCell className='recent-table-tr'>{item?.productType?.p_name}</TableCell>
                    <TableCell className='recent-table-tr'>
                      {item?.p_chargesType === 1
                        ? 'Bandwidth'
                        : item?.p_chargesType === 2
                        ? 'Requests'
                        : item?.p_chargesType === 3
                        ? 'OneTime'
                        : ''}
                    </TableCell>
                    <TableCell className='recent-table-tr'>{item?.p_volume} GB</TableCell>

                    <TableCell className='recent-table-tr'>{item?.p_unitPrice}</TableCell>
                    <TableCell className='recent-table-tr'>{item?.p_duration}</TableCell>
                    <TableCell className='recent-table-tr'>{item?.p_price}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ width: '100%', height: '100%' }}>
                  <TableCell colSpan={16} sx={{ textAlign: 'center', border: '0' }}>
                    <img src={DataNotFound} alt='data not found' style={{ width: '80px', height: '80px' }} />
                    <Typography>Data not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        />
      </Box>
    </Box>
  );
};

export default SpecificCustomerPlanDetails;
