import React, { useEffect, useState } from 'react';
import { Table, Paper, TableHead, TableRow, TableContainer, TableBody, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DataNotFound from '../../../assets/images/No Data Found.png';
import { formatDate } from '../../../components/common/dateFormat';
import { colors } from '../../../theme/colors';
import { ThreeDots } from 'react-loader-spinner';
import Pagination from '../../../components/common/Pagination';

const PaymentTable = ({
  columnName,
  isLoading,
  tableRef,
  handleChangeRowsPerPage,
  paymentOptionData,
  totalCount: totalCount,
  rowsPerPage,
  handleChangePage,
  page,
}) => {
  const [paymentData, setPaymentData] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);

  useEffect(() => {
    setPaymentData(paymentOptionData?.billing?.payments);
  }, [paymentOptionData?.billing?.payments]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: '#242323',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: '#686465',
      fontWeight: 400,
    },
  }));

  const handlePointerDown = (event) => {
    setIsMouseDown(true);
    setStartX(event.clientX || (event.touches && event.touches[0]?.clientX));
    document.body.style.cursor = 'grabbing';
  };

  const handlePointerMove = (event) => {
    if (isMouseDown && tableRef.current) {
      const scrollX = startX - (event.clientX || (event.touches && event.touches[0]?.clientX));
      tableRef.current.scrollLeft += scrollX;
      setStartX(event.clientX || (event.touches && event.touches[0]?.clientX));
    }
  };

  const handlePointerUp = () => {
    setIsMouseDown(false);
    document.body.style.cursor = 'default';
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: '100% !important',
          boxShadow: 'none',
          borderRadius: '12px',
          cursor: 'grab',
          overflowX: 'auto',

          touchAction: 'none',
        }}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onTouchStart={handlePointerDown}
        onTouchMove={handlePointerMove}
        onTouchEnd={handlePointerUp}
        ref={tableRef}
      >
        <Table
          aria-label='simple table'
          sx={{
            width: '100%',
            '& th, td': {
              padding: '22px 22px',
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ '& th': { whiteSpace: 'nowrap' } }}>
              {columnName?.map((item, index) => (
                <StyledTableCell align='start' sx={{ fontSize: '18px' }} key={index}>
                  {item?.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow sx={{ textAlign: 'center' }}>
                <TableCell colSpan={12}>
                  <Box sx={{ textAlign: 'center', '& > div': { justifyContent: 'center' } }}>
                    <ThreeDots
                      visible={true}
                      height='80'
                      width='80'
                      color={colors.theme}
                      radius='9'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClass=''
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody
              sx={{
                '& tr:last-child td': {
                  borderBottom: 0,
                },
                color: colors?.basics?.normal,
              }}
            >
              {paymentData?.length > 0 ? (
                paymentData?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                      '& td': {
                        padding: '10px 20px 10px 20 !important',
                        whiteSpace: 'nowrap',
                      },
                      '& button': {
                        minWidth: 'unset',
                        padding: ' 8px',
                        '&:hover svg path': {
                          fill: '#fff',
                        },
                      },
                    }}
                  >
                    <StyledTableCell>{formatDate(row?.startDate)}</StyledTableCell>
                    <StyledTableCell>
                      <Typography className='recent-table'>{row?.userName} </Typography>
                    </StyledTableCell>
                    <StyledTableCell>{row?.OwnerName ? row?.OwnerName : '-'}</StyledTableCell>
                    <StyledTableCell>{row?.customerSource}</StyledTableCell>
                    <StyledTableCell>{row?.paymentStatus}</StyledTableCell>
                    <StyledTableCell>{row?.paymentAmount}</StyledTableCell>
                    <StyledTableCell>{row?.price}</StyledTableCell>
                    <StyledTableCell>{row?.tax ? row?.tax : 50}</StyledTableCell>
                    <StyledTableCell>{row?.transactionID ? row?.transactionID : '-'}</StyledTableCell>
                    <StyledTableCell>{row?.paymentMethod}</StyledTableCell>
                    <StyledTableCell>{row?.volume}</StyledTableCell>
                    <StyledTableCell>{formatDate(row?.startDate)}</StyledTableCell>
                    <StyledTableCell>{formatDate(row?.endDate)}</StyledTableCell>
                    <StyledTableCell>{row?.productType}</StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ width: '100%', height: '100%' }}>
                  <TableCell colSpan={12} sx={{ textAlign: 'center', border: '0' }}>
                    <img src={DataNotFound} alt='data not found' style={{ width: '80px', height: '80px' }} />
                    <Typography>Data not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        />
      </Box>
    </>
  );
};

export default PaymentTable;
