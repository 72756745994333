import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Grid, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { colors } from '../../../theme/colors';
import styled from '@emotion/styled';
import { countryStateAPI } from '../../../redux/stateAPI';
import { stateCityAPI } from '../../../redux/cityAPI';
import { countryCityAPI } from '../../../redux/countryToCityAPI';
import { getEditCustomerDetailAPI } from '../../../redux/getEditCustomerDetailAPI';
import { getOwnerAPI } from '../../../redux/getOwnerAPI';
import { ThreeDots } from 'react-loader-spinner';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/dropdownDownArrow.svg';
import { editCustomerDetailAPI } from '../../../redux/editCustomerDetails';
import EditConfirmationModal from './editConfirmationModal';
import { CountryListOptions } from '../../../components/common/country';

const EditForm = ({ id, setEditConfirmModal, editConfirmModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [customerID, setCustomerId] = useState();
  const [customerData, setCustomerData] = useState({
    accountType: '',
    source: '',
    owner: '',
    country: '',
    city: '',
    state: '',
    username: '',
    proxyName: '',
    emailId: '',
    billingName: '',
    billingAddress: '',
    zipCode: '',
    activeStatus: '',
    autorenewal: '',
    kycApproved: '',
    p_is3DSEnabled: '',
    firstName: '',
    lastName: '',
  });

  const SwitchStyle = {
    '&.MuiSwitch-root ': {
      borderRadius: '50px',
      height: { sm: '30px', xs: '25px' },
      width: { sm: '58px', xs: '50px' },
      padding: '0',
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: { sm: 'translateX(28px)', xs: 'translateX(25px)' },
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: colors.basics.white,
      borderRadius: '50px',
      border: `1px solid ${colors.input.border}`,
      height: { sm: '28px', xs: '23px' },
    },
    '& .Mui-checked+.MuiSwitch-track': {
      backgroundColor: `${colors.theme} !important`,
      opacity: '1 !important',
      borderColor: colors.theme,
    },
    '& .MuiSwitch-thumb ': {
      backgroundColor: colors.input.border,
      boxShadow: 'none',
      width: { sm: '20px', xs: '15px' },
      height: { sm: '20px', xs: '15px' },
    },
    '& .MuiSwitch-switchBase': {
      padding: '5px',
      '&.Mui-checked': {
        '& .MuiSwitch-thumb ': {
          backgroundColor: colors.basics.white,
        },
      },
    },
  };

  const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
    '& .MuiSelect-select[aria-expanded="true"] ~ svg': {
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'}) !important`,
    },
    '& .MuiSelect-icon': {
      display: 'block !important',
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: `translateY(-50%) rotate(${isDropdownOpen ? '180deg' : '0deg'}) !important`,
      transition: 'all .5s ease',
      cursor: 'pointer',
    },
  }));
  const AccountType = [
    {
      id: 0,
      type: 'Company',
    },
    {
      id: 1,
      type: 'Freelancers',
    },
  ];

  const sourceArray = [
    {
      id: 1,
      sourceName: 'Inbound',
    },
    {
      id: 2,
      sourceName: 'Outbound',
    },
  ];

  useEffect(() => {
    const sortedCountries = CountryListOptions?.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setCountryData(sortedCountries);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getEditCustomer = async () => {
      const customerObj = {
        customerId: id,
      };
      const payload = await dispatch(getEditCustomerDetailAPI(customerObj));
      if (payload?.payload?.status === true) {
        const editData = payload?.payload?.data[0];
        setCustomerId(editData);
        setCustomerData({
          accountType: editData?.p_accountType === null ? '' : editData?.p_accountType,
          source: editData?.p_customerSource === '0' ? '' : editData?.p_customerSource,
          owner: editData?.p_ownerId ? editData?.p_ownerId : '',
          country: editData?.p_country ? editData?.p_country : '',
          city: editData?.p_city ? editData?.p_city : '',
          state: editData?.p_state ? editData?.p_state : '',
          username: editData?.p_login,
          proxyName: editData?.p_customer_name,
          emailId: editData?.p_contact_email,
          billingName: editData?.p_companyName,
          billingAddress: editData?.p_address,
          zipCode: editData?.p_zipCode,
          activeStatus: editData?.p_isActive,
          autorenewal: editData?.p_autoRenew,
          kycApproved: editData?.p_isAccepted,
          twoFaPortal: editData?.p_isTwoFactorAuthenticationEnabled,
          firstName: editData?.p_firstName,
          lastName: editData?.p_lastName,
          p_is3DSEnabled: editData?.p_is3DSEnabled,
        });

        setIsLoading(false);
      } else {
        toast.error(payload?.payload?.message);
        setIsLoading(false);
      }
    };
    getEditCustomer();
  }, [dispatch, id]);

  useEffect(() => {
    const getOwner = async () => {
      const payload = await dispatch(getOwnerAPI());
      if (payload?.payload?.status) {
        setOwnerData(payload?.payload?.data);
      }
    };
    getOwner();
  }, [dispatch]);

  useEffect(() => {
    const getState = async () => {
      if (customerData?.country) {
        const countryCode = {
          countryCode: customerData?.country,
        };
        const data = await dispatch(countryStateAPI(countryCode));
        setStateData(data?.payload);
      } else {
        console.error('');
      }
    };
    getState();
  }, [customerData?.country, dispatch]);

  useEffect(() => {
    const getCity = async () => {
      if (customerData?.country === 'US' && customerData?.state) {
        const cityCode = {
          countryCode: customerData?.country,
          statcode: customerData?.state,
        };
        const data = await dispatch(stateCityAPI(cityCode));

        if (data?.payload) {
          setCityData(data?.payload);
        } else {
          toast.error('error');
        }
      } else if (customerData?.country !== 'US' && customerData?.country) {
        const cityCode = {
          countryCode: customerData?.country,
        };
        const data = await dispatch(countryCityAPI(cityCode));
        if (data?.payload) {
          setCityData(data?.payload);
        } else {
          toast.error('error');
        }
      }
    };
    getCity();
  }, [customerData?.country, customerData?.state, dispatch]);

  const handleDropdownToggle = (event) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'country') {
      setCustomerData((prevData) => ({
        ...prevData,
        [name]: value,
        state: '',
        city: '',
      }));
    } else {
      setCustomerData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setFieldsChanged(true);
  };

  const handleSwitchChange = (event, propertyName) => {
    let newCheckedValue;
    if (propertyName === 'p_is3DSEnabled') {
      newCheckedValue = event.target.checked ? 1 : 0;
    } else {
      newCheckedValue = event.target.checked ? '1' : '0';
    }
    setCustomerData((prevData) => ({
      ...prevData,
      [propertyName]: newCheckedValue,
    }));
    setFieldsChanged(true);
  };

  const handleEdit = async () => {
    setIsLoading(true);
    const editCustomerPayload = {
      p_isActive: Number(customerData?.activeStatus),
      p_autoRenew: Number(customerData?.autorenewal),
      p_kycApproval: Number(customerData?.kycApproved),
      p_isTwoFactorAuthenticationEnabled: Number(customerData?.twoFaPortal),
      p_customer_id: Number(id),
      p_customer_name: customerData?.proxyName,
      p_login: customerData?.username,
      p_contact_email: customerData?.emailId,
      p_billing_address_id: customerID?.p_billing_address_id,
      p_accountType: Number(customerData?.accountType),
      p_kyc_detail_id: customerID?.p_kyc_detail_id,
      p_customerSource: Number(customerData?.source),
      p_ownerId: Number(customerData?.owner),
      p_isAccepted: Number(customerData?.kycApproved),
      p_is3DSEnabled: customerData?.p_is3DSEnabled,
      p_firstName: customerData?.firstName,
      p_lastName: customerData?.lastName,
      p_companyName: customerData?.billingName,
      p_address: customerData?.billingAddress,
      p_country: customerData?.country,
      p_state: customerData?.country === 'US' ? customerData?.state : '',
      p_city: customerData?.city,
      p_zipCode: customerData?.zipCode,
      p_customers_plan_id: customerID?.p_customers_plan_id,
    };
    const payload = await dispatch(editCustomerDetailAPI(editCustomerPayload));
    if (payload?.payload.status) {
      toast.success(payload?.payload?.message);
      setIsLoading(false);
      navigate('/admin/customer-details');
    } else {
      toast.error(payload?.payload?.response?.data?.message);
      setIsLoading(false);
    }
    setEditConfirmModal(false);
  };

  const handleButtonClick = () => {
    if (fieldsChanged) {
      handleEdit();
      setFieldsChanged(false);
    }
  };
  useEffect(() => {
    setFieldsChanged(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <Grid
          container
          sx={{
            '& .MuiGrid-root': {
              justifyContent: 'center',
            },
          }}
        >
          <Grid item lg={12} sm={6} container>
            <ThreeDots
              visible={true}
              height='80'
              width='80'
              color={colors.theme}
              radius='9'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClass=''
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item lg='4' sm='6'>
            <Box className='edit-customer-box'>
              <Box sx={{ padding: { sm: '24px', xs: '20px' } }}>
                <Typography variant='h3' sx={{ color: colors.basics.normal, paddingBottom: '18px' }}>
                  Account Details
                </Typography>
                <Grid container rowSpacing='14px' columnSpacing={'24px'}>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      id='username'
                      name='username'
                      placeholder='Username'
                      value={customerData.username}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      id='proxyName'
                      name='proxyName'
                      placeholder='Proxy username'
                      value={customerData.proxyName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs='12'>
                    <TextField
                      id='emailId'
                      placeholder='Email Id'
                      name='emailId'
                      value={customerData?.emailId}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <CustomStyledSelect
                      id='accounttype'
                      name='accountType'
                      value={customerData?.accountType}
                      displayEmpty
                      IconComponent={(props) => (
                        <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                      )}
                      onChange={handleChange}
                    >
                      {customerData?.accountType === '' && (
                        <MenuItem
                          value=''
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleDropdownToggle}
                          disabled
                        >
                          <em>Account type</em>
                        </MenuItem>
                      )}

                      {AccountType?.map((item, index) => (
                        <MenuItem
                          anchorEl={anchorEl}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          anchororigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformorigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          className='filter-box'
                          value={item?.id}
                          key={index}
                        >
                          {item?.type}
                        </MenuItem>
                      ))}
                    </CustomStyledSelect>
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <CustomStyledSelect
                      id='source'
                      name='source'
                      value={customerData?.source}
                      displayEmpty
                      IconComponent={(props) => (
                        <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                      )}
                      onChange={handleChange}
                    >
                      {!customerData?.source && (
                        <MenuItem
                          value=''
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleDropdownToggle}
                          disabled
                        >
                          <em>Source*</em>
                        </MenuItem>
                      )}

                      {sourceArray?.map((item) => (
                        <MenuItem
                          value={item?.id}
                          key={item.id}
                          anchorEl={anchorEl}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          anchororigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformorigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          className='filter-box'
                        >
                          {item?.sourceName}
                        </MenuItem>
                      ))}
                    </CustomStyledSelect>
                  </Grid>
                  <Grid item xs='12'>
                    <CustomStyledSelect
                      id='owner'
                      name='owner'
                      value={customerData?.owner}
                      displayEmpty
                      IconComponent={(props) => (
                        <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                      )}
                      onChange={handleChange}
                    >
                      {!customerData?.owner && (
                        <MenuItem
                          value=''
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleDropdownToggle}
                          disabled
                        >
                          <em>Owner*</em>
                        </MenuItem>
                      )}

                      {ownerData.map((item, index) => (
                        <MenuItem
                          anchorEl={anchorEl}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          className='filter-box'
                          value={item?.p_customer_id}
                          key={index}
                        >
                          {item?.p_login}
                        </MenuItem>
                      ))}
                    </CustomStyledSelect>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item lg='4' sm='6'>
            <Box className='edit-customer-box'>
              <Box sx={{ padding: { sm: '24px', xs: '20px' } }}>
                <Typography variant='h3' sx={{ color: colors.basics.normal, paddingBottom: '18px' }}>
                  Business Details
                </Typography>
                <Grid container rowSpacing='14px' columnSpacing={'24px'}>
                  {customerData?.accountType === '0' || customerData?.accountType === 0 ? (
                    <Grid item xs='12'>
                      <TextField
                        id='billingName'
                        name='billingName'
                        placeholder='Billing Name'
                        value={customerData?.billingName}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs='6'>
                        <TextField
                          id='firstName'
                          name='firstName'
                          placeholder='First Name'
                          value={customerData?.firstName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs='6'>
                        <TextField
                          id='lastName'
                          name='lastName'
                          placeholder='Last Name'
                          value={customerData?.lastName}
                          onChange={handleChange}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs='12'>
                    <TextField
                      id='billingAddress'
                      name='billingAddress'
                      placeholder='Billing Address'
                      value={customerData?.billingAddress}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <CustomStyledSelect
                      id='country'
                      name='country'
                      value={customerData?.country}
                      displayEmpty
                      IconComponent={(props) => (
                        <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                      )}
                      onChange={handleChange}
                    >
                      {!customerData?.country && (
                        <MenuItem
                          value=''
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleDropdownToggle}
                          disabled
                        >
                          <em>Country</em>
                        </MenuItem>
                      )}

                      {countryData?.map((countryName, index) => (
                        <MenuItem
                          value={countryName?.Code}
                          key={countryName?.Code}
                          anchorEl={anchorEl}
                          open={isDropdownOpen}
                          onClose={handleClose}
                          anchororigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformorigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          className='filter-box'
                        >
                          {countryName?.Name}
                        </MenuItem>
                      ))}
                    </CustomStyledSelect>
                  </Grid>

                  <Grid item xl={6} xs={12}>
                    <CustomStyledSelect
                      id='state'
                      name='state'
                      value={customerData?.state}
                      displayEmpty
                      disabled={customerData?.country !== 'US'}
                      className={customerData?.country !== 'US' ? 'disable-option' : 'enalble-option '}
                      IconComponent={(props) => (
                        <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                      )}
                      onChange={handleChange}
                    >
                      {!customerData?.state && (
                        <MenuItem
                          value=''
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleDropdownToggle}
                          disabled
                        >
                          <em>State if(US)</em>
                        </MenuItem>
                      )}

                      {Array.isArray(stateData) ? (
                        stateData.map((stateName, index) => (
                          <MenuItem
                            className='filter-box'
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            value={stateName?.isoCode}
                            key={index}
                          >
                            {stateName?.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value='State' disabled>
                          No states available
                        </MenuItem>
                      )}
                    </CustomStyledSelect>
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <CustomStyledSelect
                      id='city'
                      name='city'
                      value={customerData?.city}
                      displayEmpty
                      IconComponent={(props) => (
                        <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                      )}
                      onChange={handleChange}
                    >
                      {!customerData?.city && (
                        <MenuItem
                          value=''
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleDropdownToggle}
                          disabled
                        >
                          <em>City</em>
                        </MenuItem>
                      )}

                      {Array.isArray(cityData) && cityData.length > 0 ? (
                        cityData.map((cityName, index) => (
                          <MenuItem
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            anchororigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformorigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            className='filter-box'
                            value={cityName?.name}
                            key={index}
                          >
                            {cityName?.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value='city' disabled>
                          No city available
                        </MenuItem>
                      )}
                    </CustomStyledSelect>
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      id='zipcode'
                      name='zipCode'
                      placeholder='Zipcode'
                      value={customerData?.zipCode}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item lg='4' xs='12'>
            <Box className='edit-customer-box'>
              <Box sx={{ padding: { sm: '24px', xs: '20px' } }}>
                <Typography
                  variant='h3'
                  sx={{ color: colors.basics.normal, paddingBottom: { md: '18px', xs: '10px' } }}
                >
                  Account Permission
                </Typography>
                <Grid container rowSpacing={{ lg: '20px', md: '15px', xs: '10px' }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ fontSize: { xl: '20px', sm: '18px', xs: '16px' }, color: colors.neutral.dark }}
                      color='initial'
                    >
                      Active Status
                    </Typography>
                    <Switch
                      sx={SwitchStyle}
                      {...label}
                      checked={customerData?.activeStatus === '1'}
                      onChange={(event) => handleSwitchChange(event, 'activeStatus')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ fontSize: { xl: '20px', sm: '18px', xs: '16px' }, color: colors.neutral.dark }}
                      color='initial'
                    >
                      Auto Renewal
                    </Typography>
                    <Switch
                      sx={SwitchStyle}
                      {...label}
                      checked={customerData?.autorenewal === '1'}
                      onChange={(event) => handleSwitchChange(event, 'autorenewal')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ fontSize: { xl: '20px', sm: '18px', xs: '16px' }, color: colors.neutral.dark }}
                      color='initial'
                    >
                      KYC Approval
                    </Typography>
                    <Switch
                      sx={SwitchStyle}
                      {...label}
                      checked={customerData?.kycApproved === '1'}
                      onChange={(event) => handleSwitchChange(event, 'kycApproved')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ fontSize: { xl: '20px', sm: '18px', xs: '16px' }, color: colors.neutral.dark }}
                      color='initial'
                    >
                      3DS Portal
                    </Typography>
                    <Switch
                      sx={SwitchStyle}
                      {...label}
                      checked={customerData?.p_is3DSEnabled === 1}
                      onChange={(event) => {
                        handleSwitchChange(event, 'p_is3DSEnabled');
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </>
      )}

      <EditConfirmationModal
        editConfirmModal={editConfirmModal}
        setEditConfirmModal={setEditConfirmModal}
        handleButtonClick={handleButtonClick}
        fieldsChanged={fieldsChanged}
        isLoading={isLoading}
      />
    </>
  );
};

export default EditForm;
