import React, { useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Typography, useTheme } from '@mui/material';

import { resetPasswordEmail } from '../../../redux/resetPasswordEmailSentAPI';
import { ColorRing } from 'react-loader-spinner';
import CustomButton from '../../../components/common/customButton';
import CustomTextBox from '../../../components/common/customTextBox';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';
import { useNavigate } from 'react-router-dom';

const ForgetResetPassword = () => {
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
    'in',
    'co',
    'io',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');
  const emailValidationSchema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              username.includes('-') ||
              // domain.includes('-') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      }),
  });
  const handleResetPassword = async (event) => {
    event.preventDefault();
    setFormErrors('');
    try {
      setIsLoading(true);
      await emailValidationSchema.validate({ email });
      const resetpasswordObject = {
        p_contact_email: email,
        p_type: 1,
      };
      const data = await dispatch(resetPasswordEmail(resetpasswordObject));
      if (data?.payload?.status === true) {
        toast.success(data?.payload?.message);
        setIsLoading(false);
        navigate('/login');
      } else {
        toast.error(data?.payload?.message);
        setIsLoading(false);
      }
    } catch (error) {
      setFormErrors(error.message);
      setIsLoading(false);
    }
  };

  return (
    <AuthenticationWrapper>
      <form style={{ width: '100%' }} onSubmit={handleResetPassword}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Typography variant="h1" sx={{ color: theme.palette.neutral.dark }}>
              Reset password
            </Typography>
            <Typography variant="subtitle5" sx={{ color: theme.palette.neutral.normal, textAlign: 'center' }}>
              Enter your registered email address below for password reset.
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <CustomTextBox
              placeholder="Enter email id"
              icon
              iconType={'email'}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.trim());
              }}
            />
            {formErrors && (
              <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>{formErrors}</Typography>
            )}{' '}
          </Box>
          <CustomButton onClick={handleResetPassword} disabled={isLoading}>
            Reset password{' '}
            {isLoading && (
              <ColorRing
                visible={true}
                height='30'
                width='30'
                ariaLabel='blocks-loading'
                wrapperStyle={{}}
                wrapperClass='blocks-wrapper'
                colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
              />
            )}
          </CustomButton>
        </Box>
      </form>
    </AuthenticationWrapper>
  );
};

export default ForgetResetPassword;
