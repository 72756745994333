import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const adminDashBordAPI = createAsyncThunk('admindashboard', async (data) => {
  try {
    const response = await API.get(`/dashboard/admin`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  adminDashboard: {},
};

const adminDashboardSlice = createSlice({
  name: 'admindashboard',
  initialState,
  reducers: {
    setAdminDashboradInfo: (state, { payload }) => {
      state.adminDashboard = payload;
    },
  },
});

export const { setAdminDashboradInfo } = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;
