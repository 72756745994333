import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getUsedBandwidthAPI = createAsyncThunk('bandwidth-usage', async (data) => {
  try {
    const response = await API.get(`customersplans/getUsageData/${data?.id}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  getUsageBandwidthDetail: {},
};

const getBandwidthSlice = createSlice({
  name: 'bandwidth-usage',
  initialState,
  reducers: {
    setBandwidthUsageDetails: (state, { payload }) => {
      state.getUsageBandwidthDetail = payload;
    },
  },
});

export const { setBandwidthUsageDetails } = getBandwidthSlice.actions;
export default getBandwidthSlice.reducer;
