import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const getCustomCustomerAPI = createAsyncThunk('get-Custom-customer-Plans', async (data) => {
  try {
    const response = await API.get(
      `/plans/getCustomPlans/${data?.userId}?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  getcustomCustomerDetail: '',
};

const getCustomCustomerDetailSlice = createSlice({
  name: 'get-Custom-customer-Plans',
  initialState,
  reducers: {
    setCustomCustomerDetails: (state, { payload }) => {
      state.getcustomCustomerDetail = payload;
    },
  },
});

export const { setCustomCustomerDetails } = getCustomCustomerDetailSlice.actions;
export default getCustomCustomerDetailSlice.reducer;
