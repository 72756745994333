import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Box, Typography, useTheme } from '@mui/material';
import * as yup from 'yup';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { resetPasswordAPI } from '../../../redux/forgotPasswordAPI';
import CustomTextBox from '../../../components/common/customTextBox';
import CustomButton from '../../../components/common/customButton';
import { colors } from '../../../theme/colors';
import AuthenticationWrapper from '../../../layout/authenticationWrapper';
import { useCookies } from 'react-cookie';

const SetNewPassword = () => {
  const [fornErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [_, setCookies] = useCookies(['access_token_proxy']);
  const [newPasswordData, setNewPasswordData] = useState({
    newpassword: '',
    password: '',
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [timeDifference, setTimeDifference] = useState(null);

  useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      const targetDate = new Date(decoded?.requestedDateTime);
      const currentDate = new Date();
      const timeDifferenceInMilliseconds = targetDate - currentDate;
      const timeDifferenceInMinutes = Math.abs(Math.floor(timeDifferenceInMilliseconds / (1000 * 60)));
      if (timeDifferenceInMinutes > 10) {
        console.error('Token expired or invalid');
      } else {
        console.error('abc');
      }
      setCookies('access_token_proxy_reset', token, {
        path: '/',
        httpOnly: false,
        secure: false,
        sameSite: 'strict',
      });
      sessionStorage.setItem('access_token_proxy_session_reset', JSON.stringify(token));
      setTimeDifference(timeDifferenceInMinutes);
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }, [token, timeDifference]);

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
    newpassword: yup
      .string()
      .matches(
        /^(?!.*[@#])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$!%*?&])[A-Za-z\d$!%*?&]+$/,
        'Passwords must be at least 8 characters long and include at least one uppercase, one lowercase, one numeric, and one special character. # and @ are not allowed'
      )
      .required('Confirm Password is required')
      .oneOf([yup.ref('newpassword'), ''], 'Password must match'),
  });

  const handleChange = (field, value) => {
    setNewPasswordData({ ...newPasswordData, [field]: value });
  };

  const handleSetNewPassword = async (event) => {
    try {
      event?.preventDefault();
      setFormErrors('');
      await validationSchema.validate(newPasswordData, { abortEarly: false });
      const newPasswordObject = {
        newPasssword: newPasswordData?.password,
        confirmPassword: newPasswordData?.newpassword,
        token: token,
      };
      const data = await dispatch(resetPasswordAPI(newPasswordObject));
      if (data?.payload?.status === true) {
        toast.success(data?.payload?.message);
        navigate('/login');
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (err) {
      const errors = {};
      err.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFormErrors(errors);
      setIsLoading(false);
    }
  };
  return (
    <AuthenticationWrapper>
      {timeDifference && timeDifference > 10 ? (
        <Box
          sx={{
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              padding: '60px 24px',
              backgroundColor: colors.background.formBg,
              border: `1px solid ${colors.input.border}`,
              borderRadius: '8px',
              textAlign: 'center',
              '& a': {
                textDecoration: 'none',
                color: colors.theme,
                mt: '10px',
                display: 'block',
              },
            }}
          >
            <Typography variant="h1" sx={{ marginBottom: { md: '15px', xs: '10px' } }}>
              Opps...
            </Typography>
            <Typography variant="h5" sx={{ fontSize: { lg: '24px', md: '20px', sm: '18px', xs: '16px' } }}>
              Your token was expire please try again
            </Typography>
            <Link to="/forgetpassword">ForgetPassword</Link>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Typography variant="h1" sx={{ color: theme.palette.neutral.dark }}>
              Set New Password
            </Typography>
            <Typography variant="subtitle4" sx={{ color: theme.palette.neutral.normal }}>
              Enter your new password to reset your password
            </Typography>
          </Box>
          <form onSubmit={handleSetNewPassword} style={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                gap: '20px',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <CustomTextBox
                  placeholder="Password"
                  icon
                  type="password"
                  iconType={'password'}
                  value={newPasswordData.password}
                  onChange={(e) => handleChange('password', e.target.value)}
                />
                {fornErrors?.password && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {fornErrors?.password}
                  </Typography>
                )}{' '}
              </Box>
              <Box sx={{ width: '100%' }}>
                <CustomTextBox
                  placeholder="Confirm password"
                  icon
                  type="password"
                  iconType={'password'}
                  value={newPasswordData.newpassword}
                  onChange={(e) => handleChange('newpassword', e.target.value)}
                />
                {fornErrors?.newpassword && (
                  <Typography sx={{ color: 'red', width: '100%', marginTop: '5px' }}>
                    {fornErrors?.newpassword}
                  </Typography>
                )}{' '}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  flexWrap: { sm: 'nowrap', xs: 'wrap' },
                }}
              ></Box>
              <CustomButton type={'submit'} onClick={handleSetNewPassword} disabled={isLoading}>
                {' '}
                Submit
                {isLoading && (
                  <ColorRing
                    visible={true}
                    height='30'
                    width='30'
                    ariaLabel='blocks-loading'
                    wrapperStyle={{}}
                    wrapperClass='blocks-wrapper'
                    colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                  />
                )}
              </CustomButton>
            </Box>
          </form>
        </>
      )}
    </AuthenticationWrapper>
  );
};

export default SetNewPassword;
