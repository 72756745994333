import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const addPlanAPI = createAsyncThunk('addpaln', async (data) => {
  try {
    const response = await API.post(`/plans/create`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  addpaln: {},
};

const addPlanSlice = createSlice({
  name: 'addpaln',
  initialState,
  reducers: {
    setAddPlanSlice: (state, { payload }) => {
      state.addpaln = payload;
    },
  },
});

export const { setAddPlanSlice } = addPlanSlice.actions;
export default addPlanSlice.reducer;
