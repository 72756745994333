import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { colors } from '../../../theme/colors';
import AdminDashboardActivity from '../adminDashboardActivity';
import CustomerplanUseChart from './customerPlanUseChart';
import RecentPayments from '../recentPayments';
import RecentCustomersTable from '../recentCustomerTable';
import { adminDashBordAPI, setAdminDashboradInfo } from '../../../redux/adminDashboardAPI';

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoadng] = useState(false);
  const [adminData, setAdminData] = useState([]);

  useEffect(() => {
    setIsLoadng(true);
    const adminData = async () => {
      const payload = await dispatch(adminDashBordAPI());
      if (payload?.payload?.status) {
        setAdminData(payload?.payload?.data?.userPlanDetails);
        await dispatch(setAdminDashboradInfo(payload?.payload?.data));
        setIsLoadng(false);
      } else {
        setIsLoadng(false);
      }
    };
    adminData();
  }, [dispatch]);

  return (
    <>
      <Box className='heading-text'>
        <Typography variant='h2' pb='20px' color={colors.neutral.dark}>
          Dashboard
        </Typography>
      </Box>
      <Box className='grid-box' sx={{ width: '100%' }}>
        <Grid container spacing={'24px'}>
          <Grid
            item
            xl={9}
            xs={12}
            sx={{
              padding: '20px',
              '@media (min-width: 600px)': {
                borderBottom: 'none',
              },
              '@media (min-width: 1280px)': {
                borderRight: '1px solid #7a7474',
                borderBottom: 'none',
              },
            }}
          >
            <Grid container spacing={'20px'} rowSpacing={'40'}>
              <Grid item xs={12} lg={6}>
                <Box sx={{ p: 2, bgcolor: '#F5F7FA', borderRadius: '16px', height: '95%' }}>
                  <CustomerplanUseChart />
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: '#F5F7FA',
                    borderRadius: '16px',
                    height: '95%',
                  }}
                >
                  <RecentPayments />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: '#F5F7FA',
                    borderRadius: '16px',
                    height: '100%',
                  }}
                >
                  <RecentCustomersTable adminData={adminData} isLoading={isLoading} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={3} xs={12}>
            <Box
              sx={{
                backgroundColor: colors.background.formBg,
                borderRadius: '16px',
                height: '100%',
              }}
            >
              <AdminDashboardActivity />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdminDashboard;
