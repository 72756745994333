import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const registerAPI = createAsyncThunk('registerUser', async (data) => {
  try {
    const response = await API.post(`/authentication/register`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  signUpUser: {},
  signInUser: {},
  loggedInEmailVerification: {},
  countryCode: 0,
  phoneNumberState: 0, //0:add., 1:edit
};

const registerUserSlice = createSlice({
  name: 'registerUser',
  initialState,
  reducers: {
    setSignUpUserdata: (state, { payload }) => { // for user
      state.signUpUser = payload;
    },
    setLoggedInEmailVerification: (state, { payload }) => { // for user
      state.loggedInEmailVerification = payload;
    },
    setSignAdmin: (state, { payload }) => { // for admin
      state.signInUser = payload;
    },
    setCountryCode: (state, { payload }) => {
      state.countryCode = payload;
    },
    setPhoneNumberState: (state, { payload }) => {
      state.phoneNumberState = payload;
    },
  },
});

export const { setSignUpUserdata, setSignAdmin, setCountryCode, setPhoneNumberState, setLoggedInEmailVerification } =
  registerUserSlice.actions;
export default registerUserSlice.reducer;
