import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KycModal from '../../pages/user/KycForm/kycFormModal';
import { useGetUser } from '../../context/userProfile';

const RevokeToken = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logInUserDetails } = useGetUser();
  const accessToken = JSON.parse(sessionStorage.getItem('access_token_proxy_session'));
  const [accountInfoModal, setAccountInfoModal] = useState(false);
  const currentPath = location.pathname;
  const adminPath = currentPath.match(/^\/admin\b/);

  const RevokeTokenn = async () => {
    if (adminPath ? adminPath[0] : null) {
      return;
    } else if (logInUserDetails?.p_web_access === 0) {
      if (logInUserDetails?.p_phoneNumber === '') {
        navigate('/verifycontact-number');
      } else if (logInUserDetails?.p_isPhoneNumberConfirmed === false) {
        navigate('/otpverification');
      } else if (logInUserDetails?.iskyc === 0) {
        setAccountInfoModal(true);
      }
    } else {
      navigate(location.pathname);
    }
  };
  useEffect(() => {
    if (accessToken?.token) {
      RevokeTokenn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    logInUserDetails?.p_web_access === 0 &&
    logInUserDetails?.p_isPhoneNumberConfirmed === true && (
      <KycModal accountInfoModal={accountInfoModal} setAccountInfoModal={setAccountInfoModal} />
    )
  );
};

export default RevokeToken;
