import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { colors } from "../../../theme/colors";
import {
  Box,
  Typography,
  MenuItem,
  Button,
  Menu,
  Paper,
  TableBody,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { ThreeDots } from "react-loader-spinner";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ReactComponent as ViewIcon } from "../../../assets/images/view.svg";
import Calendar from "../../../assets/images/Calendar.svg";
import DataNotFound from "../../../assets/images/No Data Found.png";
import { getUserByID, setUserByIdDetails } from "../../../redux/getUserByIdAPI";
import { getUserAcitivityAPI } from "../../../redux/useractivityAPI";
import { getUserRequestAPI } from "../../../redux/userRequestAPI";
import { setKycDetail } from "../../../redux/kycDetailGetAPI";
import { ReactComponent as FilterFunnel } from "../../../assets/images/FilterFunnel.svg";
import { formatDate } from "../../../components/common/dateFormat";
import ReviewData from "./reviewData";
import ActionModal from "./actionModal";
import RejectModal from "./rejectModal";
import { getKycDetailFileUser } from "../../../redux/userActivityKycAccountInfo";
import Pagination from "../../../components/common/Pagination";
import { stringToCamelCase } from "../../../utils/CommonFunctions/commonFile";
import SortingHelper from "../../../components/common/sortingHelper";

const UserActivity = () => {
  const dispatch = useDispatch();
  const totalCountRef = useRef(0);
  const currentYear = new Date().getFullYear();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState({
    ACCEPT: {},
    REJECT: {},
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState();
  const [dateFilterOption, setDateFilterOption] = useState("This Year");
  const [acceptconfirmModal, setIsacceptconfirmModal] = useState(false);
  const [rejectModdal, setRejectModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [isAcceptRequest, setIsAcceptRequest] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 1 }); // 1: "asc", 0: "desc"
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1));
  const [endDate, setEndDate] = useState(new Date(currentYear, 11, 31));

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const isDatePickerDisabled = dateFilterOption !== "Custom Date";

  const CustomDatePickerWrapper = styled(Box)(({ theme }) => ({
    width: { sm: "auto", xs: "100%" },
    "& .react-datepicker-wrapper": {
      width: "100%",

      "& svg": {
        display: "none",
      },
      "& .react-datepicker-wrapper input": {},

      "& input": {
        padding: "12px 14px",
        paddingRight: "46px",
        fontSize: "18px",
        width: { sm: "auto", xs: "100%" },
        maxWidth: { xl: "auto", sm: "140px", xs: "-webkit-fill-available" },
        borderRadius: "6px",
        color: "#686465",
        border: `1px solid ${theme.palette.neutral.lighter}`,
        backgroundColor: colors.basics.white,
        backgroundImage: `url('${Calendar}')`,
        backgroundPosition: "right 14px center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "26px 26px",
        transition: "all 0.5s ease",

        "&:hover, &:focus-visible": {
          borderColor: theme?.colors?.theme,
          outline: "none",
        },
      },
    },
  }));

  const filterDandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const filterHandleClose = () => {
    setAnchorEl(null);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#242323",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: "#686465",
      fontWeight: 400,
    },
  }));

  const getDateRange = (name, customStartDate, customEndDate) => {
    setDateFilterOption(name);
    const currentDate = new Date();
    let startDate, endDate;

    switch (name) {
      case "7 Days":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;

      case "30 Days":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case "This Month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        break;

      case "Last Month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        );
        break;

      case "This Year":
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 11, 31);
        break;

      case "Custom Date":
        if (customStartDate && customEndDate) {
          startDate = new Date(customStartDate);
          endDate = new Date(customEndDate);
        } else {
          setAnchorEl(null);
          startDate = new Date(currentDate.getFullYear(), 0, 1);
          endDate = new Date(currentDate.getFullYear(), 11, 31);
        }
        break;
      default:
        startDate = null;
        endDate = null;
        break;
    }

    setAnchorEl(null);

    setStartDate(startDate);
    setEndDate(endDate);

    return { startDate, endDate };
  };

  const getUserData = async () => {
    setIsLoading(true);

    const usageObj = {
      limit: rowsPerPage,
      page: page,
      search: dateFilterOption || "",
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
    };

    await setUserData([]);

    const data = await dispatch(getUserAcitivityAPI(usageObj));
    try {
      if (data?.payload.status === true) {
        const customerdata = data?.payload?.data?.customers;
        const sortedCustomers = customerdata.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        await setUserData(sortedCustomers);
        await setRowsPerPage(
          customerdata?.length > 0 ? data.payload?.data?.paging.itemsPerPage : 0
        );
        await setTotalCount(
          customerdata?.length > 0 ? data.payload?.data?.paging.totalItems : 0
        );
        if (
          (dateFilterOption !== "" ||
            usageObj?.fromDate !== "" ||
            usageObj?.toDate !== "") &&
          totalCount !== totalCountRef.current
        ) {
          setPage(0);
        }
        await setIsLoading(false);
      } else {
        setRowsPerPage(10);
        setTotalCount(0);
        setPage(0);
        setUserData([]);
        setIsLoading(false);
      }
    } catch (error) {
      setRowsPerPage(10);
      setPage(0);
      setTotalCount(0);
      setUserData([]);
      await setIsLoading(false);
    }
  };

  useEffect(() => {
    totalCountRef.current = totalCount;
  }, [totalCount]);

  useEffect(() => {
    getUserData();
  }, [dispatch, page, rowsPerPage, dateFilterOption, startDate, endDate]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAcceptRequest = async (userEmail, req, message) => {
    setErrorMessage("");
    if (req === "REJECT" && message === "") {
      setErrorMessage("Please add reason");
      setLoadingUsers((prevLoadingUsers) => ({
        ...prevLoadingUsers,
        [req]: {
          ...prevLoadingUsers[req],
          [userEmail]: false,
        },
      }));
    } else {
      setLoadingUsers((prevLoadingUsers) => ({
        ...prevLoadingUsers,
        [req]: {
          ...prevLoadingUsers[req],
          [userEmail]: true,
        },
      }));
      const reqObject = {
        p_contact_email: userEmail,
        p_type: req === "ACCEPT" ? 1 : 2,
        p_rejectReason: req === "REJECT" && message,
      };

      const data = await dispatch(getUserRequestAPI(reqObject));
      if (data.payload?.status === true) {
        if (
          data.payload?.message === "Email sent Successfully" &&
          req === "ACCEPT"
        ) {
          setLoadingUsers((prevLoadingUsers) => ({
            ...prevLoadingUsers,
            [req]: {
              ...prevLoadingUsers[req],
              [userEmail]: false,
            },
          }));
          toast.success("Request has been accepted successfully ");
          getUserData();
          setIsacceptconfirmModal(false);
        } else if (
          data.payload?.message === "Email sent Successfully" &&
          req === "REJECT"
        ) {
          setLoadingUsers((prevLoadingUsers) => ({
            ...prevLoadingUsers,
            [req]: {
              ...prevLoadingUsers[req],
              [userEmail]: false,
            },
          }));
          setRejectModal(false);
          getUserData();
          toast.success("Request has been rejected successfully");
        }
        setRejectModal(false);
        setIsacceptconfirmModal(false);
      }
    }
  };

  const dateFilter = [
    { Name: "7 Days", Code: "Last 7 days" },
    { Name: "30 Days", Code: "Last 30 days" },
    { Name: "This Month", Code: "This month" },
    { Name: "Last Month", Code: "Last month" },
    { Name: "This Year", Code: "This year" },
    { Name: "Custom Date", Code: "Custom Date" },
  ];

  const columnName = [
    {
      id: 1,
      key: "Sign up date",
    },
    {
      id: 2,
      key: "Emails",
    },
    {
      id: 3,
      key: "Account type",
    },
    {
      id: 4,
      key: "KYC status",
    },
    {
      id: 5,
      key: "Actions",
    },
  ];

  const handleAcceptEmail = (email) => {
    const acceptData = userData.filter((row) => row.p_contact_email === email);
    setIsAcceptRequest(acceptData);
    setIsacceptconfirmModal(true);
  };
  const handleRejectEmail = (email) => {
    setErrorMessage("");
    const acceptData = userData.filter((row) => row.p_contact_email === email);
    setIsAcceptRequest(acceptData);
    setRejectModal(true);
  };

  const viewUserDetails = async (userId) => {
    setDataLoading(true);
    try {
      await dispatch(setUserByIdDetails({}));
      await dispatch(setKycDetail({}));

      const userWiseData = {
        userId: userId,
      };
      const userData = await dispatch(getUserByID(userWiseData));
      await dispatch(setUserByIdDetails(userData));

      const kycData = await dispatch(getKycDetailFileUser(userWiseData));
      await dispatch(setKycDetail(kycData));
    } catch (error) {
      setDataLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" sx={{ mb: 3, color: colors?.neutral?.dark }}>
        User Activity
      </Typography>
      <Box className="userActivity-container">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
            flexDirection: { xs: "column", md: "row", sm: "row" },
          }}
        >
          <Typography
            sx={{
              mb: 3,
              fontSize: {
                xl: "24px",
                lg: "24px",
                md: "24px",
                sm: "24px",
                xs: "18px",
              },
              fontWeight: 600,
            }}
            color="#242323"
          >
            Dashboard Access approval
          </Typography>
        </Box>

        <Box
          sx={{
            display: { lg: "flex", xs: "grid", flexWrap: "wrap" },
            justifyContent: "space-between",
            gap: { lg: "5px", xs: "20px", sm: "20px", md: "auto" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: { lg: "20px", xs: "20px", sm: "20px", md: "auto" },
              width: {
                lg: "auto",
                xs: "100%",
                sm: "auto",
                md: "auto",
              },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "auto" }, height: "48px" }}>
              {!isDatePickerDisabled ? (
                <CustomDatePickerWrapper>
                  <DatePicker
                    showIcon
                    placeholderText="Start Date"
                    selected={startDate}
                    onChange={(date) => {
                      if (date <= endDate) {
                        setStartDate(date);
                      } else {
                        toast.error("From date must not be grater to end date");
                      }
                    }}
                    disabled={isDatePickerDisabled}
                    dateFormat="dd-MM-yyyy"
                    className={
                      isDatePickerDisabled ? "disabled-datepicker" : ""
                    }
                  />
                </CustomDatePickerWrapper>
              ) : (
                <Tooltip
                  title="Select the 'custom date' filter to do a calendar search."
                  arrow
                >
                  <CustomDatePickerWrapper>
                    <DatePicker
                      showIcon
                      placeholderText="Start Date"
                      selected={startDate}
                      onChange={(date) => {
                        if (date <= endDate) {
                          setStartDate(date);
                        } else {
                          toast.error(
                            "From date must not be grater to end date"
                          );
                        }
                      }}
                      disabled={isDatePickerDisabled}
                      dateFormat="dd-MM-yyyy"
                      className={
                        isDatePickerDisabled ? "disabled-datepicker" : ""
                      }
                    />
                  </CustomDatePickerWrapper>
                </Tooltip>
              )}
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              {!isDatePickerDisabled ? (
                <CustomDatePickerWrapper>
                  <DatePicker
                    showIcon
                    placeholderText="Start Date"
                    selected={endDate}
                    onChange={(date) => {
                      if (date >= startDate) {
                        setEndDate(date);
                      } else {
                        toast.error(
                          "End date must not be earlier than the start date"
                        );
                      }
                    }}
                    disabled={isDatePickerDisabled}
                    dateFormat="dd-MM-yyyy"
                    className={
                      isDatePickerDisabled ? "disabled-datepicker" : ""
                    }
                  />
                </CustomDatePickerWrapper>
              ) : (
                <Tooltip
                  title="Select the 'custom date' filter to do a calendar search."
                  cursor
                >
                  <CustomDatePickerWrapper>
                    <DatePicker
                      showIcon
                      placeholderText="Start Date"
                      selected={endDate}
                      onChange={(date) => {
                        if (date >= startDate) {
                          setEndDate(date);
                        } else {
                          toast.error(
                            "End date must not be earlier than the start date"
                          );
                        }
                      }}
                      disabled={isDatePickerDisabled}
                      dateFormat="dd-MM-yyyy"
                      className={
                        isDatePickerDisabled ? "disabled-datepicker" : ""
                      }
                    />
                  </CustomDatePickerWrapper>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              "& button": {
                mr: "8px",
                padding: { xl: "11px 19px", xs: "7px" },
                minWidth: {
                  lg: "100%",
                  sx: "100%",
                  sm: "48%",
                  md: "48%",
                  xl: "48%",
                },
                width: "200px",
                height: "48px",
                "&:last-child": {
                  mr: "0px",
                },
                "& svg path": {
                  transition: "all .5s ease",
                },
                "&:hover svg path": {
                  fill: colors.theme,
                },
              },
            }}
          >
            <Box>
              <Button
                id="basic-button"
                className="filter-button"
                aria-controls={open ? "filterMenu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={filterDandleClick}
              >
                {dateFilterOption ? dateFilterOption : "Filter"}
                <FilterFunnel />
              </Button>

              <Menu
                id="filterMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={filterHandleClose}
                PaperProps={{
                  sx: {
                    maxHeight: "232px",
                    width: "200px !important",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                  }}
                >
                  {dateFilter?.map((item, index) => (
                    <MenuItem
                      className="filter-box"
                      key={index}
                      value={dateFilterOption}
                      onClick={() => getDateRange(item.Name)}
                    >
                      {item?.Code}
                    </MenuItem>
                  ))}
                </Box>
              </Menu>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer
            sx={{
              width: "100% !important",
              boxShadow: "none",
              borderRadius: "12px",
            }}
            component={Paper}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow sx={{ "& th": { whiteSpace: "nowrap" } }}>
                  {columnName?.map((item, index) => (
                    <StyledTableCell
                      align="start"
                      sx={{ fontSize: "18px" }}
                      key={index}
                      onClick={() =>
                        setSortConfig((prev) => ({
                          key: stringToCamelCase(item.key),
                          direction: !prev.direction,
                        }))
                      }
                    >
                      <>
                        {item?.key}
                        <SortingHelper item={item} sortConfig={sortConfig} />
                      </>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              {isLoading ? (
                <TableBody>
                  <TableRow sx={{ textAlign: "center" }}>
                    <TableCell colSpan={7}>
                      <Box
                        sx={{
                          textAlign: "center",
                          "& > div": { justifyContent: "center" },
                        }}
                      >
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color={colors.theme}
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : userData?.length > 0 ? (
                <TableBody
                  sx={{
                    "& tr:last-child td": {
                      borderBottom: 0,
                    },
                    color: colors?.basics?.normal,
                  }}
                >
                  {userData?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        textAlign: "center",
                        color: colors?.basics?.normal,
                        "& td": { whiteSpace: "nowrap" },
                      }}
                    >
                      <StyledTableCell>
                        {" "}
                        {row?.loginHistory?.length > 0
                          ? formatDate(row?.loginHistory[0]?.createdAt)
                          : formatDate(row?.p_added_date)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.p_contact_email ? row?.p_contact_email : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.kycdetail?.p_accountType === "1"
                          ? "Freelancer"
                          : row?.kycdetail?.p_accountType === "0"
                          ? "Corporate"
                          : row?.kycdetail === null
                          ? "None"
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.p_isAccepted === 0
                          ? "Pending"
                          : row?.p_isAccepted === 1
                          ? "Approved"
                          : row?.p_isAccepted === 2
                          ? "Rejected"
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Box>
                            <Button
                              sx={{
                                width: "160px",
                                background: "#FFFFFF",
                                border:
                                  row?.p_isAccepted === 1 ||
                                  row?.p_isAccepted === 2
                                    ? "1px solid gray"
                                    : "1px solid #FD337E",
                                borderRadius: "6px",
                                height: "48px",
                                fontSize: "20px",
                                textTransform: "capitalize",
                                fontWeight: 500,
                                outline: "none",
                                boxShadow: "0 0 0 0 !important",
                                color: "#FD337E",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  boxShadow:
                                    "0 2px 4px rgba(0, 0, 0, 0.2) !important",
                                },
                              }}
                              disabled={
                                row?.p_isAccepted === 1 ||
                                row?.p_isAccepted === 2
                              }
                              onClick={() =>
                                handleAcceptEmail(row.p_contact_email)
                              }
                            >
                              {loadingUsers["ACCEPT"][row?.p_contact_email]
                                ? "Loading..."
                                : "Accept"}
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              sx={{
                                width: "160px",
                                background: "#FFFFFF",
                                height: "48px",
                                fontSize: "20px",
                                fontWeight: 500,
                                border:
                                  row?.p_isAccepted === 2
                                    ? "1px solid gray"
                                    : "1px solid #686465",
                                textTransform: "capitalize",
                                borderRadius: "6px",
                                boxShadow: "0 0 0 0 !important",
                                color: "#686465",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  boxShadow:
                                    "0 2px 4px rgba(0, 0, 0, 0.2) !important",
                                },
                              }}
                              disabled={row?.p_isAccepted === 2}
                              onClick={() =>
                                handleRejectEmail(row.p_contact_email)
                              }
                            >
                              {loadingUsers["REJECT"][row?.p_contact_email]
                                ? "Loading..."
                                : "Reject"}
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              sx={{
                                width: "48px",
                                background: "#FFFFFF",
                                height: "48px",
                                fontSize: "20px",
                                fontWeight: 700,
                                border: "1px solid #686465",
                                textTransform: "capitalize",
                                borderRadius: "6px",
                                boxShadow: "0 0 0 0 !important",
                                color: "#686465",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  boxShadow:
                                    "0 2px 4px rgba(0, 0, 0, 0.2) !important",
                                },
                              }}
                              onClick={() => {
                                viewUserDetails(row?.p_customer_id);
                                navigate(
                                  `/admin/user-activity/:${row?.p_customer_id}`
                                );

                                return <ReviewData isLoading={dataLoading} />;
                              }}
                            >
                              <ViewIcon />
                            </Button>
                          </Box>
                          <ActionModal
                            EditUser={isAcceptRequest}
                            setIsacceptconfirmModal={setIsacceptconfirmModal}
                            acceptconfirmModal={acceptconfirmModal}
                            handleSubmit={(email, ACCEPT, message) =>
                              handleAcceptRequest(email, ACCEPT, message)
                            }
                            loadingUsers={loadingUsers}
                          />

                          <RejectModal
                            EditUser={isAcceptRequest}
                            setRejectModal={setRejectModal}
                            rejectModdal={rejectModdal}
                            handleSubmit={(email, REJECT, message) =>
                              handleAcceptRequest(email, REJECT, message)
                            }
                            loadingUsers={loadingUsers}
                            errorMessage={errorMessage}
                          />
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableRow sx={{ width: "100%", height: "100%" }}>
                  <TableCell
                    colSpan={6}
                    sx={{ textAlign: "center", border: "0" }}
                  >
                    <img
                      src={DataNotFound}
                      alt="data not found"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <Typography>Data not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              totalCount={totalCount}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserActivity;
