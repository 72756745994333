export const formatNumberWithCommas = (number) => {
  let floatValue = parseFloat(number);
  if (floatValue % 1 === 0) {
    floatValue = floatValue.toFixed(0);
  } else {
    floatValue = floatValue.toFixed(2);
  }
  const parts = floatValue.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};
