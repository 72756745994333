import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const addCustomerAPI = createAsyncThunk('add-customer', async (data) => {
  try {
    const response = await API.post(`/users/add/customer`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  adminAddCustomer: {},
};

const adminAddCustomerSlice = createSlice({
  name: 'add-customer',
  initialState,
  reducers: {
    setAdminaddCustomerInfo: (state, { payload }) => {
      state.adminAddCustomer = payload;
    },
  },
});

export const { setAdminaddCustomerInfo } = adminAddCustomerSlice.actions;
export default adminAddCustomerSlice.reducer;
