import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getKycDetails = createAsyncThunk('kyc-details-get', async (data) => {
  try {
    const response = await API.get(`/kyc-details/user/${data.userId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  kycDetail: {},
};

const getKycDetailSlice = createSlice({
  name: 'kycDetail',
  initialState,
  reducers: {
    setKycDetail: (state, { payload }) => {
      state.kycDetail = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKycDetails.fulfilled, (state, { payload }) => {
      state.kycDetail = payload;
    });
  },
});

export const { setKycDetail } = getKycDetailSlice.actions;
export default getKycDetailSlice.reducer;
