import Logo from './logo.svg';
import Mobile_Logo from './mobileLogo.svg';
import Bg_Authentication_Screens from './bg_authentication_screens.svg';
import Google_Icon from './google.svg';
import Facebook_Icon from './facebook.svg';
import Email_Icon from './emailIcon.svg';
import Password_Icon from './passwordIcon.svg';
import Username_Icon from './usernameIcon.svg';
import Country_Icon from './countryIcon.svg';
import Dark_Country_Icon from './darkCountryIcon.svg';
import VisibilityPassword_Icon from './visibilityPasswordIcon.svg';
import VisibilityOffPassword_Icon from './visibilityOffPasswordIcon.svg';
import DropdownDownArrow_Icon from './dropdownDownArrow.svg';
import OTPVerification_Icon from './otpVerification.svg';
import Search_Icon from './searchIcon.svg';
import Profile_Image from './profileImage.svg';
import SidebarIcon from './sidebarIcon.svg';
import FilePlus from './FilePlus.svg';
import WarningCircle from './WarningCircle.svg';
import File from './file.svg';
import Calendar from './Calendar.svg';
import FilterFunnel from './FilterFunnel.svg';
import True from './true.svg';
import False from './false.svg';
import AdminProfile from './admin-profile.svg';
import Edit from './Edit.svg';
import Logs from './Logs.svg';
import PaySlip from './PaySlip.svg';
import Copy from './Copy.svg';
import Printer from './Printer.svg';
import Download from './Download.svg';
import SortNone from './sort-none.svg';
import SortTop from './sort-top.svg';
import SortBottom from './sort-bottom.svg';
import EmailVerifyIcon from './emailVerification.svg';

export const Images = {
  Logo,
  Mobile_Logo,
  Bg_Authentication_Screens,
  Google_Icon,
  Facebook_Icon,
  Email_Icon,
  Password_Icon,
  Username_Icon,
  Country_Icon,
  VisibilityPassword_Icon,
  VisibilityOffPassword_Icon,
  DropdownDownArrow_Icon,
  OTPVerification_Icon,
  Search_Icon,
  Profile_Image,
  Dark_Country_Icon,
  SidebarIcon,
  FilePlus,
  WarningCircle,
  File,
  Calendar,
  FilterFunnel,
  True,
  False,
  AdminProfile,
  Edit,
  Logs,
  PaySlip,
  Copy,
  Printer,
  Download,
  SortNone,
  SortTop,
  SortBottom,
  EmailVerifyIcon,
};
