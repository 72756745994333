import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getIdWiseCustomerPlanDetils = createAsyncThunk('idwise-customerPlan', async (data) => {
  try {
    const response = await API.get(
      `/users/customer/plan/${data?.userId}?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  idwiseCustomerPlan: {},
};

const idwisegetCustomerPlanSlice = createSlice({
  name: 'idwiseCustomerPlan',
  initialState,
  reducers: {
    setUseridWiseCustomerPlanDetails: (state, { payload }) => {
      state.idwiseCustomerPlan = payload;
    },
  },
});

export const { setUseridWiseCustomerPlanDetails } = idwisegetCustomerPlanSlice.actions;
export default idwisegetCustomerPlanSlice.reducer;
