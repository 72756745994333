import { TablePagination } from '@mui/material';
import { colors } from '../../theme/colors';
import React from 'react';

const Pagination = ({ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, totalCount }) => {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      colSpan={6}
      count={totalCount || 0}
      rowsPerPage={rowsPerPage || 0}
      page={page || 0}
      SelectProps={{
        inputProps: {
          'aria-label': 'Show pages',
        },
        native: true,
      }}
      labelRowsPerPage='Show pages'
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        width: '100%',
        display: 'block',
        border: '0',
        '& .MuiTablePagination-toolbar': {
          justifyContent: 'flex-start',
          width: '100%',
          padding: '35px 0 0',
          flexWrap: 'wrap',
          gap: '10px',
          '& select': {
            padding: '12px 24px 12px 12px !important',
            border: '1px solid' + colors.input.border + '!important',
            backgroundColor: colors.basics.white + '!important',
            borderRadius: '4px !important',
            color: '#686465',
            fontSize: ' 18px',
            lineHeight: ' 1',
            height: 'auto',
            textAlign: 'center',
          },
          '& .MuiTablePagination-actions': {
            marginLeft: { sm: '24px', xs: 'auto' },
            marginRight: { sm: '0', xs: 'auto' },
            '& .MuiButtonBase-root ': {
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              border: `1px solid ${colors.theme}`,
              '& svg': {
                fill: colors.theme,
              },
              '&.Mui-disabled ': {
                borderColor: colors.input.border,
                cursor: 'no-drop',
                '& svg': {
                  fill: colors.input.border,
                },
              },
              '&:first-child': {
                marginRight: '12px',
              },
            },
          },
          '& .MuiTablePagination-spacer ': {
            flex: '0 0 0%',
          },
          '& .MuiTablePagination-input': {
            marginRight: 'auto',
          },
          '& .MuiTablePagination-selectLabel': {
            fontSize: '18px',
            color: '#686465',
            fontWeight: 400,
          },
          '& .MuiTablePagination-displayedRows': {
            fontSize: '18px',
            color: '#686465',
            fontWeight: 400,
          },
        },
      }}
    />
  );
};

export default Pagination;
