import { Box, Button, Modal, Typography } from '@mui/material';
import { colors } from '../../../theme/colors';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { adminPaymentAPI } from '../../../redux/adminpaymentAPI';
import { toast } from 'react-toastify';
import { setCustomCustomerDetails } from '../../../redux/getCustomCustomerPlansAPI';
import { baseURL } from '../../../api/url';
import { IsCreditCardExpired } from '../../../utils/CommonFunctions/commonFile';
import { CustomersplansSubscribe } from '../../../redux/tranzillaTokenWisePaymnet';

const RollOverInformationModal = ({
  openModal,
  setRollOverModal,
  payment,
  setOpenModal,
  setIsloading,
  planDetails,
  setModalContentUrl,
  plansType,
  logInUserDetails,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const adminPath = currentPath.match(/^\/admin\b/);

  const handleRollOver = async () => {
    const is3DSEnabled = logInUserDetails?.p_is3DSEnabled;
    const isTokenPresent = logInUserDetails?.p_tranzilaTK !== null || '';
    const isCardExpired = IsCreditCardExpired(payment?.cc_exp);
    if (adminPath && adminPath.length > 0 && adminPath[0]) {
      const data = await dispatch(adminPaymentAPI(payment));
      if (data?.payload?.response?.status === 500) {
        toast.success(data?.payload?.response.message);
        setIsloading(false);
      } else if (data?.payload?.status === true) {
        toast.success(data?.payload?.messgae);
        setIsloading(false);
        await dispatch(setCustomCustomerDetails(data?.payload?.data?.p_customer_id));
        const url = `/admin/subscription?customerId=${data?.payload?.data?.p_customer_id}`;
        window.location.href = url;
      }
    } else {
      const { p_name, p_yearlyPrice, p_price, p_plan_id, p_duration } = planDetails;
      const price = !plansType ? p_price : p_yearlyPrice;
      const p_billingChargesType = plansType ? 2 : 1;
      const p_payment_mode = 2;
      const successUrl = `${baseURL}customersplans`;
      const failiurUrl = `${baseURL}customersplans/transaction-failure`;
      let url = '';
      if (is3DSEnabled) {
      // if (is3DSEnabled && (!isTokenPresent || isCardExpired)) {
        url = `https://direct.tranzila.com/proxe/iframenew.php?name=${p_name}&sum=${price}&currency=${2}&cred_type=${1}&tranmode=VK&fail_url_address=${failiurUrl}&success_url_address=${successUrl}&p_plan_id=${p_plan_id}&p_customer_id=${
          logInUserDetails?.p_customer_id
        }&p_billingChargesType=${p_billingChargesType}&p_ownerId=${
          logInUserDetails?.p_ownerId
        }&p_customerSource=${
          logInUserDetails?.p_customerSource
        }&p_payment_mode=${p_payment_mode}&p_payment_amount=${price}&p_tax=5&p_duration=${p_duration}&p_contact_email=${
          logInUserDetails?.p_contact_email
        }&force_challenge=${1}&force_txn_on_3ds_fail=Y`;
        setModalContentUrl(url);
        setOpenModal(true);
      } else if (!is3DSEnabled) {
      // } else if (!is3DSEnabled && (!isTokenPresent || isCardExpired)) {
        url = `https://direct.tranzila.com/proxetok/iframenew.php?name=${p_name}&sum=${price}&currency=${2}&cred_type=${1}&tranmode=VK&fail_url_address=${failiurUrl}&success_url_address=${successUrl}&p_plan_id=${p_plan_id}&p_customer_id=${
          logInUserDetails?.p_customer_id
        }&p_billingChargesType=${p_billingChargesType}&p_ownerId=${
          logInUserDetails?.p_ownerId
        }&p_customerSource=${
          logInUserDetails?.p_customerSource
        }&p_payment_mode=${p_payment_mode}&p_payment_amount=${price}&p_tax=5&p_duration=${p_duration}&p_contact_email=${
          logInUserDetails?.p_contact_email
        }`;
        setModalContentUrl(url);
        setOpenModal(true);
        setIsloading(false);
      } 
      // else if (isTokenPresent && !isCardExpired) {
      //   const data = await dispatch(CustomersplansSubscribe(payment));
      //   if (data?.payload?.message === 'Network Error') {
      //     toast.success(data?.payload?.message);
      //     setIsloading(false);
      //   } else if (data?.payload?.status === true) {
      //     toast.success(data?.payload?.messgae);
      //     setIsloading(false);
      //     navigate('/billingInformation');
      //   }
      //   setRollOverModal(false);
      // }
    }
  };
  return (
    <Modal
      open={openModal}
      onClose={() => setRollOverModal(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        '& [tabindex="-1"]': {
          height: 'fit-content',
          margin: 'auto',
          borderRadius: '12px',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          width: {
            xs: '250px',
            sm: '320px',
            md: '508px',
            lg: '508px',
            xl: '508px',
          },
          maxHeight: '100%',
          height: '158px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          outline: 'none',
        }}
      >
        <Box sx={{ padding: '24px' }}>
          <Typography
            sx={{
              fontSize: '20px',
              color: colors?.neutral?.dark,
              textAlign: 'center',
            }}
          >
            You may lose the rollover benefits for your existing plan, Do you want to proceed with this plan?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '0px 20px 20px 20px',
            justifyContent: 'space-between',
            gap: '15px',
          }}
        >
          <Button
            variant='#ff4c6a'
            sx={{
              maxHeight: '100%',
              maxWidth: '100%',
              borderRadius: '12px',
              border: '1px solid gray',
              background: '#FFFFFF',
              color: '#686465',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              width: '224px',
              height: '56px',
              fontSize: { xs: '20px' },
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
            onClick={() => setRollOverModal(false)}
          >
            No
          </Button>

          <Button
            variant='#ff4c6a'
            sx={{
              backgroundColor: colors?.theme,
              maxHeight: '100%',
              maxWidth: '100%',
              width: '224px',
              height: '56px',
              borderRadius: '12px',
              color: '#ffffff',
              fontWeight: 700,
              fontSize: { xs: '20px' },
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#ff4c6a',
                color: '#ffffff',
              },
            }}
            onClick={handleRollOver}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RollOverInformationModal;
