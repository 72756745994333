import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import CustomTextBox from '../../../components/common/customTextBox';
import CustomButton from '../../../components/common/customButton';
import { ReactComponent as EditIcon } from '../../../assets/images/Edit.svg';
import { colors } from '../../../theme/colors';
import { adminProfileAPI } from '../../../redux/adminProfileAPI';
import { setUserprofile, userAPI } from '../../../redux/userProfileAPI';
import { useGetUser } from '../../../context/userProfile';
import { baseURL } from '../../../api/url';
import { Images } from '../../../assets/images';

const AdminProfile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const { logInUserDetails, setLogInUserDetails } = useGetUser();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditIcon, setsEditicon] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    email: logInUserDetails?.p_contact_email || '',
    name: logInUserDetails?.p_customer_name || '',
  });

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
    'in',
    'co',
    'io',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(3, 'Name is too short, minimum length is 3 characters')
      .max(20, 'Name is too long, maximum length is 20 characters')
      .required('Name is required'),
    email: yup
      .string()
      .test('valid-p_contact_email', 'Invalid contact email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const p_login = parts[0];
            const domain = parts[1];

            if (
              p_login === '' ||
              p_login.startsWith('.') ||
              domain.startsWith('.') ||
              p_login.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              p_login.endsWith('.') ||
              p_login.includes('-') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
  });

  const handleEditClick = () => {
    imageRef.current?.click();
  };

  useEffect(() => {
    setSelectedImage(`${baseURL}public/profile/${logInUserDetails?.p_profilePicName}`);

    setProfileData({
      email: logInUserDetails?.p_contact_email || '',
      name: logInUserDetails?.p_login || '',
    });
  }, [logInUserDetails?.p_login, logInUserDetails?.p_profilePicName, logInUserDetails?.p_contact_email]);

  const handleSubmit = async () => {
    setIsEditing(true);
    try {
      if (isEditing) {
        setIsLoading(true);
        await validationSchema.validate(profileData, { abortEarly: false });
        const formData = new FormData();
        formData.append('id', logInUserDetails?.p_customer_id);
        formData.append('p_login', profileData?.name);
        formData.append('p_contact_email', profileData?.email);

        if (imageFile) {
          formData.append('file', imageFile);
        } else if (selectedImage) {
          try {
            const response = await fetch(selectedImage);
            const blob = await response.blob();

            const fileType = selectedImage.endsWith('.png') ? 'image/png' : 'image/jpeg';
            const imageFile = new File([blob], logInUserDetails?.p_profilePicName, { type: fileType });
            formData.append('file', imageFile);
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        } else {
          console.warn('No image selected.');
        }

        try {
          const adminData = await dispatch(adminProfileAPI(formData));
          if (adminData.payload?.status) {
            toast.success(adminData.payload?.message);
            const data = await dispatch(userAPI());
            await dispatch(setUserprofile(logInUserDetails?.payload));
            setLogInUserDetails(data?.payload);
          } else {
            toast.error(adminData?.payload?.response?.data?.message);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        } finally {
          setIsLoading(false);
          setIsEditing(false);
        }
      }
    } catch (error) {
      try {
        const errors = {};
        setIsLoading(false);
        if (error && error.inner) {
          error.inner.forEach((e) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
        } else {
          console.error('Unexpected error:', error);
        }
      } catch (error) {
        console.error('Error handling failed:', error);
      }
    }
  };

  const handleChange = (event, fieldName) => {
    const { value } = event.target;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [fieldName]: value,
    }));
    setFormErrors((prevError) => ({
      ...prevError,
      [fieldName]: '',
    }));
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  return (
    <Box>
      <Typography variant='h2' sx={{ mb: 3, color: colors?.neutral.dark, ml: 1 }}>
        Profile
      </Typography>
      <Grid container spacing={'24px'}>
        <Grid item lg={6} xs={12}>
          <Box
            className='kyc-updation-form'
            sx={{
              backgroundColor: theme.palette.neutral.background,
              padding: '24px',
              borderRadius: '16px',
              display: 'grid',
            }}
          >
            <Box className='profile-box' onMouseEnter={() => setsEditicon(true)}>
              <label htmlFor='profileImageInput' className='image-label'>
                {isEditing && isEditIcon && (
                  <input
                    type='file'
                    accept='.jpg, .jpeg, .png'
                    ref={imageRef}
                    id='profileImageInput'
                    onChange={handleImageSelect}
                    className='image-input'
                  />
                )}
                {selectedImage ? (
                  <img
                    src={imageFile ? URL.createObjectURL(imageFile) : selectedImage}
                    alt='profileImage'
                    className={isEditing && isEditIcon ? 'filter-image' : 'profile-image'}
                  />
                ) : (
                  <img
                    src={Images.Username_Icon}
                    alt='Profile'
                    height='42'
                    width='42'
                    className='profile-img'
                  />
                )}
              </label>
              {isEditing && isEditIcon && (
                <Box className='edit-icon-click' onClick={handleEditClick}>
                  <EditIcon />
                </Box>
              )}
            </Box>
            <Grid container spacing={'20px'}>
              <Grid item xs={12}>
                <Box sx={{ width: '100%', marginBottom: '20px' }}>
                  <Typography className='profile-label'>Name</Typography>
                  <CustomTextBox
                    disabled={!isEditing}
                    value={profileData?.name}
                    onChange={(event) => handleChange(event, 'name')}
                  />
                  {formErrors?.name && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErrors?.name}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ width: '100%', marginBottom: '20px' }}>
                  <Typography className='profile-label'>Email</Typography>
                  <CustomTextBox
                    type='text'
                    disabled={!isEditing}
                    value={profileData?.email}
                    onChange={(event) => handleChange(event, 'email')}
                  />
                  {formErrors?.email && (
                    <Typography sx={{ color: theme?.palette?.danger, width: '100%', marginTop: '5px' }}>
                      {formErrors?.email}
                    </Typography>
                  )}
                </Box>

                <Grid item xs={12}>
                  <Box sx={{ width: { sm: '320px', xs: '100%' } }}>
                    <CustomButton type='onclick' onClick={handleSubmit} disabled={loading}>
                      {isEditing ? 'Save' : 'Edit'}
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminProfile;
