import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const CustomersplansSubscribe = createAsyncThunk('customersplans-subscribe', async (data) => {
  try {
    const response = await API.post(`customersplans/subscribe`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  customersplans: {},
  isLoading: false,
  error: null,
  status: false,
};

const customersplanSubscribeSlice = createSlice({
  name: 'customersplans-subscribe',
  initialState,
  reducers: {
    setTranzilatokenDetails: (state, { payload }) => {
      state.customersplans = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CustomersplansSubscribe.fulfilled, (state, actions) => {
        return {
          ...state,
          customersplans: actions.payload,
          status: true,
          isLoading: false,
        };
      })
      .addCase(CustomersplansSubscribe.pending, (state) => {
        return {
          ...state,
          status: false,
          isLoading: true,
        };
      })
      .addCase(CustomersplansSubscribe.rejected, (state, actions) => {
        return {
          ...state,
          error: actions.payload,
          status: false,
          isLoading: false,
        };
      });
  },
});

export const { setTranzilatokenDetails } = customersplanSubscribeSlice.actions;
export default customersplanSubscribeSlice.reducer;
