import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const customerEditEmailAPI = createAsyncThunk('customer-editEmail', async (data, thunkAPI) => {
  try {
    const response = await API.put(`/users/customer/editEmail/${data?.id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState = {
  customerEditEmail: null,
  error: null,
  status: false,
  isLoading: false,
};

const customerEmailEditSlice = createSlice({
  name: 'customer-editEmail',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(customerEditEmailAPI.fulfilled, (state, action) => {
        state.customerEditEmail = action.payload;
        state.error = null;
        state.status = true;
        state.isLoading = false;
      })
      .addCase(customerEditEmailAPI.rejected, (state, action) => {
        state.error = action;
        state.status = false;
        state.isLoading = false;
      })
      .addCase(customerEditEmailAPI.pending, (state, action) => {
        state.error = action;
        state.status = true;
        state.isLoading = true;
      });
  },
});

export default customerEmailEditSlice.reducer;
