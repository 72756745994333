import React from 'react';
import { Box, Typography, Modal, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { baseURL } from '../../../api/url';

const DocumentModal = ({ closeModal, modalOpen, modalFile }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      className='modal-paper'
    >
      <Box className='modal-box'>
        <IconButton onClick={closeModal} className='modal-button' aria-label='close'>
          <CloseIcon />
        </IconButton>

        {modalFile && modalFile?.p_filename?.toLowerCase()?.endsWith('.pdf') ? (
          <iframe
            src={`${baseURL}${modalFile?.p_path}`}
            alt={modalFile?.p_filename}
            title={modalFile?.p_filename}
            className='invoice-paper'
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        ) : modalFile &&
          (modalFile?.p_filename?.toLowerCase().endsWith('.png') ||
            modalFile?.p_filename?.toLowerCase().endsWith('.jpg') ||
            modalFile?.p_filename?.toLowerCase().endsWith('.jpeg')) ? (
          <img
            src={`${baseURL}${modalFile?.p_path}`}
            alt={modalFile?.p_filename}
            className='invoice-paper'
            style={{ maxWidth: '100%', maxHeight: '', width: 'auto', height: '100%' }}
          />
        ) : (
          <Typography variant='body1'>Unsupported file type</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default DocumentModal;
