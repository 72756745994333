import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const accountInformationAPI = createAsyncThunk('billing-address', async (data) => {
  try {
    const response = await API.post(`/billing-address`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  accountInfo: {},
};

const accountInfoSlice = createSlice({
  name: 'billing-address',
  initialState,
  reducers: {
    setAccountBllinginfo: (state, { payload }) => {
      state.accountInfo = payload;
    },
  },
});

export const { setAccountBllinginfo } = accountInfoSlice.actions;
export default accountInfoSlice.reducer;
