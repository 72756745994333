import React, { useEffect, useState, useRef, forwardRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Modal,
  Radio,
  Select,
  MenuItem,
  Button,
  Tooltip,
  TextField,
} from '@mui/material';
import { colors } from '../../../theme/colors';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { ColorRing } from 'react-loader-spinner';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { ReactComponent as DropdownDownArrowIcon } from '../../../assets/images/dropdownDownArrow.svg';
import { ReactComponent as FilePlus } from '../../../assets/images/FilePlus.svg';
import { ReactComponent as WaringIcon } from '../../../assets/images/WarningCircle.svg';
import { ReactComponent as File } from '../../../assets/images/file.svg';
import CustomButton from '../../../components/common/customButton';
import { KycRegisterDetailAPI } from '../../../redux/kycFormAPI';
import { deleteKycFileAPI } from '../../../redux/deleteFileAPI';
import { getKycDetails } from '../../../redux/kycDetailGetAPI';
import Loding from '../../../components/common/Loding';
import { useGetUser } from '../../../context/userProfile';
import 'react-phone-input-2/lib/style.css';

const CustomStyledSelect = styled(Select)(({ theme, isDropdownOpen }) => ({
  '& .MuiSelect-select[aria-expanded="true"] ~ svg ': {
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
  },
  '& svg': {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: `translateY(-50%) rotate(${isDropdownOpen ? '360deg' : '0deg'}) !important`,
    transition: 'all .5s ease',
  },
  '& .MuiSelect-icon': {
    display: 'block',
  },
  '&.MuiOutlinedInput-root ': {
    borderRadius: '8px !important',
  },
  '& .MuiSelect-select  em': {
    fontSize: '18px !important',
  },
  '& .MuiSelect-select': {
    fontSize: '18px !important',
  },
}));
const customInputStyle = {
  width: '100%',
  borderRadius: '5px',
  border: '1px solid #ccc',
  color: '#333',
  fontSize: '18px',
  height: 'auto',
  minHeight: '56px',
  '& .react-tel-input': {
    '& .country-list': {
      width: '100% !important',
    },
  },
};
const Averagemonthlyusage = [
  { name: '0', code: '1-10 GB' },
  { name: '1', code: '10-50 GB' },
  { name: '2', code: '50-100 GB' },
  { name: '3', code: '100-500 GB' },
  { name: '4', code: '500 GB - 1 TB' },
  { name: '5', code: 'OVER 1 TB' },
];

const entityType = [
  {
    id: '1',
    entityName: '  Personal',
  },
  {
    id: '2',
    entityName: '  Company',
  },
];

const KycModal = forwardRef(({ setAccountInfoModal, accountInfoModal }, ref) => {
  const kycRef = useRef(null);
  const accessToken = JSON.parse(sessionStorage.getItem('access_token_proxy_session'));
  const { logInUserDetails } = useGetUser();
  const navigate = useNavigate();
  const [KycData, setKycData] = useState({});
  const dispatch = useDispatch();
  const [kycLoading, setIsKycLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    setIsKycLoading(true);
    try {
      setData(logInUserDetails);
      setIsKycLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [logInUserDetails]);
  const isValidWebsite = (website) => {
    const websiteRegex = /^(?:\S+:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return websiteRegex.test(website);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      entryType: '',
      linkedInURL: '',
      number: '',
      monthlyUsage: '',
      message: '',
      accountType: '0',
      licenseName: '',
      website: '',
      companyName: '',
      files: [],
    },
    initialTouched: {
      firstName: false,
      lastName: false,
      address: false,
      entryType: false,
      linkedInURL: false,
      number: false,
      monthlyUsage: false,
      message: false,
      accountType: false,
      licenseName: false,
      website: false,
      companyName: false,
      files: false,
    },

    onSubmit: async (values) => {
      setErrorMessage('');
      setIsLoading(true);
      const formData = new FormData();

      if (values?.accountType === '0' && values.companyName) {
        formData.append('p_firstName', values.firstName);
        formData.append('p_lastName', values?.lastName);
        formData.append('p_address', values?.address);
        formData.append('p_entity', String(values?.entryType));
        formData.append('p_linkdinUrl', values?.linkedInURL);
        formData.append('p_contactNumber', logInUserDetails?.p_phoneNumber);
        formData.append('p_usageType', String(values?.monthlyUsage));
        formData.append('p_description', values?.message);
        formData.append('p_accountType', values?.accountType);
        formData.append('p_companyName', values?.companyName);
        formData.append('p_website', values?.website);
        formData.append('p_countryCode', `+${selectedCountry}`);
        formData.append('p_userId', String(logInUserDetails?.p_customer_id));
        values?.files?.forEach((file) => {
          formData.append('files', file);
        });

        const data = await dispatch(KycRegisterDetailAPI(formData));

        if (data?.payload?.status === true) {
          toast.success(data?.payload?.message);
          setIsLoading(false);
          setAccountInfoModal(false);
          navigate('/thankyou-page');
          setIsLoading(false);
        } else {
          toast.error(data?.payload?.response?.data?.message);
          setIsLoading(false);
        }
      } else if (values?.accountType === '1') {
        formData.append('p_firstName', values.firstName);
        formData.append('p_lastName', values?.lastName);
        formData.append('p_address', values?.address);
        formData.append('p_entity', String(values?.entryType));
        formData.append('p_linkdinUrl', values?.linkedInURL);
        formData.append('p_contactNumber', logInUserDetails?.p_phoneNumber);
        formData.append('p_usageType', String(values?.monthlyUsage));
        formData.append('p_description', values?.message);
        formData.append('p_accountType', values?.accountType);
        formData.append('p_licenceName', values?.licenseName);
        formData.append('p_website', values?.website);
        formData.append('p_countryCode', `+${selectedCountry}`);
        formData.append('p_userId', String(logInUserDetails?.p_customer_id));
        values?.files?.forEach((file) => {
          formData.append('files', file);
        });

        const data = await dispatch(KycRegisterDetailAPI(formData));
        if (data?.payload?.status === true) {
          toast.success(data?.payload?.message);
          setIsLoading(false);
          setAccountInfoModal(false);
          navigate('/thankyou-page');
        } else {
          toast.error(data?.payload?.response?.data?.message);
          setAccountInfoModal(false);
          setIsLoading(false);
        }
      } else {
        setAccountInfoModal(false);
        return setIsLoading(false);
      }
    },
    validate: (values) => {
      let errors = {};
      const minMessageLength = 20;
      const maxMessageLength = 5000;
      const nameLength = 25;
      const minnameLength = 3;
      const comapnyMaxLength = 30;
      const maxAddressLength = 100;
      if (!values.firstName) {
        errors.firstName = 'Enter Your First Name';
      } else if (values.firstName.length > nameLength) {
        errors.firstName = `First name must be at most ${nameLength} characters`;
      } else if (values.firstName.length < minnameLength) {
        errors.firstName = `First name must be at least ${minnameLength} characters`;
      }

      if (!values.lastName) {
        errors.lastName = 'Enter Your Last Name';
      } else if (values.lastName.length > nameLength) {
        errors.lastName = `Last name must be at most ${nameLength} characters`;
      } else if (values.lastName.length < minnameLength) {
        errors.lastName = `First name must be at least ${minnameLength} characters`;
      }
      if (!values.address) {
        errors.address = 'Enter Your Address';
      } else if (values.address.length > maxAddressLength) {
        errors.address = `Address must be at most ${maxAddressLength} characters`;
      }
      if (values.entryType === '') {
        errors.entryType = 'Select your Entity type';
      }
      if (values.linkedInURL === '') {
        errors.linkedInURL = 'Enter your LinkedIn URL';
      } else {
        const linkedInRegex =
          /(ftp|http|https):\/\/?((www|\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if (!linkedInRegex.test(values.linkedInURL)) {
          errors.linkedInURL = 'Please enter a valid LinkedIn URL';
        }
      }

      if (values.monthlyUsage === '') {
        errors.monthlyUsage = 'Select Your Monthly Usage';
      }
      if (!values.message) {
        errors.message = 'Enter Your Message';
      } else if (values.message.length < minMessageLength) {
        errors.message = `Message must be at least ${minMessageLength} characters`;
      } else if (values.message.length > maxMessageLength) {
        errors.message = `Message must be at most ${maxMessageLength} characters`;
      }
      if (!values.accountType) {
        errors.accountType = 'Select your Account Type';
      }

      if (formik.values.accountType === '0' && !values.website) {
        errors.website = 'Enter your Website Name ';
      } else if (values.accountType === '0' && !isValidWebsite(values.website)) {
        errors.website = 'Enter a valid Website Name';
      }
      if (formik.values.accountType === '1' && values.files.length <= 0) {
        errors.files = 'Upload the files';
      } else if (formik.values.accountType === '1' && values.files.length > 5) {
        errors.files = 'Do not upload more than 5 files';
      }
      if (
        formik.values.files.map((item) => parseInt(item.size)).reduce((acc, size) => acc + size, 0) >=
        16777216
      ) {
        errors.files = 'Please upload the files below 5mb ';
      }
      if (formik.values.accountType === '0' && !values?.companyName) {
        errors.companyName = 'Enter your company Name ';
      } else if (formik.values.accountType === '0' && values?.companyName.length > comapnyMaxLength) {
        errors.companyName = `Company name must be at most ${comapnyMaxLength} characters`;
      } else if (formik.values.accountType === '0' && values?.companyName.length < minnameLength) {
        errors.companyName = `Company name must be at least ${minnameLength} characters`;
      }

      return errors;
    },
  });
  useEffect(() => {
    if (accessToken?.token) {
      if (logInUserDetails?.p_customer_id) {
        const fetchData = async () => {
          try {
            const idObject = {
              userId: logInUserDetails?.p_customer_id,
            };
            const data = await dispatch(getKycDetails(idObject));
            await setKycData(data?.payload?.data);
            setIsKycLoading(false);
          } catch (error) {
            console.error('Error fetching KycData:', error);
          }
        };
        fetchData();
      }
    }
  }, [logInUserDetails?.p_customer_id, dispatch]);

  useEffect(() => {
    try {
      const updatedValues = {
        firstName: data?.billingAddress?.p_firstName || KycData?.p_firstName,
        lastName: data?.billingAddress?.p_lastName || KycData?.p_lastName || '',
        address: data?.billingAddress?.p_address || KycData?.p_address || '',
        entryType:
          data?.billingAddress?.p_accountType === 0
            ? '2'
            : data?.billingAddress?.p_accountType === 1
            ? '1'
            : '' || KycData?.p_entity !== undefined
            ? String(KycData?.p_entity)
            : '',
        linkedInURL: KycData?.p_linkdinUrl || '',
        number: data?.p_countryCode || KycData?.p_countryCode || '',
        monthlyUsage: KycData?.p_usageType !== undefined ? String(KycData?.p_usageType) : '',
        message: KycData?.p_description || '',
        accountType: !!data?.billingAddress
          ? String(data?.billingAddress?.p_accountType)
          : KycData === null || KycData?.p_accountType === undefined
          ? '0'
          : String(KycData?.p_accountType),
        licenseName: KycData?.p_licenceName || '',
        website: KycData?.p_website || '',
        companyName: data?.billingAddress?.p_companyName || KycData?.p_companyName || '',
        files: KycData?.localFiles ? KycData?.localFiles : [],
      };
      formik.setValues(updatedValues);
      formik.setErrors({});
      setSelectedCountry(logInUserDetails?.p_countryCode);
    } catch (error) {
      setIsKycLoading(false);
    }
  }, [KycData, formik.setValues, data]);

  const fieldName = formik.values.accountType === '0' ? 'companyName' : 'licenseName';
  const handleDropdownToggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Modal
        ref={kycRef}
        open={accountInfoModal}
        onClose={() => {
          setAccountInfoModal(false);
        }}
        className="add-plan-modal"
        aria-labelledby="add-plan-modal"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: { sm: '30px', xs: '15px' },
          overflow: 'auto',
        }}
      >
        {kycLoading ? (
          <Loding />
        ) : (
          <Box
            sx={{
              '& form': {
                backgroundColor: colors.background.formBg,
                padding: '24px',
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                outline: 'none',
              },
            }}
          >
            <form action="" onSubmit={formik?.handleSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                }}
              >
                <Typography variant="h4" sx={{ mb: '20px', color: colors.neutral.dark }}>
                  Fill your KYC details
                </Typography>
                <Box onClick={() => setAccountInfoModal(false)} sx={{ cursor: 'pointer' }}>
                  <CloseIcon />
                </Box>
              </Box>
              <Grid container rowSpacing={{ md: '0', xs: '24px' }}>
                <Grid
                  item
                  md={7}
                  xs={12}
                  sx={{
                    paddingRight: { md: '24px' },
                    borderRight: { md: `1px solid ${colors.input.border}` },
                  }}
                >
                  <Box className="kyc-updation-form">
                    <Grid container spacing={'20px'}>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <TextField
                            value={formik.values.firstName}
                            name="firstName"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('firstName', true, false);
                              formik.setErrors({ ...formik.errors, firstName: '' });
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.errors.firstName && formik.touched.firstName)}
                            placeholder="First Name*"
                            sx={{
                              '& input': {
                                fontSize: '18px',
                                fontWeight: 400,
                              },
                            }}
                          />
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                              {formik.errors.firstName}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <TextField
                            id="name"
                            value={formik.values.lastName}
                            name="lastName"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('lastName', true, false);
                              formik.setErrors({ ...formik.errors, lastName: '' });
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.errors.lastName && formik.touched.lastName)}
                            placeholder="Last Name*"
                            sx={{
                              '& input': {
                                fontSize: '18px',
                                fontWeight: 400,
                              },
                            }}
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                              {formik.errors.lastName}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <TextField
                            id="name"
                            name="address"
                            value={formik.values.address}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('address', true, false);
                              formik.setErrors({ ...formik.errors, address: '' });
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.errors.address && formik.touched.address)}
                            placeholder="Address*"
                            sx={{
                              '& input': {
                                fontSize: '18px',
                                fontWeight: 400,
                              },
                            }}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                              {formik.errors.address}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="entryType"
                              disabled={
                                data?.billingAddress?.p_accountType === 0
                                  ? '2'
                                  : data?.billingAddress?.p_accountType === 1
                                  ? '1'
                                  : '' || KycData?.p_entity !== undefined
                                  ? String(KycData?.p_entity)
                                  : ''
                              }
                              value={formik.values.entryType}
                              onChange={(e) => {
                                formik.handleChange(e);

                                const selectedEntryType = e.target.value;
                                const correspondingAccountType = selectedEntryType === '2' ? '0' : '1';
                                formik.setValues({
                                  ...formik.values,
                                  entryType: selectedEntryType,
                                  accountType: correspondingAccountType,
                                });

                                formik.setFieldTouched('entryType', true, false);
                                formik.setErrors({ ...formik.errors, entryType: '' });
                              }}
                              onBlur={formik.handleBlur}
                              error={Boolean(formik.errors.entryType && formik.touched.entryType)}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!formik.values.entryType && (
                                <MenuItem
                                  value=""
                                  sx={{ color: colors.input.placeholder + ' !important' }}
                                  disabled
                                  onClick={handleDropdownToggle}
                                >
                                  <em>Entity type*</em>
                                </MenuItem>
                              )}

                              {entityType?.map((entity, index) => (
                                <MenuItem
                                  value={entity?.id}
                                  key={index}
                                  disabled={KycData?.p_entitye !== undefined}
                                  className="filter-box"
                                  anchorEl={anchorEl}
                                  open={isDropdownOpen}
                                  onClose={handleClose}
                                  anchororigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformorigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  {entity?.entityName}
                                </MenuItem>
                              ))}
                            </CustomStyledSelect>
                            {formik.touched.entryType && formik.errors.entryType ? (
                              <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                                {formik.errors.entryType}
                              </Typography>
                            ) : null}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={12} sm={6} xs={12}>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <CustomStyledSelect
                              name="monthlyUsage"
                              value={formik.values.monthlyUsage}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('monthlyUsage', true, false);
                                formik.setErrors({ ...formik.errors, monthlyUsage: '' });
                              }}
                              onBlur={formik.handleBlur}
                              error={Boolean(formik.errors.monthlyUsage && formik.touched.monthlyUsage)}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={(props) => (
                                <DropdownDownArrowIcon {...props} onClick={handleDropdownToggle} />
                              )}
                            >
                              {!formik.values.monthlyUsage && (
                                <MenuItem
                                  value=""
                                  sx={{ color: colors.neutral.light + ' !important' }}
                                  onClick={handleDropdownToggle}
                                  disabled
                                >
                                  <em style={{ color: formik.values.monthlyUsage ? 'inherit' : 'grey' }}>
                                    Average monthly usage*
                                  </em>
                                </MenuItem>
                              )}
                              {Averagemonthlyusage.map((item, index) => (
                                <MenuItem
                                  value={item.name}
                                  key={index}
                                  className="filter-box"
                                  anchorEl={anchorEl}
                                  open={isDropdownOpen}
                                  onClose={handleClose}
                                  anchororigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformorigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  {item.code}
                                </MenuItem>
                              ))}
                            </CustomStyledSelect>
                            {formik.touched.monthlyUsage && formik.errors.monthlyUsage ? (
                              <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                                {formik.errors.monthlyUsage}
                              </Typography>
                            ) : null}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <TextField
                            id="message"
                            name="linkedInURL"
                            value={formik.values.linkedInURL}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('linkedInURL', true, false);
                              formik.setErrors({ ...formik.errors, linkedInURL: '' });
                              if (e.target.value.length > 100) {
                                setErrorMessage('LinkedIn URL must be at most 100 characters');
                              } else {
                                setErrorMessage('');
                              }
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="LinkedIn URL"
                            sx={{
                              backgroundColor: colors.basics.white,
                              borderRadius: '8px',
                              '& input': {
                                fontSize: '18px',
                                fontWeight: 400,
                              },
                            }}
                            rows={4}
                            fullWidth
                          />
                          {formik.touched.linkedInURL && formik.errors.linkedInURL ? (
                            <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                              {formik.errors.linkedInURL}
                            </Typography>
                          ) : null}
                          {errorMessage ? (
                            <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                              {errorMessage}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: '100%',
                            '& .react-tel-input ': {
                              display: 'flex',
                              alignItems: 'stretch',
                              '& .selected-flag': {
                                width: '100%',
                                backgroundColor: colors.basics.white,
                              },
                              '& .country-list': {
                                width: '100%',
                              },
                              '& .flag-dropdown': {
                                position: 'unset',
                                order: '1',
                                width: '90px',
                                borderRadius: '8px !important',
                                marginRight: '12px',
                                overflow: 'hidden',
                                transition: 'all .5s ease',
                                backgroundColor: 'transparent',
                                borderColor:
                                  formik.touched.number &&
                                  formik.errors.number &&
                                  colors.basics.danger + '!important',
                                '&.open, &:hover, &:focus-within': {
                                  borderColor: colors.theme,
                                },
                              },
                            },
                            '& input.form-control ': {
                              order: '2',
                              borderRadius: '8px',
                              borderColor:
                                formik.touched.number &&
                                formik.errors.number &&
                                colors.basics.danger + '!important',
                              p: '14px',
                              transition: 'all .5s ease',
                              '&:hover': {
                                borderColor: colors.theme + '!important',
                              },
                            },
                            '& input:focus': {
                              borderColor: colors.theme + '!important',
                            },
                            '& .selected-flag': {
                              padding: '0px 0px 0px 25px !important',
                            },
                          }}
                        >
                          <PhoneInput
                            country={'in'}
                            value={`+${logInUserDetails?.p_countryCode || ''}${
                              logInUserDetails?.p_phoneNumber || ''
                            }`}
                            disabled
                            name="number"
                            inputStyle={customInputStyle}
                          />

                          {formik.touched.number && formik.errors.number ? (
                            <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                              {formik.errors.number}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box>
                          <TextField
                            id="message"
                            name="message"
                            multiline
                            sx={{
                              backgroundColor: colors.basics.white,
                              borderRadius: '8px',
                              '& textarea': {
                                fontSize: '18px',
                                fontWeight: 400,
                              },
                            }}
                            rows={4}
                            fullWidth
                            placeholder="Describe your use case shortly..."
                            value={formik.values.message}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('message', true, false);
                              formik.setErrors({ ...formik.errors, message: '' });
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.errors.message && formik.touched.message)}
                          />
                          {formik.touched.message && formik.errors.message ? (
                            <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                              {formik.errors.message}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item md={5} xs={12} sx={{ paddingLeft: { md: '24px !important' } }}>
                  <Box className="kyc-updation-form">
                    <Box
                      className="account-type"
                      sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: '24px' }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ mr: '24px', color: colors.neutral.dark, fontWeight: 400 }}
                      >
                        Account Type :
                      </Typography>
                      <Box>
                        <RadioGroup
                          row
                          name="accountType"
                          value={formik.values.accountType}
                          defaultValue="0"
                          onChange={(e) => {
                            formik.handleChange(e);
                            const selectedAccountType = e.target.value;
                            const correspondingEntryType = selectedAccountType === '0' ? '2' : '1';
                            formik.setValues({
                              ...formik.values,
                              entryType: correspondingEntryType,
                              accountType: selectedAccountType,
                            });

                            formik.setFieldTouched('accountType', true, false);
                            formik.setErrors({ ...formik.errors, accountType: '' });
                          }}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.errors.accountType && formik.touched.accountType)}
                          sx={{
                            '& .MuiRadio-root': {
                              padding: '0',
                              marginRight: '8px',
                            },
                            '& .MuiFormControlLabel-root ': {
                              ml: '0',
                            },
                            '& .MuiFormControlLabel-label': {
                              fontSize: '20px',
                            },
                            '& span svg[data-testid="RadioButtonCheckedIcon"] ': {
                              fill: colors.theme,
                            },
                            '& .Mui-checked input + span svg:last-child': {
                              fill: colors.theme,
                            },
                            '& .Mui-checked input + span svg:first-child': {
                              fill: colors.basics.normal,
                            },
                          }}
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Corporate"
                            disabled={
                              !!data?.billingAddress || KycData?.p_accountType !== undefined
                                ? String(KycData?.p_accountType)
                                : ''
                            }
                            sx={{
                              color: colors?.basics?.normal,
                              display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                              marginBottom: { xs: '5px', sm: '5px', lg: '0' },
                            }}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Freelancer"
                            disabled={
                              !!data?.billingAddress || KycData?.p_accountType !== undefined
                                ? String(KycData?.p_accountType)
                                : ''
                            }
                            sx={{
                              color: colors?.basics?.normal,
                              display: { xs: 'flex', sm: 'inline-flex', lg: 'inline-flex' },
                              marginBottom: { xs: '5px', sm: '5px', lg: '0' },
                            }}
                          />
                        </RadioGroup>
                      </Box>
                      {formik.touched.accountType && formik.errors.accountType ? (
                        <Typography variant="caption" sx={{ color: colors.basics.danger, width: '100%' }}>
                          {formik.errors.accountType}
                        </Typography>
                      ) : null}
                    </Box>

                    <Box className="licenseName-field" sx={{ mb: '24px' }}>
                      <TextField
                        name={fieldName}
                        value={
                          formik.values.accountType === '0'
                            ? formik.values.companyName
                            : formik.values.licenseName
                        }
                        onChange={(e) => {
                          const updatedField =
                            formik.values.accountType === '0' ? 'companyName' : 'licenseName';

                          formik.handleChange(e);
                          formik.setFieldTouched(updatedField, true, false);
                          formik.setErrors({ ...formik.errors, [updatedField]: '' });
                        }}
                        error={Boolean(
                          formik.values.accountType === '0' &&
                            formik.errors.companyName &&
                            formik.touched.companyName
                        )}
                        placeholder={
                          formik.values.accountType === '0'
                            ? 'Enter your company name*'
                            : 'Enter license name(If any)'
                        }
                        sx={{
                          '& input': {
                            fontSize: '18px',
                            fontWeight: 400,
                          },
                        }}
                      />
                      {formik.values.accountType === '0' &&
                        formik.touched.companyName &&
                        formik.errors.companyName && (
                          <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                            {formik.errors.companyName}
                          </Typography>
                        )}
                    </Box>

                    <Box className="website-field" sx={{ mb: '24px' }}>
                      <TextField
                        name="website"
                        value={formik.values.website}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldTouched('website', true, false);
                          formik.setErrors({ ...formik.errors, website: '' });
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.errors.website && formik.touched.website)}
                        placeholder={
                          formik.values.accountType === '0'
                            ? 'Enter company website*'
                            : 'Enter your website(If any)'
                        }
                        sx={{
                          '& input': {
                            fontSize: '18px',
                            fontWeight: 400,
                          },
                        }}
                      />
                      {formik.values.accountType === '0' &&
                        formik.touched.website &&
                        formik.errors.website && (
                          <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                            {formik.errors.website}
                          </Typography>
                        )}
                    </Box>

                    {formik.values.accountType === '1' && (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            marginTop: '10px',
                            color: colors?.neutral?.normal,
                            fontSize: '16px',
                            fontWeight: 400,
                          }}
                        >
                          <Typography sx={{ marginLeft: '10px', color: colors?.basics?.normal }}>
                            {formik.values.accountType === '1' && 'Upload an ID or Driving license.'}
                          </Typography>
                          <Tooltip title={"Please don't upload the documents more then 5MB "}>
                            <Button variant="text" sx={{ m: 0, p: 0, minWidth: 'auto' }}>
                              {' '}
                              <WaringIcon />
                            </Button>
                          </Tooltip>
                          {/* <WaringIcon /> */}
                        </Box>
                        {formik.values.files?.length > 0 ? (
                          <>
                            <Box
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '20px 24px',
                                padding: '20px 0',
                              }}
                            >
                              {Object.values(formik.values.files).map((file, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                    padding: '16px 12px',
                                    border: `1px solid ${colors.input.border}`,
                                    borderRadius: 2,
                                    backgroundColor: colors.basics.white,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      '& svg': {
                                        flexShrink: '0',
                                        width: '28px',
                                        height: 'auto',
                                      },
                                    }}
                                  >
                                    <File />
                                  </Box>
                                  <Box sx={{ paddingLeft: '12px' }}>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: '8px',
                                        fontWeight: '400',
                                        color: colors.basics.normal,
                                        wordBreak: 'break-word',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        fontSize="inherit"
                                        sx={{
                                          display: '-webkit-box',
                                          WebkitBoxOrient: 'vertical',
                                          WebkitLineClamp: 1,
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {file?.p_filename
                                          ? file?.p_filename
                                            ? file?.p_filename?.replace(/(\s*)\.pdf(?!.*\.pdf)/, '')
                                            : file?.p_mimetype?.replace(/(\s*)\.pdf(?!.*\.pdf)/, '')
                                          : file?.name
                                          ? file?.name?.replace(/(\s*)\.pdf(?!.*\.pdf)/, '')
                                          : file?.type?.replace(/(\s*)\.pdf(?!.*\.pdf)/, '')}
                                        {/* {file?.p_filename
                                            ? file?.p_filename?.replace(/(\s*)\.pdf(?!.*\.pdf)/, '')
                                            : file?.p_mimetype?.replace(/(\s*)\.pdf(?!.*\.pdf)/, '')} */}
                                      </Typography>
                                      <Typography variant="caption" fontSize="inherit" flexShrink={0}>
                                        {file?.type?.replace(/^application\//, '')}
                                      </Typography>
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: '8px',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        color: colors.input.placeholder,
                                      }}
                                      title={file ? file.size : file.p_size + 'Byte'}
                                    >
                                      {(file ? file.size : file.p_size / (1024 * 1024))?.toFixed(2)}mb
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      transform: 'translate(50%, -50%)',
                                      '& svg': {
                                        width: '24px',
                                        height: 'auto',
                                      },
                                    }}
                                  >
                                    <CloseIcon
                                      onClick={async () => {
                                        if (file.p_local_file_id) {
                                          const idObject = {
                                            deleteId: file?.p_local_file_id,
                                          };
                                          const deleteData = await dispatch(deleteKycFileAPI(idObject));

                                          if (deleteData?.payload?.status) {
                                            toast.success(deleteData?.payload?.message);
                                            const newFiles = formik.values.files.filter(
                                              (files) => files.p_local_file_id !== file?.p_local_file_id
                                            );
                                            formik.setFieldValue('files', newFiles);
                                          } else {
                                            toast.error('Error deleting file');
                                          }
                                        } else {
                                          const newFiles = [...formik.values.files];
                                          newFiles.splice(index, 1);
                                          formik.setFieldValue('files', newFiles);
                                        }
                                      }}
                                    />
                                  </Box>
                                </Box>
                              ))}
                            </Box>

                            <Box
                              sx={{
                                position: 'relative',
                                width: 'fit-content',
                                label: {
                                  border: `1px solid ${colors.theme}`,
                                  backgroundColor: colors.basics.white,
                                  minWidth: '100px',
                                  padding: '10px',
                                  textAlign: 'center',
                                  color: colors.theme,
                                  borderRadius: '8px',
                                  display: 'inline-block',
                                  fontWeight: '500',
                                  cursor: 'pointer',
                                },
                                '& input': {
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                  width: '100%',
                                  height: '100%',
                                  display: 'none',
                                  '&:disabled + label': {
                                    color: colors.input.placeholder,
                                    borderColor: colors.input.border,
                                    background: colors.background.formBg,
                                    cursor: 'no-drop',
                                  },
                                },
                              }}
                            >
                              <input
                                id="fileMore"
                                name="files"
                                type="file"
                                disabled={
                                  formik.values.files
                                    .map((item) => parseInt(item.size))
                                    .reduce((acc, size) => acc + size, 0) >= 5242880 ||
                                  formik.values.files.length >= 5
                                }
                                onChange={(event) => {
                                  const newFiles = [...formik.values.files, ...event.currentTarget.files];

                                  if (
                                    Array.from(event.currentTarget.files).some(
                                      (file) =>
                                        !file.type.includes('pdf') &&
                                        !file.type.includes('png') &&
                                        !file.type.includes('jpg') &&
                                        !file.type.includes('jpeg')
                                    )
                                  ) {
                                    toast.error('Please upload only PDF, PNG, JPG, or JPEG files.');
                                  } else {
                                    formik.setFieldValue('files', newFiles);
                                  }
                                }}
                                accept=".pdf, .png, .jpg, .jpeg"
                                multiple
                              />
                              <label htmlFor="fileMore">+ Add file</label>
                            </Box>
                          </>
                        ) : (
                          <Box>
                            <Box
                              className="file-field"
                              sx={{
                                border: `1px dashed ${
                                  formik.values.files.length === 0
                                    ? colors.input.border
                                    : colors.basics.danger
                                }`,
                                padding: '48px 50px',
                                backgroundColor: colors.basics.white,
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                position: 'relative',
                                '& input': {
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                  width: '100%',
                                  height: '100%',
                                  cursor: 'pointer',
                                  opacity: '0',
                                },
                                mt: '20px',
                              }}
                            >
                              <Box className="svg-file">
                                <FilePlus />
                              </Box>
                              <Typography variant="subtitle1" sx={{ color: colors?.basics?.normal }}>
                                Drag and drop file here
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: '14px',
                                  color: colors.input.placeholder,
                                }}
                              >
                                or
                              </Typography>
                              <Button variant="outlined">Browse file</Button>
                              <input
                                type="file"
                                name="files"
                                disabled={
                                  formik.values.files
                                    .map((item) => parseInt(item.size))
                                    .reduce((acc, size) => acc + size, 0) >= 16777216 && true
                                }
                                onChange={(event) => {
                                  const newFiles = [...formik.values.files, ...event.currentTarget.files];
                                  formik.setFieldValue('files', newFiles);
                                  if (formik.values.files.length < 1) {
                                  } else if (
                                    formik.values.files
                                      .map((item) => parseInt(item.size))
                                      .reduce((acc, size) => acc + size, 0) >= 16777216
                                  ) {
                                    formik.setErrors('files', 'Please upload the files below 5mb');
                                  } else {
                                    formik.setFieldValue('files', newFiles);
                                  }
                                  if (
                                    Array.from(event.currentTarget.files).some(
                                      (file) =>
                                        !file.type.includes('pdf') &&
                                        !file.type.includes('png') &&
                                        !file.type.includes('jpg') &&
                                        !file.type.includes('jpeg')
                                    )
                                  ) {
                                    toast.error('Please upload only PDF, PNG, JPG, or JPEG files.');
                                  } else {
                                    formik.setFieldValue('files', newFiles);
                                  }
                                }}
                                accept=".pdf, .png, .jpg, .jpeg"
                                multiple
                              />
                            </Box>
                          </Box>
                        )}
                        {formik.touched.files && formik.errors.files ? (
                          <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                            {formik.errors.files}
                          </Typography>
                        ) : null}
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'block',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '320px',
                  marginTop: '20px',
                  placeSelf: 'center',
                  width: '100%',
                }}
              >
                <CustomButton
                  type="submit"
                  boxShadow="unset"
                  disabled={
                    formik.values.files
                      .map((item) => parseInt(item.size))
                      .reduce((acc, size) => acc + size, 0) >= 5242880 ||
                    formik.values.files.length > 5 ||
                    isLoading
                  }
                >
                  Save
                  {isLoading && (
                    <ColorRing
                      visible={true}
                      height='30'
                      width='30'
                      ariaLabel='blocks-loading'
                      wrapperStyle={{}}
                      wrapperClass='blocks-wrapper'
                      colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                    />
                  )}
                </CustomButton>
                {formik.values.files
                  .map((item) => parseInt(item.size))
                  .reduce((acc, size) => acc + size, 0) >= 5242880 && (
                  <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                    File size should be below 5MB.
                  </Typography>
                )}
                {formik.values.files.length > 5 && (
                  <Typography variant="caption" sx={{ color: colors.basics.danger }}>
                    You can't upload more than 5 files.
                  </Typography>
                )}
              </Box>
            </form>
          </Box>
        )}
      </Modal>
    </>
  );
});

export default KycModal;
