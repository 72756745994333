import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getSignleFile = createAsyncThunk('local-files', async (data) => {
  try {
    const response = await API.get(`/local-files/${data.fileId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  localfiles: {},
};

const singleFileSlice = createSlice({
  name: 'localfiles',
  initialState,
  reducers: {
    setSingleFileDetails: (state, { payload }) => {
      state.localfiles = payload;
    },
  },
});

export const { setSingleFileDetails } = singleFileSlice.actions;
export default singleFileSlice.reducer;
