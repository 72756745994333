import React from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { colors } from '../../../theme/colors';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

const TermsAndConditions = ({ termsModal, setIsTermsModal }) => {
  return (
    <Modal
      open={termsModal}
      onClose={() => {
        setIsTermsModal(false);
      }}
      className='add-plan-modal'
      aria-labelledby='add-plan-modal'
      aria-describedby='modal-modal-description'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: { sm: '30px', xs: '15px' },
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.basics.white,
          padding: { md: '24px', xs: '15px' },
          borderRadius: '16px',
          maxWidth: { md: '688px', xs: '90%' },
          width: '100%',
          height: 'auto',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px',
            outline: '0',
          }}
        >
          <Typography variant='h2' color={colors?.neutral?.dark}>
            Terms & Conditions
          </Typography>
          <Box onClick={() => setIsTermsModal(false)} sx={{ cursor: 'pointer' }}>
            <CloseIcon />
          </Box>
        </Box>
        <Typography sx={{ color: '#7a7474', textAlign: 'justify' }}>
          Welcome to Prox-E (“we”, “us”, “our”, or “the Company”). The following terms and conditions
          (“Terms”) govern your access to and use of our website, products, and services (“Services”). By
          accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these
          Terms, you must not access or use our Services. Eligibility
        </Typography>
        <Typography sx={{ marginTop: '20px', color: '#7a7474', textAlign: 'justify' }}>
          You must be at least 18 years old to access or use our Services. By agreeing to these Terms, you
          represent and warrant that you are of legal age to form a binding contract with the Company.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>Account: </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          Registration To use our Services, you may be required to create an account. You agree to provide
          accurate and complete information during registration and to keep your account information up to
          date. You are responsible for maintaining the confidentiality of your account credentials and for
          all activities that occur under your account. You agree to notify us immediately if you suspect any
          unauthorized access or use of your account.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>License Grant: </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          Subject to your compliance with these Terms, we grant you a limited, non-exclusive,
          non-transferable, and revocable license to access and use our Services for your personal or
          commercial purposes.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>
          Prohibited Uses You may not use our Services to:{' '}
        </Typography>
        <Typography sx={{ margin: '5px', color: '#7a7474', textAlign: 'justify' }}>
          Engage in any illegal or unauthorized activities, including but not limited to hacking, spamming, or
          violating any applicable laws or regulations.
        </Typography>
        <Typography sx={{ margin: '5px', color: '#7a7474', textAlign: 'justify' }}>
          Access or use another user’s account without permission.
        </Typography>
        <Typography sx={{ margin: '5px', color: '#7a7474', textAlign: 'justify' }}>
          Share or distribute our Services in any way that violates these Terms or any applicable laws or
          regulations.
        </Typography>
        <Typography sx={{ margin: '5px', color: '#7a7474', textAlign: 'justify' }}>
          Interfere with or disrupt the operation of our Services or the servers or networks connected to
          them.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>
          Service Availability:{' '}
        </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          We do not guarantee that our Services will be available at all times or that they will be
          error-free. We reserve the right to modify, suspend, or discontinue any part of our Services at any
          time without notice.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>
          Fees and Payment:{' '}
        </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          By using our Services, you agree to pay any fees associated with your use of the Services. We
          reserve the right to change our pricing at any time. You are responsible for all taxes associated
          with your use of the Services.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>Refund Policy: </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          All sales are final, and no refunds will be issued for any reason, except as required by applicable
          law or as specified in our refund policy. If you believe you are eligible for a refund, please
          contact our support team.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>Refund Policy: </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          All sales are final, and no refunds will be issued for any reason, except as required by applicable
          law or as specified in our refund policy. If you believe you are eligible for a refund, please
          contact our support team.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>
          Third-Party Services:{' '}
        </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          Our Services may include links to third-party websites or services. We are not responsible for the
          content or practices of these third parties and do not endorse them. Your use of any third-party
          services is at your own risk and subject to the terms and conditions of those third parties.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>Termination: </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          We reserve the right to terminate your access to our Services at any time without notice if we
          believe you have violated these Terms or for any other reason at our sole discretion.
        </Typography>
        <Typography sx={{ marginTop: '20px', fontWeight: 500, fontSize: '18px' }}>
          Disclaimers and Limitation of Liability :{' '}
        </Typography>
        <Typography sx={{ margin: '10px', color: '#7a7474', textAlign: 'justify' }}>
          Our Services are provided “as is” and “as available” without any warranties, express or implied. We
          disclaim all warranties, including but not limited to the warranties of merchantability, fitness for
          a particular purpose, and non-infringement.
        </Typography>
        <Typography sx={{ marginTop: '20px', color: '#7a7474', textAlign: 'justify' }}>
          In no event shall Prox-E, its affiliates, officers, directors, employees, agents, or licensors be
          liable for any direct, indirect, incidental, special, consequential, or punitive damages, including
          but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from
          (i) your access to or use of or inability to access or use the Services; (ii) any conduct or content
          of any third party on the Services; (iii) any content obtained from the Services; and (iv)
          unauthorized access, use, or alteration of your transmissions or content, whether based on warranty,
          contract, tort (including negligence) or any other legal theory, whether or not we have been
          informed of the possibility of such damage, and even if a remedy set forth herein is found to have
          failed of its essential purpose. Indemnification{' '}
        </Typography>
      </Box>
    </Modal>
  );
};

export default TermsAndConditions;
