import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const countryStateAPI = createAsyncThunk('country-states', async (data) => {
  try {
    const response = await API.get(`/authentication/country-states?countryCode=${data?.countryCode}`);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  countryState: {},
};

const countryStateSlice = createSlice({
  name: 'countryState',
  initialState,
  reducers: {
    setCountryStateSlice: (state, { payload }) => {
      state.countryState = payload;
    },
  },
});

export const { setCountryStateSlice } = countryStateSlice.actions;
export default countryStateSlice.reducer;
