import React from 'react';
import { Box } from '@mui/material';
import AdminSidebar from './adminSidebar';
import AdminHeader from './adminHeader';

const AdminLayout = ({ children }) => {
  return (
    <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <AdminSidebar />
      <Box
        sx={{
          width: { xl: 'calc(100% - 315px)', md: 'calc(100% - 260px)', xs: '100%' },
          marginLeft: 'auto',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <AdminHeader />
        <Box sx={{ padding: { sm: '24px', xs: '20px' } }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default AdminLayout;
