import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const EmailconfirmationAPI = createAsyncThunk('emilconfirmation-sendEmail', async (data, thunkAPI) => {
  try {
    const response = await API.post(`/email-confirmation/send-email`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState = {
  emailConfirmation: null,
  error: null,
  status: false,
  emailIsLoading: false,
};

const emailVerificationSlice = createSlice({
  name: 'emilconfirmation',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(EmailconfirmationAPI.fulfilled, (state, action) => {
        state.emailConfirmation = action.payload;
        state.error = null;
        state.status = true;
        state.emailIsLoading = false;
      })
      .addCase(EmailconfirmationAPI.rejected, (state, action) => {
        state.error = action;
        state.status = false;
        state.emailIsLoading = false;
      })
      .addCase(EmailconfirmationAPI.pending, (state, action) => {
        state.error = action;
        state.status = true;
        state.emailIsLoading = true;
      });
  },
});

// export const { setUser, setError } = userSlice.actions;
export default emailVerificationSlice.reducer;
