import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';

export const ProductProxyPlanAPI = createAsyncThunk('proxy-plan', async (paymentdata) => {
  try {
    const response = await API.get(`/product-type/product-types/${paymentdata?.p_customer_id}`);

    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  proxyPlanInfo: {},
};

const PoxyPlanInfoSlice = createSlice({
  name: 'proxy-plan',
  initialState,
  reducers: {
    setProxyPlanInfo: (state, { payload }) => {
      state.proxyPlanInfo = payload;
    },
  },
});

export const { setProxyPlanInfo } = PoxyPlanInfoSlice.actions;
export default PoxyPlanInfoSlice.reducer;
