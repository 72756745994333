import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../src/api/services";

export const getTraficUsageAPI = createAsyncThunk(
  "traffic-usage",
  async (data) => {
    try {
      const response = await API.get(`traffic/customer`, { params: data });
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getAdminTraficUsageAPI = createAsyncThunk(
  "admin/traffic-usage",
  async (data) => {
    try {
      const response = await API.get(`traffic/admin?`, { params: data });
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  getTraficUsageDetail: {},
  isLoading: false,
  adminTraffic: [],
  adminFilterCustomers: []
};

const getTraficUsageSlice = createSlice({
  name: "traffic-usage",
  initialState,
  reducers: {
    setTraficUsageDetails: (state, { payload }) => {
      state.getTraficUsageDetail = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminTraficUsageAPI.fulfilled, (state, actions) => {
        return {
          ...state,
          adminTraffic: actions.payload,
          adminFilterCustomers: actions.payload.customers,
          isLoading: false,
        };
      })
      .addCase(getAdminTraficUsageAPI.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getAdminTraficUsageAPI.rejected, (state, actions) => {
        return {
          ...state,
          adminTraffic: [],
          adminFilterCustomers: [],
          isLoading: false,
        };
      });
  },
});

export const { setTraficUsageDetails } = getTraficUsageSlice.actions;
export default getTraficUsageSlice.reducer;
