import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../theme/colors';

const AdminDashboardActivity = () => {
  return (
    <>
      <Box
        sx={{
          padding: '24px',
          height: '100%',
        }}
      >
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: 600, color: colors.neutral.dark }}>
            Account Activity
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: '600px',
            '& div[role="tabpanel"] div': {
              padding: 0,
            },
          }}
        >
          <Box>
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  mt: 2,
                  fontSize: '16px',
                  fontWeight: 400,
                  color: colors.basics.normal,
                  '& ul': { gap: '12px', display: 'grid' },
                }}
              >
                <ul>
                  <li>Last login 05-12-2023 07:45:00pm</li>
                  <li>Successfully added new management plan.</li>
                  <li>Successfully edited customer details.</li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminDashboardActivity;
