import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const getUserRequestAPI = createAsyncThunk('email-confirmation/send-email/opinion', async (data) => {
  try {
    const response = await API.post(`/email-confirmation/send-email/opinion`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  userRequest: {},
};

const userRequestSlice = createSlice({
  name: 'userRequest',
  initialState,
  reducers: {
    setUserRequest: (state, { payload }) => {
      state.userRequest = payload;
    },
  },
});

export const { setUserRequest } = userRequestSlice.actions;
export default userRequestSlice.reducer;
