import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, TableCell, TableRow } from '@mui/material';
import { colors } from '../../../theme/colors';
import { ReactComponent as PaySlip } from '../../../assets/images/PaySlip.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/Edit.svg';
import { formatDate } from '../../../components/common/dateFormat';
import InvoiceModal from './invoiceModal';
import { useDispatch } from 'react-redux';
import { setPaymentList } from '../../../redux/paymentListAPI';
import ActivePlanconfirmationModal from '../../user/proxyPlans/activePlanconfirmationModal';

const CustomerDetailsRow = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [activePlanconfirmationModal, setActivePlanconfirmationModal] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': {
            border: 0,
          },
          '& td': {
            padding: '12px 16px 12px 20px',
            whiteSpace: 'nowrap',
          },
          '& a': {
            border: `1px solid ${colors.theme}`,
            padding: '7px',
            display: 'inline-flex',
            verticalAlign: 'middle',
            width: 'fit-content',
            alignItems: 'center',
            minWidth: 'auto',
            borderRadius: '4px',
            transition: 'all .5s ease',
            '& svg path': {
              transition: 'all .5s ease',
            },
            '&:hover': {
              backgroundColor: colors.theme,
            },
            '&:hover svg path': {
              fill: '#fff',
            },
          },
        }}
      >
        <TableCell className='recent-table-tr'>
          {item.p_login ? item.p_login.charAt(0).toUpperCase() + item.p_login.slice(1) : '-'}
        </TableCell>
        <TableCell className='recent-table-tr'>{item.ownerName ? item.ownerName : '-'}</TableCell>
        <TableCell className='recent-table-tr'>
          {item.customerSource ? item.customerSource : 'Inbound'}
        </TableCell>
        <TableCell className='recent-table-tr'>{formatDate(item.registeredDate)}</TableCell>
        <TableCell className='recent-table-tr'>{item?.planStatus === '1' ? 'Yes' : 'No'}</TableCell>
        <TableCell className='recent-table-tr'>{item?.isKyc}</TableCell>
        <TableCell
          sx={{
            fontWeight: '400',
            fontSize: '16px',
            color: colors.basics.normal,
            width: '1%',
            whiteSpace: 'nowrap',
          }}
        >
          <Button
            variant='grayOutlined'
            type='button'
            sx={{
              mr: '8px',
              textTransform: 'capitalize',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '27px',
            }}
            onClick={async () => {
              await dispatch(setPaymentList(item));
              navigate(`/admin/customer-details/customer-plan/${item.customerId}`);
            }}
          >
            Plans
          </Button>
          <Button
            variant='grayOutlined'
            type='button'
            sx={{
              mr: '24px',
              textTransform: 'capitalize',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '27px',
            }}
            onClick={async () => {
              if (item?.planStatus === '1') {
                setActivePlanconfirmationModal(true);
              } else {
                await dispatch(setPaymentList(item));
                navigate('/admin/buyplan');
              }
            }}
          >
            Add Plan
          </Button>
          <Button
            variant='grayOutlined'
            type='button'
            disabled={item?.invoiceLink === null}
            sx={{
              mr: '8px',
              ml: '15px',
              padding: '7px',
              minWidth: 'auto',
              borderColor: item?.invoiceLink === null ? colors?.basics?.normal : colors?.basics?.normal,
              color: (theme) => (item?.invoiceLink === null ? theme.palette.text.disabled : 'inherit'),
              '& svg path': { transition: 'all .5s ease' },
              '&:hover svg path': {
                fill: item?.invoiceLink === null ? colors?.basics?.normal : colors.theme,
              },
            }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <PaySlip />
          </Button>
          <Link to={`/admin/customer-details/edit/${item.customerId}`}>
            <EditIcon />
          </Link>
        </TableCell>
      </TableRow>
      <InvoiceModal closeModal={closeModal} modalOpen={modalOpen} modalFile={item} />
      <ActivePlanconfirmationModal
        activePlanconfirmationModal={activePlanconfirmationModal}
        setActivePlanconfirmationModal={setActivePlanconfirmationModal}
        customerDetail={item}
      />
    </>
  );
};

export default CustomerDetailsRow;
