import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../src/api/services';

export const editCustomerPlanAPI = createAsyncThunk('edit-customer-plan', async (data) => {
  try {
    const response = await API.put(`/users/edit/customer/plan/${data?.billingId}`, {
      startDate: data?.startDate,
      endDate: data?.endDate,
      status: data?.status,
    });
    return response?.data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  editCustomerPlan: {},
};

const editCustomerPlanSlice = createSlice({
  name: 'edit-customer-plan',
  initialState,
  reducers: {
    setEditCustomerPlanDetails: (state, { payload }) => {
      state.editCustomerPlan = payload;
    },
  },
});

export const { setEditCustomerPlanDetails } = editCustomerPlanSlice.actions;
export default editCustomerPlanSlice.reducer;
