import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useGetUser } from '../../context/userProfile';

const Page404 = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { logInUserDetails } = useGetUser();
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='h1' gutterBottom>
          404
        </Typography>
        <Typography variant='h4' gutterBottom>
          Oops! Page not found.
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          The page you are looking for might have been removed or doesn't exist.
        </Typography>
        <Button
          sx={{
            backgroundColor: theme.palette.accent2,
            color: theme.palette.neutral.dark,
            width: 'fit-content',
          }}
          onClick={() => {
            if (logInUserDetails?.p_web_access === 0) {
              navigate('/admin/dashboard');
            } else {
              navigate('/');
            }
          }}
        >
          Go Home
        </Button>
      </Box>
    </Box>
  );
};

export default Page404;
